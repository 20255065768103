import React, { Component } from "react";
import { Container, Card, CardBody, Col, FormGroup, Label, Button, Input, Row } from "reactstrap";
import { Link, Redirect } from "react-router-dom";
import { Formik } from "formik";
import { validateFormNegotiation } from "../../helpers/FormValidators";
import BetterAlerts from "../../components/BetterAlerts";
import Breadcrumbs from "../../components/Breadcrumb";
import { convertToBool, formatBRL, formatBRLInput, formatDate } from './helpers';
import onSubmit from "./helpers/on-submit";
import { ListDuration } from './components/list-duration.component';
import { UserComponent, UserFilterComponent } from "./components/user.component";
import { AnnouncementComponentNegotiation } from "./components/announcement.component";
import { get } from "../../crud/plans.crud";
import { get as getUser } from "../../crud/users.crud";
import { formatBRLNoSign, formatTelefone, limparMoedaFunction } from "../../helpers/Utils";
import { FormHelperText, InputAdornment, TextField } from "@material-ui/core";
import Swal from 'sweetalert2';
import moment from "moment";
import { approveRefuseCredit, get as getProposition, removeFromParty, update as updateProposition } from "../../crud/propositions.crud";
import { faCheck, faSync, faTimes, faUser } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getAppliedByAnnouncement } from '../../crud/coupons.crud';
import BetterCircularProgress from '../../components/BetterCircularProgress';
import { getComissioningParticipants } from "../../crud/announcements.crud";

class NegotiationEdit extends Component {
	state = {
		timeout: null,
		submitted: false,
		loading: true,
		success: false,
		errors: [],
		load_from_announcement: false,
		commissioningParticipants: [],
		data: {
			coupon: {},
			landlord: {},
			tenant: {},
			start_date: formatDate({ format: 'YYYY-MM-DD' }),
			duration: 0,
			security_deposit_months: 0,
			announcement: {},
			tenant_parties: [],
			plan: {},
			commissioning: false,
		},
		listStatusUser: [
			// 'Proprietário',
			'Inquilino',
			// 'Comissionado',
			// 'Responsável',
			// 'Administrador'
		],
		plan_list: [
			{ key: 'WARRANTY', label: 'Garantia de pagamento' },
			{ key: 'WARRANTY_GUARANTEE_DAMAGE', label: 'Garantia de pagamento com danos' },
		],
		list: [],
		usersToRemove: []
	};

	onSubmit = async (values) => {
		if (moment().isAfter(values.start_date, 'day')) {
			return Swal.fire('Ops', 'A data do início do contrato não pode ser inferior ao dia atual.', 'error');
		}

		if (!values.announcement?.id) {
			values.announcement = this.state.data.announcement;
		}

		values.id = this.props.match.params.id;
		values.status = this.state.data.negotiation.status;
		values.tenant = values.users[0];
		values.plan = this.state.list.find(item => item.name?.trim() === values?.war_type?.trim())

		values.announcement.values = {
			...values.announcement?.values,
			rent: limparMoedaFunction(values.val_rent),
			iptu: limparMoedaFunction(values.val_iptu),
			condo: limparMoedaFunction(values.val_condo)
		};

		values.announcement.tenant_pays = {
			...values.announcement.tenant_pays,
			iptu: convertToBool(values?.tp_iptu),
			condo: convertToBool(values?.tp_condo),
			other: false
		};
		console.log(this.state.data.negotiation);

		if (this.state?.data?.negotiation?.parties?.length > 0 && this.state?.usersToRemove.length > 0) {

			for (const userToRemove of this.state.usersToRemove) {
				const alreadyExistsOnProposition = this.state?.data?.negotiation?.parties.find(obj => obj.user_id === userToRemove);

				if (alreadyExistsOnProposition) {
					try {
						await removeFromParty(this.props.match.params.id, userToRemove).then(res => {

						})
					} catch (err) {
						console.log(`Err removing user from party: ${err}`);
					}
				}
			}
		}

		this.setState({ loading: true, errors: [] });
		onSubmit(values, true).then((res) => {
			this.setState({ submitted: true, success: !(Boolean(res.data.error)), errors: res.data.error ? [res.data.error] : [] });
		}).catch((err) => {
			if (Array.isArray(err)) {
				this.setState({ errors: [...this.state.errors, ...err] });
			} else {
				this.setState({ errors: [...this.state.errors, err] });
			}
		}).finally(() => {
			this.setState({ loading: false });
		});
	};

	componentDidMount = () => {
		this.getData();

		get().then(({ data }) => this.setState({ list: data.data }));
	};

	getData = async () => {
		try {
			const { data } = await getProposition(this.props.match.params.id);

			let appliedCoupon = null;

			getAppliedByAnnouncement(data.data.property_announcement_id).catch(err => {
				alert('An error occurred, please contact the support.');
			}).then(res => {
				if (res?.status == 200) {
					if (res.data) {
						appliedCoupon = res.data;
						this.setState({ coupon: res.data });
					}
				}
			});

			if (!appliedCoupon && data.data.contract?.coupon) {
				this.setState({ coupon: data.data.contract?.coupon });
			}

			await getComissioningParticipants({ announcement_id: data.data.property_announcement_id }).catch(err => { }).then(async res => {
				if (res?.status == 200) {
					if (res.data.data) {
						let responseComissioning = [];

						if (res.data?.total > 0) {
							for (const usuario of res.data.data) {
								if (usuario?.user?.id !== data?.data?.landlord_id) {
									await getUser(usuario.user.id).catch(() => { }).then((res) => {
										usuario.user = { ...usuario.user, ...res.data.data, typeUser: usuario.user_role };
									});

									responseComissioning.push(usuario)
								} else {
									this.setState({ data: { landlord: { ...data.data.landlord, typeUser: usuario.user_role } } })
								}
							}
						} else {
							this.setState({ data: { landlord: { ...data.data.landlord, typeUser: 'landlord' } } })
						}

						this.setState({ commissioningParticipants: responseComissioning })
					}
				}
			});

			this.setState({
				data: {
					...this.state.data,
					announcement: data.data.property,
					negotiation: data.data,
					plan: data.data.plan,
				}
			})
		} catch {

		} finally {
			this.setState({ loading: false })
		}
	};

	selectInquilino = (e, value, setFieldValue, handleChange, values) => {
		const users = values?.users;

		if (value.type_account === "PJ") {
			return Swal.fire('Ops', 'Você não pode selecionar uma empresa como inquilino.', 'error');
		} else {
			value.typeUser = 'Inquilino';
			setFieldValue(`users[${users?.length}]`, value);
		}

		handleChange(e);
	};

	onChangeAnuncio = async (setFieldValue, handleChange, e, value) => {
		setFieldValue("announcement", value);

		setFieldValue("war_type", value.warranty.type);

		setFieldValue("val_rent", formatBRL(value.values.rent));
		setFieldValue("val_iptu", typeof value.values.iptu == 'string' ? formatBRL(value.values?.iptu) : formatBRL(String(value.values?.iptu)));
		setFieldValue("val_condo", typeof value.values.condo == 'string' ? formatBRL(value.values?.condo) : formatBRL(String(value.values?.condo)));

		setFieldValue("tp_condo", value.tenant_pays.condo);
		setFieldValue("tp_iptu", value.tenant_pays.iptu);

		let data = this.state.data
		data.plan = this.state.list.find(item => item.name?.trim() === value?.warranty?.type?.trim())
		data.announcement = value;

		await getUser(value.user_id).then((res) => {
			data.landlord = res.data.data;

			setFieldValue("landlord", res.data.data);
		}).catch((err) => { }).finally(() => {
			this.setState({ ...this.state, data: data })

			handleChange(e);
		});

		await getComissioningParticipants({ announcement_id: data.announcement.id }).catch(err => { }).then(async res => {
			if (res?.status == 200) {
				if (res.data.data) {
					let responseComissioning = [];

					for (const usuario of res.data.data) {
						if (usuario.user.id != value.user_id) {
							await getUser(usuario.user.id).catch(() => { }).then((res) => {
								usuario.user = { ...usuario.user, ...res.data.data, typeUser: usuario.user_role };
							});

							responseComissioning.push(usuario)
						}
					}

					this.setState({ commissioningParticipants: responseComissioning })
				}
			}
		});
	}

	submitStatusUser = (setFieldValue, index, values, valorSelect) => {
		const tenant = values?.users[index];
		tenant.typeUser = valorSelect

		setFieldValue(`users[${index}]`, tenant);
		setFieldValue(`userSearch`, '');
	}

	calcTotalFinal = (values) => {
		let valorAluguel = limparMoedaFunction(values.val_rent);
		let valorIptu = limparMoedaFunction(values.val_iptu);
		let valorCondo = limparMoedaFunction(values.val_condo)

		let valorTotal = (Number(valorAluguel) + Number(valorIptu) + Number(valorCondo));

		return formatBRLNoSign(valorTotal);
	};

	getInitialValues = () => {
		const negotiation = this.state.data.negotiation;

		const tenants = [negotiation?.tenant]

		if (negotiation?.parties?.length > 0) {
			for (const user of negotiation.parties) {
				tenants.push(user?.userParty)
			}
		}

		return {
			users: tenants,
			userSearch: '',
			war_type: negotiation?.plan?.name,

			val_rent: formatBRL(negotiation?.details?.values?.rent),
			val_iptu: typeof negotiation?.details?.values?.iptu == 'string' ? formatBRL(negotiation?.details?.values?.iptu) : formatBRL(String(negotiation?.details?.values?.iptu)),
			val_condo: typeof negotiation?.details?.values?.condo == 'string' ? formatBRL(negotiation?.details?.values?.condo) : formatBRL(String(negotiation?.details?.values?.condo)),

			tp_condo: negotiation?.details?.tenant_pays?.condo,
			tp_iptu: negotiation?.details?.tenant_pays?.iptu,
			tp_other: null,

			percentage: negotiation?.plan.percentage ?? '',
			start_date: negotiation?.details?.start_date === 'Data inválida' ? moment().format('YYYY-MM-DD') : moment(negotiation?.details?.start_date, 'MM/DD/YYYY').format('YYYY-MM-DD'),
			duration: negotiation?.details?.duration,
		};
	}

	deleteUser = (setFieldValue, values, index) => {
		const userToRemove = values.users[index];

		let users = values?.users;
		users.splice(index, 1);

		setFieldValue('users', users);

		const oldUsersToRemove = this.state.usersToRemove;
		oldUsersToRemove.push(userToRemove.id)
		this.setState({ usersToRemove: oldUsersToRemove })
	}


	render() {
		const property = this.state?.data.announcement;
		const address = property?.address;
		const landlord = this.state?.data?.landlord;

		var tipoNegociacao = new URLSearchParams(document.location.search);
		tipoNegociacao = tipoNegociacao.get("typeNegociacao");


		const UserBox = ({ usuario, proprietario, index, setFieldValue, values, disableRemove }) => {
			return (
				<div className='boxInfos-container boxInfos-container-unico boxInfoUser boxUsuarioNegociacao mt-0'>
					<div className='boxUser boxAnuncioNew'>
						<div className='headerUser'>
							<div className='container-imovel-imagem'>
								<div className="boxUserTableUsers" style={{ alignSelf: 'end' }}>
									{usuario?.profile_image?.medium?.url ?
										<div className='userPhoto' style={{ backgroundImage: `url(${usuario?.profile_image?.medium?.url})`, marginRight: 0 }} />
										:
										<div className='userPhotoNone' style={{ marginRight: 0 }}>
											<FontAwesomeIcon icon={faUser} />
										</div>
									}
								</div>

								<div className='tagsAssets groupAlign' style={{ flex: 'auto' }}>
									<h3 onClick={() => window.open(`/users/show/${usuario?.id}`, '_blank')} style={{ cursor: 'pointer', color: '#4AA3FF', marginBottom: 0 }}>
										{usuario?.name}
									</h3>

									<div className="informationsDatas">
										<div className='boxInformation'>
											<p>{formatTelefone(usuario?.phone ?? '0')}</p>
										</div>

										<div className='boxInformation'>
											<p>{usuario?.email}</p>
										</div>
									</div>
								</div>
							</div>

							<div className="d-flex" style={{ gap: 24 }}>
								<select style={{ background: "#FFF", height: "40px", width: 160, marginLeft: 'auto' }} onChange={e => this.submitStatusUser(setFieldValue, index, values, e.target.value)} disabled={proprietario} value={usuario?.typeUser} className="custom-select">
									{proprietario && (
										<>
											<option key="admin" value='admin'>Administrador</option>
											<option key="commissioned" value='commissioned' >Comissionado</option>
											<option key="landlord" value='landlord' >Dono</option>
											<option key="Proprietário" value='Proprietário' >Proprietário</option>
										</>
									)}

									{this.state.listStatusUser.map((status) => <option key={status} value={status}>{status}</option>)}
								</select>

								{(!proprietario && !disableRemove) && (
									<div className="deleteImovel" onClick={() => this.deleteUser(setFieldValue, values, index)}>
										<FontAwesomeIcon icon={faTimes} />
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
			)
		}

		return (
			<React.Fragment>
				<BetterCircularProgress loading={this.state.loading}>

					<div className="page-content container-page userShow userNew userIndex anuncio negociacao">
						<Container fluid>
							<BetterAlerts errors={this.state.errors} />

							{this.state.success ?
								<Redirect to={{ pathname: `/negotiation/show/${this.props.match.params.id}?typeNegociacao=${tipoNegociacao}`, state: { success: ['Negociação editada com sucesso.'] } }} />
								: null
							}

							<Formik initialValues={this.getInitialValues()} validate={(values) => validateFormNegotiation(values, "update")} onSubmit={this.onSubmit}>
								{({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) =>
									<form noValidate={true} autoComplete="off" onSubmit={handleSubmit} className="form">
										<div style={{ width: '736px' }}>
											<div className='header' style={{ marginBottom: 0 }}>
												<h1>Editar negociação</h1>

												<Breadcrumbs breadcrumbItems={[{ link: '/negotiation/index', title: 'Negociações' }, { link: `/negotiation/edit/${this.props.match.params.id}?typeNegociacao=${tipoNegociacao}`, title: `ID ${this.props.match.params.id}` }]} />
											</div>

											<div className='box'>
												<h5>IMÓVEL</h5>

												<div className='formulario'>
													<Col md={12} className="p-0 m-0">
														<Label>Buscar imóvel</Label>

														<AnnouncementComponentNegotiation name="announcement" onBlur={handleBlur} value={values.announcement}
															onChange={(e, value) => this.onChangeAnuncio(setFieldValue, handleChange, e, value)} />
													</Col>

													{property?.id && (
														<div className='boxInfos-container boxInfos-container-unico boxInfoUser' style={{ marginTop: 0 }}>
															<div className='boxUser boxAnuncioNew'>
																<div className='headerUser'>
																	<div className='container-imovel-imagem'>
																		{property?.images?.[0]?.medium?.url ?
																			<div className='userPhoto' style={{ backgroundImage: `url(${property?.images?.[0]?.medium?.url})`, flex: 'none' }} />
																			:
																			<div className='userPhoto noPhotoAnnouncement' style={{ flex: 'none' }} />
																		}

																		<div className="boxUserTableUsers" style={{ alignSelf: 'end' }}>
																			{landlord?.profile_image?.medium?.url ?
																				<div className='userPhoto' style={{ backgroundImage: `url(${landlord?.profile_image?.medium?.url})` }} />
																				:
																				<div className='userPhotoNone'>
																					<FontAwesomeIcon icon={faUser} />
																				</div>
																			}
																		</div>

																		<div className='tagsAssets groupAlign' style={{ flex: 'auto' }}>
																			<h3 onClick={() => window.open(`/announcements/show/${property?.id}`, '_blank')} style={{ cursor: 'pointer', color: '#4AA3FF' }}>
																				{`${address?.street} ${address?.number}, ${address?.complement ?? ''} ${address?.neighbourhood ? `, ${address?.neighbourhood}` : ''} - ${address?.city}/${address?.state}`}
																			</h3>

																			<div className='tagGroup'>
																				<div className='tag' style={{ backgroundColor: property?.is_published ? '#1CBB8C' : '#5664D2' }}>
																					{property?.is_published ? 'Anunciado' : 'Não anunciado'}
																				</div>
																			</div>

																			<div className="informationsDatas">
																				<div className='boxInformation'>
																					<p>Criada em <b>{moment(property?.created_at).format('DD/MM/YY HH:mm:ss')}</b></p>
																				</div>

																				<div className='boxInformation'>
																					<p>Atualizado em <b>{moment(property?.updated_at ?? property?.created_at).format('DD/MM/YY HH:mm:ss')}</b></p>
																				</div>
																			</div>
																		</div>
																	</div>

																	<div className="deleteImovel" onClick={() => { this.setState({ data: { property: null } }); setFieldValue('announcement', null) }}>
																		<FontAwesomeIcon icon={faTimes} />
																	</div>
																</div>
															</div>
														</div>
													)}
												</div>
											</div>

											<div className='box'>
												<h5>USUÁRIOS</h5>

												<div className='formulario'>
													<Col md={12} className="p-0 m-0">
														<Label>Adicione usuários</Label>

														<UserFilterComponent placeholder="Busque por nome, email, telefone, ID ou documento" style={{ width: 'auto' }} name="userSearch" onBlur={handleBlur} onChange={(e, value) => this.selectInquilino(e, value, setFieldValue, handleChange, values)} value={values.userSearch} />
													</Col>

													{landlord?.id && (
														<UserBox usuario={landlord} proprietario />
													)}

													{this.state.commissioningParticipants.length > 0 && this.state.commissioningParticipants?.map((comissionado, index) => (
														<UserBox usuario={comissionado.user} index={index} proprietario />
													))}

													{values.users?.length > 0 && values.users?.map((tenant, index) => (
														<UserBox usuario={tenant} index={index} setFieldValue={setFieldValue} values={values} disableRemove={this.state.data.negotiation?.tenant?.id === tenant?.id ? true : false} />
													))}
												</div>
											</div>

											<div className='box'>
												<h5>VALORES</h5>

												<div className='formulario'>
													<FormGroup className="w-100" style={{ display: 'flex', gap: '42px', alignItems: 'baseline' }}>
														<Label className="col-form-label label-principalRadio">
															Garantia
														</Label>

														<Label className="pl-2 input-radio-group">
															<Input type="radio" name={'war_type'} id={`tipo-1`} value={'WARRANTY'} onChange={handleChange} onBlur={handleBlur} color="primary" checked={['WARRANTY'].includes(values.war_type)} />
															<Label className='radioLabel' style={{ padding: "3px" }} for={`tipo-1`}>
																Pagamento
															</Label>
														</Label>

														<Label className="pl-2 input-radio-group">
															<Input type="radio" name={'war_type'} id={`tipo-2`} value={'WARRANTY_GUARANTEE_DAMAGE'} onChange={handleChange} onBlur={handleBlur} color="primary" checked={['WARRANTY_GUARANTEE_DAMAGE'].includes(values.war_type)} />
															<Label className='radioLabel' style={{ padding: "3px" }} for={`tipo-2`}>
																Pagamento com danos
															</Label>
														</Label>
													</FormGroup>

													<Col lg={4} className="p-0 marginInputMobileRua">
														<Label>Valor do aluguel</Label>

														<TextField name="val_rent" placeholder="R$ 3.000,00" type="text" variant="outlined" value={values.val_rent} onBlur={handleBlur} onChange={(e) => handleChange(formatBRLInput(e))}
															helperText={touched.val_rent && errors.val_rent} error={Boolean(touched.val_rent && errors.val_rent)} />
													</Col>

													<Row className="p-0 m-0">
														<Col lg={4} className="p-0 m-0">
															<Label>Valor IPTU</Label>

															<TextField name="val_iptu" type="text" placeholder="R$ 3.000,00" variant="outlined" disabled={values.tp_iptu == 'false'} value={values.val_iptu} onBlur={handleBlur} onChange={(e) => handleChange(formatBRLInput(e))}
																helperText={touched.val_iptu && errors.val_iptu} error={Boolean(touched.val_iptu && errors.val_iptu)} />
														</Col>

														<Col lg={8} className="m-0" style={{ paddingLeft: 32, display: 'flex', alignItems: 'end' }}>
															<FormGroup className="w-100" style={{ display: 'flex', gap: '24px', alignItems: 'baseline', marginBottom: 0, paddingTop: 35 }}>
																<Label className="pl-2 input-radio-group">
																	<Input type="radio" name={'tp_iptu'} id={`tp_iptu-1`} value={'false'} onChange={handleChange} onBlur={handleBlur} color="primary" checked={['false', false].includes(values.tp_iptu)} />
																	<Label style={{ padding: "3px" }} for={`tp_iptu-1`}>
																		Proprietário paga
																	</Label>
																</Label>

																<Label className="pl-2 input-radio-group">
																	<Input type="radio" name={'tp_iptu'} id={`tp_iptu-1`} value={'true'} onChange={handleChange} onBlur={handleBlur} color="primary" checked={['true', true].includes(values.tp_iptu)} />
																	<Label style={{ padding: "3px" }} for={`tp_iptu-2`}>
																		Inquilino Paga
																	</Label>
																</Label>
															</FormGroup>
														</Col>
													</Row>


													<Row className="p-0 m-0">
														<Col lg={4} className="p-0 m-0">
															<Label>Valor Condomínio</Label>

															<TextField name="val_condo" placeholder="R$ 3.000,00" type="text" variant="outlined" disabled={values.tp_iptu == 'false'} value={values.val_condo} onBlur={handleBlur} onChange={(e) => handleChange(formatBRLInput(e))}
																helperText={touched.val_condo && errors.val_condo} error={Boolean(touched.val_condo && errors.val_condo)} />
														</Col>

														<Col lg={8} className="m-0" style={{ paddingLeft: 32, display: 'flex', alignItems: 'end' }}>
															<FormGroup className="w-100" style={{ display: 'flex', gap: '24px', alignItems: 'baseline', marginBottom: 0, paddingTop: 35 }}>
																<Label className="pl-2 input-radio-group">
																	<Input type="radio" name={'tp_condo'} id={`tipo-1-condo`} value={'false'} onChange={handleChange} onBlur={handleBlur} color="primary" checked={['false', false].includes(values.tp_condo)} />
																	<Label style={{ padding: "3px" }} for={`tipo-1-condo`}>
																		Proprietário paga
																	</Label>
																</Label>

																<Label className="pl-2 input-radio-group">
																	<Input type="radio" name={'tp_condo'} id={`tipo-1-condo`} value={'true'} onChange={handleChange} onBlur={handleBlur} color="primary" checked={['true', true].includes(values.tp_condo)} />
																	<Label style={{ padding: "3px" }} for={`tipo-2-condo`}>
																		Inquilino Paga
																	</Label>
																</Label>
															</FormGroup>
														</Col>
													</Row>
												</div>
											</div>

											<div className='box'>
												<h5>DETALHES DA NEGOCIAÇÃO</h5>

												<div className='formulario'>
													<Row className="p-0 m-0">
														<Col lg={5} className="p-0 m-0">
															<Label>Plano</Label>

															<select style={{ background: "#FFF", height: "40px" }} name={values.war_type} onChange={(e) => setFieldValue('war_type', e.target.value)} error={Boolean(touched.war_type && errors.war_type)} onBlur={handleBlur} value={values.war_type} className="custom-select">
																<option key={null} value={null} hidden>Selecione um plano</option>
																{this.state.plan_list.map(({ key, label }) => <option key={key} value={key}>{label}</option>)}
															</select>

															{(touched.war_type && errors.war_type) ? <FormHelperText error>O campo plano é obrigatório</FormHelperText> : null}
														</Col>

														<Col lg={3} className="marginInputMobile pl-4 pr-4">
															<Label>Taxa</Label>

															<TextField name="percentage" type="text" variant="outlined" value={values.percentage} onBlur={handleBlur} onChange={handleChange}
																helperText={touched.percentage && errors.percentage} error={Boolean(touched.percentage && errors.percentage)}
																InputProps={{ endAdornment: <InputAdornment position='end'>%</InputAdornment> }} />
														</Col>

														{values.war_type != '' && (
															<Col lg={3} className="p-0 m-0 col-text-plan">
																<div>
																	<p>Para este plano, digite</p>
																	<b>{values.war_type == 'WARRANTY' ? 'entre 5% e 7%' : 'entre 7% e 10%'}</b>
																</div>
															</Col>
														)}
													</Row>

													<Row className="p-0 m-0">
														<Col lg={4} className="p-0 m-0">
															<Label>Data do início do contrato</Label>

															<TextField name="start_date" type="date" placeholder="00/00/0000" variant="outlined" value={values.start_date} onBlur={handleBlur} onChange={handleChange}
																helperText={touched.start_date && errors.start_date} error={Boolean(touched.start_date && errors.start_date)} />
														</Col>

														<Col lg={4} className="marginInputMobile">
															<ListDuration name="duration" onBlur={handleBlur} onChange={handleChange} value={values.duration} error={(touched.duration && errors.duration)}
																onError="O campo tempo de contrato é obrigatório." />
														</Col>

														{this.state?.coupon?.id && (
															<Col lg={4} className="marginInputMobile col-text-coupon">
																<Label>Cupom aplicado</Label>

																<div>
																	<b style={{ cursor: 'pointer' }} onClick={() => window.open(`/coupons/show/${this.state?.coupon?.coupon_id}`, '_blank')}>{this.state?.coupon?.code}</b>
																</div>
															</Col>
														)}
													</Row>
												</div>
											</div>

											<div className='box-footer footer-values-negotiation'>
												<div style={{ display: 'flex', alignItems: 'center' }}>
													<div>
														<p className='p-type-bold'>Total mensal de</p>
														<p className='p-type-green'><b>R$</b>{this.calcTotalFinal(values)}</p>
													</div>

													<p className='plus-divisor' style={{ color: '#8590A5', margin: '0px 20px' }}></p>

													{values.duration && (
														<div>
															<p className='p-type-bold'>Por</p>
															<p className='p-type-2'><span>{values.duration}</span> meses</p>
														</div>
													)}
												</div>

												<div className="d-flex" style={{ gap: 8 }}>
													<Link to='/negotiation/index' className='button-secundary'>
														<i className="fa fa-arrow-left" />
														VOLTAR
													</Link>

													<button type="submit" className='button-primary' disabled={this.state.submitted} style={{ width: 95 }}>
														{this.state.submitted ?
															<>
																<FontAwesomeIcon spin icon={faSync} />
															</> :
															<>
																SALVAR
																<FontAwesomeIcon icon={faCheck} />
															</>
														}
													</button>
												</div>
											</div>
										</div>
									</form>
								}
							</Formik>
						</Container>
					</div>
				</BetterCircularProgress>
			</React.Fragment>
		);
	}
}

export default NegotiationEdit;
