/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-mixed-operators */
/* eslint-disable array-callback-return */
import React, { Component, createRef } from 'react';
import { Container, Col, Row, Label, TabContent, TabPane, Nav, NavItem, NavLink, Input } from "reactstrap";
import Breadcrumbs from '../../components/Breadcrumb';
import BetterCircularProgress from '../../components/BetterCircularProgress';
import { Link, withRouter } from "react-router-dom";
import { get as getProposition, remove as deleteProposition, update as updateProposition, getUsersWithLaudoStatus, resendEmailTenantLock, resendEmailCreditRefused } from '../../crud/propositions.crud';
import { store as createContract, get as getContract, deleteContract, queryContractualEdits, cancelContract, getAllByFilter as GetContracts, updateStopGuarantee, updateRetainValues, cancelContractualEdit } from '../../crud/contracts.crud';
import { getLetterOfAttorney } from '../../crud/letter-of-attorney.crud';
import { getProperties as getAnnouncementInfos } from '../../crud/announcements.crud';
import { get as getPlans } from '../../crud/plans.crud';
import { formatBRL } from '../../helpers/Utils';
import { formatBRLInput, getLaudoInfo, getLaudoTag, limparMoeda, ocorrenciasGet } from './helpers';
import { getByPropositionId as getCreditConsultByPropositionId, update as updateLaudo, store as createLaudo } from '../../crud/credit-consut.crud';
import { getAppliedByAnnouncement } from '../../crud/coupons.crud';
import moment from 'moment';
import { getChatByPropositionId, updateChatStatus } from '../../crud/chat.crud';
import BetterAlerts from '../../components/BetterAlerts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faCheck, faCircleCheck, faChevronRight, faFileCirclePlus, faFileCircleXmark, faFileImport, faFilePlus, faFileSignature, faFilter, faLink, faMagnifyingGlass, faMagnifyingGlassPlus, faPen, faRotate, faTimes, faUser, faUserMagnifyingGlass, faFilePen, faArrowsRotate, faShieldSlash, faSplit, faEnvelopeOpenDollar, faHandsHoldingDollar } from '@fortawesome/pro-regular-svg-icons';
import { faImages, faUser as faUserSolid, faX } from '@fortawesome/pro-solid-svg-icons';
import classnames from 'classnames';
import { Select, TextareaAutosize, TextField } from '@material-ui/core';
import DataTableUsersProposition from '../../components/datatables/negotiation-contracts/DataTableUsersProposition';
import DataTableContractSignature from '../../components/datatables/negotiation-contracts/DataTableContractSignature';
import { cancelFriendlyAgreement, get as getAllBillings, getFriendlyAgreement } from '../../crud/billings.crud';
import DataTableBillingNegotiation from '../../components/datatables/negotiation-contracts/DataTableBillingNegotiation';
import { getAllByFilter as getJuridicDocs } from '../../crud/doc-users.crud';
import DataTableDocList from '../../components/datatables/negotiation-contracts/DataTableDocList';
import Swal from 'sweetalert2';
import { Formik } from 'formik';
import { store as storeDocJudicial } from "../../crud/doc-users.crud";
import { Autocomplete } from '@material-ui/lab';
import { UserFilterComponent } from './components/user.component';
import { getOccurrences, storeOccurrences } from '../../crud/occurrences.crud';
import DataTableOccurrences from '../../components/datatables/negotiation-contracts/DataTableOccurrences';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import Modal from 'react-bootstrap/Modal';
import { query as queryTermination } from '../../crud/termination.crud';
import { getVistoriasByContract } from '../../crud/property-inspection.crud';

class NegotiationShow extends Component {
	DataTableUserAnnouncementsRef = createRef();
	dataTableDocListRef = createRef();
	dataTableOccurrences = createRef();
	dataTableContractSignatureRef = createRef();

	state = {
		loading: false,
		activeTab: '1',
		data: null,
		participantUsers: [],
		user: null,
		errors: false,
		contract: false,
		terminations: [],
		contractual_edits: [],
		contract_link: '',
		submittingLaudo: false,
		laudo: null,
		status: '',
		coupon: null,
		searchDocumento: '',
		judicializado: false,
		submittingLock: false,
		envioArquivo: {
			tituloArquivo: '',
			categoriaArquivo: '',
			descricaoArquivo: ''
		},
		categorias: [
			'Termo de encerramento',
			'Termo de rescisão',
			'Termo de confissão de dívida',
			'Termo aditivo de contrato',
			'Notificação extrajudicial',
			'Procuração assinada pelo proprietário',
			'Assinados conjuntamente',
			'Cobranças jurídicas',
			'Cobranças',
			'Documentos internos',
			'Inadimplência',
			'Confissão de dívida',
			'Sentença',
			'Processo Conhecimento',
			'Processo Fase de Execução',
			'Outros',
		],
		statusList: [
			{ key: 'PLACEHOLDER', label: 'Chat_iniciado' },
			{ key: 'DRAFT', label: 'Criada_Integracoes' },
			{ key: 'AWAITING_TENANT_LOCK', label: 'Aguardando_trava_inquilino' },
			{ key: 'FIRST_PROPOSAL', label: 'Criada_admin' },
			{ key: 'AWAITING_CREDIT_REVIEW', label: 'Aguardando_analise_financeira' },
			{ key: 'APPROVED', label: 'Analise_financeira_aprovada' },
			{ key: 'CREDIT_REFUSED', label: 'Analise_financeira_recusada' },
			{ key: 'AWAITING_SIGN', label: 'Aguardando_Assinaturas' },
			{ key: 'FINALIZED', label: 'Contrato_assinado' },
			{ key: 'FINISHED', label: 'Contrato_rescindido' }
		],
		chatStatusList: [
			{ key: 'ativo', label: 'Conversa ativa' },
			{ key: 'arquivado', label: 'Conversa arquivada' }
		],
		chatRoomId: null,
		contract_users: [],
		chatStatus: 'ativo',
		createOccurrence: false,
		showModalVerContrato: false,
		showModalHistorico: false,
		showModalCriarContrato: false,
		showModalPararGarantia: false,
		showModalRetainValues: false,
		showModalCancelEdition: false,
		showModalCancelFriendlyAgreement: false,
		filtersCount: 0,
		propertyInspectionList: [],
		contract_id: '',
		imovel: {},
		totalOcorrencias: null,
		newFilters: [],
		letters_attorney: [],
		friendly_agreement: [],
		canceledFriendlyAgreement: false,
		contratoSelecionado: {},
		filters: {
			signup_after: '',
			signup_before: '',
			classificacao: {
				context: '',
				reason: '',
				type: '',
				result: '',
				status: ''
			}
		},
		success: null,
		firstBill: null,
		lastLaudoConsult: null,
		securitized: false,
		anticipated: false,
		selectedContract: null,  // Para armazenar o contrato selecionado
	};

	componentDidMount = async () => {
		try {
			this.setState({ loading: true });

			if (this.props.location.state) {
				if (this.props.location.state.success) {
					this.setState({ success: this.props.location.state.success });
				}
			}

			const propositionId = this.props.match.params.id;

			const creditConsultRes = await getCreditConsultByPropositionId(propositionId);
			if (creditConsultRes?.status === 200) {
				const fcLaudo = creditConsultRes.data?.data?.fc_laudo;

				this.setState({ lastLaudoConsult: creditConsultRes.data?.data?.updated_at })

				if (this.props.location.state) {
					if (this.props.location.state.success) {
						this.setState({ success: this.props.location.state.success });
					}
				}

				if (fcLaudo) {
					const htmlAprovado = fcLaudo?.parecer?.locacao?.parecer_inquilinos?.aprovados;
					const htmlSugestao = fcLaudo?.inquilinos?.[0]?.laudo?.parecer_sistemico?.[0]?.dica_ouro;
					const aprovadoTexto = this.extractTextFromHtml(htmlAprovado);
					const sugestaoTexto = this.extractTextFromHtml(htmlSugestao);

					this.setState({ laudo: { ...creditConsultRes.data.data, aprovadoTexto, sugestaoTexto } });
				}
			}

			const chatRes = await getChatByPropositionId(propositionId);
			if (chatRes?.status === 200) {
				const chatStatus = this.getChatStatus(chatRes.data.data.statusRooms);
				const chatRoomId = chatRes.data.data.id;
				this.setState({ chatStatus, chatRoomId });
			}

			const plansRes = await getPlans();
			this.setState({ plans: plansRes.data.data });

			const propositionRes = await getProposition(propositionId);
			const propositionData = propositionRes.data.data;

			const announcementInfosRes = await getAnnouncementInfos({ id: propositionData.property_announcement_id, isAdmin: true });
			this.setState({ imovel: announcementInfosRes.data.data[0] });

			let appliedCoupon = null;
			const appliedByAnnouncementRes = await getAppliedByAnnouncement(propositionData.property_announcement_id);

			if (appliedByAnnouncementRes?.status === 200 && appliedByAnnouncementRes.data) {
				appliedCoupon = appliedByAnnouncementRes.data;
				this.setState({ coupon: appliedByAnnouncementRes.data });
			}

			if (!appliedCoupon && propositionData.contract?.coupon) {
				this.setState({ coupon: propositionData.contract?.coupon });
			}

			if (propositionData.contract?.id) {
				const contractId = propositionData.contract.id;

				this.setState({ contract_id: contractId });
				this.handleGetTerminations(contractId);
				this.handleGetContractualEdits(contractId);
				this.handleGetLetterOfAttorney(contractId);
				this.handleGetContractsJudicial(contractId);
				this.handleGetFriendlyAgreement(contractId);

				const vistoriasRes = await getVistoriasByContract(contractId);
				this.setState({ propertyInspectionList: vistoriasRes.data.data });

				const contractRes = await getContract(contractId);
				this.setState({ contract_link_sign: contractRes.data.data.fileSignUrl, contract_link: contractRes.data.data.fileOriginalUrl });

				if (contractRes.data.data.entity) {
					this.setState({ contract_users: contractRes.data.data.entity.contract_users });
				}

				let params = {};

				params.contract_id = propositionData.contract?.id;
				params.number = '0';
				params.type = 'monthly'

				const billings = await getAllBillings(params).catch((err) => {
					//err
				});

				this.setState({ firstBill: billings?.data?.data[0] })
			}

			this.setState({ data: propositionData });

			const usersWithLaudoStatusRes = await getUsersWithLaudoStatus(propositionId);

			this.setState({ participantUsers: usersWithLaudoStatusRes.data });

			if (propositionData.contract?.id) {
				await getAllBillings({ contract_id: propositionData.contract?.id, securitized: true }).catch((err) => { }).then(({ data, status }) => {
					if (status == 200) {
						if (data?.total > 0) {
							this.setState({ securitized: true });
						}
					}
				});

				await getAllBillings({ contract_id: propositionData.contract?.id, anticipated: true }).catch((err) => { }).then(({ data, status }) => {
					if (status == 200) {
						if (data?.total > 0) {
							this.setState({ anticipated: true });
						}
					}
				});
			}
		} catch (error) {
			this.setState({ errors: [error?.message ?? 'Houve um erro, entre em contato com o suporte.'] });
		} finally {
			this.setState({ loading: false });
		}
	};

	handleGetContractualEdits = async (contractId) => {
		await queryContractualEdits({ contract_id: contractId, '$sort[created_at]': -1 }).catch(err => { }).then(res => {
			this.setState({ contractual_edits: [res.data.data] });
		});
	}

	handleGetLetterOfAttorney = async (contractId) => {
		await getLetterOfAttorney(contractId).catch(err => { }).then(res => {
			this.setState({ letters_attorney: res?.data?.data });
		});
	}

	handleGetFriendlyAgreement = async (contractId) => {
		await getFriendlyAgreement({ contract_id: contractId, $paranoid: false, $limit: 30 }).catch(err => { }).then(res => {
			this.setState({ friendly_agreement: res?.data?.data });
		});
	}

	handleGetContractsJudicial = async (contractId) => {
		await GetContracts({ contractWithJudicial: true, searchAll: { search: contractId } }).catch(err => { }).then(res => {
			if (res.data.total > 0) {
				this.setState({ judicializado: true });
			}
		});
	}

	extractTextFromHtml = (html) => {
		const div = document.createElement("div");
		div.innerHTML = html;
		return div.textContent || div.innerText || "";
	};

	getChatStatus = (statusRooms) => {
		if (statusRooms?.some(objStatus => !objStatus.archived)) {
			return 'ativo';
		} else if (statusRooms?.length > 0) {
			return 'arquivado';
		}

		return 'ativo';
	};

	handleGetTerminations = (contract_id) => {
		queryTermination({ contract_id: contract_id, $paranoid: false }).then(res => {
			this.setState({ terminations: res.data.data });
		});
	}

	deleteNegotiation = async () => {
		try {
			await deleteProposition(this.state.data.id);

			this.setState({ success: ['Negociação deletada.'] });

			if (this.state.data.contract.id) {
				await deleteContract(this.state.data.contract.id);
			}

			window.open('/negotiation/index', '_self');
		} catch (err) {
			let errorMessage = "Não foi possível deletar a negociação";

			if (err?.response?.data?.message) {
				errorMessage = err.response.data.message;
			}

			this.setState({ errors: [errorMessage] });
		}
	};

	buttonCreateContract = async () => {
		try {
			this.setState({ loading: true });

			const {
				data,
				// plans 
			} = this.state;

			if (!data.plan_id) {
				// const plan = plans.find(p => p.name === data.property.warranty.type);

				// const updateRes = await updateProposition(this.state.data.id, { ...data, plan_id: plan.id });
			};

			const createContractRes = await createContract({ proposition_id: this.state.data.id });
			const contractData = createContractRes.data.data.res;

			this.setState({ success: ['Contrato criado com sucesso'], contract_id: contractData.id });

			window.open(`/negotiation/show/${this.props.match.params.id}?typeNegociacao=contratando`, '_self');
		} catch (error) {
			this.setState({ errors: ['Erro ao criar contrato'] });
		} finally {
			this.setState({ loading: false });
		}
	};

	executeLaudo = () => {
		this.setState({ submittingLaudo: true });

		let params = {
			proposition_id: this.props.match.params.id,
			announcement_id: this.state.data.property_announcement_id,
			tenant_id: this.state.data.tenant_id
		}

		createLaudo(params).catch(err => {
			if (!err?.response?.data?.message) this.setState({ errors: ['Ocorreu um erro ao tentar criar a avaliação.'] });
		}).then(res => {
			if (res?.status === 200) {
				this.setState({ laudo: { fc_laudo: null } });
			}
		}).finally(() => {
			getCreditConsultByPropositionId(this.props.match.params.id).catch(err => { }).then(res => {
				if (res?.status == 200) {
					let htmlAprovado = res.data.data?.fc_laudo?.parecer?.locacao?.parecer_inquilinos?.aprovados;
					let divPrivate = document.createElement("div");

					divPrivate.innerHTML = htmlAprovado;

					let textAprovado = divPrivate.textContent || divPrivate.innerText || "";

					res.data.data.aprovadoTexto = textAprovado;

					this.setState({ laudo: res.data.data, lastLaudoConsult: moment().toDate() });
				}
			});

			this.setState({ submittingLaudo: false });
		});
	};

	reprocessLaudo = () => {
		this.setState({ submittingLaudo: true });

		updateLaudo({ proposition_id: this.props.match.params.id }).catch(err => { }).then(res => {
			if (res?.status === 200) {
				this.setState({ submittingLaudo: false, laudo: { ...this.state.laudo, fc_laudo: null } });
			}
		}).finally(() => {
			getCreditConsultByPropositionId(this.props.match.params.id).catch(err => { }).then(res => {
				if (res?.status == 200) {
					this.setState({ laudo: res.data.data });
				}
			});

			this.setState({ submittingLaudo: false });
		});
	};

	submitStatus = (e) => {
		this.setState({ status: e.target.value });

		updateProposition(this.props.match.params.id, { status: e.target.value });
	};

	submitChatStatus = (e) => {
		if (e.target.value != this.state.chatStatus) {
			this.setState({ chatStatus: e.target.value });
			let status = e.target.value == 'ativo' ? 'unarchive' : 'archive';

			updateChatStatus(this.props.match.params.id, { status });
		}
	};

	getContractStatus = () => {
		const negotiation = this?.state?.data;
		// const property = negotiation?.property;
		// const address = property?.address;
		// const details = negotiation?.details;
		// const values = details?.values;
		const contract = negotiation?.contract;
		// const contractDetails = contract?.details;

		if (contract?.id && !contract?.is_terminated && !contract?.scheduled_termination_date) {
			return 'vigentes'
		} else if (contract && contract?.is_terminated) {
			return 'encerrados'
		} else if (contract?.id && contract?.scheduled_termination_date) {
			return 'encerrando'
		} else if (contract?.id && contract?.is_terminated == true && contract?.signed != true) {
			return 'cancelados'
		} else if (!['FINALIZED', 'FINISHED', 'AWAITING_SIGN'].includes(negotiation?.status)) {
			return 'negociando'
		} else if (contract.signed != true && contract.is_terminated != true) {
			return 'contratando'
		} else {
			return '';
		}
	}

	getStatusProposition = (obj) => {
		let status = '';
		let backgroundColor = '#4AA3FF'

		switch (obj?.status) {
			case 'PLACEHOLDER':
				status = 'Chat iniciado';
				backgroundColor = '#4AA3FF';
				break;
			case 'DRAFT':
				status = 'Criada integrações';
				backgroundColor = '#5664D2';
				break;
			case 'AWAITING_TENANT_LOCK':
				status = 'Aguardando trava inquilino';
				backgroundColor = '#5664D2';
				break;
			case 'AWAITING_CREDIT_REVIEW':
				status = 'Aguardando análise financeira';
				backgroundColor = '#EFA200';
				break;
			case 'APPROVED':
				status = 'Análise financeira aprovada';
				backgroundColor = '#1CBB8C';
				break;
			case 'FIRST_PROPOSAL':
				status = 'Criada Admin';
				backgroundColor = '#5664D2';
				break;
			case 'CREDIT_REFUSED':
				status = 'Análise financeira reprovada';
				backgroundColor = '#FF3D60';
				break;
			case 'AWAITING_SIGN':
				status = 'Aguardando assinatura de contrato';
				backgroundColor = '#EFA200';
				break;
			case 'FINALIZED':
				status = 'Contrato Vigente';
				backgroundColor = '#1CBB8C';
				break;
			case 'FINISHED':
				status = 'Contrato encerrado';
				backgroundColor = '#5664D2';
				break;
			default:
				status = obj?.status;
				break;
		}

		if (this.getContractStatus() == 'encerrando') {
			status = 'Contrato encerrando';
			backgroundColor = '#5664D2';
		}

		return (
			<div className="tag" style={{ textAlign: "center", backgroundColor }}>
				{status}
			</div>
		);
	}

	toggleTab(tab) {
		if (this.state.activeTab !== tab) this.setState({ activeTab: tab });
	};

	getWarranty = (key) => {
		switch (key) {
			case 'FREE':
				return 'Grátis';

			case 'WARRANTY':
				return 'Pagamento';


			case 'WARRANTY_GUARANTEE_DAMAGE':
				return 'Completa';

			default:
				return 'Não identificado';
		}
	};

	getOriginFriendlyName = (origin) => {
		if (origin === 'CONTRACT_GENERATOR') {
			return 'Gerador de contrato'
		} else if (origin === 'Convidar_inquilino') {
			return 'Convidar inquilino'
		} else if (origin === 'ADMIN') {
			return 'Admin'
		} else if (origin === 'PLACEHOLDER') {
			return 'Criada Integrações'
		} else {
			return 'Orgânico'
		}
	}

	fetchUsersProposition = async () => {
		return getUsersWithLaudoStatus(this.props.match.params.id).catch(({ message }) => { })
	};

	fetchContractSignature = async () => {
		return getContract(this.state.data?.contract?.id).catch((err) => { })
	};

	fetchAnnouncementsBillings = (data) => {
		let params = { ...data };

		params.contract_id = this?.state?.contract_id;

		return getAllBillings(params).catch((err) => {
			//err
		});
	};

	fetchJuridicDocs = (data) => {
		let params = { ...data };

		params.contract_id = this?.state?.contract_id;

		if (this.state.searchDocumento) {
			params.searchAll = this.state.searchDocumento;
		}

		return getJuridicDocs(params).catch((err) => { });
	}

	uploadDoc = (values, resetForm) => {
		if (!this.state.file) {
			Swal.fire('Ops', 'É necessário selecionar ao menos um arquivo para continuar.', 'error');
			return;
		}

		this.setState({ loading: true });

		let formData = new FormData();

		formData.append('file', this.state.file);

		if (values?.description && values.description.trim() !== "") {
			formData.append('description', values.description.trim());
		}

		if (values?.vencimento && values.vencimento.trim() !== "") {
			formData.append('vencimento', values.vencimento.trim());
		}

		if (values?.categoria && values.categoria.trim() !== null && values.categoria.trim() !== '') {
			formData.append('category', values.categoria.trim());
		}

		if (values?.payments_value && values.payments_value.trim() !== null && values.payments_value.trim() !== '') {
			formData.append('payments_value', limparMoeda(values.payments_value.trim()));
		}

		formData.append('contract_id', this.state.data.contract.id);
		formData.append('friendly_contract_code', this.state.data.contract.friendly_code);

		storeDocJudicial(formData).then((res) => {
			this.setState({ file: null });

			resetForm();

			this.dataTableDocListRef?.current?.fetchData();
		}).catch((err) => {
			return Swal.fire('Ops', 'Ocorreu um erro ao fazer upload do arquivo.', 'error');
		}).finally(() => {
			this.setState({ loading: false });
		})
	};

	getInitialValues = () => {
		return {
			description: '',
			payments_value: '',
			categoria: '',

			status: '',
			context: '',
			reason: '',
			type: '',
			result: '',
			administrator: {}
		};
	};

	fetchOccurrences = (data) => {
		let params = { ...data };

		if (!this?.state?.contract_id) return;

		params.contract_id = this?.state?.contract_id;

		if (this.state?.searchOcorrencia) {
			params.searchAll = {
				search: this.state?.searchOcorrencia
			}
		}

		if (this.state.filters.signup_after?.trim() != '') {
			params.created_at_gte = this.state.filters.signup_after
		}

		if (this.state.filters.signup_before?.trim() != '') {
			params.created_at_lte = this.state.filters.signup_before
		}

		if (this.state.filters.classificacao.context?.trim() != '') {
			params.context = this.state.filters.classificacao.context
		}

		if (this.state.filters.classificacao.reason?.trim() != '') {
			params.reason = this.state.filters.classificacao.reason
		}

		if (this.state.filters.classificacao.type?.trim() != '') {
			params.type = this.state.filters.classificacao.type
		}

		if (this.state.filters.classificacao.result?.trim() != '') {
			params.result = this.state.filters.classificacao.result
		}

		if (this.state.filters.classificacao.status?.trim() != '') {
			params.status = this.state.filters.classificacao.status
		}

		return getOccurrences(params).catch((err) => {
			//err
		});
	};

	sendOccurrence = (data, resetForm) => {
		data.contract_id = this.state.data?.contract?.id ?? '';
		storeOccurrences(data).then((res) => {
			resetForm();

			this.dataTableOccurrences?.current?.fetchData();
		}).catch((err) => {
			return Swal.fire('Ops', 'Ocorreu um erro ao fazer upload da ocorrencia.', 'error');
		}).finally(() => {
			this.setState({ loading: false });
		})
	}

	handleDeleteContract = async () => {
		this.setState({ loading: true });

		try {
			await deleteContract(this.state?.data?.contract?.id);
			this.setState({ success: ['Contrato excluído com sucesso!'] });
		} catch (err) {
			this.setState({ errors: ['Houve um erro e não foi possível a exclusão do seu contrato.'] });
		} finally {
			window.open(`/negotiation/show/${this.props.match.params.id}?typeNegociacao=negociando`, '_self');

			this.setState({ loading: false });
		}
	}

	// Função para receber o contrato selecionado do DataTableContractSignature
	handleContractSelected = (contract) => {
		this.setState({ selectedContract: contract });
	}

	handleCancelFriendlyAgreement = async () => {
		this.setState({ loading: true });

		// Usar o ID do acordo amigável do contrato selecionado
		const friendlyAgreementId = this.state.selectedContract?.friendly_agreement_id || this.state?.friendly_agreement[0]?.id;

		try {
			await cancelFriendlyAgreement(friendlyAgreementId);

			this.setState({ errors: ["Acordo e assinatura da confissão de dívida cancelados."] });

			// Atualizar a lista de acordos amigáveis após o cancelamento
			if (this.state.data?.contract?.id) {
				await this.handleGetFriendlyAgreement(this.state.data.contract.id);
			}

			// Atualizar a tabela de contratos
			if (this.dataTableContractSignatureRef?.current) {
				this.dataTableContractSignatureRef.current.fetchData();
			}
		} catch (err) {
			this.setState({ errors: ['Houve um erro e não foi possível o cancelamento do acordo de confissão de dívida.'] });
		} finally {
			this.setState({ loading: false });
			this.handleClose();
		}
	}

	handleCancelContract = async () => {
		this.setState({ loading: true });

		try {
			await cancelContract(this.state?.data?.contract?.id);
			this.setState({ success: ['Contrato cancelado com sucesso!'] });
		} catch (err) {
			this.setState({ errors: ['Houve um erro e não foi possívelo cancelamento do seu contrato.'] });
		} finally {
			window.open(`/negotiation/show/${this.props.match.params.id}?typeNegociacao=cancelado`, '_self');

			this.setState({ loading: false });
		}
	}

	getTagRegularidade = (obj) => {
		if (obj?.status == "Irregular") {
			return (
				<div className='tag' style={{ backgroundColor: '#FF3D60' }}>
					Irregular
				</div>
			)
		} else {
			return (
				<div className='tag' style={{ backgroundColor: '#1CBB8C' }}>
					Nada consta
				</div>
			)
		}
	}

	getLaudoResult = (obj) => {
		return (
			<>
				{obj?.lastDate ? (
					<>
						<span style={{
							color: '#505D69', fontFamily: 'Inter', fontSize: '13.008px', fontStyle: 'normal', fontWeight: 600, lineHeight: 'normal'
						}}>Última movimentação: </span>
						{obj?.lastDate}
					</>
				) : null}
				<p style={{ marginBottom: 0 }}>{obj?.result}</p>
			</>
		)
	}

	handleClose = () => {
		this.setState({
			showModalHistorico: false,
			showModalVerContrato: false,
			showModalCancelarNegociacao: false,
			showModalCriarContrato: false,
			showModalCancelarNegociacaoPrimeiroPagamento: false,
			showModalPararGarantia: false,
			showModalRetainValues: false,
			showModalCancelFriendlyAgreement: false,
		});
	}

	handleOpenModalCancelFriendlyAgreement = (contratoSelecionado) => {
		this.setState({
			showModalCancelFriendlyAgreement: true,
			selectedContract: contratoSelecionado || this.state.selectedContract
		});
	}

	handleShowVerContrato = () => {
		this.handleClose();

		this.setState({ showModalVerContrato: true });
	}

	handleTotalOcorrencias = (total) => {
		this.setState({ totalOcorrencias: total });
	}

	FiltersModule = ({ filtroModular, index }) => {
		const getFilterChoised = (filter) => {
			let component = <></>

			if (filter == 'data_cadastro') {
				component = (
					<>
						<div className='d-flex'>
							<Label htmlFor="signup_after" className="col-form-label">De</Label>
							<Input className="form-control" type="date" id="signup_after" value={this.state.filters.signup_after} style={{ width: 200, margin: '0 16px' }}
								onChange={(e) => this.setState({ filters: { ...this.state.filters, signup_after: e.target.value } }, () => this.dataTableOccurrences.current.fetchData())} />
						</div>

						<div className='d-flex'>
							<Label htmlFor="signup_before" className="col-form-label">Até</Label>
							<Input className="form-control" type="date" id="signup_before" value={this.state.filters.signup_before} style={{ width: 200, margin: '0 16px' }}
								onChange={(e) => this.setState({ filters: { ...this.state.filters, signup_before: e.target.value } }, () => this.dataTableOccurrences.current.fetchData())} />
						</div>
					</>
				)
			} else if (filter == 'classificacao') {
				component = (
					<>
						<div className="p-0 m-0 d-flex">
							<select onChange={({ target }) => this.setState({ filters: { classificacao: { ...this.state.filters.classificacao, context: target.value } } }, () => this.dataTableOccurrences.current.fetchData())}
								value={this.state.filters.classificacao?.context} className="custom-select" style={{ width: 150, flex: 'none' }}>
								<option value="">Contexto</option>
								{ocorrenciasGet(this.state.filters.classificacao)?.context.map((option) => <option value={option}>{option}</option>)}
							</select>

							{ocorrenciasGet(this.state.filters.classificacao)?.reason?.length > 0 && (
								<>
									<div className='divisionArrow' style={{ paddingTop: 6 }}>
										<FontAwesomeIcon icon={faChevronRight} />
									</div>

									<select onChange={({ target }) => this.setState({ filters: { classificacao: { ...this.state.filters.classificacao, reason: target.value } } }, () => this.dataTableOccurrences.current.fetchData())}
										value={this.state.filters.classificacao?.reason} className="custom-select" style={{ width: 200, flex: 'none' }}>
										<option value="">Motivo</option>
										{ocorrenciasGet(this.state.filters.classificacao)?.reason.map((option) => <option value={option}>{option}</option>)}
									</select>

									{ocorrenciasGet(this.state.filters.classificacao)?.type?.length > 0 && (
										<div className='divisionArrow' style={{ paddingTop: 6 }}>
											<FontAwesomeIcon icon={faChevronRight} />
										</div>
									)}
								</>
							)}

							{ocorrenciasGet(this.state.filters.classificacao)?.type?.length > 0 && (
								<div>
									<select onChange={({ target }) => this.setState({ filters: { classificacao: { ...this.state.filters.classificacao, type: target.value } } }, () => this.dataTableOccurrences.current.fetchData())}
										value={this.state.filters.classificacao?.type} className="custom-select" style={{ width: 300, flex: 'none' }}>
										<option value="">Tipo</option>
										{ocorrenciasGet(this.state.filters.classificacao)?.type.map((option) => <option value={option}>{option}</option>)}
									</select>
								</div>
							)}

							{ocorrenciasGet(this.state.filters.classificacao)?.result?.length > 0 && (
								<>
									<div className='divisionArrow' style={{ paddingTop: 6 }}>
										<FontAwesomeIcon icon={faChevronRight} />
									</div>

									<select onChange={({ target }) => this.setState({ filters: { classificacao: { ...this.state.filters.classificacao, result: target.value } } }, () => this.dataTableOccurrences.current.fetchData())}
										value={this.state.filters.classificacao?.result} className="custom-select" style={{ width: 300, flex: 'none' }}>
										<option value="">Resultado</option>
										{ocorrenciasGet(this.state.filters.classificacao)?.result.map((option) => <option value={option}>{option}</option>)}
									</select>
								</>
							)}
						</div>
					</>

				)
			} else {
				component = <></>
			}

			return component;
		}

		const onSetFilter = (filtro) => {
			let stateFilter = this.state.newFilters.slice();
			stateFilter[index] = filtro;

			this.setState({ newFilters: stateFilter })
		}

		const getSelectOptions = (nameFiltro) => {
			const stateFilter = this.state.newFilters;

			if (stateFilter.includes(nameFiltro)) {
				return 'd-none'
			} else {
				return ''
			}
		}

		const removeFilter = (filterUnit, filterName) => {
			let stateFilter = this.state.newFilters;

			stateFilter.splice(filterUnit, 1);

			switch (filterName) {
				case 'data_cadastro':
					this.setState({ filters: { ...this.state.filters, signup_after: '', signup_before: '' } }, () => this.dataTableOccurrences.current.fetchData())

					break;

				case 'classificacao':
					this.setState({ filters: { ...this.state.filters, classificacao: {} } }, () => this.dataTableOccurrences.current.fetchData())

					break;

				default:
					break;
			}

			this.setState({ newFilters: stateFilter })
		}

		return (
			<Col md={12} className="d-flex m-0 p-0 filtroUnit" style={{ padding: '0' }}>
				<div className='d-flex flex-none' style={{ minWidth: '96%', flexWrap: 'wrap', rowGap: 16 }}>
					<select onChange={({ target }) => onSetFilter(target.value)} value={this.state.newFilters[index]} className="custom-select" style={{ width: 300, flex: 'none' }}>
						<option value="">Selecione um filtro</option>
						<option className={getSelectOptions('data_cadastro')} value="data_cadastro">Data de Cadastro</option>
						<option className={getSelectOptions('classificacao')} value="classificacao">Classificação</option>
					</select>

					{this.state.newFilters[index] != '' && (
						<div className='divisionArrow'>
							<FontAwesomeIcon icon={faChevronRight} />
						</div>
					)}

					{getFilterChoised(this.state.newFilters[index])}
				</div>

				<div onClick={() => removeFilter(index, this.state.newFilters[index])}>
					<FontAwesomeIcon icon={faTimes} />
				</div>
			</Col>
		)
	}

	getAnnouncementCustomLink = (announcement) => {
		const currentUrl = window.location.href;

		let preUrl = '';
		let complement = '';

		const localEnvironment = currentUrl.toLowerCase().includes('localhost');
		const devEnvironment = currentUrl.toLowerCase().includes('dev-admin');

		//preUrl
		if (localEnvironment || devEnvironment) {
			preUrl = 'https://dev-app.mellro.com'
		} else {
			preUrl = 'https://mellro.com'
		}

		//Complement
		if (announcement?.address?.city && announcement?.address?.neighbourhood) {
			complement = `/imovel/brasil/${announcement.address.city}/${announcement?.address?.neighbourhood}/${announcement.id}`;
		} else {
			complement = `/imovel/${announcement?.id}`;
		}

		return window.open(preUrl + complement)
	};

	getFriendlyTypeName = (type) => {
		if (type == 'EXTRAJUDICIAL') {
			return 'Alguém descumpriu o contrato';
		}

		if (type == 'TERMINATION_TERM') {
			return 'Rescisão de comum acordo';
		}

		if (type == 'CLOSURE_TERM') {
			return 'Acabou o tempo previsto do contrato';
		}
	}

	tempoRestanteTermination = (dataAgendada) => {
		const hoje = moment.utc(new Date());
		const fim = moment.utc(dataAgendada, 'YYYY-MM-DD');

		let diasFaltando = fim.diff(hoje, 'days');

		if (diasFaltando <= 0) {
			diasFaltando = 0;
		}

		return (
			<div className="tag" style={{ backgroundColor: '#FF3D60' }}>
				{diasFaltando + 1} dias
			</div>
		)
	}

	getValorTotalPendente = () => {
		// let valorPendente = 'N / A';
		let soma = 0;
		let somaProtestos = 0;
		let somaPendencias = 0;

		if (this.state.laudo?.fc_laudo) {
			this.state.laudo?.fc_laudo?.pretendentes?.forEach((obj, i) => {
				if (obj.laudo?.restricoes_financeiras?.result?.protestos?.resumo?.valor_total_pendencia) {
					soma = soma + parseFloat(obj.laudo?.restricoes_financeiras?.result?.protestos?.resumo?.valor_total_pendencia);
					somaProtestos = somaProtestos + parseFloat(obj.laudo?.restricoes_financeiras?.result?.protestos?.resumo?.valor_total_pendencia);
				}
				if (obj.laudo?.restricoes_financeiras?.result?.pendencias?.resumo?.valor_total_pendencia) {
					soma = soma + parseFloat(obj.laudo?.restricoes_financeiras?.result?.pendencias?.resumo?.valor_total_pendencia);
					somaPendencias = somaPendencias + parseFloat(obj.laudo?.restricoes_financeiras?.result?.pendencias?.resumo?.valor_total_pendencia);
				}
			});

			// valorPendente = formatBRL(valor);
		}

		return { total: formatBRL(soma), totalProtestos: formatBRL(somaProtestos), totalPendencias: formatBRL(somaPendencias) };
	};

	getFraude = () => {
		let fraude = 'N / A';

		if (this.state?.laudo?.fc_laudo) {
			let hasFraude = false;

			this.state?.laudo?.fc_laudo?.pretendentes?.forEach((obj, i) => {
				if (obj?.pessoa?.tem_alerta_fraude) hasFraude = true;
			});

			fraude = hasFraude ? 'Sim' : 'Nada consta';
		}

		return fraude;
	};

	checkSubmitLaudo = () => {
		if (this.state.submittingLaudo) {
			return true;
		}

		if (!this.state.laudo?.fc_laudo && this.state?.lastLaudoConsult) {
			if (moment().isBefore(moment(this.state?.lastLaudoConsult).add(2, 'hours'))) {
				return true;
			}
		}

		return false;
	}

	submitTenantLock = async (id) => {
		this.setState({ submittingLock: true })
		await resendEmailTenantLock(id).catch(err => {
			this.setState({ submittingLock: false })
		}).then(res => {
			this.setState({ submittingLock: false })
		})
	}

	submitCreditRefusedEmail = async (id) => {
		this.setState({ submittingLock: true })
		await resendEmailCreditRefused(id).catch(err => {
			this.setState({ submittingLock: false })
		}).then(res => {
			this.setState({ submittingLock: false })
		})
	}

	getDatatableAnalise = (analise) => {
		const parecereTags = analise.fc_laudo.parecer.locacao.parecer_inquilinos;

		let lastDateAcaoCivil;

		for (const u of analise.fc_laudo?.pretendentes) {
			if (u?.laudo?.restricoes_financeiras?.result?.acoes_civeis?.resumo?.data_ultima_ocorrencia) {
				lastDateAcaoCivil = moment(u?.laudo?.restricoes_financeiras?.result?.acoes_civeis?.resumo?.data_ultima_ocorrencia).format('DD/MM/YYYY')
			}
		}

		let acaoDespejo = { action: 'Ações de despejo' };
		acaoDespejo.result = parecereTags.temAcoes;
		acaoDespejo.status = parecereTags.temAcoes != "NADA CONSTA" ? 'Irregular' : 'Regular'

		let acoesCivil = { action: 'Ações civil' };
		acoesCivil.result = parecereTags.temAcoesCiveis;
		acoesCivil.status = parecereTags.temAcoesCiveis != "NADA CONSTA" ? 'Irregular' : 'Regular';
		acoesCivil.lastDate = lastDateAcaoCivil;

		// let acoesCriminais = { action: 'Ações criminais' };
		// acoesCriminais.result = parecereTags.temAcoesCriminais;
		// acoesCriminais.status = parecereTags.temAcoesCriminais != "NADA CONSTA" ? 'Irregular' : 'Regular'

		let acoesFraude = { action: 'Ações de fraude' };
		acoesFraude.result = this.getFraude() == 'Sim' ? parecereTags.score_conjunto : "NADA CONSTA";
		acoesFraude.status = this.getFraude() == 'Sim' ? 'Irregular' : 'Regular'

		return [acaoDespejo, acoesCivil, acoesFraude];
	}

	getStatusTerminationContract = (obj) => {
		let status = obj?.status;
		let backgroundColor = '#4AA3FF'

		if (obj?.deleted_at) {
			status = 'Cancelado'
			backgroundColor = '#FF3D60'
		} else if (obj?.status == 'AWAITING_COURT_APPROVAL') {
			status = 'Aguardando jurídico'
			backgroundColor = '#EFA200';
		} else if (obj?.status == 'AWAITING_SIGNING') {
			status = 'Aguardando assinatura'
			backgroundColor = '#EFA200';
		} else if (obj?.status == 'APPROVED') {
			status = 'Aprovado'
			backgroundColor = '#1CBB8C';
		} else if (obj?.status == 'SIGNED') {
			status = 'Assinado'
			backgroundColor = '#1CBB8C';
		} else {
			status = 'Rescindido pelo admin'
		}

		return (
			<div className="tag" style={{ textAlign: "center", backgroundColor: backgroundColor }}>
				{status}
			</div>
		);
	}

	handleOpenModalHistorico = (contratoSelecionado) => {
		this.setState({ showModalHistorico: true, contratoSelecionado: contratoSelecionado });
	}

	handleOpenCancelCheckEdition = (selectedContract) => {
		this.setState({ showModalCancelEdition: true, contratoSelecionado: selectedContract });
	}

	handleCancelEdition = async () => {
		const selectedContract = this.state?.contratoSelecionado;

		await cancelContractualEdit(selectedContract.id).catch((err) => {
			this.setState({ errors: ['Houve um erro ao cancelar a edição contratual, entre em contato com o suporte.'] });
			this.setState({ showModalCancelEdition: false });
		}).then(async (res) => {
			this.setState({ success: ['Edição contratual e assinatura do aditivo de contrato cancelados.'] });
			this.setState({ showModalCancelEdition: false });
			this.setState({ contratoSelecionado: { ...this.state.contratoSelecionado, status: 'CANCELED' } });
			window.scrollTo(0, 0)

			await this.handleGetContractualEdits(selectedContract.contract_id);
			await this.dataTableContractSignatureRef?.current?.fetchData();
		})
	}

	handlePararGarantia = () => {
		let contractVar = this.state?.data?.contract;

		updateStopGuarantee({ stop_guarantee: contractVar?.stop_guarantee ? false : true }, this.state.contract_id).catch((err) => {
			return this.setState({ errors: ['Ocorreu um erro, entre em contato com o suporte.'] });
		}).then((res) => {
			if (res?.status == 200) {
				this.setState({ errors: [], success: [contractVar?.stop_guarantee ? 'A garantia é válida novamente' : 'A garantia deixou de ser válida.'] });
			}
		}).finally(() => {
			this.handleClose();
			window.location.reload();
		})
	}

	handleReterValores = () => {
		let contractVar = this.state?.data?.contract;

		updateRetainValues({ retain_values: contractVar?.retain_values ? false : true }, this.state.contract_id).catch((err) => {
			return this.setState({ errors: ['Ocorreu um erro, entre em contato com o suporte.'] });
		}).then((res) => {
			if (res?.status == 200) {
				this.setState({ errors: [], success: [contractVar?.retain_values ? 'Os valores do contrato foram liberados.' : 'Os valores do contrato foram retidos.'] });
			}
		}).finally(() => {
			this.handleClose();
			window.location.reload();
		})
	}

	render() {
		const BoxInfo = ({ title, href, children, classNameBody, preenchido, noTag = false, indicadorNumero, comissionamento, cardComissionamento, noHeader, noEdit, center = false }) => {
			return (
				<div className='boxInfoUser' style={center ? { display: 'flex', justifyContent: 'center', alignItems: 'center' } : {}}>
					{noHeader ? null : (
						<div className='headerBoxInfoUser'>
							<div className='title-tag'>
								<h4>{title}</h4>

								{indicadorNumero && (
									<div className='indicadorUsuarios'>
										{(indicadorNumero) ?? 0}
									</div>
								)}

								{!cardComissionamento ? null :
									comissionamento ? (
										<div className='tag'>
											Sim
										</div>
									) :
										<div className='tag' style={{ backgroundColor: '#FCB92C' }}>
											Não
										</div>
								}

								{noTag ? null : preenchido ?
									<div className='tag'>
										Preenchido
									</div>
									:
									<div className='tag' style={{ backgroundColor: '#FCB92C' }}>
										Pendente
									</div>
								}
							</div>

							{noEdit ? null :
								<a href={href} className='btnSecundary'>
									EDITAR
									<FontAwesomeIcon icon={faPen} />
								</a>
							}
						</div>
					)}

					<div className={`${classNameBody} boxInfoBody`}>
						{children}
					</div>
				</div>
			)
		};

		const getWarranty = (key, plan = {}) => {
			let garantia = '';

			if (key == 'FREE') {
				garantia = 'Grátis';
			} else if (key == 'WARRANTY') {
				garantia = 'Pagamento';
			} else if (key == 'WARRANTY_GUARANTEE_DAMAGE') {
				garantia = 'Completa';
			} else {
				garantia = 'Não identificado';
			}

			return (
				<div className='d-flex'>
					{garantia}

					{plan?.percentage ?
						<div className="tag" style={{ backgroundColor: '#5664D2', marginLeft: 8 }}>
							{plan.percentage}%
						</div>
						: null}
				</div>
			)
		};

		const tempoRestante = (obj) => {
			if (obj?.details) {
				const { end_date, duration } = obj.details;

				const hoje = moment(new Date());
				const fim = moment(end_date, 'MM/DD/YYYY');

				let mesesFaltando = moment(fim).diff(hoje, 'M');
				let porcentagem = Math.round((mesesFaltando / duration) * 100)

				if (Math.sign(mesesFaltando) < 0) {
					mesesFaltando = 0;
				}

				if (porcentagem < 0) {
					porcentagem = 100;
				}

				return (
					<div className='d-flex'>
						{mesesFaltando}m

						<div className="tag" style={{ backgroundColor: '#5664D2', marginTop: -4, marginLeft: 8 }}>
							{porcentagem}%
						</div>
					</div>
				)
			} else return null;
		}

		const getNomeNegociacao = () => {
			let text = '';

			this.state.participantUsers.map((participante, index) => {
				let firstName = participante.name.split(' ');
				firstName = firstName[0];

				let lastName = index + 1 == this.state.participantUsers.length;

				if (text == '') {
					text = firstName;
				} else {
					if (lastName) {
						text = text + ' e ' + firstName;
					} else {
						text = text + ', ' + firstName;
					}
				}
			})

			text = text + ` em ${address?.neighbourhood ? `${address?.neighbourhood},` : ''} ${address?.city} - ${address?.state}`

			return text;
		};

		const negotiation = this?.state?.data;
		const property = negotiation?.property;
		const address = property?.address;
		const details = negotiation?.details;
		const values = details?.values;
		const contract = negotiation?.contract;
		const contractDetails = contract?.details;

		const hasAwaitingSignContratualEdit = this.state?.contractual_edits[0]?.find((edit) => edit.status == 'AWAITING_SIGN');

		const getContractStatus = () => {
			if (contract?.id && !contract?.is_terminated && !contract?.scheduled_termination_date) {
				return 'vigentes'
			} else if (contract && contract?.is_terminated) {
				return 'encerrados'
			} else if (contract?.id && contract?.scheduled_termination_date) {
				return 'encerrando'
			} else if (contract?.id && contract?.is_terminated == true && contract?.signed != true) {
				return 'cancelados'
			} else if (!['FINALIZED', 'FINISHED', 'AWAITING_SIGN'].includes(negotiation?.status)) {
				return 'negociando'
			} else if (contract.signed != true && contract.is_terminated != true) {
				return 'contratando'
			} else {
				return '';
			}
		}

		var tipoNegociacao = new URLSearchParams(document.location.search);
		tipoNegociacao = tipoNegociacao.get("typeNegociacao") ?? getContractStatus();

		return (
			<React.Fragment>
				<BetterCircularProgress loading={this.state.loading}>
					<div className="page-content container-page userShow userNew userIndex anuncio negociacao">
						<Container fluid>
							<div className='header' style={{ marginBottom: this.state?.success ? '1rem' : '' }}>
								<h1>Negociação</h1>

								<Breadcrumbs breadcrumbItems={[{ link: '/negotiation/index', title: 'Negociações' }, { link: '/negotiation/index', title: 'Negociações e contratos' }, { link: `/negotiation/show/${this.state.data?.id}`, title: `ID ${this.props.match.params.id}` }]} />
							</div>

							<BetterAlerts success={this.state.success} errors={this.state.errors} icon={faCircleCheck} style={{ marginBottom: '34px', color: '#1CBB8C', backgroundColor: '#D6F3EA', border: '1px solid #1CBB8C ' }} fullWidth={true} />

							<div className='boxUser'>
								<div className='headerUser'>
									{property?.images?.[0]?.medium?.url ?
										<div className='userPhoto' style={{ backgroundImage: `url(${property?.images?.[0]?.medium?.url})` }} />
										:
										<div className='userPhoto noPhotoAnnouncement' />
									}

									<div className='fotoParticipantes'>
										{this.state.participantUsers.map((participante, index) => (
											<div className="boxUserTableUsers" style={{ marginLeft: index > 0 ? `-15%` : '0%' }}>
												{participante?.profile_image?.medium?.url ?
													<div className='userPhoto' style={{ backgroundImage: `url(${participante?.profile_image?.medium?.url})` }} />
													:
													<div className='userPhotoNone' >
														<FontAwesomeIcon icon={faUser} />
													</div>
												}
											</div>
										))}
									</div>

									<div className='tagsAssets groupAlign' style={{ marginLeft: ((this?.state?.participantUsers?.length || 2) * 30) + 'px' }}>
										<h3>{getNomeNegociacao()}</h3>

										<div className='tagGroup'>
											{(this.state?.firstBill?.id && this.state?.firstBill?.is_paid === false) &&
												<div className='tag' style={{ backgroundColor: '#EFA200' }}>Aguardando pagamento do primeiro aluguel</div>
											}

											{((negotiation?.status != 'CREDIT_REFUSED' && negotiation?.status != 'APPROVED') || tipoNegociacao == 'negociando' || tipoNegociacao == 'contratando' && negotiation?.status == 'CREDIT_REFUSED') &&
												this.getStatusProposition(negotiation)
											}

											{this.state.securitized ?
												(
													<div className='tag' style={{ backgroundColor: '#4AA3FF' }}>Securitizado</div>
												) : null}

											{this.state.anticipated ?
												<div className='tag' style={{ backgroundColor: '#5664D2' }}>Antecipado</div>
												: null}

											{tipoNegociacao == 'encerrando' && (
												<div className='tag' style={{ backgroundColor: '#EFA200' }}>Encerramento agendado</div>
											)}

											{this.state.judicializado && (
												<div className='tag' style={{ backgroundColor: '#5664D2' }}>Judicializado</div>
											)}

											{contract?.stop_guarantee && (
												<div className='tag' style={{ backgroundColor: '#852032' }}>Garantias parada</div>
											)}

											{contract?.retain_values && (
												<div className='tag' style={{ backgroundColor: '#343A40' }}>Valores retidos</div>
											)}

											{(tipoNegociacao == 'contratando' && negotiation?.status != 'CREDIT_REFUSED') && (
												<div className='tag' style={{ backgroundColor: getLaudoInfo(this.state.laudo)?.color }}>
													{getLaudoInfo(this.state.laudo)?.text}
												</div>
											)}

											{(Number(this.state.totalOcorrencias ?? this.dataTableOccurrences?.current?.state?.totalRecords ?? 0)) > 0 && (
												<div className='tag' style={{ backgroundColor: '#FF3D60' }}>{(Number(this.state.totalOcorrencias ?? this.dataTableOccurrences?.current?.state?.totalRecords ?? 0))} ocorrências</div>
											)}
										</div>
									</div>
								</div>

								<div className='bodyAssets'>
									<div className='boxInformation'>
										<p>Criada em</p>
										<b>{moment(this?.state?.data?.created_at).format('DD/MM/YY HH:mm:ss')}</b>
									</div>

									<div className='boxInformation'>
										<p>Atualizado em</p>
										<b>{moment(this.state?.data?.updated_at).format('DD/MM/YY HH:mm:ss')}</b>
									</div>

									{tipoNegociacao == 'encerrando' && (
										<>
											<div className='boxInformation'>
												<p>Tempo de contrato</p>
												<b>{tempoRestante(contract)}</b>
											</div>

											{this.state?.terminations?.[0]?.type == 'EXTRAJUDICIAL' && this.state?.terminations?.[0]?.sentence_date ? (
												<div className='boxInformation'>
													<p>Data sentença</p>
													<b>{moment(contract?.scheduled_termination_date, 'YYYY-MM-DD').format('DD/MM/YY')}</b>
												</div>
											) : null}

											<div className='boxInformation'>
												<p>Rescisão agendada</p>
												<b>{this.state?.terminations?.[0]?.exit_date ? moment(this.state?.terminations?.[0]?.exit_date, 'YYYY-MM-DD').format('DD/MM/YYYY') : moment(contract?.scheduled_termination_date, 'YYYY-MM-DD').format('DD/MM/YYYY')}</b>
											</div>
										</>

									)}

									{tipoNegociacao == 'encerrados' && (
										<>
											{this.state?.terminations?.[0]?.type == 'EXTRAJUDICIAL' && this.state?.terminations?.[0]?.sentence_date ? (
												<div className='boxInformation'>
													<p>Data sentença</p>
													<b>{moment(this.state?.terminations?.[0]?.sentence_date, 'YYYY-MM-DD').format('DD/MM/YYYY')}</b>
												</div>
											) : null}

											<div className='boxInformation'>
												<p>Data rescisão</p>
												<b>{this.state?.terminations?.[0]?.exit_date ? moment(this.state?.terminations?.[0]?.exit_date, 'YYYY-MM-DD').format('DD/MM/YYYY') : moment(contract?.approved_end_date, 'YYYY-MM-DD').format('DD/MM/YYYY')}</b>
											</div>
										</>
									)}

									{(tipoNegociacao == 'negociando' || tipoNegociacao == 'contratando') &&
										<div className='boxInformation'>
											<p>Outros interessados</p>

											<b className='inBlue' style={{ float: 'right', cursor: 'default' }}>
												{this.state?.imovel?.interessados ?? 0} <FontAwesomeIcon icon={faUserSolid} />
											</b>
										</div>
									}

									<div className='boxInformation'>
										<p>Garantia</p>
										<b>{this.getWarranty(negotiation?.plan?.name)}</b>
									</div>

									<div className='boxInformation'>
										<p>Valor total</p>
										<b>{formatBRL(values?.total ?? values?.rent)}</b>
									</div>
								</div>

								<div className='footerAssets'>
									{tipoNegociacao == 'negociando' && (
										<>
											<button className='btnSecundary' onClick={() => this.setState({ showModalCancelarNegociacao: true })}>
												CANCELAR NEGOCIAÇÃO
											</button>

											<button className='btnSecundary' onClick={() => this.setState({ showModalCriarContrato: true })}>
												CRIAR CONTRATO
												<FontAwesomeIcon icon={faFileCirclePlus} />
											</button>

											{this.state.chatRoomId && (
												<>
													<Select type="submit" style={{ background: "#FFF", height: "40px", width: 236, marginLeft: 'auto' }} onChange={e => this.submitChatStatus(e)}
														value={this.state.chatStatus} className="custom-select">
														{[...this.state.chatStatusList].map((obj) => <option style={{ cursor: 'pointer' }} key={obj.key} value={obj.key}>{obj.label}</option>)}
													</Select>
												</>
											)}
										</>
									)}

									{!contract?.signed && !contract?.is_terminated && (
										<>
											<button className='btnSecundary' onClick={() => this.setState({ showModalCancelarNegociacao: true })}>
												CANCELAR CONTRATO
												<FontAwesomeIcon icon={faFileCirclePlus} />
											</button>

											{this.state.chatRoomId && (
												<>
													<Select type="submit" style={{ background: "#FFF", height: "40px", width: 236, marginLeft: 'auto' }} onChange={e => this.submitChatStatus(e)}
														value={this.state.chatStatus} className="custom-select">
														{[...this.state.chatStatusList].map((obj) => <option style={{ cursor: 'pointer' }} key={obj.key} value={obj.key}>{obj.label}</option>)}
													</Select>
												</>
											)}
										</>
									)}

									{tipoNegociacao == 'vigentes' && (
										<>
											<Link className='btnSecundary' to={`/contracts/friendly-agreement/${contract?.id}`}>
												CRIAR ACORDO
												<FontAwesomeIcon icon={faFileCirclePlus} />
											</Link>

											<button disabled={hasAwaitingSignContratualEdit} className='btnSecundary' onClick={() => window.open(`/contracts/contractual-edit/${contract?.id}`, "_self")}>
												EDITAR CONTRATO
												<FontAwesomeIcon icon={faFilePen} />
											</button>

											<button className='btnSecundary' onClick={() => window.open(`/contracts/editar-comissoes/${contract?.id}`, "_self")}>
												EDITAR COMISSÕES
												<FontAwesomeIcon icon={faSplit} />
											</button>

											<button className='btnSecundary' onClick={() => this.setState({ showModalPararGarantia: true })}>
												{contract?.stop_guarantee ? 'VOLTAR GARANTIAS' : 'PARAR GARANTIAS'}
												<FontAwesomeIcon icon={faShieldSlash} />
											</button>

											<button className='btnSecundary' onClick={() => this.setState({ showModalRetainValues: true })}>
												{contract?.retain_values ? 'LIBERAR VALORES' : 'RETER VALORES'}
												<FontAwesomeIcon icon={contract?.retain_values ? faEnvelopeOpenDollar : faHandsHoldingDollar} />
											</button>

											{(this.state?.firstBill?.id && this.state?.firstBill?.is_paid === false) ? (
												<>
													{this.state.chatRoomId && (
														<>
															<Select type="submit" style={{ background: "#FFF", height: "40px", width: 236, marginLeft: 'auto' }} onChange={e => this.submitChatStatus(e)}
																value={this.state.chatStatus} className="custom-select">
																{[...this.state.chatStatusList].map((obj) => <option style={{ cursor: 'pointer' }} key={obj.key} value={obj.key}>{obj.label}</option>)}
															</Select>
														</>
													)}
													<button className='btnRed' onClick={() => this.setState({ showModalCancelarNegociacaoPrimeiroPagamento: true })}>
														CANCELAR CONTRATO
														<FontAwesomeIcon icon={faFileCirclePlus} />
													</button>
												</>
											) : (
												<Link className='btnRed ml-auto' to={`/contracts/termination/${contract?.id}`} >
													AGENDAR RESCISÃO
													<FontAwesomeIcon icon={faFileCirclePlus} />
												</Link>
											)}

										</>
									)}

									{tipoNegociacao == 'encerrando' && (
										<>
											<Link className='btnSecundary' to={`/contracts/friendly-agreement/${contract?.id}`}>
												CRIAR ACORDO
												<FontAwesomeIcon icon={faFileCirclePlus} />
											</Link>

											<button disabled={hasAwaitingSignContratualEdit} className='btnSecundary' onClick={() => window.open(`/contracts/contractual-edit/${contract?.id}`, "_self")}>
												EDITAR CONTRATO
												<FontAwesomeIcon icon={faFilePen} />
											</button>

											<button className='btnSecundary' onClick={() => window.open(`/contracts/editar-comissoes/${contract?.id}`, "_self")}>
												EDITAR COMISSÕES
												<FontAwesomeIcon icon={faSplit} />
											</button>

											<button className='btnSecundary' onClick={() => this.setState({ showModalPararGarantia: true })}>
												{contract?.stop_guarantee ? 'VOLTAR GARANTIAS' : 'PARAR GARANTIAS'}
												<FontAwesomeIcon icon={faShieldSlash} />
											</button>

											<button className='btnSecundary' onClick={() => this.setState({ showModalRetainValues: true })}>
												{contract?.retain_values ? 'LIBERAR VALORES' : 'RETER VALORES'}
												<FontAwesomeIcon icon={contract?.retain_values ? faEnvelopeOpenDollar : faHandsHoldingDollar} />
											</button>

											<Link className='btnRed ml-auto' to={`/contracts/termination/${contract?.id}`} >
												NOVA RESCISÃO
												<FontAwesomeIcon icon={faFileCirclePlus} />
											</Link>
										</>
									)}

									{tipoNegociacao == 'encerrados' && (
										<>
											<Link className='btnSecundary' to={`/contracts/friendly-agreement/${contract?.id}`}>
												CRIAR ACORDO
												<FontAwesomeIcon icon={faFileCirclePlus} />
											</Link>

											<button className='btnSecundary' onClick={() => this.setState({ showModalPararGarantia: true })}>
												{contract?.stop_guarantee ? 'VOLTAR GARANTIAS' : 'PARAR GARANTIAS'}
												<FontAwesomeIcon icon={faShieldSlash} />
											</button>

											<button className='btnSecundary' onClick={() => this.setState({ showModalRetainValues: true })}>
												{contract?.retain_values ? 'LIBERAR VALORES' : 'RETER VALORES'}
												<FontAwesomeIcon icon={contract?.retain_values ? faEnvelopeOpenDollar : faHandsHoldingDollar} />
											</button>
										</>
									)}
								</div>
							</div>

							<div className='box'>
								<Nav tabs className="nav-tabs-custom" role="tablist">
									<NavItem>
										<NavLink id="info-tab" className={classnames({ active: this.state.activeTab === '1' })} onClick={() => { this.toggleTab('1'); }}>
											Detalhes
										</NavLink>
									</NavItem>

									<NavItem>
										<NavLink id="info-tab" className={classnames({ active: this.state.activeTab === '2' })} onClick={() => { this.toggleTab('2'); }}>
											Contrato
										</NavLink>
									</NavItem>

									<NavItem>
										<NavLink id="announcements-tab" className={classnames({ active: this.state.activeTab === '3' })} onClick={() => { this.toggleTab('3'); }}>
											<div className='tabName'>Pagamentos
												<div className='indicadorUsuarios'>
													{(Number(this.DataTableUserAnnouncementsRef?.current?.state?.totalRecords ?? 0)) ?? 0}
												</div>
											</div>
										</NavLink>
									</NavItem>

									<NavItem>
										<NavLink id="announcements-tab" className={classnames({ active: this.state.activeTab === '4' })} onClick={() => { this.toggleTab('4'); }}>
											<div className='tabName'>Documentos
												<div className='indicadorUsuarios'>
													{(Number(this.dataTableDocListRef?.current?.state?.totalRecords ?? 0)) ?? 0}
												</div>
											</div>
										</NavLink>
									</NavItem>

									<NavItem>
										<NavLink id="announcements-tab" className={classnames({ active: this.state.activeTab === '5' })} onClick={() => { this.toggleTab('5'); }}>
											<div className='tabName'>Ocorrências
												<div className='indicadorUsuarios'>
													{(Number(this.state.totalOcorrencias ?? this.dataTableOccurrences?.current?.state?.totalRecords ?? 0)) ?? 0}
												</div>
											</div>
										</NavLink>
									</NavItem>
								</Nav>

								<BetterCircularProgress loading={this.state.loading}>
									<TabContent activeTab={this.state.activeTab}>
										<TabPane tabId="1" role="tabpanel">
											<h2>Negociação</h2>

											<div className='boxInfos-container'>
												<BoxInfo title="VALORES" noTag noEdit={contract?.id ? true : false} href={`/negotiation/edit/${this.state.data?.id}?typeNegociacao=${tipoNegociacao}`}>
													<div className='boxInformationRow' style={{ gap: 16 }}>
														<div className='boxInformation'>
															<p>Valor do aluguel</p>

															<b>{formatBRL(values?.rent)}</b>
														</div>

														<div className='boxInformation'>
															<div style={{ display: 'flex', gap: 8, alignItems: 'end' }}>
																<div>
																	<p>IPTU</p>

																	<b>{formatBRL(values?.iptu ?? 0)}</b>
																</div>

																<div className='tag' style={{ backgroundColor: '#5664D2' }}>
																	{details?.tenant_pays?.iptu ? 'Inquilino Paga' : 'Proprietário paga'}
																</div>
															</div>
														</div>
													</div>

													<div className='boxInformation'>
														<div style={{ display: 'flex', gap: 8, alignItems: 'end' }}>
															<div>
																<p>Condomínio</p>

																<b>{formatBRL(values?.condo ?? 0)}</b>
															</div>

															<div className='tag' style={{ backgroundColor: '#5664D2' }}>
																{details?.tenant_pays?.condo ? 'Inquilino Paga' : 'Proprietário paga'}
															</div>
														</div>
													</div>
												</BoxInfo>

												<BoxInfo title="DETALHES DA NEGOCIAÇÃO" noEdit={contract?.id ? true : false} noTag href={`/negotiation/edit/${this.state.data?.id}?typeNegociacao=${tipoNegociacao}`}>
													<div className='boxInformationRow' style={{ alignItems: 'flex-start' }}>
														<div className='boxInformation'>
															<p>Origem</p>

															<b>
																{this.getOriginFriendlyName(negotiation?.origin)}
															</b>
														</div>

														<div className='boxInformation'>
															<p style={{ marginBottom: '12px' }}>Garantia</p>

															<div className='tag' style={{ backgroundColor: '#5664D2' }}>
																{negotiation?.plan?.name ? this.getWarranty(negotiation?.plan?.name) : 'Não preenchido'}
															</div>
														</div>
													</div>

													<div className='boxInformationRow' style={{ alignItems: 'flex-start' }}>
														<div className='boxInformation'>
															<p>Tempo de contrato</p>
															<b>{details?.duration ? `${details?.duration} meses` : 'Não preenchido'}</b>
														</div>

														<div className='boxInformation'>
															<p>% do contrato</p>
															<b>{negotiation?.plan?.percentage ? `${negotiation?.plan?.percentage}%` : 'Não preenchido'}</b>
														</div>

														{this.state?.coupon?.code && (
															<div className='boxInformation'>
																<p>Cupom aplicado</p>
																<div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
																	<Link className='inBlue' to={`/coupons/show/${this.state?.coupon?.couponId}`}>
																		{this.state?.coupon?.code}
																	</Link>
																</div>
																<p className='mb-0 mt-1'>Desconto de {this.state?.coupon?.discountType === 'PERCENTAGE' ? `${this.state?.coupon?.discountValue}%` : formatBRL(this.state?.coupon?.discountValue)}</p>
															</div>
														)}
													</div>
												</BoxInfo>
											</div>

											<div className='boxInfo-imovel-negociacao'>
												<div className='boxInfo-imovel-header' style={{ justifyContent: 'space-between' }}>
													<h2>Constituintes</h2>

													<div style={{ display: 'flex', gap: '8px' }}>
														{negotiation?.status === 'AWAITING_TENANT_LOCK' || negotiation?.status === 'CREDIT_REFUSED' ?
															<button disabled={this.state.submittingLock} onClick={(e) => {
																if (negotiation?.status === 'AWAITING_TENANT_LOCK') {
																	this.submitTenantLock(this.state.data?.id);
																} else {
																	this.submitCreditRefusedEmail(this.state?.data?.id)
																}
															}} className='btnSecundary'>
																{this.state.submittingLock ?
																	<i className={`fas fa-spinner fa-spin`} />
																	: (
																		<>
																			REENVIAR TRAVA
																			<FontAwesomeIcon icon={faArrowsRotate} />
																		</>
																	)
																}
															</button>
															:
															null
														}

														{contract?.id ? null :
															<a href={`/negotiation/edit/${this.state.data?.id}?typeNegociacao=${tipoNegociacao}`} className='btnSecundary'>
																EDITAR
																<FontAwesomeIcon icon={faPen} />
															</a>
														}
													</div>
												</div>

												<h4>IMÓVEL</h4>

												<div style={{ marginTop: 11.5 }} className='boxInfos-container boxInfos-container-unico boxInfoUser'>
													<div className='boxUser'>
														<div className='headerUser'>
															<div className='container-imovel-imagem'>
																{property?.images?.[0]?.medium?.url ?
																	<div className='userPhoto' style={{ backgroundImage: `url(${property?.images?.[0]?.medium?.url})`, flex: 'none' }} />
																	:
																	<div className='userPhoto noPhotoAnnouncement' style={{ flex: 'none' }} />
																}

																<div className='tagsAssets groupAlign' style={{ flex: 'auto' }}>
																	<h3 style={{ cursor: 'pointer', color: '#4AA3FF' }}><Link to={`/announcements/show/${property?.id}`}>{`${address?.street} ${address?.number}, ${address?.complement ?? ''} ${address?.neighbourhood ? `, ${address?.neighbourhood}` : ''} - ${address?.city}/${address?.state}`}</Link></h3>

																	<div className='tagGroup'>
																		<div className='tag' style={{ backgroundColor: property?.is_published ? '#1CBB8C' : '#5664D2' }}>
																			{property?.is_published ? 'Anunciado' : 'Não anunciado'}
																		</div>
																	</div>
																</div>
															</div>

															<div className='boxInformation'>
																<p>Criada em</p>
																<b>{moment(property?.created_at).format('DD/MM/YY HH:mm:ss')}</b>
															</div>

															<div className='boxInformation'>
																<p>Atualizado em</p>
																<b>{moment(property?.updated_at).format('DD/MM/YY HH:mm:ss')}</b>
															</div>

															<div className='boxInformation'>
																<p>Anúncio Mellro</p>
																<b className='inBlue' onClick={() => this.getAnnouncementCustomLink(property)}>
																	Link anúncio
																	<FontAwesomeIcon style={{ fontSize: 12, marginLeft: 8 }} icon={faLink} />
																</b>
															</div>
														</div>
													</div>
												</div>

												<h4>USUÁRIOS</h4>

												<div className='datatable-box' style={{ marginTop: 32 }}>
													<BetterCircularProgress loading={this.state.loading}>
														<DataTableUsersProposition usuariosProposition fetchEvent={this.fetchUsersProposition} />
													</BetterCircularProgress>
												</div>

												<div className='boxInfo-imovel-header'>
													<h2>Análise financeira</h2>

													<div className='d-flex' style={{ gap: 12 }}>
														{tipoNegociacao == 'negociando' && (
															<button onClick={this.state.laudo?.fc_laudo ? this.reprocessLaudo : this.executeLaudo} disabled={this.checkSubmitLaudo()} style={{ minWidth: 133.84 }} className='btnSecundary'>
																{this.state.submittingLaudo ?
																	<i className={`fas fa-spinner fa-spin`} />
																	:
																	<>
																		{this.state.laudo?.fc_laudo ? 'RETENTAR' : 'CONSULTAR'}
																		<FontAwesomeIcon icon={this.state.laudo?.fc_laudo ? faRotate : faUserMagnifyingGlass} />
																	</>
																}
															</button>
														)}

														{this.state.laudo?.fc_laudo &&
															<Link to={this.state.submittingLaudo ? `?typeNegociacao=${tipoNegociacao}` : `/negotiation/laudo/${this.props.match.params.id}?typeNegociacao=${tipoNegociacao}`} className='btnSecundary' style={{ minWidth: 133.84 }}>
																{this.state.submittingLaudo ?
																	<i className={`fas fa-spinner fa-spin`} />
																	:
																	<>
																		VER MAIS
																		<FontAwesomeIcon icon={faMagnifyingGlassPlus} />
																	</>
																}
															</Link>
														}
													</div>
												</div>

												<div className='boxInfos-container boxInfos-container-unico'>
													<BoxInfo noHeader noTag>

														<Row className='content-box' style={this.state.laudo?.fc_laudo ? {} : { justifyContent: 'center', padding: '32px 0' }}>
															{this.state.laudo?.fc_laudo ?
																<div className='analiseReview'>
																	<header style={{ alignItems: 'center' }}>
																		CONSULTADO EM {moment(this.state.laudo.created_at).format('DD/MM/YY hh:mm:ss')}

																		{getLaudoTag(this.state.laudo)}

																		{this.state.laudo?.fc_laudo?.humanized_analysis &&
																			<div className='tag' style={{ backgroundColor: '#1CBB8C' }}>
																				Aprovado humanizado
																			</div>
																		}
																	</header>

																	<div className='body'>
																		<div className='body-1'>
																			<div className='container-info1'>
																				<div className='boxInformation'>
																					<p>Renda pessoal</p>
																					<b>{formatBRL(this.state.laudo?.fc_laudo?.parecer?.sintese[0]?.renda?.principal?.valor ?? 0)}</b>
																				</div>

																				<div className='boxInformation'>
																					<p>Valor total pendente</p>
																					<b style={{ color: limparMoeda(this.getValorTotalPendente().total) > parseFloat(this.state.laudo?.fc_laudo?.locacao?.aluguel) ? '#FF3D60' : '#1CBB8C' }}>
																						{this.getValorTotalPendente().total}
																					</b>
																				</div>
																			</div>

																			<div className='container-info1'>
																				<div className='boxInformation'>
																					<p>Situação de CPF</p>
																					<div className='tag' style={this.state.laudo?.fc_laudo?.pretendentes?.[0]?.laudo?.situacao_cpf?.result == 'Regular' ? { background: '#1CBB8C' } : { background: '#FF3D60' }}>
																						{this.state.laudo?.fc_laudo?.pretendentes?.[0]?.laudo?.situacao_cpf?.result}
																					</div>
																				</div>

																				<div className='boxInformation'>
																					<p>Suspeita de óbito</p>
																					<div className='tag' style={this.state.laudo?.fc_laudo?.pretendentes?.[0]?.laudo?.suspeita_obito?.result ? { background: '#FF3D60' } : { background: '#1CBB8C' }}>
																						{this.state.laudo?.fc_laudo?.pretendentes?.[0]?.laudo?.suspeita_obito?.result ? 'Sim' : 'Não'}
																					</div>
																				</div>
																			</div>
																		</div>

																		{this.state?.laudo?.sugestaoTexto && (
																			<div className='body-2'>
																				<div className='boxInformation'>
																					<p>Sugestão da análise</p>
																					<b>
																						{this.state?.laudo?.sugestaoTexto != 'undefined' ? this.state?.laudo?.sugestaoTexto : 'Sem sugestões'}
																					</b>
																				</div>
																			</div>
																		)}

																		{this.state?.laudo?.humanized_justification && (
																			<div className='body-3'>
																				<div className='boxInformation'>
																					<p>Motivo da aprovação</p>
																					<span>
																						{this.state?.laudo?.humanized_justification}
																					</span>
																				</div>
																			</div>
																		)}
																	</div>

																	<footer>
																		<div className='datatable-box datatableFC' style={{ marginTop: 24 }}>
																			<DataTable value={this.getDatatableAnalise(this?.state?.laudo)} lazy={true} emptyMessage="Nenhum registro encontrado!">
																				<Column style={{ width: 124 }} field="action" />
																				<Column style={{ width: 108 }} field="status" body={(obj) => this.getTagRegularidade(obj)} />
																				<Column style={{ width: 792 }} field="result" body={(obj) => this.getLaudoResult(obj)} />
																			</DataTable>
																		</div>
																	</footer>
																</div>
																:
																<div className='no-images-box'>
																	Análise financeira pendente
																</div>
															}
														</Row>
													</BoxInfo>
												</div>
											</div>
										</TabPane>

										<TabPane tabId="2" role="tabpanel">
											<div className='boxInfo-imovel-negociacao'>
												{this.state?.terminations?.[0]?.id && (
													<div>
														<div className='boxInfo-imovel-header'>
															<div className='d-flex' style={{ gap: 16, margin: '40px 0 16px 0' }}>
																<h2 style={{ margin: 0 }}>Encerramento</h2>

																{this.getStatusTerminationContract(this.state?.terminations?.[0])}
															</div>

															<div className='ml-auto'>
																{this.state?.terminations?.[0]?.type == 'EXTRAJUDICIAL' && this.state?.terminations?.[0]?.status == 'AWAITING_COURT_APPROVAL' ?
																	<div className='d-flex' style={{ gap: 8 }}>
																		<button className='btnSecundary' onClick={() => this?.props?.history?.push(`/contracts/termination-confirm/${this.state?.terminations[0]?.id}`)}>
																			CANCELAR RESCISÃO
																			<FontAwesomeIcon icon={faFileSignature} />
																		</button>

																		<button className='btnRed' onClick={() => this?.props?.history?.push(`/contracts/termination-confirm/${this.state?.terminations[0]?.id}`)}>
																			APROVAR RESCISÃO
																			<FontAwesomeIcon icon={faFileCircleXmark} />
																		</button>
																	</div>
																	: null}
															</div>
														</div>

														<div className='boxInfos-container boxInfos-container-unico'>
															<div style={{ marginTop: 11.5 }} className='boxInfos-container boxInfos-container-unico boxInfoUser'>
																<div className='boxUser'>
																	<div className='headerUser'>
																		<div className='container-imovel-imagem' style={{ flex: 'inherit' }}>
																			{property?.images?.[0]?.medium?.url ?
																				<div className='userPhoto' style={{ backgroundImage: `url(${property?.images?.[0]?.medium?.url})` }} />
																				:
																				<div className='userPhoto noPhotoAnnouncement' />
																			}

																			<div className='fotoParticipantes' style={{ bottom: 0 }}>
																				{this.state.participantUsers.map((participante, index) => (
																					<div className="boxUserTableUsers" style={{ marginLeft: index > 0 ? `-15%` : '0%' }}>
																						{participante?.profile_image?.medium?.url ?
																							<div className='userPhoto' style={{ backgroundImage: `url(${participante?.profile_image?.medium?.url})` }} />
																							:
																							<div className='userPhotoNone' >
																								<FontAwesomeIcon icon={faUser} />
																							</div>
																						}
																					</div>
																				))}
																			</div>
																		</div>

																		<div className='boxInformation' style={{ flex: 1, marginLeft: ((this.state.participantUsers.length) * 22) }}>
																			<p style={{ marginBottom: 4 }}>Motivo</p>
																			<b>{this.getFriendlyTypeName(this.state?.terminations?.[0]?.type)}</b>
																			{this.getStatusTerminationContract(this.state?.terminations?.[0])}
																		</div>

																		<div className='boxInformation'>
																			<p style={{ marginBottom: 4 }}>Pagamento para</p>

																			<Link style={{ marginBottom: 4 }} to={`/users/show/${negotiation?.landlord?.id}`}>
																				{negotiation?.landlord?.name}
																			</Link>

																			{negotiation?.landlord?.employment.type ?
																				<div className='tag' style={{ backgroundColor: '#1CBB8C', marginTop: 4 }}>Dados bancários preenchidos</div>
																				:
																				<div className='tag' style={{ backgroundColor: '#EFA200', marginTop: 4 }}>Dados bancários não preenchidos</div>
																			}
																		</div>

																		{this.state?.terminations?.[0]?.type == 'EXTRAJUDICIAL' && this.state?.terminations?.[0]?.sentence_date ? (
																			<div className='boxInformation'>
																				<p style={{ marginBottom: 4 }}>Data sentença</p>
																				<b>
																					{moment(this.state?.terminations?.[0]?.sentence_date).format('DD/MM/YYYY')}
																				</b>
																				{this.tempoRestanteTermination(this.state?.terminations?.[0]?.sentence_date ?? new Date()) ?? 'N/A'}
																			</div>
																		) : null}

																		<div className='boxInformation'>
																			<p style={{ marginBottom: 4 }}>Data rescisão</p>
																			<b>
																				{this.state?.terminations[0]?.status == "AWAITING_COURT_APPROVAL" ? 'Aguardando jurídico' : moment(this.state.terminations[0]?.exit_date ? this.state.terminations[0]?.exit_date : new Date()).format('DD/MM/YYYY') ?? 'N/A'}
																			</b>
																			{this.state?.terminations[0]?.status == "AWAITING_COURT_APPROVAL" ?
																				<div className='tag' style={{ backgroundColor: '#FF3D60', marginTop: 4 }}>Aguardando</div> : this.tempoRestanteTermination(this.state.terminations[0]?.exit_date ?? new Date()) ?? 'N/A'
																			}
																		</div>

																		<div className='boxInformation'>
																			<p style={{ marginBottom: 4 }}>Requisitado por</p>

																			<Link style={{ marginBottom: 4 }} to={`/users/show/${this.state?.terminations[0]?.termination_requested_by == 'landlord' ? negotiation?.landlord?.id : negotiation?.tenant?.id}`}>
																				{this.state?.terminations[0]?.termination_requested_by != null ? this.state?.terminations[0]?.termination_requested_by == 'landlord' ? negotiation?.landlord?.name : negotiation?.tenant?.name : 'Sem informações'}
																			</Link>

																			<div className='d-flex' style={{ gap: 8, alignItems: 'center', marginTop: 4 }}>
																				<div className='tag' style={{ backgroundColor: '#4AA3FF' }}>{this.state?.terminations[0]?.termination_requested_by == 'landlord' ? 'Responsável' : 'Inquilino'}</div>
																				{moment(this.state?.terminations[0]?.created_at).format('DD/MM/YY HH:mm:ss')}
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												)}

												<div className='boxInfo-imovel-header'>
													<h2>Contratos e assinaturas</h2>

													{contract?.id ? null :
														<button className='btnSecundary' onClick={() => this.setState({ showModalCriarContrato: true })}>
															CRIAR CONTRATO
															<FontAwesomeIcon icon={faFileCirclePlus} />
														</button>
													}
												</div>

												<div className='boxInfos-container boxInfos-container-unico'>
													{contract?.id ? (
														<>
															<div className='datatable-box' style={{ marginTop: 32 }}>
																<BetterCircularProgress loading={this.state.loading}>
																	<DataTableContractSignature handleOpenModalHistorico={this.handleOpenModalHistorico} handleOpenModalCancelFriendlyAgreement={this.handleOpenModalCancelFriendlyAgreement} state={this.state} fetchEvent={this.fetchContractSignature} contractualEdits={this.state?.contractual_edits} letters_attorney={this.state?.letters_attorney} terminations={this.state?.terminations} friendlyAgreement={this.state?.friendly_agreement} handleOpenCancelCheckEdition={this.handleOpenCancelCheckEdition} ref={this.dataTableContractSignatureRef} onContractSelected={this.handleContractSelected} />
																</BetterCircularProgress>
															</div>
														</>
													) : (
														<BoxInfo noHeader noTag>
															<Row className='content-box' style={this.state?.data?.images?.length > 0 ? {} : { justifyContent: 'center', padding: '32px 0' }}>
																<div className='no-images-box'>
																	Nenhum contrato ativo vinculado
																</div>
															</Row>
														</BoxInfo>
													)}
												</div>

												<div className='boxInfo-imovel-header'>
													<h2>Renovações de contrato</h2>
												</div>

												{contractDetails?.renovation_info?.length > 0 ?
													<div className='datatable-box' style={{ marginTop: 24 }}>
														<DataTable value={contractDetails?.renovation_info} lazy={true}>
															<Column style={{ width: 104 }} field="date" header="Data" body={({ date }) => moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY')} />
															<Column style={{ width: 761 }} field="readjustment_indice" header="Tipo" body={({ readjustment_indice }) => readjustment_indice == "igp_m" ? 'IGP-M' : 'Acordo mútuo'} />
															<Column style={{ width: 99 }} field="readjustment_percentage" header="Reajuste" body={({ readjustment_percentage }) => `${readjustment_percentage}%`} />
															<Column style={{ width: 108 }} field="new_rent" header="Valor total" body={({ new_rent }) => formatBRL(new_rent || 0)} />
														</DataTable>
													</div>
													:
													<div className='boxInfos-container boxInfos-container-unico'>
														<BoxInfo noHeader noTag>
															<Row className='content-box' style={{ justifyContent: 'center', padding: '32px 0' }}>
																<div className='no-images-box'>
																	Nenhum contrato ativo vinculado
																</div>
															</Row>
														</BoxInfo>
													</div>
												}

												<div className='boxInfo-imovel-header'>
													<h2>Vistoria</h2>
												</div>

												{this.state.propertyInspectionList.length > 0 ?
													this.state.propertyInspectionList.map((vistoria, index) => (
														<Link key={index} to={`/contracts/property-inspection/show/${contract?.id}`}>
															<div style={{ marginTop: 11.5 }} className='boxInfos-container boxInfos-container-unico boxInfoUser vistoriaBox'>
																<div className='boxUser'>
																	<div className='headerUser'>
																		<div className='container-imovel-imagem'>
																			<div className='media-box' style={{ width: 33.6 * vistoria?.medias.slice(0, 5)?.length, height: 68 }}>
																				{vistoria?.medias?.slice(0, 5).map((media, i) => (
																					<>
																						<img src={media.Location} style={{ left: 16.8 * i + 1 }} />
																					</>
																				))}

																				{vistoria?.medias?.length > 5 ? (
																					<div className='medias-more'>
																						<p>+{vistoria?.medias?.length - 5}</p>
																					</div>
																				) : null}

																				{vistoria?.medias?.length === 0 ? (
																					<div className='inspection-inactive' style={{ marginRight: '10px' }}>
																						<FontAwesomeIcon icon={faImages} />
																					</div>
																				) : null}
																			</div>

																			<div className='tagsAssets groupAlign' style={{ flex: 'auto' }}>
																				<h3>{vistoria?.type == "EXIT" ? 'Vistoria de saída do imóvel' : 'Vistoria de entrada no imóvel'}</h3>

																				<div className='tagGroup'>
																					<div className='tag' style={{ backgroundColor: moment(vistoria?.end_date).isBefore(moment(new Date())) ? '#1CBB8C' : '#5664D2' }}>
																						{moment(vistoria?.end_date).isBefore(moment(new Date())) ? 'Finalizada' : 'Em andamento'}
																					</div>
																				</div>
																			</div>
																		</div>

																		<div className='boxInformation'>
																			<p>Postagens</p>
																			<b>{vistoria?.medias?.length ?? '0'}</b>
																		</div>

																		<div className='boxInformation'>
																			<p>Período de vistoria</p>
																			<b>{moment(vistoria?.start_date).format('DD/MM/YY')}</b> a <b>{moment(vistoria?.end_date).format('DD/MM/YY')}</b>
																		</div>

																		<div className='boxInformation'>
																			<p>Atualizado em</p>
																			<b>{moment(vistoria?.updated_at).format('DD/MM/YY HH:mm:ss')}</b>
																		</div>
																	</div>
																</div>
															</div>
														</Link>
													)) :
													<div className='boxInfos-container boxInfos-container-unico'>
														<BoxInfo noHeader noTag href={`/negotiation/edit/${this.state.data?.id}?typeNegociacao=${tipoNegociacao}`}>
															<Row className='content-box' style={{ justifyContent: 'center', padding: '32px 0' }}>
																<div className='no-images-box'>
																	Nenhuma vistoria ativa
																</div>
															</Row>
														</BoxInfo>
													</div>
												}
											</div>
										</TabPane>

										<TabPane tabId="3" role="tabpanel">
											<BetterCircularProgress loading={this.state.loading}>
												<div className='datatable-box' style={{ marginTop: 32 }}>
													<DataTableBillingNegotiation contract_id={this?.state?.data?.contract?.id} fetchEvent={this.fetchAnnouncementsBillings}
														crudUrl="/billing" idRow={`id`} ref={this.DataTableUserAnnouncementsRef} messageNoData='Nenhum pagamento vinculado' />
												</div>
											</BetterCircularProgress>
										</TabPane>

										<TabPane tabId="4" role="tabpanel">
											<Formik initialValues={this.getInitialValues()} onSubmit={(values, { resetForm }) => this.uploadDoc(values, resetForm)} enableReinitialize={true}>
												{({ values, handleChange, handleBlur, handleSubmit, setFieldValue, resetForm }) =>
													<form noValidate={true} autoComplete="off" onSubmit={handleSubmit}>
														<Col md={12} className="box-filtros" style={{ padding: 16, backgroundColor: '#F1F5F7', marginTop: 32 }}>
															<Col md={12} className="d-flex m-0 p-0">
																<div className='generalSearch'>
																	<Input className="inputCustomNew" type="text" value={this.state.searchDocumento} placeholder="Busque por ID ou palavra chave"
																		onChange={(e) => this.setState({ searchDocumento: e.target.value }, () => this.dataTableDocListRef.current.fetchData())} />

																	<FontAwesomeIcon icon={faMagnifyingGlass} />
																</div>

																{(contract?.id &&
																	<label htmlFor={'file'} className="buttonPrimary w-100" style={{ width: '100%', maxWidth: '121px' }}>
																		IMPORTAR
																		<FontAwesomeIcon icon={faFileImport} />

																		<input className="doc-juridico-select" type="file" name={'file'} id={'file'} style={{ display: 'none' }} accept="*/*"
																			onChange={(event) => { this.setState({ file: event.currentTarget.files[0] }) }} />
																	</label>
																)}
															</Col>
														</Col>

														{(contract?.id && this.state.file?.name) &&
															<Col md={12} className="box-filtros send-file form" style={{ padding: 16, backgroundColor: '#F1F5F7', marginTop: 8, justifyContent: 'flex-start' }}>
																<div className='formulario' style={{ flex: 1 }}>
																	<Row className="p-0 m-0">
																		<Col lg={3} className="p-0 m-0" style={{ maxWidth: 300 }}>
																			<Label>Título do arquivo</Label>

																			<div style={{ width: '-webkit-fill-available' }}>
																				<TextField readOnly type="text" variant="outlined" value={this.state?.file?.name ?? ''} />
																			</div>
																		</Col>

																		<Col lg={4} className="marginInputMobile" style={{ maxWidth: 250 }}>
																			<Label>Categoria</Label>

																			<Autocomplete name={'categoria'} className="mb-2" style={{ background: "#FFF", height: "40px" }} value={values.categoria} onBlur={handleBlur}
																				disableClearable noOptionsText="Sem resultados." variant="outlined" clearOnBlur={false} options={this.state.categorias}
																				onChange={e => setFieldValue('categoria', e.target.textContent)} renderInput={(params) => (<TextField {...params} type="text" placeholder="Digite para pesquisar" variant="outlined" />)}
																			/>
																		</Col>

																		{/* <Col lg={3} className="marginInputMobile" style={{ maxWidth: 250 }}>
																			<Label>Vencimento (opcional)</Label>

																			<Input className="form-control" type="date" id="vencimento" placeholder='DD/MM/AAAA' value={values.vencimento} style={{ background: "#FFF", height: "40px" }} onBlur={handleBlur} onChange={handleChange} />
																		</Col> */}

																		{['Confissão de dívida', 'Sentença', 'Processo Conhecimento', 'Processo Fase de Execução', 'Cobranças', 'Cobranças jurídicas'].includes(values?.categoria) ?
																			<Col md={2}>
																				<Label style={{ color: "#343A40" }}>
																					R$ cobrança/negociação
																				</Label>

																				<TextField name="payments_value" type="text" variant="outlined" value={values.payments_value} placeholder="R$ 0,00" onBlur={handleBlur}
																					onChange={(e) => handleChange(formatBRLInput(e))} style={{ background: '#fff' }} />
																			</Col>
																			: null}
																	</Row>

																	<div>
																		<Label style={{ width: '100%' }}>Descrição</Label>

																		<TextareaAutosize placeholder='Descreva' style={{ padding: '7px 14px', width: '100%' }} rowsMin={5} name="description" type="text" variant="outlined" value={values.description} onBlur={handleBlur} onChange={handleChange} multiline />
																	</div>

																	<div style={{ display: 'flex', marginLeft: 'auto', gap: 8 }}>
																		<button type='submit' className="buttonPrimary w-100" style={{ width: '100%', maxWidth: '136px' }}>
																			SALVAR DOC
																			<FontAwesomeIcon icon={faFileImport} />
																		</button>

																		<button type='button' className="btnSecundary w-100" onClick={() => { resetForm(); this.setState({ file: null }) }} style={{ width: '100%', maxWidth: '96px' }}>
																			CANCELAR
																		</button>
																	</div>
																</div>
															</Col>
														}

														<div className='datatable-box' style={{ marginTop: 32 }}>
															<BetterCircularProgress loading={this.state.loading}>
																<DataTableDocList filters={this.state.filters} contract_id={this?.state?.data?.contract?.id} fetchEvent={this.fetchJuridicDocs} crudUrl="/contracts" idRow={`id`} ref={this.dataTableDocListRef} />
															</BetterCircularProgress>
														</div>
													</form>
												}
											</Formik>
										</TabPane>

										<TabPane tabId="5" role="tabpanel">
											<Formik initialValues={this.getInitialValues()} onSubmit={(values, { resetForm }) => this.sendOccurrence(values, resetForm)} enableReinitialize={true}>
												{({ values, handleChange, handleBlur, handleSubmit, setFieldValue, resetForm }) =>
													<form noValidate={true} autoComplete="off" onSubmit={handleSubmit}>
														{tipoNegociacao != 'negociando' && (
															<>
																<Col md={12} className="box-filtros" style={{ padding: 16, backgroundColor: '#F1F5F7', marginTop: 32 }}>
																	<Col md={12} className="d-flex m-0 p-0">
																		<div className='generalSearch'>
																			<Input className="inputCustomNew" type="text" value={this.state.searchOcorrencia} placeholder="Busque por ID ou palavra chave"
																				onChange={(e) => this.setState({ searchOcorrencia: e.target.value }, () => this.dataTableOccurrences.current.fetchData())} />

																			<FontAwesomeIcon icon={faMagnifyingGlass} />
																		</div>

																		<button type="button" onClick={() => this.setState({ newFilters: [...this.state.newFilters, ''] })} className="btnSecundary w-100" style={{ width: '100%', maxWidth: '98px' }}>
																			FILTRO
																			<FontAwesomeIcon icon={faFilter} />
																		</button>

																		<div onClick={() => this.setState({ createOccurrence: true })} className="buttonPrimary w-100" style={{ width: '100%', maxWidth: '89px', marginLeft: 8, cursor: 'pointer' }}>
																			CRIAR
																			<FontAwesomeIcon icon={faFilePlus} />
																		</div>
																	</Col>
																</Col>

																{this.state.newFilters.map((filtroModular, index) => {
																	return (
																		<Col md={12} className="box-filtros" style={{ padding: 16, backgroundColor: '#F1F5F7', marginTop: 8 }}>
																			<this.FiltersModule filtroModular={filtroModular} key={index} index={index} />
																		</Col>
																	)
																})}
															</>
														)}

														{this.state.createOccurrence && (
															<Col md={12} className="box-filtros send-file form" style={{ padding: 16, backgroundColor: '#F1F5F7', marginTop: 8, justifyContent: 'flex-start' }}>
																<div className='formulario' style={{ flex: 1 }}>
																	<h4>CLASSIFICAÇÃO</h4>

																	<Row className="p-0 m-0" style={{ alignItems: 'center', gap: 8 }}>
																		<div style={{ maxWidth: 150, width: '100%' }}>
																			<Label>Contexto</Label>

																			<select onChange={({ target }) => setFieldValue('context', target.value)} value={values.context} className="custom-select" style={{ width: 150, flex: 'none' }}>
																				<option value=""></option>
																				{ocorrenciasGet(values)?.context.map((option) => <option value={option}>{option}</option>)}
																			</select>
																		</div>

																		{ocorrenciasGet(values)?.reason?.length > 0 && (
																			<>
																				<div className='divisionArrow' style={{ paddingTop: 20 }}>
																					<FontAwesomeIcon icon={faChevronRight} />
																				</div>

																				<div style={{ maxWidth: 200, width: '100%' }}>
																					<Label>Motivo</Label>

																					<select onChange={({ target }) => setFieldValue('reason', target.value)} value={values.reason} className="custom-select" style={{ width: 200, flex: 'none' }}>
																						<option value=""></option>
																						{ocorrenciasGet(values)?.reason.map((option) => <option value={option}>{option}</option>)}
																					</select>
																				</div>
																			</>
																		)}

																		{ocorrenciasGet(values)?.type?.length > 0 && (
																			<>
																				<div className='divisionArrow' style={{ paddingTop: 20 }}>
																					<FontAwesomeIcon icon={faChevronRight} />
																				</div>

																				<div style={{ maxWidth: 300, width: '100%' }}>
																					<Label>Tipo</Label>

																					<select onChange={({ target }) => setFieldValue('type', target.value)} value={values.type} className="custom-select" style={{ width: 300, flex: 'none' }}>
																						<option value=""></option>
																						{ocorrenciasGet(values)?.type.map((option) => <option value={option}>{option}</option>)}
																					</select>
																				</div>
																			</>
																		)}

																		{ocorrenciasGet(values)?.result?.length > 0 && (
																			<>
																				<div className='divisionArrow' style={{ paddingTop: 20 }}>
																					<FontAwesomeIcon icon={faChevronRight} />
																				</div>

																				<div style={{ maxWidth: 300, width: '100%' }}>
																					<Label>Resultado</Label>

																					<select onChange={({ target }) => setFieldValue('result', target.value)} value={values.result} className="custom-select" style={{ width: 300, flex: 'none' }}>
																						<option value=""></option>
																						{ocorrenciasGet(values)?.result.map((option) => <option value={option}>{option}</option>)}
																					</select>
																				</div>
																			</>
																		)}
																	</Row>

																	<h4>OCORRÊNCIA</h4>

																	<Row className="p-0 m-0" style={{ alignItems: 'center', gap: 8 }}>
																		<div style={{ maxWidth: 200, width: '100%' }}>
																			<Label>Status da ocorrência</Label>

																			<select onChange={({ target }) => setFieldValue('status', target.value)} value={values.status} className="custom-select" style={{ width: 200, flex: 'none' }}>
																				<option value=""></option>
																				{ocorrenciasGet(values)?.status.map((option) => <option value={option}>{option}</option>)}
																			</select>
																		</div>

																		<div style={{ maxWidth: 150, width: '100%' }}>
																			<Label>Admin responsável</Label>

																			<UserFilterComponent value={values.administrator} onChange={(_, value) => setFieldValue('administrator', value)} />
																		</div>
																	</Row>

																	<div>
																		<Label style={{ width: '100%' }}>Descrição</Label>

																		<TextareaAutosize placeholder='Descreva' style={{ padding: '7px 14px', width: '100%' }} rowsMin={5} name="description" type="text" variant="outlined" value={values.description} onBlur={handleBlur} onChange={handleChange} multiline />
																	</div>

																	<div style={{ display: 'flex', marginLeft: 'auto', gap: 8 }}>
																		<button disabled={!values.status || !values.administrator?.id || !values.context} type='submit' className="buttonPrimary w-100" style={{ width: '100%', maxWidth: '136px' }}>
																			SALVAR
																			<FontAwesomeIcon icon={faFileImport} />
																		</button>

																		<button type='button' className="btnSecundary w-100" onClick={() => { resetForm(); this.setState({ createOccurrence: false }) }} style={{ width: '100%', maxWidth: '96px' }}>
																			CANCELAR
																		</button>
																	</div>
																</div>
															</Col>
														)}

														<BetterCircularProgress loading={this.state.loading}>
															<div className='datatable-box' style={{ marginTop: 32 }}>
																{tipoNegociacao != 'negociando' && contract?.id ?
																	<DataTableOccurrences crudUrl={"/ocorrencias"} idRow={`id`} handleTotalOcorrencias={this.handleTotalOcorrencias}
																		negotiation_id={negotiation?.id} contract_id={contract?.id} fetchEvent={this.fetchOccurrences} ref={this.dataTableOccurrences} />
																	:
																	<BoxInfo noHeader noTag center>
																		<Row className='content-box' style={{ justifyContent: 'center', padding: '32px 0' }}>
																			<div className='no-images-box'>
																				Nenhuma ocorrência vinculada
																			</div>
																		</Row>
																	</BoxInfo>
																}
															</div>
														</BetterCircularProgress>
													</form>
												}
											</Formik>
										</TabPane>
									</TabContent>
								</BetterCircularProgress>
							</div>
						</Container>
					</div>
				</BetterCircularProgress>

				<Modal dialogClassName="modalHistorico" centered show={this.state.showModalHistorico} onHide={this.handleClose}>
					<Modal.Header closeButton={false}>
						<Modal.Title>Histórico do contrato</Modal.Title>

						<button onClick={this.handleClose} style={{ backgroundColor: 'transparent', color: '#8590A5', display: 'flex', alignItems: 'center', border: 'none', fontWeight: '900', fontSize: '18px' }}>
							X
						</button>
					</Modal.Header>
					<Modal.Body>
						<div style={{ marginTop: 16 }}>
							<DataTableContractSignature
								handleOpenModalHistorico={this.handleOpenModalHistorico}
								state={this.state}
								handleShowVerContrato={this.handleShowVerContrato}
								setState={this.setState}
								fetchEvent={this.fetchContractSignature}
								contractualEdits={this.state?.contractual_edits}
								letters_attorney={this.state?.letters_attorney}
								terminations={this.state?.terminations}
								modal
							/>
						</div>
					</Modal.Body>
					<Modal.Footer>
						<button className='btnSecundary' onClick={this.handleClose}>
							<FontAwesomeIcon icon={faArrowLeft} />
							Voltar
						</button>
					</Modal.Footer>
				</Modal>

				<Modal dialogClassName="modalVerContrato" centered show={this.state?.showModalVerContrato} onHide={this.handleClose}>
					<Modal.Header closeButton={false}>
						<Modal.Title>{this.state.contratoSelecionado.type == 'Procuração' ? 'Procuração' : 'Contrato de locação de imóvel residencial'} </Modal.Title>

						<button onClick={this.handleClose} style={{ backgroundColor: 'transparent', color: '#8590A5', display: 'flex', alignItems: 'center', border: 'none', fontWeight: '900', fontSize: '18px' }}>
							X
						</button>
					</Modal.Header>
					<Modal.Body>
						<div className='bodyAssets d-flex' style={{ gap: 24 }}>
							<div className='boxInformation'>
								<p>Criada em</p>
								<b>{moment(this.state.contratoSelecionado?.created_at).format('DD/MM/YY HH:mm:ss')}</b>
							</div>

							<div className='boxInformation'>
								<p>Prazo de contrato</p>
								<b>{this.state.contratoSelecionado?.details?.duration} meses</b>
							</div>

							<div className='boxInformation'>
								<p>Garantia</p>
								<b>{getWarranty(this.state.contratoSelecionado?.plan?.name, this.state.contratoSelecionado?.plan)}</b>
							</div>

							<div className='boxInformation'>
								<p>Valor total</p>
								<b>{formatBRL(this.state.contratoSelecionado?.details?.values?.total || 0)}</b>
							</div>
						</div>

						<div style={{ marginTop: 32 }}>
							<DataTableUsersProposition state={this.state} contratoSelecionado={this.state.contratoSelecionado} contract_id={contract?.id} fetchEvent={this.fetchUsersProposition} modal />
						</div>
					</Modal.Body>
					<Modal.Footer>
						<button className='btnSecundary' onClick={this.handleClose}>
							<FontAwesomeIcon icon={faArrowLeft} />
							Voltar
						</button>
					</Modal.Footer>
				</Modal>

				<Modal dialogClassName="modalCancelarNegociacao" centered show={this.state?.showModalCancelarNegociacao} onHide={this.handleClose}>
					<Modal.Header closeButton={false}>
						<Modal.Title>CANCELAR NEGOCIAÇÃO</Modal.Title>

						<button onClick={this.handleClose} style={{ backgroundColor: 'transparent', color: '#8590A5', display: 'flex', alignItems: 'center', border: 'none', fontWeight: '900', fontSize: '18px' }}>
							X
						</button>
					</Modal.Header>
					<Modal.Body>
						<div style={{ marginTop: 16 }}>
							<h3>Você tem certeza que deseja cancelar?</h3>

							<p>Ao continuar, o responsável pelo imóvel e o inquilino perderão o contato e a negociação será fechada</p>

							<Label className="pl-2 input-radio-group">
								<Input type="radio" name={'agreeCancelar'} id={`agreeCancelar`} value={!this.state.agreeCancelar} onChange={() => this.setState({ agreeCancelar: !this.state.agreeCancelar })} color="primary" checked={this.state.agreeCancelar} />
								<Label style={{ padding: "3px" }} for={`agreeCancelar`}>
									Quero cancelar a negociação e sei que esta ação é <b>irreversível.</b>
								</Label>
							</Label>
						</div>
					</Modal.Body>
					<Modal.Footer>
						<button className='btnSecundary' onClick={this.handleClose}>
							<FontAwesomeIcon icon={faArrowLeft} />
							VOLTAR
						</button>

						<button className='btnRed' disabled={!this.state.agreeCancelar} onClick={tipoNegociacao == 'contratando' ? this.handleDeleteContract : this.deleteNegotiation}>
							CANCELAR NEGOCIAÇÃO
							<FontAwesomeIcon icon={faCheck} />
						</button>
					</Modal.Footer>
				</Modal>

				<Modal dialogClassName="modalCancelarNegociacaoPrimeiroPagamento" centered show={this.state?.showModalCancelarNegociacaoPrimeiroPagamento} onHide={this.handleClose}>
					<Modal.Header closeButton={false} style={{ alignItems: 'center' }}>
						<Modal.Title style={{ color: 'var(--Cinza-600, #505D69)', fontFamily: 'Inter', fontSize: '16.26px', fontStyle: 'normal', fontWeight: 600, lineHeight: 'normal' }}>CANCELAR CONTRATO</Modal.Title>

						<button onClick={this.handleClose} style={{ backgroundColor: 'transparent', color: '#8590A5', display: 'flex', alignItems: 'center', border: 'none', fontWeight: '900', fontSize: '18px' }}>
							<FontAwesomeIcon icon={faX} style={{ color: '#8590A5', fontSize: 16 }} />
						</button>
					</Modal.Header>
					<Modal.Body>
						<div>
							<h3 style={{ color: '#343A40', fontFamily: 'Inter', fontSize: '16.26px', fontStyle: 'normal', fontWeight: 600, lineHeight: 'normal', marginBottom: 12 }}>Você tem certeza que deseja cancelar?</h3>

							<p style={{ color: '#505D69', fontFamily: 'Inter', fontSize: '13px', fontStyle: 'normal', fontWeight: 400, lineHeight: 'normal', marginBottom: 24 }}>Ao continuar, o responsável pelo imóvel e o inquilino perderão o contato e a negociação será fechada</p>

							<Label className=" input-radio-group" style={{ paddingLeft: '22px' }}>
								<Input type="checkbox" name={'agreeCancelar'} id={`agreeCancelar`} value={!this.state.agreeCancelar} onChange={() => this.setState({ agreeCancelar: !this.state.agreeCancelar })} color="primary" checked={this.state.agreeCancelar} style={{ border: '2px solid #DADBDD' }} />
								<Label style={{ padding: "3px", color: '#505D69', fontFamily: 'Inter', fontSize: '13px', fontStyle: 'normal', fontWeight: 400, lineHeight: 'normal' }} for={`agreeCancelar`}>
									Quero cancelar a negociação e sei que esta ação é <b style={{ color: '#FF3D60' }}>irreversível.</b>
								</Label>
							</Label>

							<Label className="input-radio-group" style={{ paddingLeft: '22px', marginBottom: 0 }}>
								<Input type="checkbox" name="agreeDidntPaid" id="agreeDidntPaid" value={!this.state.agreeDidntPaid} onChange={() => this.setState({ agreeDidntPaid: !this.state.agreeDidntPaid })} color="primary" checked={this.state.agreeDidntPaid} style={{ border: '2px solid #DADBDD' }} />

								<Label style={{ padding: "3px", color: '#505D69', fontFamily: 'Inter', fontSize: '13px', fontStyle: 'normal', fontWeight: 400, lineHeight: 'normal' }} for={`agreeDidntPaid`}>
									Confirmo que o inquilino <b style={{ fontWeight: '600' }}>não fez</b> não tem mais interesse em fazer o <b style={{ fontWeight: '600' }}>pagamento do primeiro aluguel</b> e não quer mais alugar o imóvel.
								</Label>
							</Label>
						</div>
					</Modal.Body>
					<Modal.Footer>
						<button className='btnSecundary' onClick={this.handleClose}>
							<FontAwesomeIcon icon={faArrowLeft} />
							VOLTAR
						</button>

						<button className='btnRed' disabled={!this.state.agreeCancelar || !this.state.agreeDidntPaid} onClick={this.handleCancelContract}>
							CANCELAR CONTRATO
							<FontAwesomeIcon icon={faCheck} />
						</button>
					</Modal.Footer>
				</Modal>

				<Modal dialogClassName="modalCriarContrato" centered show={this.state?.showModalCriarContrato} onHide={this.handleClose}>
					<Modal.Header closeButton={false}>
						<Modal.Title>CRIAR CONTRATO</Modal.Title>

						<button onClick={this.handleClose} style={{ backgroundColor: 'transparent', color: '#8590A5', display: 'flex', alignItems: 'center', border: 'none', fontWeight: '900', fontSize: '18px' }}>
							X
						</button>
					</Modal.Header>
					<Modal.Body>
						<div style={{ marginTop: 16 }}>
							<h3>Você tem certeza que deseja criar um contrato para esta negociação?</h3>

							<p>Ao continuar, o responsável pelo imóvel e todos os inquilinos serão notificados que um contrato está disponível para assinatura</p>

							<p>Os dados referentes ao contrato serão os mesmos da negociação. Para alterar algum dado, cancele o contrato, edite a negociação e crie um novo contrato.</p>
						</div>
					</Modal.Body>
					<Modal.Footer>
						<button className='btnSecundary' onClick={this.handleClose}>
							<FontAwesomeIcon icon={faArrowLeft} />
							VOLTAR
						</button>

						<button className='buttonGreen' onClick={this.buttonCreateContract}>
							CRIAR CONTRATO
							<FontAwesomeIcon icon={faFileCirclePlus} />
						</button>
					</Modal.Footer>
				</Modal>

				<Modal dialogClassName="modalCancelarNegociacao" centered show={this.state?.showModalPararGarantia} onHide={this.handleClose}>
					<Modal.Header closeButton={false}>
						<Modal.Title>{contract?.stop_guarantee ? 'VOLTAR GARANTIAS' : 'PARAR GARANTIAS'}</Modal.Title>

						<button onClick={this.handleClose} style={{ backgroundColor: 'transparent', color: '#8590A5', display: 'flex', alignItems: 'center', border: 'none', fontWeight: '900', fontSize: '18px' }}>
							X
						</button>
					</Modal.Header>
					<Modal.Body>
						<div style={{ marginTop: 16 }}>
							<h3>{contract?.stop_guarantee ? 'Você tem certeza que deseja voltar as garantias desse contrato?' : 'Você tem certeza que deseja parar as garantias desse contrato?'}</h3>

							<p>{contract?.stop_guarantee ? 'Ao continuar, apenas as garantias futuras serão realizadas automaticamente neste contrato.' : 'Ao continuar, nenhuma garantia futura será realizada neste contrato.'}</p>

							<Label className="pl-2 input-radio-group">
								<Input type="radio" name={'agreeCancelar'} id={`agreeCancelar`} value={!this.state.agreeCancelar} onChange={() => this.setState({ agreeCancelar: !this.state.agreeCancelar })} color="primary" checked={this.state.agreeCancelar} />
								<Label style={{ padding: "3px" }} for={`agreeCancelar`}>
									{contract?.stop_guarantee ? 'Confirmo que desejo voltar as garantias desse contrato.' : 'Confirmo que desejo parar as garantias desse contrato.'}
								</Label>
							</Label>
						</div>
					</Modal.Body>
					<Modal.Footer>
						<button className='btnSecundary' onClick={this.handleClose}>
							<FontAwesomeIcon icon={faArrowLeft} />
							VOLTAR
						</button>

						<button className='btnRed' disabled={!this.state.agreeCancelar} onClick={this.handlePararGarantia}>
							{contract?.stop_guarantee ? 'VOLTAR GARANTIAS' : 'PARAR GARANTIAS'}
							<FontAwesomeIcon icon={faCheck} />
						</button>
					</Modal.Footer>
				</Modal>

				<Modal dialogClassName="modalCancelarNegociacao" centered show={this.state?.showModalRetainValues} onHide={this.handleClose}>
					<Modal.Header closeButton={false}>
						<Modal.Title>{contract?.retain_values ? 'LIBERAR VALORES' : 'RETER VALORES'}</Modal.Title>

						<button onClick={this.handleClose} style={{ backgroundColor: 'transparent', color: '#8590A5', display: 'flex', alignItems: 'center', border: 'none', fontWeight: '900', fontSize: '18px' }}>
							X
						</button>
					</Modal.Header>
					<Modal.Body>
						<div style={{ marginTop: 16 }}>
							<h3>{contract?.retain_values ? 'Você tem certeza que deseja liberar valores desse contrato?' : 'Você tem certeza que deseja reter valores desse contrato?'}</h3>

							<p>{contract?.retain_values ? 'Ao continuar, apenas todos os valores retidos serão liberados e garantias de pagamento serão executadas neste contrato.' : 'Ao continuar, nenhum valor será transferido para as contas bancárias de todos os recebedores.'}</p>

							<Label className="pl-2 input-radio-group">
								<Input type="radio" name={'agreeCancelar'} id={`agreeCancelar`} value={!this.state.agreeCancelar} onChange={() => this.setState({ agreeCancelar: !this.state.agreeCancelar })} color="primary" checked={this.state.agreeCancelar} />
								<Label style={{ padding: "3px" }} for={`agreeCancelar`}>
									{contract?.retain_values ? 'Confirmo que desejo liberar valores desse contrato.' : 'Confirmo que desejo reter valores desse contrato.'}
								</Label>
							</Label>
						</div>
					</Modal.Body >
					<Modal.Footer>
						<button className='btnSecundary' onClick={this.handleClose}>
							<FontAwesomeIcon icon={faArrowLeft} />
							VOLTAR
						</button>

						<button className='btnRed' disabled={!this.state.agreeCancelar} onClick={this.handleReterValores}>
							{contract?.retain_values ? 'LIBERAR  VALORES' : 'RETER VALORES'}
							<FontAwesomeIcon icon={faCheck} />
						</button>
					</Modal.Footer>
				</Modal>

				<Modal dialogClassName="modalCancelarNegociacao" centered show={this.state?.showModalCancelEdition} onHide={() => this.setState({ showModalCancelEdition: false })}>
					<Modal.Header closeButton={false}>
						<Modal.Title>Tem certeza que deseja cancelar a edição contratual?</Modal.Title>

						<button onClick={() => this.setState({ showModalCancelEdition: false })} style={{ backgroundColor: 'transparent', color: '#8590A5', display: 'flex', alignItems: 'center', border: 'none', fontWeight: '900', fontSize: '18px' }}>
							X
						</button>
					</Modal.Header>
					<Modal.Body>
						<div >
							<p style={{ marginBottom: '0px' }}>Ao cancelar, não será possível assinar o aditivo de contrato e a edição dos dados não vai acontecer</p>
						</div>
					</Modal.Body>
					<Modal.Footer>
						<button className='btnThird' onClick={async () => this.handleCancelEdition()}>
							Cancelar edição
						</button>

						<button className='buttonPrimary' onClick={() => this.setState({ showModalCancelEdition: false })}>
							VOLTAR
						</button>
					</Modal.Footer>
				</Modal>

				<Modal dialogClassName="modalCancelarNegociacao" centered show={this.state?.showModalCancelFriendlyAgreement} onHide={this.handleClose}>
					<Modal.Header closeButton={false}>
						<Modal.Title>{'Tem certeza que deseja cancelar o acordo e a assinatura da confissão de dívida?'}</Modal.Title>

						<button onClick={this.handleClose} style={{ backgroundColor: 'transparent', color: '#8590A5', display: 'flex', alignItems: 'center', border: 'none', fontWeight: '900', fontSize: '18px' }}>
							X
						</button>
					</Modal.Header>
					<Modal.Body>
						<div style={{ marginTop: 16 }}>
							<p>Ao cancelar, não será possível assinar a confissão de dívida e a criação das parcelas não vai acontecer.</p>
						</div>
					</Modal.Body>
					<Modal.Footer>
						<button className='btnGreyNormal' onClick={this.handleCancelFriendlyAgreement}>
							Cancelar acordo
						</button>

						<button className='btnSecundaryNormal' onClick={this.handleClose}>
							Voltar
						</button>
					</Modal.Footer>
				</Modal>
			</React.Fragment >
		);
	}
}

export default withRouter(NegotiationShow);