import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import _ from 'lodash';
import { formatBRL } from '../../helpers/Utils';
import moment from 'moment';


export default class DataTableUsersHistoricoFinanceiro extends Component {
	constructor() {
		super();

		this.state = {
			data: [],
			rows: 10,
			totalRecords: 0,
			first: 0,
			last: 0,
			loading: false,
			sortField: 'id',
			sortOrder: '1',
			fetchingCsvData: false,
			csvData: [],
		};

		this.onPage = this.onPage.bind(this);
	}

	componentDidMount() {
		let DataTableUsersHistoricoFinanceiro = JSON.parse(localStorage.getItem('DataTableUsersHistoricoFinanceiro'));

		if (DataTableUsersHistoricoFinanceiro) {
			this.setState({
				rows: DataTableUsersHistoricoFinanceiro.rows,
				totalRecords: DataTableUsersHistoricoFinanceiro.totalRecords,
				first: DataTableUsersHistoricoFinanceiro.first,
				last: DataTableUsersHistoricoFinanceiro.last,
				loading: DataTableUsersHistoricoFinanceiro.loading,
				sortField: DataTableUsersHistoricoFinanceiro.sortField,
				sortOrder: DataTableUsersHistoricoFinanceiro.sortOrder,
			}, () => {
				this.fetchData();
			});
		} else {
			this.fetchData();
		}
	}

	componentDidUpdate(prevProps) {
		if (prevProps.filters != this.props.filters) {
			this.onFilterChanged();
		}
	}

	onFilterChanged = _.debounce(() => {
		if (!this.state.isLoading) {
			this.fetchData();
		}
	}, 700);

	refreshTable() {
		this.onPage(this.state);
	}

	onPage = async (event) => {
		if (event.data) {
			event.data = null;
		}

		let DataTableUsersHistoricoFinanceiro = {
			rows: event.rows,
			totalRecords: this.state.totalRecords,
			first: event.first,
			last: event.rows + event.first,
			loading: this.state.loading,
			sortField: this.state.sortField,
			sortOrder: this.state.sortOrder,
		};

		localStorage.setItem('DataTableUsersHistoricoFinanceiro', JSON.stringify(DataTableUsersHistoricoFinanceiro));

		this.setState({
			...this.state,
			rows: event.rows,
			loading: true
		});

		this.fetchData(event.rows, event.first);
	};

	fetchData = (limit, skip) => {
		let data = {
			$limit: limit ? limit : this.state.rows,
			$skip: skip != null ? skip : this.state.first,
			$sort: { [this.state.sortField]: this.state.sortOrder }
		};

		this.props.fetchEvent(data).then(async (data) => {
			if (data?.data) {
				this.setState({
					...this.state,
					data: data?.data?.data,
					totalRecords: data?.data?.data?.totalCount,
					first: data?.data?.data?.offset,
					last: data?.data?.data?.offset + data?.data?.limit,
					loading: false
				});
			}
		});
	};

	getValor = valor => {
		return (
			<div style={{ textAlign: 'center' }}>
				<p>{valor ? formatBRL(valor) : ''}</p>
			</div>
		);
	};

	sortTable = e => {
		this.setState({ sortField: e.sortField, sortOrder: e.sortOrder }, () => {
			let DataTableUsersHistoricoFinanceiro = {
				rows: this.state.rows,
				totalRecords: this.state.totalRecords,
				first: this.state.first,
				last: this.state.last,
				loading: this.state.loading,
				sortField: this.state.sortField,
				sortOrder: this.state.sortOrder,
			};

			localStorage.setItem('DataTableUsersHistoricoFinanceiro', JSON.stringify(DataTableUsersHistoricoFinanceiro));

			this.fetchData();
		});
	};

	render() {
		const paginatorRight = (
			<button onClick={() => { this.fetchData(this.state?.totalRecords); this.setState({ rows: this.state?.totalRecords }) }} className='btnSecundary' style={{ marginLeft: 24 }}>
				Ver todos
			</button>
		);

		return (
			<DataTable ref={(el) => this.dt = el} value={this.state.data.data} paginator={true} rows={this.state.rows} rowsPerPageOptions={[10, 25, 50, this.state?.totalRecords]}
				first={this.state.first} last={this.state.last} header={null} responsive={true} className="ml-auto" paginatorRight={paginatorRight}
				totalRecords={this.state.totalRecords} lazy={true} onPage={this.onPage} loading={this.state.loading} loadingIcon={`fas fa-sync fa-spin`}
				paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
				currentPageReportTemplate="Mostrando {first} até {last} de {totalRecords} registros" emptyMessage="Nenhum registro encontrado!"
				onSort={(e) => this.sortTable(e)} sortField={this.state.sortField} sortOrder={this.state.sortOrder}>
				<Column field="date" body={({ date }) => moment(date).format('DD/MM/YYYY')} header="Data" />
				<Column field="id" header="ID" />
				<Column body={({ value }) => this.getValor(value)} field="value" header="Valor" />
				<Column body={({ balance }) => this.getValor(balance)} field="balance" header="Saldo" />
				<Column field="type" style={{ wordBreak: 'break-all' }} header="Tipo" />
				<Column field="description" header="Descrição" />
				<Column body={({ transferId }) => transferId ?? '-'} field="transferId" header="ID da transferência" />
			</DataTable>
		);
	}
}