import React, { Component, createRef } from 'react';
import { Container, Card, CardBody, Col, FormGroup, Label, TabContent, TabPane, Button, Nav, NavItem, NavLink, Alert, Table, Input, DropdownItem, Dropdown, DropdownToggle, Row, DropdownMenu } from "reactstrap";
import classnames from 'classnames';
import BetterCircularProgress from '../../components/BetterCircularProgress';
import { get as getUser } from '../../crud/users.crud';
import { get as getContract, getContractFileSign, deleteContract, getContractAdminLogs, getContractUsers, createContractAdminLogs, queryContractualEdits } from '../../crud/contracts.crud';
import { get as getBillings, getFriendlyAgreement } from '../../crud/billings.crud';
import { getPlanById } from "../../crud/plans.crud";
import { Link, Redirect } from "react-router-dom";
import { get as getPlans } from "../../crud/plans.crud";
import { formatBRL } from '../../helpers/Utils';
import Breadcrumbs from '../../components/Breadcrumb';
import DataTableContractsPayment from '../../components/datatables/DataTableContractsPayment';
import moment from 'moment';
import { getVistoriaFile, getVistoriasByContract } from '../../crud/property-inspection.crud';
import BetterAlerts from '../../components/BetterAlerts';
import DocsJuridicos from '../../components/contract/DocsJuridicos';
import { cancelTermination, getTerminationUsers, query as queryTermination, getTerminationFile, getClosureTermFile } from '../../crud/termination.crud';
import DataTableLogsContrato from '../../components/datatables/logs/DataTableLogsContrato';
import { TextField } from '@material-ui/core';
import arrowDownToLine from '../../assets/images/icons-custom/arrow-down-to-line.svg';
import xmark from '../../assets/images/icons-custom/file-xmark.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faFilePen } from '@fortawesome/pro-solid-svg-icons';

class ContractShow extends Component {
	dataTableLogsContratoRef = createRef();

	state = {
		loading: false,
		success: [],
		message: null,
		data: null,
		landlord: null,
		tenant: null,
		activeTab: '1',
		tenant_parties: [],
		planName: '',
		proposition_id: null,
		contract_id: null,
		propertyInspectionList: [],
		canRestore: false,
		commissionParticipants: [],
		affiliate_user_list: [],
		terminations: [],
		contractual_edits: [],
		disable_edit: false,
		terminations_users: [],
		comentario: '',
		testemunhas: [],
		filters: {
			depoisDe: '',
			antesDe: '',
			informacao: ''
		},
		flags: {
			tenant_sign: false,
			landlord_sign: false,
			show_depoisDe: false,
			show_antesDe: false,
			show_informacao: false,
		},
		friendlyAgreement: [],
		showCreateFriendlyAgreement: false,
		disableVistoria: false
	}

	componentDidMount = () => {
		this.setState({ loading: true });

		if (this.props.location.state) {
			if (this.props.location.state.success) {
				this.setState({ success: this.props.location.state.success });
			}
		}

		this.fetchData();

		this.setState({ loading: false })
	};

	fetchData = () => {
		getContract(this.props.match.params.id).catch(err => {
			alert('An error occurred, please contact the support.');
		}).then(async res => {
			if (res?.status == 200) {
				if (res.data) {
					this.setState({ data: res.data.data });
					let contract = res.data.data;
					this.setState({ proposition_id: contract.entity.proposition_id });
					this.setState({ contract_id: contract.entity.id });
					let affiliate_user_list = [];

					contract.entity.contract_users.map(async user_contract => {
						if (user_contract.user_id == contract.entity.tenant_id && user_contract.signed) {
							this.setState({
								flags: {
									...this.state.flags,
									tenant_sign: user_contract.signed
								}
							});
						}

						if (user_contract.user_id == contract.entity.landlord_id && user_contract.signed) {
							this.setState({
								flags: {
									...this.state.flags,
									landlord_sign: user_contract.signed
								}
							});
						}

						if (user_contract.affiliate_users) {
							user_contract.affiliate_users.map(async (obj, i) => {
								await getUser(obj.affiliate_id).catch(error => {
									alert('An error occurred, please contact the support.');
								}).then(async result => {
									if (result?.status == 200) {
										if (result.data) {
											let findUserArray = affiliate_user_list.find(afiliado => afiliado.id == result.data.data.id);

											if (!findUserArray) {
												affiliate_user_list.push(result.data.data);
											}
										}
									}
								});
							})
						}
					});

					this.setState({ affiliate_user_list });

					if (contract.entity.is_terminated) {
						const params = { contract_id: contract?.entity?.id, $sort: { ['due_date']: -1 }, type: 'termination' };
						await getBillings(params).then(resultBilling => {
							if (resultBilling?.status == 200) {
								if (resultBilling?.data?.data?.length > 0) {
									if (resultBilling.data.data[0].type == 'termination' && !resultBilling.data.data[0].is_paid) {
										if (contract?.entity?.approved_end_date
											&& moment(contract.entity?.approved_end_date).add(45, 'days').isSameOrAfter(moment())) {
											this.setState({ canRestore: true })
										}
									}
								}
							}
						});
					}

					await getUser(contract.entity.landlord_id).catch(err => {
						alert('An error occurred, please contact the support.');
					}).then(async res => {
						if (res?.status == 200) {
							if (res.data) {
								this.setState({ landlord: res.data.data });
							}
						}
					});

					await getUser(contract.entity.tenant_id).catch(err => {
						alert('An error occurred, please contact the support.');
					}).then(async res => {
						if (res?.status == 200) {
							if (res.data) {
								this.setState({ tenant: res.data.data });
							}
						}
					});

					let plan_list = [];
					let plan = null;

					await getPlans().then(({ data }) => {
						plan_list = data.data;
					});

					if (plan_list.length > 0) {
						plan_list.forEach((obj, i) => {
							if (obj.id == res.data?.entity?.coupon?.plan_id) {
								plan = obj;
								this.setState({ couponPlan: plan });
							}
						});
					}

					if (contract?.entity?.tenant_parties) {
						let parties = [];

						contract?.entity?.tenant_parties.forEach(async id => {
							await getUser(id).catch(err => {
								alert('An error occurred, please contact the support.');
							}).then(async res => {
								if (res?.status == 200) {
									if (res.data) {
										parties.push(res.data.data);
									}
								}
							});
						});

						this.setState({ tenant_parties: parties });
					}

					for (const participant of contract.entity.contract_users) {
						if (participant.commissioning && participant.user_id !== contract.entity.landlord_id) {
							await getUser(participant.user_id).catch(err => {
								alert('An error occurred, please contact the support.');
							}).then(res => {
								if (res?.status == 200) {
									if (res.data) {
										const newArr = this.state.commissionParticipants;
										newArr.push({ ...participant, user: { name: res.data.data.name } });
										this.setState({ commissionParticipants: newArr })
									}
								}
							})
						}
					}

					if (this.state.data?.entity?.proposition?.plan_id) {
						await getPlanById(this.state.data?.entity?.proposition?.plan_id).then((res) => {
							if (res?.status == 200) {
								const planName = res.data.data.friendly_name[0].toUpperCase() + res.data.data.friendly_name.slice(1).toLowerCase();
								this.setState({ ...this.state, planName: planName });
							}
						});
					}

					getFriendlyAgreement({ contract_id: contract?.entity?.id }).then(res => {
						if (res?.data?.data) {
							this.setState({ friendlyAgreement: res?.data?.data })

							if (res?.data?.total === 0) {
								this.setState({ showCreateFriendlyAgreement: true })
							}
						}
					})
				}
			}
		}).finally(() => {
			this.setState({ loading: false });
		});

		queryTermination({ contract_id: this.props.match.params.id }).catch(err => {

		}).then(res => {
			this.setState({ terminations: res.data.data });

			if (res.data?.data[0]?.id) {
				getTerminationUsers({ termination_id: res.data?.data[0]?.id }).then(res => {
					let testemunhas = [];

					res.data.data.map(user => {
						if (user.user_type == 'witness') {
							testemunhas.push(user);
						}
					});

					testemunhas.sort(function (a, b) {
						const createdAtA = new Date(a.created_at);
						const createdAtB = new Date(b.created_at);

						return createdAtA - createdAtB;
					});

					this.setState({ terminations_users: res.data.data, testemunhas: testemunhas });
				}).catch((err) => {
					console.log(err)
				});
			}
		});

		queryContractualEdits({ contract_id: this.props.match.params.id, status_ne: 'SIGNED', '$sort[created_at]': -1 }).catch(err => {
			console.log(err);
		}).then(res => {
			const awaiting = res.data.data.find(obj => obj.status === 'AWAITING_SIGN');
			if (awaiting) {
				this.setState({ contractual_edits: [awaiting] });
			} else {
				this.setState({ contractual_edits: [res.data.data[0]] });
			}


			if (res.data.data.some(obj => obj.status === 'AWAITING_SIGN')) {
				this.setState({ disable_edit: true })
			}
		});

		getVistoriasByContract(this.props.match.params.id).catch(err => {

		}).then(res => {
			this.setState({ propertyInspectionList: res.data.data });
		});

		getContractAdminLogs(this.props.match.params.id).catch(err => {

		}).then(res => {
			this.setState({ adminLogsList: res.data.data });
		}).catch(() => {
			console.log('erro contrato log');
		});
	}

	toggleTab(tab) {
		if (this.state.activeTab !== tab) {
			this.setState({
				activeTab: tab
			});
		}
	};

	formatDate(date) {
		const newDate = date?.split('/')[1] + '/' + date?.split('/')[0] + '/' + date?.split('/')[2];
		return newDate;
	}

	renderPlan = () => {
		return (
			this.state.data?.entity?.property?.warranty?.security_deposit_months > 0 ?
				<p className="col-form-label" style={{ fontSize: '13px', lineHeight: '16px' }}>{"- " + this.state.data?.entity?.property?.warranty?.security_deposit_months + " caução de entrada"}</p>
				:
				<p className="col-form-label" style={{ fontSize: '13px', lineHeight: '16px' }}>Sem garantia</p>
		)
	}

	handleDownloadVistoria = async (id) => {
		try {
			this.setState({ disableVistoria: true })
			await getVistoriaFile(id).then((data) => { console.log(data); window.open(data?.request?.responseURL, '_blank'); this.setState({ disableVistoria: false }) });
		} catch (err) {
			console.log(err);
			this.setState({ errors: ['Pdf da vistoria não encontrado, entre em contato com o suporte.'] });
		}
	}

	handleDeleteContract = async () => {
		this.setState({ loading: true });
		try {
			await deleteContract(this.state.contract_id);
			this.setState({ success: true, message: 'Contrato excluído com sucesso!' });
		} catch (err) {
			this.setState({ errors: true, message: 'Houve um erro e não foi possível a exclusão do seu contrato.' });
		}
		this.setState({ loading: false });
	}

	getContractOperation = (operation) => {
		switch (operation) {
			case 'RESTORE_CONTRACT':
				return 'Contrato restaurado';
			default:
				return 'N/A';
		}
	};

	formatIndice = (indice) => {
		if (indice === 'igp_m') {
			return 'IGP-M'
		}
		if (indice === 'ipca') {
			return 'IPCA'
		}
		if (indice === 'common_agreement') {
			return 'Comum acordo'
		}
	}

	getTerminationType = (contract) => {
		if (contract?.terminated_by === contract.landlord_id) {
			return 'Responsável'
		} else if (contract?.terminated_by === contract.tenant_id) {
			return 'Inquilino'
		} else {
			return ''
		}
	}

	getContractAdminLogText = (operation, log) => {
		switch (operation) {
			case 'RESTORE_CONTRACT':
				return (
					<>
						<p>{`Data de rescisão anterior: ${moment(log?.terminated_at_before).format('DD/MM/YYYY HH:mm:ss')}`}</p>

						{log?.terminated_by_before &&
							<Link to={`/users/show/${log?.terminated_by_before}`}>
								{`Rescindido por: ${log?.terminated_by_before ?? 'N/A'}`}
							</Link>
						}
					</>
				)
			default:
				return 'N/A';
		}
	};

	getUserType = (user) => {
		if (user.user_type === 'admin') {
			return 'Administrador'
		} else if (user.user_type === 'commissioned') {
			return 'Comissionado'
		} else if (user.user_type === 'landlord') {
			return 'Dono'
		}
	}

	// handleCancelTermination = (id) => {
	// 	cancelTermination(id).catch(err => {
	// 		this.setState({ errors: ['Não foi possivel concluir a rescisão de contrato.', ...this.state.success] });
	// 	}).then(res => {
	// 		this.fetchData();
	// 		this.setState({ success: ['A rescisão de contrato foi cancelada.', ...this.state.success] });
	// 	});
	// }

	getFriendlyTypeName = (type) => {
		if (type == 'EXTRAJUDICIAL') {
			return 'Alguém descumpriu o contrato';
		}

		if (type == 'TERMINATION_TERM') {
			return 'Rescisão de comum acordo';
		}

		if (type == 'CLOSURE_TERM') {
			return 'Acabou o tempo previsto do contrato';
		}
	}

	fetchContractAdminLogs = async (data) => {
		let params = { ...data };

		if (this.state.flags.show_depoisDe && this.state.filters.depoisDe) params.created_at_gte = this.state.filters.depoisDe;
		if (this.state.flags.show_antesDe && this.state.filters.antesDe) params.created_at_lte = this.state.filters.antesDe;
		if (this.state.flags.show_informacao && this.state.filters.informacao) params.log_loggerJson = this.state.filters.informacao;

		return getContractAdminLogs(this.props.match.params.id, params).catch(() => {
			console.log('erro contrato fetch log');
		});
	}

	handleSendComentarioLog = () => {
		createContractAdminLogs({ id: this.props.match.params.id, comment: this.state.comentario }).then(() => {
			this.setState({
				comentario: '',
				success: [`Comentário adicionado com sucesso!`]
			});

			this.dataTableLogsContratoRef.current.fetchData();
		}).catch((e) => {
		});
	}

	saveFilters = () => {
		localStorage.setItem('dataTableLogsContratoFilters', JSON.stringify(this.state.filters));
		localStorage.setItem('dataTableLogsContratoFlags', JSON.stringify(this.state.flags));

		this.fetchContractAdminLogs();
	};

	render() {
		const Asset = ({ titleAsset, textAsset, icon, colorBackground, colorIcon, message, children }) => {
			return (
				<div className={'assetPersonComissao'}>
					<div style={{ backgroundColor: colorBackground ?? '#E1E3F7' }} className={'containerFigure'}>
						<i className={icon} style={{ color: colorIcon ?? '#5664D2' }} />
					</div>

					<div>
						{titleAsset ? <h6>{titleAsset}</h6> : null}

						<h5>{textAsset ? textAsset : children}</h5>

						{message ? <p className={'info'} style={{ marginTop: 8 }}>
							{message}
						</p> : null}
					</div>
				</div>
			)
		}

		const TerminationButton = ({ user, user_id }) => {
			var component = <></>;

			const usuario = this.state.terminations_users?.find(termination_user => {
				if (user_id && termination_user?.user_id == user_id) {
					return termination_user;
				} else if (!user_id && termination_user?.cpf == user?.cpf) {
					return termination_user;
				} else {
					return null;
				}
			})

			const participanteAssinou = (usuario?.user_id == user_id && usuario?.signed == true);
			const testemunhaAssinou = (user?.cpf == usuario?.cpf && usuario?.signed == true);
			const participanteNaoAssinou = (usuario?.user_id == user_id && usuario?.signed == false);
			const testemunhaNaoAssinou = (user?.cpf == usuario?.cpf && usuario?.signed == false);

			if (participanteAssinou || testemunhaAssinou) {
				component = (
					<Button color="primary" className="col-form-label" onClick={() => window.open(usuario.link)}>
						Rescisão assinada
					</Button>
				)

			} else if (participanteNaoAssinou || testemunhaNaoAssinou) {
				component = (
					<Button color="secondary" className="col-form-label" onClick={() => window.open(usuario.link)}>
						Assinatura da rescisão
					</Button>
				)
			} else {
				return <></>
			}

			return component;
		}

		const handleSeeTermination = async () => {
			const id = this.state?.terminations[0].id;
			const sign = this.state?.terminations[0].status === 'SIGNED' ? true : false;

			try {
				await getTerminationFile(id, sign).then((data) => { console.log(data); window.open(data?.request?.responseURL, '_blank') });
			} catch (err) {
				console.log(err);
				this.setState({ errors: ['Houve um erro ao buscar o termo de rescisão, entre em contato com o suporte.'] });
			}
		}

		const handleSeeClosureTerm = async () => {
			const id = this.state?.terminations[0].id;

			try {
				await getClosureTermFile(id).then((data) => { console.log(data); window.open(data?.request?.responseURL, '_blank') });
			} catch (err) {
				console.log(err);
				this.setState({ errors: ['Houve um erro ao buscar o termo de encerramento, entre em contato com o suporte.'] });
			}
		}

		return (
			<React.Fragment>

				{this.state.proposition_id ? (
					<Redirect
						to={{
							pathname: `/negotiation/show/${this.state.proposition_id}`,
							state: {},
						}}
					/>
				) : null}


				<BetterCircularProgress loading={this.state.loading}>
					<div className="page-content container-page ContractShow rescisao">
						<Container fluid>
							<Breadcrumbs breadcrumbItems={[{ link: '/contracts/index', title: 'Contrato' }, { link: `/contracts/show.${this.state.data?.entity?.id}`, title: 'Visualizar' }]} />

							<BetterAlerts success={this.state.success} errors={this.state.errors} />

							{this.state.success && this.state.message ? <Alert color="success">{this.state.message}</Alert> : null}

							<Card>
								<CardBody>
									<div style={{ display: 'flex', flexDirection: 'row', flex: 1 }}>
										<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginRight: '24px' }}>
											<p style={{ marginBottom: 0, fontSize: '26px', fontWeight: '600', color: '#5664D2' }}>{this.state.data?.entity?.friendly_code ?? 'AAA000'}</p>
										</div>
										<div  >
											<Nav tabs className="nav-tabs-custom" role="tablist">
												<NavItem>
													<NavLink style={{ paddingLeft: '2rem', paddingRight: '2rem' }} id="info-tab" className={classnames({ active: this.state.activeTab === '1' })} onClick={() => { this.toggleTab('1'); }} >Detalhes</NavLink>
												</NavItem>
												<NavItem>
													<NavLink style={{ paddingLeft: '2rem', paddingRight: '2rem' }} id="announcements-tab" className={classnames({ active: this.state.activeTab === '2' })} onClick={() => { this.toggleTab('2'); }}>Pagamentos</NavLink>
												</NavItem>
												<NavItem>
													<NavLink style={{ paddingLeft: '2rem', paddingRight: '2rem' }} id="property-inspections-tab" className={classnames({ active: this.state.activeTab === '3' })} onClick={() => { this.toggleTab('3'); }}>Vistoria</NavLink>
												</NavItem>
												<NavItem>
													<NavLink style={{ paddingLeft: '2rem', paddingRight: '2rem' }} id="admin-logs-tab" className={classnames({ active: this.state.activeTab === '5' })} onClick={() => { this.toggleTab('5'); }}>Histórico</NavLink>
												</NavItem>
												<NavItem>
													<NavLink style={{ paddingLeft: '2rem', paddingRight: '2rem' }} id="docs-juridicos-tab" className={classnames({ active: this.state.activeTab === '6' })} onClick={() => { this.toggleTab('6'); }}>Docs. jurídicos</NavLink>
												</NavItem>
											</Nav>
										</div>
									</div>

									<TabContent activeTab={this.state.activeTab} style={{ paddingTop: '1.5rem' }}>
										<TabPane tabId="1" role="tabpanel">
											<div className="d-flex">
												<div className="p-3" style={{ background: '#F1F5F7', width: '504px', minHeight: '672px', height: '100%', borderRadius: '6px' }}>
													<Col md={12}>
														<Label className="col-form-label mb-2" style={{ color: '#8590A5', fontSize: '16px' }}>Partes</Label>

														<FormGroup className="w-100">
															<Label className="col-form-label" style={{ color: '#4AA3FF', fontSize: '13px' }}>ID do contrato</Label>
															<p className="col-form-label" style={{ fontSize: '13px', lineHeight: '16px' }}>
																{this.state.contract_id ?
																	<Link to={`/contracts/show/${this.props.match.params.id}`}>{this.props.match.params.id}</Link>
																	:
																	`N/A`}
															</p>
														</FormGroup>

														<FormGroup className="w-100">
															<Label className="col-form-label" style={{ color: '#4AA3FF', fontSize: '13px' }}>Proprietário</Label>
															<p className="col-form-label d-flex" style={{ fontSize: '13px', lineHeight: '16px', flexDirection: 'row', alignItems: 'center' }}>
																{this.state.landlord?.id ?
																	<Link className='mr-auto' to={`/users/show/${this.state.landlord?.id}`}>{this.state.landlord?.name}</Link>
																	:
																	`N/A`}

																<TerminationButton user={this.state.landlord} user_id={this.state.landlord?.id} />
															</p>
														</FormGroup>

														{this.state?.commissionParticipants.length > 0 ? this.state.commissionParticipants.map(participant => {
															return (
																<FormGroup className="w-100">
																	<Label className="col-form-label" style={{ color: '#4AA3FF', fontSize: '13px' }}>{this.getUserType(participant)}</Label>

																	<p className="col-form-label d-flex" style={{ fontSize: '13px', lineHeight: '16px', flexDirection: 'row', alignItems: 'center' }}>
																		{participant.user_id ?
																			<Link className='mr-auto' to={`/users/show/${participant.user_id}`}>{participant.user?.name}</Link>
																			:
																			`N/A`}

																		<TerminationButton user={participant} user_id={participant.id} />
																	</p>
																</FormGroup>
															)
														}) : null}

														<FormGroup className="w-100">
															<Label className="col-form-label" style={{ color: '#4AA3FF', fontSize: '13px' }}>Imóvel</Label>

															<p className="col-form-label" style={{ fontSize: '13px', lineHeight: '16px' }}>
																{this.state.data?.entity?.property?.id ?
																	<Link to={`/announcements/show/${this.state.data?.entity?.property?.id}`}>{this.state.data?.entity?.property?.address?.neighbourhood}</Link>
																	:
																	`N/A`}
															</p>
														</FormGroup>

														<FormGroup className="w-100">
															<Label className="col-form-label" style={{ color: '#4AA3FF', fontSize: '13px' }}>Inquilino</Label>

															<p className="col-form-label d-flex" style={{ fontSize: '13px', lineHeight: '16px', flexDirection: 'row', alignItems: 'center' }}>
																{this.state.tenant?.id ?
																	<Link className='mr-auto' to={`/users/show/${this.state.tenant?.id}`}>{this.state.tenant?.name}</Link>
																	:
																	`N/A`}

																<TerminationButton user={this.state.tenant} user_id={this.state.tenant?.id} />
															</p>
														</FormGroup>

														{this.state?.tenant_parties.length > 0 ?
															<FormGroup className="w-100">
																<Label className="col-form-label" style={{ color: '#4AA3FF', fontSize: '13px' }}>Inquilinos</Label>

																{this.state?.tenant_parties.map((participant, index) =>
																	<p className="col-form-label d-flex" style={{ fontSize: '13px', lineHeight: '16px', flexDirection: 'row', alignItems: 'center' }}>
																		{participant.id ?
																			<Link className='mr-auto' to={`/users/show/${participant.id}`}>{participant?.name}</Link>
																			:
																			`N/A`}

																		<TerminationButton user={participant} user_id={participant?.id} />
																	</p>
																)}
															</FormGroup>
															: null
														}

														{this.state?.affiliate_user_list.length > 0 ?
															<FormGroup className="w-100">
																<Label className="col-form-label" style={{ color: '#4AA3FF', fontSize: '13px' }}>Afiliado</Label>

																{this.state?.affiliate_user_list.map((participant, index) =>
																	<p className="col-form-label d-flex" style={{ fontSize: '13px', lineHeight: '16px', flexDirection: 'row', alignItems: 'center' }}>
																		{participant?.id ?
																			<Link className='mr-auto' to={`/users/show/${participant?.id}`}>{participant?.name}</Link>
																			:
																			`N/A`}

																		<TerminationButton user={participant} user_id={participant?.id} />
																	</p>
																)}
															</FormGroup>
															: null
														}

														{this.state?.testemunhas.map((participant, index) =>
															<FormGroup className="w-100">
																<Label className="col-form-label" style={{ color: '#4AA3FF', fontSize: '13px' }}>Testemunha {index + 1}</Label>

																<p className="col-form-label d-flex" style={{ fontSize: '13px', lineHeight: '16px', flexDirection: 'row', alignItems: 'center' }}>
																	<p className='mr-auto'>{participant?.name}</p>

																	<TerminationButton user={participant} user_id={null} />
																</p>
															</FormGroup>
														)}
													</Col>
												</div>

												<div className="p-3 rounded ml-4 mr-4" style={{ background: '#F9F9F9', width: '504px', height: '672px', borderRadius: "10px" }}>
													<Col md={12}>
														<Label className="col-form-label mb-2" style={{ color: '#8590A5', fontSize: '16px' }}>Pagamentos</Label>
														<FormGroup className="w-100">
															<Label className="col-form-label" style={{ color: '#4AA3FF', fontSize: '13px' }}>Valor do aluguel</Label>
															<p className="col-form-label" style={{ fontSize: '13px', lineHeight: '16px' }}>{this.state.data?.entity?.details?.values?.rent ? formatBRL(this.state.data?.entity?.details?.values?.rent) : 'N/A'}</p>
														</FormGroup>

														<FormGroup className="w-100">
															<Label className="col-form-label" style={{ color: '#4AA3FF', fontSize: '13px' }}>IPTU</Label>
															<p className="col-form-label" style={{ fontSize: '13px', lineHeight: '16px' }}>{this.state.data?.entity?.details?.values?.iptu ? 'Inquilino paga' : 'Proprietário paga'}</p>
														</FormGroup>

														{this.state.data?.entity?.details?.values?.iptu ?
															<FormGroup className="w-100">
																<Label className="col-form-label" style={{ color: '#4AA3FF', fontSize: '13px' }}>Valor do IPTU</Label>
																<p className="col-form-label" style={{ fontSize: '13px', lineHeight: '16px' }}>{formatBRL(this.state.data?.entity?.details?.values?.iptu)}</p>
															</FormGroup> : null
														}

														<FormGroup className="w-100">
															<Label className="col-form-label" style={{ color: '#4AA3FF', fontSize: '13px' }}>Condomínio</Label>
															<p className="col-form-label" style={{ fontSize: '13px', lineHeight: '16px' }}>{this.state.data?.entity?.details?.values?.condo ? 'Inquilino paga' : 'Proprietário paga'}</p>
														</FormGroup>

														{this.state.data?.entity?.details?.values?.condo ?
															<FormGroup className="w-100">
																<Label className="col-form-label" style={{ color: '#4AA3FF', fontSize: '13px' }}>Valor do Condomínio</Label>
																<p className="col-form-label" style={{ fontSize: '13px', lineHeight: '16px' }}>{formatBRL(this.state.data?.entity?.details?.values?.condo)}</p>
															</FormGroup> : null
														}

														{this.state.data?.entity?.details?.renovation_info ? this.state.data?.entity?.details?.renovation_info?.map(renovation => (
															<FormGroup className="w-100">
																<Label className="col-form-label" style={{ color: '#4AA3FF', fontSize: '13px' }}>Renovação de contrato</Label>
																<p className="col-form-label" style={{ fontSize: '13px', lineHeight: '16px' }}>● {moment(renovation.date, 'YYYY-MM-DD').format('DD/MM/YYYY')}; {renovation.readjustment_percentage.toFixed(2).replace('.', ',')}% - {this.formatIndice(renovation.readjustment_indice)}; <span style={{ color: '#1CBB8C' }}>{formatBRL(renovation.new_rent)}</span> </p>
															</FormGroup>
														)) : null}
													</Col>
												</div>

												<div className="p-3 rounded ml-4 mr-4" style={{ background: '#F9F9F9', width: '504px', minHeight: '672px', borderRadius: "10px" }}>
													<Col md={12}>
														<Label className="col-form-label mb-2" style={{ color: '#8590A5', fontSize: '16px' }}>Detalhes & Plano</Label>
														<FormGroup className="w-100">
															<Label className="col-form-label" style={{ color: '#4AA3FF', fontSize: '13px' }}>Data do início do contrato</Label>
															<p className="col-form-label" style={{ fontSize: '13px', lineHeight: '16px' }}>{this.formatDate(this.state.data?.entity?.details?.start_date)}</p>
														</FormGroup>

														<FormGroup className="w-100">
															<Label className="col-form-label" style={{ color: '#4AA3FF', fontSize: '13px' }}>Prazo original do contrato</Label>
															<p className="col-form-label" style={{ fontSize: '13px', lineHeight: '16px' }}>{this.state.data?.entity?.proposition?.details?.duration ? this.state.data?.entity?.proposition?.details?.duration + ' meses' : 'N/A'}</p>
														</FormGroup>

														<FormGroup className="w-100">
															<Label className="col-form-label" style={{ color: '#4AA3FF', fontSize: '13px' }}>Plano</Label>
															<p className="col-form-label" styel={{ fontSize: '13px', lineHeight: '16px', textTransform: 'capitalize' }}>{this.state.planName ? this.state.planName : 'N/A'}</p>
															{this.state.planName === 'Caução' ? this.renderPlan() : null}
														</FormGroup>

														<FormGroup className="w-100">
															<Label className="col-form-label" style={{ color: '#4AA3FF', fontSize: '13px' }}>Porcentagem do contrato</Label>
															<p className="col-form-label" style={{ fontSize: '13px', lineHeight: '16px' }}>{this.state.data?.entity?.plan?.percentage}%</p>
														</FormGroup>

														{this.state.data?.entity?.coupon?.code &&
															<FormGroup className="w-100">
																<Label className="col-form-label" style={{ color: '#4AA3FF', fontSize: '13px', marginBottom: 23 }}>Cupom Aplicado</Label>
																<p className="col-form-label" style={{ fontSize: '13px', lineHeight: '16px', paddingBottom: 0 }}>
																	{this.state.data?.entity?.coupon?.code}
																</p>

																<p className="col-form-label" style={{ fontSize: '13px', lineHeight: '16px', paddingBottom: 0 }}>
																	{this.state.planName}
																</p>

																<p className="col-form-label" style={{ fontSize: '13px', lineHeight: '16px', paddingBottom: 0 }}>
																	Desconto: {this.state.data?.entity?.coupon?.discount_type == "PERCENTAGE" ? `${this.state.data?.entity?.coupon?.discount_amount}%` : formatBRL(this.state.data?.entity?.coupon?.discount_amount)}
																</p>

																{this.state.data?.entity?.coupon?.expiration_date && (
																	<p className="col-form-label" style={{ fontSize: '13px', lineHeight: '16px' }}>
																		Data de expiração: {moment(this.state.data?.entity?.coupon?.expiration_date).format('DD/MM/YYYY')}
																	</p>
																)}
															</FormGroup>
														}

														{(this.state?.data?.entity?.is_terminated && this.state?.terminations.length == 0) ?
															<FormGroup className="w-100">
																<Label className="col-form-label" style={{ color: '#FF3D60', fontSize: '13px' }}>Data de saída do Imóvel</Label>
																<p className="col-form-label" style={{ fontSize: '13px', lineHeight: '16px' }}>{this.state?.data?.entity?.approved_end_date ? moment(this.state?.data?.entity?.approved_end_date, 'YYYY/MM/DD').format('DD/MM/YYYY') : 'N/A'}</p>
															</FormGroup>
															:
															<></>
														}

														{this.state?.terminations.length > 0 ?
															<FormGroup className="w-100 rescisaoBox">
																<Label className="col-form-label" style={{ color: '#FF3D60', fontSize: '13px' }}>Data de saída do Imóvel</Label>
																{this.state?.terminations[0].type === 'EXTRAJUDICIAL' && this.state.terminations[0]?.sentence_date ?
																	(<>
																		<>
																			<p className="col-form-label" style={{ fontSize: '13px', lineHeight: '16px' }}>
																				{this.state?.terminations[0].status == "AWAITING_COURT_APPROVAL" ? 'Aguardando jurídico' : moment(this.state.terminations[0]?.sentence_date ? this.state.terminations[0]?.sentence_date : new Date).format('DD/MM/YYYY') ?? 'N/A'}
																			</p>
																		</>
																	</>) : (
																		<>
																			<p className="col-form-label" style={{ fontSize: '13px', lineHeight: '16px' }}>
																				{this.state?.terminations[0].status == "AWAITING_COURT_APPROVAL" ? 'Aguardando jurídico' : moment(this.state.terminations[0]?.exit_date ? this.state.terminations[0]?.exit_date : new Date).format('DD/MM/YYYY') ?? 'N/A'}
																			</p>
																		</>
																	)}

																{this.state?.terminations[0].type == 'EXTRAJUDICIAL' && this.state?.terminations[0].status == 'AWAITING_COURT_APPROVAL' ?
																	<div className='buttonBox mt-4'>
																		<button className='btn' onClick={() => this?.props?.history?.push(`/contracts/termination-confirm/${this.state?.terminations[0].id}`)}>
																			Confirmar rescisão
																			<i className='fa fa-check'></i>
																		</button>

																		<button className='btn' onClick={() => this?.props?.history?.push(`/contracts/termination-confirm/${this.state?.terminations[0].id}`)}>
																			Cancelar rescisão
																			<i className='fa fa-times'></i>
																		</button>
																	</div>
																	: null}

																<Label className="col-form-label" style={{ color: '#FF3D60', fontSize: '13px' }}>Rescisão de contrato</Label>

																<div style={{ display: 'flex', flexDirection: 'column', rowGap: 24 }}>
																	<Asset titleAsset={'Motivo da saída'} textAsset={this.getFriendlyTypeName(this.state?.terminations[0].type)} icon={'fa fa-arrow-right'} />
																	<Asset titleAsset={'Quem pediu a rescisão de contrato'} textAsset={this.state?.terminations[0].termination_requested_by != null ? this.state?.terminations[0].termination_requested_by == 'landlord' ? 'Responsável' : 'Inquilino' : this.state?.data?.entity?.contract_users.find(obj => obj.user_type === 'landlord')?.user_id === this.state?.terminations[0].created_by ? 'Responsável' : 'Inquilino'} icon={'fa fa-user'} />
																	<Asset titleAsset={'Cobrança para'} icon={'fa fa-file'}>
																		<Link to={`/users/show/${this.state.tenant?.id}`}>{this.state.tenant?.name}</Link>
																	</Asset>
																</div>
															</FormGroup>
															:
															<></>
														}
													</Col>
												</div>
											</div>
										</TabPane>

										<TabPane tabId="2" role="tabpanel">
											<div className="d-flex">
												<DataTableContractsPayment terminations={this.state?.terminations} noDelete crudButtons={true} idRow={`id`} noShow contractId={this.props.match.params.id} agreement={this.state?.friendlyAgreement[0]} />
											</div>
										</TabPane>

										<TabPane tabId="3" role="tabpanel">
											<div className="d-flex">
												<Table striped>
													<tbody>
														<tr>
															<th>Vistoria</th>
															<th>Data de início</th>
															<th>Último dia de edição</th>
															<th>Nº postagens</th>
															<th>Ações</th>
														</tr>
														{this.state.propertyInspectionList.length > 0 ?
															this.state.propertyInspectionList.map((obj, i) => {
																return (
																	<tr key={`vist_${i}`}>
																		<td style={{ verticalAlign: 'middle' }}>{`Vistoria de ${obj.type === 'ENTRY' ? 'entrada' : 'saída'}`}</td>
																		<td style={{ verticalAlign: 'middle' }}>{moment(obj.start_date).add(3, 'hours').format('DD/MM/YYYY')}</td>
																		<td style={{ verticalAlign: 'middle' }}>{moment(obj.end_date).format('DD/MM/YYYY')}</td>
																		<td style={{ verticalAlign: 'middle' }}>{obj.totalItems}</td>
																		<td>
																			<Link target='_blank' to={`/contracts/property-inspection/show/${this.props.match.params.id}`} className="btn btn-table-action"><i className="fas fa-eye" style={{ color: '#5664D2' }}></i></Link>
																		</td>
																	</tr>
																)
															})
															:
															<tr>
																<td colSpan={5}>
																	Não existem vistorias para esse contrato
																</td>
															</tr>
														}
													</tbody>
												</Table>
											</div>
										</TabPane>

										<TabPane tabId="5" role="tabpanel">
											<Row className="align-right mb-30 ml-auto">
												<Dropdown className="mr-10 wm-100" isOpen={this.state.is_filter_open} toggle={() => this.setState({ is_filter_open: !this.state.is_filter_open })} >
													<DropdownToggle className="btn-index-action" color="primary" caret>
														ADD FILTRO <i className="ri-filter-3-line align-middle ml-2"></i>
													</DropdownToggle>
													<DropdownMenu className="btn-index-action">
														{!this.state.flags.show_depoisDe ? <DropdownItem onClick={() => this.setState({ flags: { ...this.state.flags, show_depoisDe: true } })}>Depois de</DropdownItem> : null}
														{!this.state.flags.show_antesDe ? <DropdownItem onClick={() => this.setState({ flags: { ...this.state.flags, show_antesDe: true } })}>Antes de</DropdownItem> : null}
														{!this.state.flags.show_informacao ? <DropdownItem onClick={() => this.setState({ flags: { ...this.state.flags, show_informacao: true } })}>Pesquisar informação</DropdownItem> : null}
													</DropdownMenu>
												</Dropdown>
											</Row>

											<Row className="container-center-input">
												{this.state.flags.show_depoisDe ?
													<Col md={4}>
														<FormGroup row>
															<Label htmlFor="depoisDe" className="col-md-12 col-form-label">Depois de</Label>
															<div className="col-12 col-sm-8 container-center-input">
																<Input className="form-control" type="date" id="depoisDe" value={this.state.depoisDe}
																	onChange={(e) => this.setState({ filters: { ...this.state.filters, depoisDe: e.target.value } }, () => this.saveFilters())} />
															</div>
															<Button onClick={() => this.setState({ flags: { ...this.state.flags, show_depoisDe: false }, filters: { ...this.state.filters, depoisDe: '' } }, () => this.saveFilters())} color="light" type="button" className="waves-effect waves-light btn-rounded btn-close-input">
																<i className="ri-close-circle-line align-middle"></i>
															</Button>
														</FormGroup>
													</Col>
													: false
												}

												{this.state.flags.show_antesDe ?
													<Col md={4}>
														<FormGroup row>
															<Label htmlFor="antesDe" className="col-md-12 col-form-label">Antes de</Label>
															<div className="col-12 col-sm-8 container-center-input">
																<Input className="form-control" type="date" id="antesDe" value={this.state.antesDe}
																	onChange={(e) => this.setState({ filters: { ...this.state.filters, antesDe: e.target.value } }, () => this.saveFilters())} />
															</div>
															<Button onClick={() => this.setState({ flags: { ...this.state.flags, show_antesDe: false }, filters: { ...this.state.filters, antesDe: '' } }, () => this.saveFilters())} color="light" type="button" className="waves-effect waves-light btn-rounded btn-close-input">
																<i className="ri-close-circle-line align-middle"></i>
															</Button>
														</FormGroup>
													</Col>
													: false
												}

												{this.state.flags.show_informacao ?
													<Col md={4}>
														<FormGroup row>
															<Label htmlFor="informacao" className="col-md-12 col-form-label">Pesquisar informação</Label>
															<div className="col-12 col-sm-8 container-center-input">
																<Input className="form-control" type="text" id="informacao" value={this.state.informacao}
																	onChange={(e) => this.setState({ filters: { ...this.state.filters, informacao: e.target.value } }, () => this.saveFilters())} />
															</div>
															<Button onClick={() => this.setState({ flags: { ...this.state.flags, show_informacao: false }, filters: { ...this.state.filters, informacao: '' } }, () => this.saveFilters())} color="light" type="button" className="waves-effect waves-light btn-rounded btn-close-input">
																<i className="ri-close-circle-line align-middle"></i>
															</Button>
														</FormGroup>
													</Col>
													: false
												}
											</Row>

											<DataTableLogsContrato ref={this.dataTableLogsContratoRef} filters={this.state.filters} fetchEvent={this.fetchContractAdminLogs} crudUrl="/contracts" idRow={`id`} />

											<div style={{ marginTop: 24, display: 'flex', padding: 24, gap: 36, borderRadius: 8, backgroundColor: '#F1F5F7', border: '1px solid #DADBDD' }}>
												<div style={{ gap: 12 }}>
													<h4 style={{ color: '#343A40', fontFamily: 'Inter', fontSize: 22.7 }}>Adicionar comentário</h4>
													<p style={{ color: '#505D69', fontFamily: 'Inter', fontSize: 13 }}>Comentários adicionados não podem ser excluídos ou editados</p>
												</div>

												<div style={{ flex: 1 }}>
													<label>Comentário</label>

													<div className='d-flex'>
														<TextField style={{ backgroundColor: '#FFF', flex: 1 }} type="text" variant="outlined" value={this.state.comentario}
															placeholder='Digite seu comentário...' onChange={e => this.setState({ comentario: e.target.value })} />

														<button style={{ backgroundColor: '#4AA3FF', color: '#FFF' }} onClick={this.handleSendComentarioLog} disabled={this.state.comentario == ''} className="btn btn-bold ml-4">
															Comentar

															<i className="fa fa-comment ml-2"></i>
														</button>
													</div>
												</div>
											</div>
										</TabPane>

										<TabPane tabId="6" role="tabpanel">
											<DocsJuridicos contract_id={this.props.match.params.id} friendly_code={this.state.data?.entity?.friendly_code} />
										</TabPane>
									</TabContent>
								</CardBody>

								<CardBody className="row">
									<Link to='/contracts/index' className="btn btn-secondary btn-bold ml-2 mr-2"><i className="fa fa-arrow-left mr-2"></i>Voltar</Link>
									{this.state?.showCreateFriendlyAgreement ? <Link to={`/contracts/friendly-agreement/${this.props.match.params.id}`} className="btn btn-primary ml-2" >Criar acordo <i className="fa fa-handshake ml-2"></i></Link> : null}
									{this.state.activeTab === '1' ?
										<>
											<Button color="primary" className="btn btn-primary ml-3" onClick={() => {
												(this.state.flags.tenant_sign && this.state.flags.landlord_sign) ?
													window.open(this.state.data.fileSignUrl, '_blank').focus()
													:
													window.open(this.state.data.fileOriginalUrl, '_blank').focus()
											}}>
												Ver contrato {this.state.flags.tenant_sign && this.state.flags.landlord_sign ? 'assinado' : 'não assinado'}  <i className="fas fa-file-contract ml-2"></i>
											</Button>

											<Button color="primary" className="btn btn-primary ml-3" onClick={() => {
												this.props.history.push(`/contracts/contractual-edit/${this.props.match.params.id}`)
											}} disabled={this.state?.disable_edit}>
												Edição contratual <FontAwesomeIcon icon={faFilePen} style={{ marginLeft: '8.5px' }} ></FontAwesomeIcon>
											</Button>

											{this.state?.contractual_edits?.length > 0 ?
												this.state?.contractual_edits.map(edit => {
													if (!edit?.id) {
														return '';
													}
													return (
														<Button color="primary" className="btn btn-primary ml-3" onClick={() => {
															this.props.history.push(`/show/contractual-edit/${edit?.id}`)
														}} >
															Assinatura aditivo <FontAwesomeIcon icon={faEye} style={{ marginLeft: '8.5px' }} ></FontAwesomeIcon>
														</Button>
													)
												})
												: null}

											{this.state?.terminations.length > 0 && this.state?.terminations[0].type === 'TERMINATION_TERM' && this.state.data?.entity?.is_terminated ? <>
												<button onClick={async (e) => {
													e.preventDefault();
													await handleSeeTermination();
												}} className="ml-3" style={{ backgroundColor: '#8590A5', width: '186px', height: '36px', padding: '8px 12px', color: '#E1E3F7', border: 'none', borderRadius: '4px', fontFamily: 'Inter', fontWeight: '600', fontSize: '13px', lineHeight: '15px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
													Ver termo de rescisão <img src={xmark} alt="xmark" className='ml-2' width={12} height={28} />
												</button>
											</> : null}

											{this.state?.terminations.length > 0 && this.state?.terminations[0].type === 'CLOSURE_TERM' && this.state.data?.entity?.is_terminated ? <>
												<button onClick={async (e) => {
													e.preventDefault();
													await handleSeeClosureTerm();
												}} className="ml-3" style={{ backgroundColor: '#8590A5', width: '222px', height: '36px', padding: '8px 12px', color: '#E1E3F7', border: 'none', borderRadius: '4px', fontFamily: 'Inter', fontWeight: '600', fontSize: '13px', lineHeight: '15px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
													Ver termo de encerramento <img src={xmark} alt="xmark" className='ml-2' width={12} height={28} />
												</button>
											</> : null}

											{this.state.data && !this.state.data?.entity?.is_terminated && this.state.flags.tenant_sign && this.state.flags.landlord_sign &&
												<Link className="btn btn-danger ml-3" to={`/contracts/termination/${this.props.match.params.id}`}>
													Agendar rescisão <i className="fas ri-file-excel-line ml-2"></i>
												</Link>
											}
										</>
										: null
									}

									{this.state.activeTab === '3' ?
										<>
											{this.state.propertyInspectionList.length > 0 ?
												this.state.propertyInspectionList.map((obj, i) => {
													if (moment().isAfter(moment(obj?.end_date).subtract(1, 'days'))) {
														return (
															<button disabled={this?.state?.disableVistoria} onClick={async () => { await this.handleDownloadVistoria(obj.id); }} className="btn btn-primary btn-bold ml-2">
																Baixar vistoria de {obj.type === 'ENTRY' ? 'entrada' : 'saída'}	<img src={arrowDownToLine} alt="download" className='ml-2' width={12} height={20} />
															</button>
														)
													}
												}) : null}

										</>
										: null
									}

									{!this.state.flags.tenant_sign || !this.state.flags.landlord_sign ? (
										<Button color="danger" type="submit" className="waves-effect waves-light ml-3"
											onClick={() => this.handleDeleteContract()}>
											Cancelar contrato <i className="fa fa-times align-middle ml-2"></i>
										</Button>
									) : null}

									{this.state.canRestore ? (
										<Link className="btn btn-success ml-3" to={`/contracts/restore/${this.props.match.params.id}`}>
											Restaurar contrato <i className="ri-open-arm-line align-middle ml-2"></i>
										</Link>
									) : null}
								</CardBody>
							</Card >
						</Container >
					</div >
				</BetterCircularProgress >
			</React.Fragment >
		)
	}
}

export default ContractShow;