import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Link } from "react-router-dom";
import { Column } from 'primereact/column';
import _ from 'lodash';
import { CircularProgress } from "@material-ui/core";
import { getRawById } from '../../../crud/announcements.crud';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileContract, faUserMagnifyingGlass } from '@fortawesome/pro-solid-svg-icons';
import { formatBRL, limparMoeda } from '../../../helpers/Utils';

export default class DataTableAnnouncements extends Component {
	constructor() {
		super();

		this.state = {
			data: [],
			rows: 10,
			totalRecords: 0,
			first: 0,
			last: 0,
			loading: false,
			sortField: 'id',
			sortOrder: '1',
			isLoading: false
		};

		this.onPage = this.onPage.bind(this);
	}

	componentDidMount() {
		let announcementsDataTable = JSON.parse(localStorage.getItem('announcementsDataTable'));

		if (announcementsDataTable) {
			this.setState({
				rows: announcementsDataTable.rows,
				totalRecords: announcementsDataTable.totalRecords,
				first: announcementsDataTable.first,
				last: announcementsDataTable.last,
				loading: announcementsDataTable.loading,
				sortField: announcementsDataTable.sortField,
				sortOrder: announcementsDataTable.sortOrder,
			}, () => {
				this.fetchData();
			});
		} else {
			this.fetchData();
		}
	}

	componentDidUpdate(prevProps) {
		if (prevProps.filters != this.props.filters) {
			this.onFilterChanged();
		}
	}

	onFilterChanged = _.debounce(() => {
		if (!this.state.isLoading) {
			this.fetchData();
		}
	}, 700);

	fetchData = (limit, skip) => {
		let data = {
			$limit: limit ? limit : this.state.rows,
			$skip: skip != null ? skip : this.state.first,
			$sort: { [this.state.sortField]: this.state.sortOrder }
		};

		this.props.fetchEvent(data).then(async res => {
			this.props.handleSetIndicadorAtual(res.data.total);

			await res.data.data.map(async (obj, i) => {
				if (obj.metadata?.raw_announcement_id) {
					obj.loading_crawler_data = true;
					await getRawById(obj.metadata?.raw_announcement_id).catch(err => {
						obj.crawler_data = null
						obj.loading_crawler_data = false;
					}).then(res => {
						if (res?.data?.data) {
							obj.crawler_data = res.data.data;
						}
					});
				}

				this.setState({
					...this.state,
					data: res.data.data,
					totalRecords: res.data.total,
					first: res.data.skip,
					last: res.data.skip + res.data.limit,
					loading: false
				});
			});

			this.setState({
				...this.state,
				data: res.data.data,
				totalRecords: res.data.total,
				first: res.data.skip,
				last: res.data.skip + res.data.limit,
				loading: false
			});
		});
	};

	refreshTable() {
		this.onPage(this.state);
	}

	onPage = async (event) => {
		if (event.data) {
			event.data = null;
		}

		let announcementsDataTable = {
			rows: event.rows,
			totalRecords: this.state.totalRecords,
			first: event.first,
			last: event.rows + event.first,
			loading: this.state.loading,
			sortField: this.state.sortField,
			sortOrder: this.state.sortOrder,
		}

		localStorage.setItem('announcementsDataTable', JSON.stringify(announcementsDataTable));

		this.setState({
			...this.state,
			rows: event.rows,
			loading: true
		});

		this.fetchData(event.rows, event.first);
	};

	columnOpcoesCrud = (rowData) => {
		let idRow = this.props.idRow ? this.props.idRow : 'id';

		if (this.props.crudUrl) {
			return (
				<Link to={`${this.props.crudUrl}/show/${rowData[idRow]}`} className='showButton'>
					<i className='fa fa-chevron-right' />
				</Link>
			);
		} else {
			return null;
		}
	};

	getIsPublished = (obj) => {
		if (obj.is_published) {
			return (
				<div>
					<p>Sim</p>
				</div>
			)
		} else {
			return (
				<div>
					<p>Não</p>
				</div>
			)
		}
	};

	getIsInspection = (obj) => {
		if (obj.is_published) {
			return (
				<div>
					<p>feita</p>
				</div>
			)
		} else {
			return (
				<div>
					<p>Não feita</p>
				</div>
			)
		}
	};

	getCrawlerData = (obj) => {
		if (obj.crawler_data) {
			return (
				<div>
					<p>{`${obj.crawler_data.source} ${obj.crawler_data.source_id}`}</p>
				</div>
			)
		} else {
			if (obj.loading_crawler_data) {
				return (
					<div>
						<CircularProgress color="inherit" style={{ color: "#4AA3FF" }} size={15} />
					</div>
				)
			}
		}
	};

	getUserName = (user) => {
		if (user) {
			return (
				<div className='negotiation-active' style={{ textAlign: 'left', justifyContent: 'flex-start', padding: 16 }}>
					<FontAwesomeIcon icon={faUserMagnifyingGlass} style={{ marginRight: '8.5px' }} />

					<Link to={`/users/show/${user?.id}`} >{user?.name}</Link>
				</div>
			)
		}
	};

	sortTable = e => {
		this.setState({ sortField: e.sortField, sortOrder: e.sortOrder }, () => {
			let announcementsDataTable = {
				rows: this.state.rows,
				totalRecords: this.state.totalRecords,
				first: this.state.first,
				last: this.state.last,
				loading: this.state.loading,
				sortField: this.state.sortField,
				sortOrder: this.state.sortOrder,
			};

			localStorage.setItem('announcementsDataTable', JSON.stringify(announcementsDataTable));

			this.fetchData();
		});
	};

	orderData = (data) => {
		return data?.sort((a, b) => {
			return (a?.user?.name > b?.user?.name) ? 1 : ((b?.user?.name > a?.user?.name) ? -1 : 0);
		});
	};

	getWarranty = (key) => {
		switch (key) {
			case 'FREE':
				return 'Grátis';

			case 'WARRANTY':
				return 'Pagamento';


			case 'WARRANTY_GUARANTEE_DAMAGE':
				return 'Completa';

			default:
				return 'Não identificado';
		}
	};

	getInNumber = (value) => {
		value = formatBRL(value);
		value = limparMoeda(value);
		value = Number(value);

		return value;
	};

	getValuesFormated = (values) => {
		let rent = this.getInNumber(values?.total ?? values?.rent ?? 0);

		return (
			<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
				{formatBRL(rent)}
			</div>
		)
	};

	getRatings = (positive_ratings) => {
		if (positive_ratings > 0) {
			return (
				<div className='negotiation-active'>
					{positive_ratings ?? 0}
					<FontAwesomeIcon icon={faUserMagnifyingGlass} style={{ marginLeft: '8.5px' }} />
				</div>
			);
		} else {
			return (
				<div className='negotiation-disabled'>
					--
					<FontAwesomeIcon icon={faUserMagnifyingGlass} style={{ marginLeft: '8.5px' }} />
				</div>
			);
		}
	};

	duracaoContrato = (contract) => {
		let durationContract = contract?.details?.duration;

		return (
			<Link to={`/negotiation/show/${contract?.proposition_id}`} className='negotiation-active'>
				{durationContract} meses
				<FontAwesomeIcon icon={faFileContract} />
			</Link>
		);
	}

	dataHora = (date) => {
		if (!date) {
			return '--'
		}

		return (
			<div>
				{moment(date).format('DD/MM/YYYY')}
				<br />
				{moment(date).format('HH:mm:ss')}
			</div>
		);
	}

	render() {
		return (
			<DataTable ref={(el) => this.dt = el} value={this.state.data} paginator={true} rows={this.state.rows} rowsPerPageOptions={[10, 25, 50]}
				first={this.state.first} last={this.state.last} responsive={true} onPage={this.onPage} header={null}
				totalRecords={this.state.totalRecords} lazy={true} loading={this.state.loading} loadingIcon={`fas fa-sync fa-spin`}
				paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
				currentPageReportTemplate="Mostrando {first} até {last} de {totalRecords} registros" emptyMessage="Nenhum registro encontrado!"
				onSort={(e) => this.sortTable(e)} sortField={this.state.sortField} sortOrder={this.state.sortOrder} >
				<Column style={{ width: '10%' }} sortable field="created_at" body={(obj, i) => this.dataHora(obj.created_at)} header="Anunciado em" />

				<Column style={{ width: '9%' }} sortable field="friendly_code" header="Imóvel" />

				<Column style={{ width: '17%' }} sortable field="address.street" header="Endereço" body={({ address }) => `${address.street} ${address.number}, ${address?.complement ?? ''} ${address.neighbourhood ? `, ${address.neighbourhood}` : ''},  - ${address.city}/${address.state}`} />

				<Column style={{ width: '17%' }} field="user.name" body={(obj, i) => this.getUserName(obj.user)} header="Proprietário" />

				{(this.props.activeTab == '2' || this.props.activeTab == '3') &&
					<Column style={{ width: '17%' }} field="tenant" header="Inquilinos" body={(obj, i) => this.getUserName(obj.ultimoContrato?.tenant)} />
				}

				<Column style={{ width: '11%' }} sortable field="warranty.type" header="Garantia" body={(obj, i) => this.getWarranty(obj?.warranty?.type)} />

				{(this.props.activeTab == '1' || this.props.activeTab == '4') &&
					<Column style={{ width: '13%' }} field="positive_ratings" header="Interessados" body={({ interessados }, i) => this.getRatings(interessados || 0)} />
				}

				{this.props.activeTab == '2' ?
					<Column style={{ width: '14%' }} field="durationContract" body={body => this.duracaoContrato(body?.ultimoContrato)} header="Duração de contrato" />
					:
					<Column style={{ width: '14%' }} sortable field="updated_at" body={(obj, i) => this.dataHora(this.props.activeTab == '3' ? obj.deleted_at : obj.updated_at)} header={`${this.props.activeTab == '3' ? 'Inativo em' : 'Atualizado em'}`} />
				}

				<Column style={{ width: '12%' }} sortable field="values.total" body={(obj, i) => this.getValuesFormated(obj?.values)} header="Valor total" />

				<Column header="" body={this.columnOpcoesCrud} style={{ width: '2%', padding: 0 }} />
			</DataTable>
		);
	}
}