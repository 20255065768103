import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { get as getUser } from '../../crud/users.crud';
import { get as getContract } from '../../crud/contracts.crud';
import { formatBRL } from "../../helpers/Utils";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import moment from "moment";
import { Button } from "reactstrap";
import { createRentBoleto, getById as getBillingById } from "../../crud/billings.crud";

export default class DataTableNegativacao extends Component {
	constructor() {
		super();

		this.state = {
			property: {},
			data: null,
			rows: 10,
			totalRecords: 0,
			first: 0,
			last: 0,
			loading: false,
			sortField: 'id',
			sortOrder: 1,
		};

		this.onPage = this.onPage.bind(this);
	}

	componentDidMount() {
		let cobrancaNegativacaoDataTable = JSON.parse(localStorage.getItem("cobrancaNegativacaoDataTable"));

		if (cobrancaNegativacaoDataTable) {
			this.setState(
				{
					data: cobrancaNegativacaoDataTable.data,
					rows: cobrancaNegativacaoDataTable.rows,
					totalRecords: cobrancaNegativacaoDataTable.totalRecords,
					first: cobrancaNegativacaoDataTable.first,
					last: cobrancaNegativacaoDataTable.last,
					sortField: cobrancaNegativacaoDataTable.sortField,
					sortOrder: cobrancaNegativacaoDataTable.sortOrder,
					loading: false
				},
			);
		} else {
			this.fetchData();
		}
	}

	componentDidUpdate(prevProps) {
		if (prevProps.filters !== this.props.filters) {
			this.fetchData();
		}
	}

	refreshTable() {
		this.onPage(this.state);
	}

	onPage = async (event) => {
		this.setState({ loading: true });

		if (event.data) event.data = null;

		let cobrancaNegativacaoDataTable = {
			rows: event.rows,
			totalRecords: this.state.totalRecords,
			data: this.data,
			first: event.first,
			last: event.rows + event.first,
			loading: this.state.loading,
			sortField: this.state.sortField,
			sortOrder: this.state.sortOrder,
		};

		localStorage.setItem("cobrancaNegativacaoDataTable", JSON.stringify(cobrancaNegativacaoDataTable));

		this.setState({ ...this.state, rows: event.rows });

		this.fetchData(event.rows, event.first);
	};

	fetchData = async (limit, skip) => {
		this.setState({ loading: true });

		let options = {
			$limit: limit ? limit : this.state.rows,
			$skip: skip != null ? skip : this.state.first,
			$sort: { [this.state.sortField]: this.state.sortOrder },
		};

		return this.props.fetchEvent(options).then(async (res) => {
			if (res.data.data.length == 0) {
				this.setState({
					...this.state,
					data: [],
					totalRecords: res?.data?.data?.total,
					first: skip,
					last: skip + res?.data?.data?.limit,
				});
			}

			this.setState({
				...this.state,
				data: res.data.data,
				totalRecords: res?.data?.data.total,
				first: skip,
				last: skip + res?.data?.data.limit,
			});
		}).finally(() => {
			this.setState({ loading: false });
		});
	};

	sortTable = async (e) => {
		this.setState({ sortField: e.sortField, sortOrder: e.sortOrder, loading: true }, () => {
			let cobrancaNegativacaoDataTable = {
				rows: this.state.rows,
				totalRecords: this.state.totalRecords,
				first: this.state.first,
				last: this.state.last,
				loading: this.state.loading,
				sortField: this.state.sortField,
				sortOrder: this.state.sortOrder,
				data: this.state.data
			};
			localStorage.setItem("cobrancaNegativacaoDataTable", JSON.stringify(cobrancaNegativacaoDataTable));

			this.fetchData();
		});
	};

	getValue = (obj) => {
		return (
			<div style={{ textAlign: "center" }}>
				<p>{formatBRL(obj || 0)}</p>
			</div>
		);
	};

	getUserName = (obj, type) => {
		if (type === "landlord" && obj?.landlord) {
			return (
				<div style={{ textAlign: "center" }}>
					<Link to={`/users/show/${obj.landlord?.id}`}>
						{obj.landlord?.name}
					</Link>
				</div>
			);
		}

		if (type === "tenant" && obj?.tenant) {
			return (
				<div style={{ textAlign: "center" }}>
					<Link to={`/users/show/${obj.tenant?.id}`}>{obj.tenant?.name}</Link>
				</div>
			);
		}

		if (type === "contract" && obj?.contract) {
			return (
				<div style={{ textAlign: "center" }}>
					<Link to={`/contracts/show/${obj?.contract?.entity?.id}`}>{obj?.contract?.entity?.plan?.friendly_name}</Link>
				</div>
			);
		}

		return (
			<div style={{ textAlign: "center" }}>
				Não encontrado
			</div>
		);
	};

	getCity = (obj) => {
		if (obj?.property) {
			return (
				<div style={{ textAlign: "center" }}>
					<Link to={`/announcements/show/${obj.property?.id}`}>{obj.property?.address?.city}</Link>
				</div>
			);
		} else {
			return (
				<div style={{ textAlign: "center" }}>
					Não encontrado
				</div>
			)
		}
	};

	getNeighbourhood = (obj) => {
		return (
			<div style={{ textAlign: "center" }}>
				<Link to={`/announcements/show/${obj.property?.id}`}>
					{obj.property?.address?.neighbourhood}
				</Link>
			</div>
		);
	};

	getStatus = (obj) => {
		if (obj?.status === 'PENDING') {
			return (
				<div className="bg-secondary  d-flex flex-row" style={{ width: '98px', fontSize: '11px', lineHeight: '16px', height: '24px', alignItems: 'center' }}>
					<p className="col-form-label text-white">Pendente</p>
				</div>
			);
		} else if (obj?.status === 'AWAITING_APPROVAL') {
			return (
				<div className="d-flex flex-row" style={{ width: '140px', fontSize: '11px', lineHeight: '16px', height: '24px', alignItems: 'center', backgroundColor: '#FCB92C' }}>
					<p className="col-form-label text-white">Aguardando aprovação</p>
				</div>
			);
		} else if (obj?.status === 'AWAITING_CANCELLATION') {
			return (
				<div className="d-flex flex-row" style={{ width: '140px', fontSize: '11px', lineHeight: '16px', height: '24px', alignItems: 'center', backgroundColor: '#FF3D60' }}>
					<p className="col-form-label text-white">Aguardando cancelamento</p>
				</div>
			);
		} else if (obj?.status === 'PROCESSED') {
			return (
				<div className=" d-flex flex-row" style={{ width: '131px', fontSize: '11px', lineHeight: '16px', height: '24px', alignItems: 'center', backgroundColor: '#4AA3FF' }} >
					<p className="col-form-label text-white">Em negativação</p>
				</div>
			);
		} else if (obj?.status === 'PAID') {
			return (
				<div className="d-flex flex-row" style={{ width: '120px', fontSize: '11px', lineHeight: '16px', height: '24px', alignItems: 'center', backgroundColor: '#1CBB8C' }}>
					<p className="col-form-label text-white">Recuperado</p>
				</div>
			);
		} else if (obj?.status === 'PARTIALLY_PAID') {
			return (
				<div className=" d-flex flex-row" style={{ width: '120px', fontSize: '11px', lineHeight: '16px', height: '24px', alignItems: 'center', backgroundColor: '#5664D2' }}>
					<p className="col-form-label text-white">Pagamento iniciado</p>
				</div>
			);
		} else if (obj?.status === 'DENIED') {
			return (
				<div className="d-flex flex-row" style={{ width: '120px', fontSize: '11px', lineHeight: '16px', height: '24px', alignItems: 'center', backgroundColor: '#852032' }}>
					<p className="col-form-label text-white">Negado</p>
				</div>
			);
		} else if (obj?.status === 'CANCELLED') {
			return (
				<div className=" d-flex flex-row" style={{ width: '120px', fontSize: '11px', lineHeight: '16px', height: '24px', alignItems: 'center', backgroundColor: '#FF3D60' }}>
					<p className="col-form-label text-white">Cancelada</p>
				</div>
			);
		} else {
			return (
				<div className="bg-secondary d-flex flex-row" style={{ width: '98px', fontSize: '11px', lineHeight: '16px', height: '24px', alignItems: 'center' }}>
					<p className="col-form-label text-white">Aguardando</p>
				</div>
			);
		}
	};

	getDateFormatted = (date, billing_id) => {
		if (billing_id) {
			return (
				<a href={`/billing/show/${billing_id}`}>
					{date ? moment(date).format("DD/MM/YYYY") : '-'}
				</a>
			)
		}
		return (
			<p style={{ marginBottom: '0px' }}>
				{date ? moment(date).format("DD/MM/YYYY") : '-'}
			</p>
		);
	};

	getStatusProposition = (obj) => {
		let status = '';
		switch (obj.status) {
			case 'FIRST_PROPOSAL':
				status = 'Criada Admin';
				break;
			case 'AWAITING_SIGN':
				status = 'Aguardando sinal';
				break;
			case 'FINALIZED':
				status = 'Finalizado';
				break;
			default:
				status = obj.status;
				break;
		}
		return (
			<div style={{ textAlign: "center" }}>
				<p>{status}</p>
			</div>
		);
	}

	onBoletoCreation = async (obj) => {
		this.setState({ ...this.state, loading: true, });

		createRentBoleto(obj.id).then((data) => {
			setTimeout(() => {
				getBillingById(obj.id).then((data) => {
					navigator.clipboard.writeText(data.data?.boleto?.pdf_url);
					this.setState({ ...this.state, loading: false, });
				});
			}, 5000);
		}).catch(err => {
			this.setState({ ...this.state, boletoError: true, loading: false, });

			setTimeout(() => {
				this.setState({ ...this.state, boletoError: false, });
			}, 3000);
		}).finally(() => {
			this.setState({ ...this.state, loading: false, });
		})
	}

	columnOptionsCrud = (obj) => {
		if (this.props.crudUrl) {
			return (
				<div style={{ textAlign: "center" }}>
					<Link to={`/pagamento/negativacao/show/${obj.billing_id}/${obj.id}`} className="btn btn-table-action mr-2">
						<i className="fas fa-eye" style={{ color: "#5664D2" }} />
					</Link>
				</div>
			);
		}
	};

	export() {
		this.dt.exportCSV();
	}

	render() {
		return (
			<DataTable ref={(el) => this.dt = el} value={this.state?.data?.data} paginator={true} rows={this.state.rows} rowsPerPageOptions={[10, 25, 50]} first={this.state.first} last={this.state.last}
				totalRecords={this.state.totalRecords} lazy={true} onPage={this.onPage} loading={this.state.loading} responsive={true} loadingIcon={`fas fa-sync fa-spin`} onSort={(e) => this.sortTable(e)}
				paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" emptyMessage="Nenhum registro encontrado!"
				currentPageReportTemplate="Mostrando {first} até {last} de {totalRecords} registros" sortField={this.state.sortField} sortOrder={this.state.sortOrder}>
				{this.props.export ? this.export() : false}
				<Column field="landlord.name" body={(obj) => this.getUserName(obj, 'landlord')} header="Responsável" />
				<Column field="tenant.name" body={(obj) => this.getUserName(obj, 'tenant')} header="Inquilino" />
				<Column sortable field="billing_id" body={(obj) => this.getDateFormatted(obj.billing.due_date, obj.billing_id)} header="Cobrança negativada" />
				<Column sortable field="start_date" body={(obj) => this.getDateFormatted(obj.start_date)} header="Data de início" />
				<Column field="status" body={(obj) => this.getStatus(obj)} header="Status" style={{ width: '160px' }} />
				<Column header="Ações" body={(obj) => this.columnOptionsCrud(obj)} style={{ width: '160px' }} />
			</DataTable>
		);
	}
}
