import reduce from 'lodash/reduce';
import isPlainObject from 'lodash/isPlainObject';
import { Constants } from '../helpers/Constants';
import axios from 'axios';

export function setupAxios(axios, store) {
	axios.interceptors.request.use(config => {
		let authToken = localStorage.getItem("authToken");

		if (authToken) {
			config.headers.Authorization = `Bearer ${authToken}`;
		}

		return config;
	},
		err => Promise.reject(err)
	);

	axios.interceptors.response.use(function (response) {
		return response;
	}, function (error) {
		if (process.env.NODE_ENV !== 'development') {
			if (error.response?.status == 401) {
				localStorage.removeItem('authToken');
				localStorage.removeItem('authUser');
			}

			if (error.response?.status == 403) {
				// window.location.href = '/error/error-v1';
				console.log(error.response?.status)
			}

			if (error.response?.status == 500) {
				// window.location.href = '/error/error-v2';
				console.log(error.response?.status)
			}

			if (error.response?.status == 404) {
				// window.location.href = '/error/error-v3';
				console.log(error.response?.status)
			}
		}
		return Promise.reject(error);
	});
}

export const allowOnlyNumbersInput = (input) => {
	let value = input.target.value;

	value = value.replace(/[^\d.]+/g, '');

	input.target.value = value;

	return input.target.value;
};

export const allowOnlyPercentageInput = (input) => {
	// Padroniza a string para remover espaços em branco
	input.target.value = input.target.value.replace(/\s/g, "");

	// // Define a regex para permitir apenas números, vírgulas e pontos
	input.target.value = input.target.value.replace(/^(\d*[.]?\d{0,2}).*/, '$1');

	//permite valores de 0 a 100 apenas
	if (Number(input.target.value) > 100) {
		input.target.value = '100'
	}

	// Retorna o número convertido
	return input.target.value;
};

export function parseQuery(query, _prefix = '') {
	return reduce(query, (acc, val, key) => {
		const prefix = _prefix ? `${_prefix}[${key}]` : key;
		if (isPlainObject(val)) {
			return { ...acc, ...parseQuery(val, prefix) };
		}

		return { ...acc, [prefix]: val };
	}, {});
};

export const adicionarCasasDecimais = (numero) => {
	// Verifica se o número possui casas decimais
	if (Number.isInteger(numero)) {
		// Adiciona duas casas decimais zeradas
		numero = numero.toFixed(2);
	}

	return numero;
}

export const buscaCep = (input, setFieldValue, nomeEndereco, nomeBairro, nomeCidade, nomeUf) => {
	if (input.target.value.length > 8) {
		var cep = input.target.value.replace(/[^0-9]/, "");

		const instance = axios.create();

		instance
			.get(`https://viacep.com.br/ws/${cep}/json/`, {
				transformRequest: (data, headers) => {
					delete headers.common["Authorization"];
				},
			})
			.then((res) => {
				setFieldValue(nomeEndereco, res.data.logradouro);
				setFieldValue(nomeBairro, res.data.bairro);
				setFieldValue(nomeCidade, res.data.localidade);
				setFieldValue(nomeUf, res.data.uf);
			})
			.catch((err) => { });
	}

	return formatCepInput(input);
};

export const formatBRL = value => {
	const formatter = new Intl.NumberFormat('pt-BR', {
		style: 'currency',
		currency: 'BRL',
		minimumFractionDigits: 2
	});

	value = formatter.format(value);

	return value;
};

export const formatIntNumber = value => {
	const formatter = new Intl.NumberFormat('pt-BR', {
		style: 'decimal',
		maximumFractionDigits: 0
	});

	value = formatter.format(value);

	return value;
};

export const formatBRLInput = input => {
	input.target.value = Number(input.target.value.toString().replace(/[^0-9-]/g, '')) / 10 ** 2;

	input.target.value = formatBRL(input.target.value);

	return input;
};

export const formatCPF = value => {
	value = value.replace(/\D/g, "");
	value = value.replace(/(\d{3})(\d)/, "$1.$2");
	value = value.replace(/(\d{3})(\d)/, "$1.$2");
	value = value.replace(/(\d{3})(\d{1,2})$/, "$1-$2");

	return value;
};

export const formatCPFInput = input => {
	input.target.value = formatCPF(input.target.value);

	return input;
};

export const formatCNPJInput = input => {
	let value = input.target.value;

	value = value.replace(/\D/g, "");
	value = value.replace(/(\d{2})(\d)/, "$1.$2");
	value = value.replace(/(\d{3})(\d)/, "$1.$2");
	value = value.replace(/(\d{3})(\d)/, "$1/$2");
	value = value.replace(/(\d{4})(\d{1,2})$/, "$1-$2");

	input.target.value = value;

	return input;
};

export const formatCNPJ = value => {
	value = value.replace(/\D/g, "");
	value = value.replace(/(\d{2})(\d)/, "$1.$2");
	value = value.replace(/(\d{3})(\d)/, "$1.$2");
	value = value.replace(/(\d{3})(\d)/, "$1/$2");
	value = value.replace(/(\d{4})(\d{1,2})$/, "$1-$2");

	return value;
};

export const formatTelefone = value => {
	value = value.replace(/\D/g, "");
	value = value.replace(/(\d{0})(\d)/, "$1($2");
	value = value.replace(/(\d{2})(\d)/, "$1) $2");
	value = value.replace(/(\d{1})(\d{1,4})$/, "$1-$2");

	return value;
};

export const formatTelefoneInput = input => {
	input.target.value = formatTelefone(input.target.value);

	return input;
};

export const formatCep = value => {
	value = value.replace(/\D/g, "");
	value = value.replace(/(\d{5})(\d)/, "$1-$2");

	return value;
};

export const formatCepInput = input => {
	input.target.value = formatCep(input.target.value);

	return input;
};

export const limparMoeda = (valor) => {
	valor = valor.replace("R$", "");
	valor = valor.replace(".", "");
	valor = valor.replace(",", ".");
	valor = valor.replace('/^{Z}+|{Z}+$/u', '');
	valor = valor.trim();

	return valor;
};

export const limparCPF = (valor) => {
	valor = valor.replaceAll(".", "");
	valor = valor.replace("-", "");
	valor = valor.replace('/^{Z}+|{Z}+$/u', '');
	valor = valor.trim();

	return valor;
};

export const limparCNPJ = (valor) => {
	valor = valor.replaceAll(".", "");
	valor = valor.replace("-", "");
	valor = valor.replace("/", "");
	valor = valor.replace('/^{Z}+|{Z}+$/u', '');
	valor = valor.trim();

	return valor;
};

export const limparMoedaFunction = (valor) => {
	valor = valor.replace(/[^0-9,]*/g, '').replace(',', '.');

	valor = valor.trim();

	if (valor == '') return 0;

	return valor;
};

export const formatBRLNoSign = (value) => {
	const formatter = new Intl.NumberFormat("pt-BR", {
		style: "currency",
		currency: "BRL",
		symbol: "",
	});

	value = formatter.format(value).replace("R$", "");

	return value;
};

export const limparTelefone = (valor) => {
	valor = valor.replaceAll("(", "");
	valor = valor.replaceAll(")", "");
	valor = valor.replaceAll("-", "");
	valor = valor.replaceAll(" ", "");
	valor = valor.replace('/^{Z}+|{Z}+$/u', '');
	valor = valor.trim();

	return valor;
};

export const getImovelTipoName = (type) => {
	switch (type) {
		case 'apartment':
			return 'Apartamento';
		case 'house':
			return 'Casa';
		case 'Casa':
			return 'Casa';
		case 'studio':
			return 'Studio';
		case 'condo_house':
			return 'Casa em condomínio';
		default:
			return '';
	}
};

export const getLatLngFromCEP = async (cep, callback) => {
	if (cep.length > 8) {
		cep = cep.replace(/\D/, "");
		const instance2 = axios.create();

		await instance2.get(`https://maps.googleapis.com/maps/api/geocode/json?address=${cep}&key=${Constants.google_api_key}`, {
			transformRequest: (data, headers) => {
				delete headers.common['Authorization'];
			}
		}).then(res => {
			callback(res.data.results[0].geometry.location);
		}).catch(err => {
			console.log(err);
		});
	}
};

export const isEmpty = (item) => {
	const type = (item) ? item.constructor.name.toLowerCase() : false;
	return !(
		(type === 'bollean') ||
		((type === 'array' || type === 'string') && item?.length) ||
		(type === 'object' && Object.keys(item).length)
	);
}

export function bytesToSize(bytes) {
	const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
	const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
	return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
}

export const getStates = () => {
	return [
		"AC",
		"AL",
		"AP",
		"AM",
		"BA",
		"CE",
		"DF",
		"ES",
		"GO",
		"MA",
		"MT",
		"MS",
		"MG",
		"PA",
		"PB",
		"PR",
		"PE",
		"PI",
		"RJ",
		"RN",
		"RS",
		"RO",
		"RR",
		"SC",
		"SP",
		"SE",
		"TO",
	];
};

export const getProfessions = async () => {
	const instance = axios.create();

	const response = await instance
		.get(`https://dev-api.mellro.com/api/occupations/all`)
		.then((res) => {
			return res.data.data
		})
		.catch((err) => {
			console.log(err);
		});

	return response;
}

export const getLegalNatures = async () => {
	const instance = axios.create();

	const response = await instance
		.get(`https://dev-api.mellro.com/api/legal-natures/all`)
		.then((res) => {
			return res.data.data
		})
		.catch((err) => {
			console.log(err);
		});

	return response;
}

export const getDeclaredIncomes = async () => {
	const instance = axios.create();

	const response = await instance
		.get(`https://dev-api.mellro.com/api/declared-incomes/all`)
		.then((res) => {
			return res.data.data
		})
		.catch((err) => {
			console.log(err);
		});

	return response;
}

export const getBusinessTypes = async () => {
	const instance = axios.create();

	const response = await instance
		.get(`https://dev-api.mellro.com/api/business-types/all`)
		.then((res) => {
			return res.data.data
		})
		.catch((err) => {
			console.log(err);
		});

	return response;
}

export const getReasonAccountRefused = (reason) => {
	switch (reason) {
		case 'NOT_RETRY':
			return `Indica que o CPF do cadastro já recebeu alguma marcação de fraude pelo nosso time de Prevenção e foi bloqueado.
					Por isso, não é possível abrir uma nova conta para esse CPF fraudado.`;
		case 'NOT_ALLOWED':
			return 'Indica que o parceiro está fazendo a chamada em algum endpoint ao qual não tem acesso por razões contratuais.';
		case 'SELFIE_RECUSED':
			return 'A selfie enviada apresenta problemas relativos à prova de vida.';
		case 'DOCUMENT_RECUSED':
			return `Há problemas na comparação da foto do documento do cliente com a imagem da selfie enviada,
			não sendo possível confirmar que se trata da mesma pessoa.`;
		case 'OCR_RECUSED':
			return `Existem divergências entre as informações retornadas pela leitura do documento e as informações fornecidas pelo cliente.`;
		case 'AGE_RECUSED':
			return `Existem divergências entre a data de nascimento informada pelo cliente e a verificada nos bureaus de informação.
			Esse status também retorna em caso de tentativa de cadastro de menores de 16 anos.`;
		case 'NAME_RECUSED':
			return `Há problemas relativos à comparação do nome do cliente ou da mãe, fornecidos por escrito no aplicativo, com os retornados pelo bureau e OCR.`;
		case 'STATUS_RECUSED':
			return `Há problemas relativos ao CPF informado no cadastro.`;
		case 'EMAIL_RECUSED':
			return `Foi detectada fraude relacionada ao e-mail informado pelo cliente.`;
		case 'FORBIDDEN_WORDS':
			return `Foi detectada alguma palavra de cunho ofensivo ou de baixo calão.`;
		case 'RISKY_BEHAVIOR':
			return `Indica alto risco de fraude associado ao cadastro realizado.`;
		case 'NOT_FOUND':
			return `As informações do cliente não foram localizadas nos bureaus.
			Esse cenário é mais comum para menores de idade e pessoas que completaram 18 anos recentemente.`;
		case 'DATA_RECUSED':
			return `Há problemas com os dados informados pelo cliente, quando comparados com aqueles obtidos via bureau.
			Os dados podem ser: nome, nome da mãe, data de nascimento, situação do CPF junto à Receita Federal, nome da empresa e CPF não pertencente ao QSA (quadro de sócios e administradores).
			Observação: Devido ao caráter genérico desse status, futuramente ele será descontinuado.`;
		case 'PHOTO_RECUSED':
			return `Problema relacionado à imagem da selfie ou do documento.
			No caso da selfie, é possível que ela não esteja permitindo a identificação clara do cliente ou que aparente ser a foto de um retrato ou tela.
			Observação: Devido ao caráter genérico desse status, futuramente ele será descontinuado.`;
		default:
			return '';
	}
};

export const getReasonAccountRefusedWhatToDo = (reason) => {
	switch (reason) {
		case 'NOT_RETRY':
			return `Não é possível realizar o cadastro desse cliente.
			Interrompa as tentativas de cadastro desse CPF.`;
		case 'NOT_ALLOWED':
			return 'Entre em contato com seu representante comercial.';
		case 'SELFIE_RECUSED':
			return `Instrua seu cliente a tirar a selfie em um local bem iluminado e não utilizar acessórios, como bonés, óculos de sol etc.
					Recomendamos adicionar uma máscara na tela para orientar o local em que o usuário deve posicionar o rosto no momento da captura da foto.
					Após a foto ser tirada, exiba o resultado para que o usuário a aprove. Caso não haja aprovação, ofereça a opção de tirar nova foto.`;
		case 'DOCUMENT_RECUSED':
			return `Recomende que a foto contenha apenas a imagem do documento, sem interferência de fundo, e que esteja bem enquadrada.
			Se possível, a foto deve ser tirada com o documento fora do plástico.`;
		case 'OCR_RECUSED':
			return `O documento fotografado deve ser RG ou CNH.
					Recomende que a foto contenha apenas a imagem do documento, sem interferência de fundo, e que esteja bem enquadrada.
					Se possível, a foto deve ser tirada com o documento fora do plástico.`;
		case 'AGE_RECUSED':
			return `Solicite que o cliente confira os dados fornecidos, pois eles devem estar de acordo com os informados no documento enviado.
					Não permita que clientes menores de 16 anos realizem o cadastro.`;
		case 'NAME_RECUSED':
			return `Peça a seu cliente que confirme os dados fornecidos, pois eles devem estar de acordo com os informados no documento enviado.
					Recordamos que o problema pode referir-se à alteração do nome do cliente por estado civil (nome de solteiro x nome de casado) que não foi atualizado nos sistemas públicos de registro.`;
		case 'STATUS_RECUSED':
			return `Verifique o status do CPF de seu cliente junto à Receita Federal.
					Se necessário, solicite que ele regularize sua situação.
					Caso verificado que a situação de seu cliente é regular, entre em contato com o Service Desk do Bankly.`;
		case 'EMAIL_RECUSED':
			return `Não é possível realizar o cadastro desse cliente.`;
		case 'FORBIDDEN_WORDS':
			return `Solicite que o cliente reenvie os dados, atentando-se ao teor das palavras.`;
		case 'RISKY_BEHAVIOR':
			return `Não é possível realizar o cadastro desse cliente.`;
		case 'NOT_FOUND':
			return `Entre em contato com o Service Desk do Bankly.`;
		case 'DATA_RECUSED':
			return `Solicite ao cliente que envie os dados novamente com base no detalhamento dos status.`;
		case 'PHOTO_RECUSED':
			return `Recomende ao seu cliente que a selfie seja tirada a uma certa distância da câmera, para que se possa obter informações do plano de fundo.
					Os documentos fotografados devem ser RG ou CNH. A câmera deve estar próxima ao documento, para facilitar a análise da face do cliente.
					Em todos os casos, é importante atentar-se ao enquadramento e à iluminação.`;
		default:
			return '';
	}
};

export const findPessoalInformation = (texto) => {
	if (!texto || texto === '') {
		return false;
	}

	const regexEmail = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/;
	const regexCPF = /\b\d{3}[\.\-]?\d{3}[\.\-]?\d{3}[\.\-]?\d{2}\b/;
	const regexCNPJ = /\b\d{2}[\.\-\/]?\d{3}[\.\-]?\d{3}[\.\-\/]?\d{4}[\.\-]?\d{2}\b|\b\d{14}\b/;
	const regexTelefone = /\b(?:\(\d{2}\)\s*|\d{2}\s*)?9?\d{4}-?\d{4}\b/;

	const matchCPF = texto.match(regexCPF);
	const cpfValido = matchCPF ? matchCPF.some(cpf => validaCpf(cpf.replace(/\D/g, ''))) : false;

	const matchCNPJ = texto.match(regexCNPJ);
	const cnpjValido = matchCNPJ ? matchCNPJ.some(cnpj => validaCnpj(cnpj.replace(/\D/g, ''))) : false;

	return (
		regexEmail.test(texto) ||
		cpfValido ||
		cnpjValido ||
		regexTelefone.test(texto)
	);
}

export const validaCnpj = (cnpj) => {
	cnpj = cnpj.replace(/[^\d]+/g, '');

	if (cnpj.length !== 14 || /^(\d)\1+$/.test(cnpj)) return false;

	const calcularDigito = (base, pesos) => {
		let soma = 0;
		for (let i = 0; i < pesos.length; i++) {
			soma += parseInt(base.charAt(i)) * pesos[i];
		}
		let resto = soma % 11;
		return resto < 2 ? 0 : 11 - resto;
	};

	const base = cnpj.slice(0, 12);
	const primeiroDigito = calcularDigito(base, [5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2]);
	const segundoDigito = calcularDigito(base + primeiroDigito, [6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2]);

	return primeiroDigito === parseInt(cnpj.charAt(12)) && segundoDigito === parseInt(cnpj.charAt(13));
};

export const validaCpf = (cpf) => {
	var add = 0;
	var rev = 0;

	cpf = cpf.replace(/[^\d]+/g, '');
	if (cpf == '') return false;

	if (cpf.length != 11) return false;

	for (var i = 0; i < 9; i++) {
		add += parseInt(cpf.charAt(i)) * (10 - i);
	}

	rev = 11 - (add % 11);

	if (rev == 10 || rev == 11) {
		rev = 0;
	}
	if (rev != parseInt(cpf.charAt(9))) {
		return false;
	}

	add = 0;

	for (var i = 0; i < 10; i++) {
		add += parseInt(cpf.charAt(i)) * (11 - i);
	}

	rev = 11 - (add % 11);

	if (rev == 10 || rev == 11) {
		rev = 0;
	}

	if (rev != parseInt(cpf.charAt(10))) {
		return false;
	}
	return true;
}