/* eslint-disable eqeqeq */
import moment from "moment";
import { limparMoeda } from "./Utils";

export const createFormikValidation = (formMap, values) => {
	const errors = {};

	for (let [key, value] of formMap) {
		if (!values[key]) {
			errors[key] = "O campo " + value + " é obrigatório."
		} else if (['crawler_approved', 'crawler_not_converted'].includes(key)) {
			if (values[key].length > 160) {
				errors[key] = "O texto não pode ter mais de 160 caracteres. "
			}
		}
	}

	return errors;
};

export const validateFormAddHistorico = (values) => {
	let map = new Map();

	map.set('historico', 'histórico');

	let errors = createFormikValidation(map, values);

	return errors;
};

export const validateFormUser = (values, action, step, addSocio) => {
	let map = new Map();

	if (action == 'store') {
		map.set('name', 'nome');
		map.set('email', 'email');
		map.set('cpf', 'cpf');
		map.set('phone', 'celular');
		map.set('password', 'senha');

		map.set('city', 'cidade');
		// map.set('complement', 'complemento');
		map.set('neighbourhood', 'bairro');
		map.set('number', 'número');
		map.set('state', 'estado');
		map.set('zip_code', 'código postal');
		map.set('street', 'endereço');
	}

	let errors = createFormikValidation(map, values);

	return errors;
};

export const validateSmsConfig = (values) => {
	let map = new Map();

	map.set('crawler_approved', 'SMS Crawler aprovação proprietários');
	map.set('crawler_not_converted', 'SMS Crawler não convertidos');

	let errors = createFormikValidation(map, values);

	return errors;
};

export const validateHsmConfig = (values) => {
	let map = new Map();

	map.set('hsm_name', 'HSM crawler proprietários');

	let errors = createFormikValidation(map, values);

	return errors;
};

export const validateNewCrawler = (values) => {
	let map = new Map();

	map.set('name', 'nome');
	map.set('cron', 'Agendar Crawler');
	map.set('stateCode', 'Estado');
	map.set('cityCode', 'Código Cidade');

	let errors = createFormikValidation(map, values);

	return errors;
};

export const validateFormVouchers = values => {
	let map = new Map();

	map.set('code', 'código');
	map.set('user', 'usuário');
	map.set('expiration_date', 'expiration date');

	let errors = createFormikValidation(map, values);

	return errors;
};

export const validateFormAnnouncements = values => {
	let map = new Map();

	let errors = createFormikValidation(map, values);

	return errors;
};

export const validateFormCoupon = (values) => {
	let map = new Map();

	map.set('code', 'código');
	// map.set('plan', 'plano');
	// map.set('discount_value', 'desconto');
	// map.set('discount_type', 'tipo do desconto');
	// map.set('application_start_date', 'data inicial da aplicação');
	// map.set('application_end_date', 'data final da aplicação');
	// map.set('expiration_date', 'data de expiração');

	let errors = createFormikValidation(map, values);

	return errors;
};

export const validateFormNegotiation = (values, form) => {
	let map = new Map();

	map.set('start_date', 'data do ínicio');
	map.set('duration', 'tempo de contrato');

	let errors = createFormikValidation(map, values);

	values.percentage = values?.percentage?.replace(/,/g, '.')

	if (form == 'update') {
		if (values?.war_type === 'WARRANTY' && (parseFloat(values?.percentage).toFixed(2) > 7 || parseFloat(values?.percentage).toFixed(2) < 5)) {
			errors.percentage = 'O valor da porcentagem do plano deve estar entre 5% e 7%'
		}

		if (values?.war_type === 'WARRANTY_GUARANTEE_DAMAGE' && (parseFloat(values?.percentage).toFixed(2) > 10 || parseFloat(values?.percentage).toFixed(2) < 7)) {
			errors.percentage = 'O valor da porcentagem do plano deve estar entre 7% e 10%'
		}
	} else if (form == 'store') {
		if (typeof values?.plan == 'string') {
			if (values?.war_type === 'WARRANTY' && (parseFloat(values?.percentage).toFixed(2) > 7 || parseFloat(values?.percentage).toFixed(2) < 5)) {
				errors.percentage = 'O valor da porcentagem do plano deve estar entre 5% e 7%'
			}

			if (values?.war_type === 'WARRANTY_GUARANTEE_DAMAGE' && (parseFloat(values?.percentage).toFixed(2) > 10 || parseFloat(values?.percentage).toFixed(2) < 7)) {
				errors.percentage = 'O valor da porcentagem do plano deve estar entre 7% e 10%'
			}
		}

	}

	return errors;
};

export const validateFormGratification = (values, form) => {
	let map = new Map();

	map.set('bonus_rate', 'taxa de gratificação');
	map.set('role', 'cargo');

	let errors = createFormikValidation(map, values);

	if (values.bonus_rate > 50) {
		errors.bonus_rate = 'Limite de 50% na soma de todas gratificações.';
	}

	return errors;
};

export const validateFormCrawler = values => {
	let map = new Map();

	map.set('name', 'nome do locador');
	map.set('phone', 'telefone do locador');

	let errors = createFormikValidation(map, values);

	return errors;
};

export const validateDadosBancarios = (values) => {
	let map = new Map();

	let errors = createFormikValidation(map, values);

	return errors;
};

export const validateContractTermination = (values, text, usufruidoMaximo, reembolsosMaximo, quebraContratualMaximo, multaRescisoriaMaximo) => {
	let map = new Map();

	let errors = createFormikValidation(map, values);

	if (parseFloat(limparMoeda(values?.multaRescisoria)) > 0 && parseFloat(limparMoeda(values?.multaRescisoria)) > parseFloat(multaRescisoriaMaximo.toFixed(2))) {
		errors.multaRescisoria = 'Você não pode definir um valor maior do que o calculado.';
	}

	if (parseFloat(limparMoeda(values?.aluguelUsufruido)) > 0 && parseFloat(limparMoeda(values?.aluguelUsufruido)) > parseFloat(usufruidoMaximo.toFixed(2))) {
		errors.aluguelUsufruido = 'Você não pode definir um valor maior do que o calculado.';
	}

	if (parseFloat(limparMoeda(values?.reembolso)) > 0 && parseFloat(limparMoeda(values?.reembolso)) > parseFloat(reembolsosMaximo.toFixed(2))) {
		errors.reembolso = 'Você não pode definir um valor maior do que o calculado.';
	}

	if (parseFloat(limparMoeda(values?.multaQuebraContratual)) > 0 && parseFloat(limparMoeda(values?.multaQuebraContratual)) > parseFloat(quebraContratualMaximo.toFixed(2))) {
		errors.multaQuebraContratual = 'Você não pode definir um valor maior do que o calculado.';
	}

	return errors;
};

export const validateFormAddTenant = values => {
	let map = new Map();

	map.set('name', 'nome do locador');
	map.set('email', 'email do locador');
	map.set('cpf', 'cpf do locador');
	map.set('income_value', 'renda do locador');
	map.set('origem', 'origem da renda do locador');

	let errors = createFormikValidation(map, values);

	return errors;
};

export const validateContractEdit = (values, currentStep, contract_users) => {
	let map = new Map();

	if (currentStep === 1) {
		map.set('signExpirationDate', 'data máxima de assinatura')

		if (values.type === 'CONTRACT_DATA') {
			if (values.check_landlord_data) {
				map.set('landlord_cep', 'cep')
				map.set('landlord_state', 'estado')
				map.set('landlord_city', 'cidade')
				map.set('landlord_neighbourhood', 'bairro')
				map.set('landlord_street', 'rua')
				map.set('landlord_number', 'número')
				map.set('landlord_name', 'nome')
			}

			if (values.check_announcement_data) {
				map.set('announcement_cep', 'cep')
				map.set('announcement_state', 'estado')
				map.set('announcement_city', 'cidade')
				map.set('announcement_neighbourhood', 'bairro')
				map.set('announcement_street', 'rua')
				map.set('announcement_number', 'número')
			}

			if (values.check_tenant_data) {
				for (const tenant of contract_users.filter(obj => obj.user_type === 'tenant')) {
					if (values[`check_tenant_${tenant.user_id}`]) {
						map.set(`tenant_${tenant.user_id}_cep`, 'cep')
						map.set(`tenant_${tenant.user_id}_state`, 'estado')
						map.set(`tenant_${tenant.user_id}_city`, 'cidade')
						map.set(`tenant_${tenant.user_id}_neighbourhood`, 'bairro')
						map.set(`tenant_${tenant.user_id}_street`, 'rua')
						map.set(`tenant_${tenant.user_id}_number`, 'número')
						map.set(`tenant_${tenant.user_id}_name`, 'nome')
					}
				}
			}
		}
	}

	let errors = createFormikValidation(map, values);

	console.log(errors);

	return errors;
};

export const validateFormReembolso = values => {
	let map = new Map();

	map.set('amount', 'valor');
	map.set('type', 'tipo do reembolso');
	map.set('title', 'titulo');
	map.set('anexos', 'comprovantes');

	let errors = createFormikValidation(map, values);

	if (values?.anexos?.length == 0) {
		errors.anexos = "O campo de anexos é obrigatório."
	}

	return errors;
};

export const validateFormBilling = (values) => {
	let map = new Map();

	map.set('value', 'valor do aluguel');
	map.set('due_date', 'data de vencimento');

	let errors = createFormikValidation(map, values);

	return errors;
};

export const contractPercentageEdit = (values) => {
	let map = new Map();

	map.set('percentage', 'porcentagem do plano');

	let errors = createFormikValidation(map, values);
	return errors
}

export const validateInspectionEdit = (values) => {
	const errors = {};

	const startDate = moment(values.start_date, 'YYYY-MM-DD');
	const endDate = moment(values.end_date, 'YYYY-MM-DD');

	if (!endDate.isValid()) {
		errors.end_date = 'A data de término é inválida.';
	} else if (!startDate.isValid()) {
		errors.start_date = 'A data de início é inválida.';
	} else if (endDate.isSameOrBefore(startDate)) {
		errors.end_date = 'A data de término deve ser posterior à data de início.';
	}

	return errors;
};