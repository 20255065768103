import React, { Component } from 'react';
import { Container, Card, CardBody, Button, Col, Label, FormGroup, Alert } from "reactstrap";
import Breadcrumbs from '../../components/Breadcrumb';
import BetterCircularProgress from '../../components/BetterCircularProgress';
import { Link, Redirect } from "react-router-dom";
import { getByPropositionId as getCreditConsultByPropositionId, update as updateLaudo, addTenant } from '../../crud/credit-consut.crud';
import { get as getProposition, update as updateProposition } from '../../crud/propositions.crud';
import { getByFilter as getUserByFilter } from '../../crud/users.crud';
import { formatBRLInput, formatCPFInput, limparCPF, limparMoeda } from '../../helpers/Utils';
import { Formik } from "formik";
import { validateFormAddTenant } from '../../helpers/FormValidators';
import { TextField } from "@material-ui/core";
import { updateIncome } from '../../crud/income-users.crud';
import { store as storeUser } from '../../crud/users.crud';
import Swal from 'sweetalert2';

class NegotiationLaudoAddTenant extends Component {

	state = {
		loading: false,
		data: null,
		insurance: null,
		errors: false,
		crudUrl: '/negotiation',
		contract: false,
		iptu: '',
		condo: '',
		rent: '',
		start_date: '',
		message: '',
		parties: [],
		contract_id: '',
		contract_link: '',
		plans: [],
		flags: {
			show_button_subscribe_landlord: false,
			show_button_subscribe_tenant: false,
			contract_created: false,
			contract_signed_landlord: false,
			contract_signed_tenant: false,
		},
		submittingLaudo: false,
		laudo: null,
	};

	componentDidMount = async () => {
		this.setState({ loading: true });
		let laudo = null;
		let announcement = null;

		await getCreditConsultByPropositionId(this.props.match.params.id).catch(err => { }).then(res => {
			if (res?.status == 200) {
				laudo = res.data.data;
				// console.log(res.data.data);
				// this.setState({ laudo: res.data.data });
				// console.log('Parecer locação: ' + res.data.data.fc_laudo.parecer.locacao.parecer_inquilinos.parecer);
			}
		});

		await getProposition(this.props.match.params.id).catch(err => {
			this.setState({ errors: true });
		}).then(({ data }) => {
			announcement = data.data;
		});

		if (laudo) {
			// TODO - Change to announcement parties
			laudo.fc_laudo.pretendentes.forEach((pre, idxPre) => {
				if (announcement.tenant.cpf === pre.pessoa.cpf) {
					pre.pessoa.user_id = announcement.tenant.id;
				} else {
					if (announcement.parties?.length > 0) {
						announcement.parties.forEach((part, idxPart) => {
							if (part.cpf === pre.pessoa.cpf) {
								pre.pessoa.user_id = part.user_id;
							}
						});
					}
				}
			});
		}

		console.log({ laudo, announcement });
		this.setState({ loading: false, laudo, data: announcement });
	};

	reprocessLaudo = () => {
		this.setState({ submittingLaudo: true });

		updateLaudo({ proposition_id: this.props.match.params.id }).catch(err => { }).then(res => {
			if (res?.status === 200) {
				this.setState({ submittingLaudo: false, laudo: { ...this.state.laudo, fc_laudo: null } });
			}
		}).finally(() => {
			this.setState({ submittingLaudo: false });
		});
	};

	htmlSugestao = (html) => {
		return <div dangerouslySetInnerHTML={{ __html: html }} />
	};

	handleCpfChange = (cpf, setFieldValue) => {
		if (cpf.length === 14) {
			console.log('full cpf');

			getUserByFilter({ cpf: limparCPF(cpf) }).catch(err => { }).then(res => {
				if (res?.status === 200) {
					console.log(res.data);

					if (res.data.total > 0) {
						setFieldValue('name', res.data.data[0].name);
						setFieldValue('email', res.data.data[0].email);
					} else {
						setFieldValue('name', '');
						setFieldValue('email', '');
					}
				}
			});
		}
	};

	getInitialValues = () => {
		return {
			cpf: '',
			email: '',
			name: '',
			income_value: '',
			origem: '',
		};
	};

	getOrigensRenda = () => {
		return [
			{ key: 'clt', label: 'Funcionário Privado ou Público Registrado (CLT)' },
			{ key: 'public_agent', label: 'Funcionário Público (Estatutário)' },
			{ key: 'legal_person', label: 'Empresário' },
			{ key: 'liberal_professional', label: 'Profissional liberal' },
			{ key: 'autonomous', label: 'Autônomo' },
			{ key: 'retired', label: 'Aposentado ou Pensionista' },
			{ key: 'intern', label: 'Estagiário ou Bolsista' },
		];
	};

	onSubmit = async (values) => {
		if (!values.cpf || !values.email || !values.name || !values.income_value || !values.origem) {
			return Swal.fire('Ops', 'Você precisa preencher todos os campos antes de continuar', 'error');
		}

		if (values.cpf.length < 14) return Swal.fire('Ops', 'O CPF informado não é válido', 'error');

		this.setState({ loading: true });

		let user = null;
		let dataIncome = {
			type: values.origem,
			gross_income: limparMoeda(values.income_value),
		};

		await getUserByFilter({ cpf: limparCPF(values.cpf) }).catch(err => { }).then(res => {
			if (res?.status === 200) {
				console.log(res.data);

				if (res.data.total > 0) {
					user = res.data.data[0];
				}
			}
		});

		console.log(user);

		if (!user) {
			console.log('entrou');
			let dataUser = {
				name: values.name,
				email: values.email,
				cpf: limparCPF(values.cpf),
				password: values.cpf,
			};

			await storeUser(dataUser).catch((err) => { }).then((res) => {
				if (res?.status === 200) {
					user = res.data.data;
				}
			});
		}

		console.log(user);
		await updateIncome(user.id, dataIncome).catch(err => { }).then((res) => { });

		let data = {
			tenant_parties: [user.id]
		};

		await updateProposition(this.props.match.params.id, data).catch(err => { }).then((res) => {
			if (res?.status === 200) {
				// this.setState({ loading: false, success: true, message: 'Inquilino adicionado com sucesso.' });
			}
		});

		let origemRenda = '';

		this.getOrigensRenda().forEach((obj, i) => {
			if (obj.key === values.origem) origemRenda = obj.label;
		})
        console.log(this.state)
		console.log({origemRenda})

		let dataTenant = {
			fc_laudo_id: this.state.laudo.fc_solicitacao_id,
			name: user.name,
			cpf: limparCPF(values.cpf),
			income_type: 'PERSONAL_INCOME',
			main_income_source: origemRenda,
			income_value: limparMoeda(values.income_value),
		}

		await addTenant(dataTenant).catch(err => { }).then((res) => {
			if (res?.status === 200) {
				this.setState({ loading: false, success: true, message: 'Inquilino adicionado com sucesso.' });
			}
		});
	};

	render() {
		return (
			<React.Fragment>
				<BetterCircularProgress loading={this.state.loading}>
					<div className="page-content container-page">
						<Container fluid>
							<Breadcrumbs title="" breadcrumbItems={[{ link: '/negotiation/index', title: 'Negotiation' }, { link: `/negotiation/edit/${this.props.match.params.id}`, title: 'Visualizar' }, { link: `/negotiation/laudo/${this.props.match.params.id}`, title: 'Resultado' }, { link: '', title: 'Adicionar Inquilino' }]} />
							{this.state.errors && !this.state.message ?
								<Redirect to="/negotiation/index" />
								: null
							}

							{/* {this.state.success && !this.state.message ?
								<Redirect to={{ pathname: '/negotiation/index', state: { success: ['Proposition deleted successfuly!'] } }} />
								: null
							} */}

							{this.state.success && this.state.message ? <Alert color="success">{this.state.message}</Alert> : null}
							{this.state.errors && this.state.message ? <Alert color="danger">{this.state.message}</Alert> : null}

							<Formik initialValues={this.getInitialValues()} validate={values => validateFormAddTenant(values)} onSubmit={(values) => this.onSubmit(values)}>
								{({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue, setFieldTouched }) => (
									<form noValidate={true} autoComplete="off" onSubmit={handleSubmit}>
										<Card>
											<CardBody>
												<div className="d-flex">
													<div className="mr-2 p-3" style={{ background: '#F1F5F7', width: '504px', height: '672px', borderRadius: '6px' }}>
														<Col md={12}>
															<Label className="col-form-label mb-2" style={{ color: '#8590A5', fontSize: '16px' }}>Dados do novo inquilino</Label>

															<Col md={12}>
																<FormGroup>
																	<Label className="col-form-label">CPF *</Label>
																	<TextField name="cpf" type="text" variant="outlined" value={values.cpf} onBlur={handleBlur} onChange={e => { this.handleCpfChange(e.target.value, setFieldValue); handleChange(formatCPFInput(e)) }}
																		helperText={touched.cpf && errors.cpf} error={Boolean(touched.cpf && errors.cpf)} inputProps={{ maxLength: 14 }} style={{ backgroundColor: '#fff' }} />
																</FormGroup>
															</Col>

															<Col md={12}>
																<FormGroup>
																	<Label className="col-form-label">Nome *</Label>
																	<TextField name="name" type="text" variant="outlined" value={values.name} onBlur={handleBlur} onChange={handleChange}
																		helperText={touched.name && errors.name} error={Boolean(touched.name && errors.name)} style={{ backgroundColor: '#fff' }} />
																</FormGroup>
															</Col>

															<Col md={12}>
																<FormGroup>
																	<Label className="col-form-label">Email *</Label>
																	<TextField name="email" type="email" variant="outlined" value={values.email} onBlur={handleBlur} onChange={handleChange}
																		helperText={touched.email && errors.email} error={Boolean(touched.email && errors.email)} style={{ backgroundColor: '#fff' }} />
																</FormGroup>
															</Col>
														</Col>
													</div>

													<div className="p-3" style={{ background: '#F1F5F7', width: '504px', height: '672px', borderRadius: '6px' }}>
														<Col md={12}>
															<Label className="col-form-label mb-2" style={{ color: '#8590A5', fontSize: '16px' }}>Informar renda</Label>

															<Col md={12}>
																<FormGroup>
																	<Label className="col-form-label">Valor da renda principal *</Label>
																	<TextField name="income_value" type="text" variant="outlined" value={values.income_value} onBlur={handleBlur} onChange={e => handleChange(formatBRLInput(e))}
																		helperText={touched.income_value && errors.income_value} error={Boolean(touched.income_value && errors.income_value)} style={{ backgroundColor: '#fff' }} />
																</FormGroup>
															</Col>

															<Col md={12}>
																<Label className="col-form-label">Origem da renda principal:</Label>
																<select style={{ background: "#FFF", height: "40px" }} name='origem' onChange={handleChange}
																	onBlur={handleBlur} value={values.origem} className="custom-select" >
																	<option key={null} value={null} hidden></option>
																	{this.getOrigensRenda().map((obj) => {
																		return (
																			<option key={obj.key} value={obj.key}>{obj.label}</option>
																		);
																	})}
																</select>
															</Col>
														</Col>
													</div>
												</div>

												<div style={{ marginTop: '24px' }} className="d-flex">
													<Link to={`/negotiation/laudo/${this.state.data?.id}`} className="btn btn-secondary btn-bold mr-2"><i className="fa fa-times mr-2"></i>Cancelar</Link>

													<Button color="success" type="submit" className="waves-effect waves-light" >
														<i className={this.state.submittingLaudo ? 'fas fa-spinner fa-spin' : 'fa fa-check mr-2'}></i> Salvar
													</Button>
												</div>
											</CardBody>
										</Card>
									</form>
								)}
							</Formik>
						</Container>
					</div>
				</BetterCircularProgress>
			</React.Fragment>
		);
	}
}

export default NegotiationLaudoAddTenant;