import React, { Component, createRef } from 'react';
import { Col, Label, Button, FormGroup, Row, Card, CardBody, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import { TextField } from "@material-ui/core";
import { getAllByFilter, store } from "../../crud/doc-users.crud";
import Swal from 'sweetalert2';
import { Autocomplete } from '@material-ui/lab';
import { formatBRLInput, limparMoeda } from '../../helpers/Utils';
import { Formik } from 'formik';
import { UserComponent } from '../../pages/negotiation/components/user.component';
import DataTableDocList from '../datatables/DataTableDocList';
import BetterAlerts from '../BetterAlerts';

export default class DocsJuridicos extends Component {
	dataTableDocListRef = createRef();

	constructor() {
		super();

		this.state = {
			errors: [],
			success: [],
			file: null,
			loading: false,
			categorias: [
				'Termo de encerramento',
				'Termo de rescisão',
				'Termo de confissão de dívida',
				'Termo aditivo de contrato',
				'Notificação extrajudicial',
				'Procuração assinada pelo proprietário',
				'Assinados conjuntamente',
				'Cobranças jurídicas',
				'Cobranças',
				'Documentos internos',
				'Inadimplência',
				'Sentença',
				'Processo conhecimento',
				'Processo fase de execução',
				'Outros',
			],
			filters: {
				documento: '',
				categoria: '',
				upload_user: '',
			},
			flags: {
				show_documento: false,
				show_categoria: false,
				show_upload_user: false,
			},
			is_filter_open: false,
		};
	}

	componentDidMount() {
		let filters = this.state.filters;
		let flags = this.state.flags;

		if (localStorage.getItem('userDocsFilters')) {
			filters = JSON.parse(localStorage.getItem('userDocsFilters'));
		};

		if (localStorage.getItem('userDocsFlags')) {
			flags = JSON.parse(localStorage.getItem('userDocsFlags'));
		};

		this.setState({ filters, flags });
	};

	saveFilters = () => {
		localStorage.setItem('userDocsFilters', JSON.stringify(this.state.filters));
		localStorage.setItem('userDocsFlags', JSON.stringify(this.state.flags));
	};

	uploadDoc = (values, resetForm) => {
		if (!this.state.file) {
			Swal.fire('Ops', 'É necessário selecionar ao menos um arquivo para continuar.', 'error');
			return;
		}

		this.setState({ loading: true });

		let formData = new FormData();

		formData.append('file', this.state.file);

		if (values.description.trim() !== "") {
			formData.append('description', values.description.trim());
		}

		if (values.categoria.trim() !== null && values.categoria.trim() !== '') {
			formData.append('category', values.categoria.trim());
		}

		if (values.payments_value.trim() !== null && values.payments_value.trim() !== '') {
			formData.append('payments_value', limparMoeda(values.payments_value.trim()));
		}

		if (values.due_date.trim() !== null && values.due_date.trim() !== '') {
			formData.append('due_date', values.due_date);
		}

		formData.append('contract_id', this.props.contract_id);
		formData.append('friendly_contract_code', this.props.friendly_code);

		store(formData).then((res) => {
			this.setState({ file: null, success: ['Documento salvo com sucesso!'] });

			resetForm();

			this.dataTableDocListRef.current.fetchData();
		}).catch((err) => {
			Swal.fire('Ops', 'Ocorreu um erro ao fazer upload do arquivo.', 'error');
		}).finally(() => {
			this.setState({ loading: false });
		})
	};

	getInitialValues = () => {
		return {
			description: '',
			payments_value: '',
			due_date: '',
			categoria: '',
		};
	};

	fetchByFilter = (data) => {
		let params = { ...data };

		params.contract_id = this.props.contract_id;

		if (this.state.flags.show_categoria && this.state.filters.categoria.trim() != '') params.category = this.state.filters.categoria;
		if (this.state.flags.show_documento && this.state.filters.documento.trim() != '') {
			params = {
				...params,
				file_desc: {
					secure_url_ilike: this.state.filters.documento
				}
			}
		}
		//  params.file_desc.secure_url_ilike = this.state.filters.documento;
		if (this.state.flags.show_upload_user && this.state.filters.upload_user.id.trim() != '') params.created_by = this.state.filters.upload_user.id;

		return getAllByFilter(params);
	};

	render() {
		return (
			<div>
				<h2>Documentos jurídicos</h2>

				<Formik initialValues={this.getInitialValues()} validate={(values) => { }} onSubmit={(values, { resetForm }) => this.uploadDoc(values, resetForm)} enableReinitialize={true}>
					{({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) =>
						<form noValidate={true} autoComplete="off" onSubmit={handleSubmit}>
							<BetterAlerts errors={this.state.errors} success={this.state.success} />

							<div style={{ padding: 24, background: '#F1F5F7', margin: '24px 0' }}>
								<Row>
									<Col md={12}>
										<h2>Novo documento jurídico</h2>
									</Col>

									<Col md={10}>
										<Label className="col-form-label" style={{ color: '#343A40', fontSize: '13px' }}>Descrição do documento <span style={{ color: '#8590A5' }}>(opcional)</span></Label>

										<TextField name="description" type="text" variant="outlined" value={values.description} placeholder="Escreva uma descrição do documento..." onBlur={handleBlur}
											onChange={handleChange} style={{ background: '#fff' }} />
									</Col>

									<Col md={2}>
										<Label className="col-form-label" style={{ color: '#343A40', fontSize: '13px' }}>Upload de arquivo</Label>
										<br />

										<label htmlFor={'file'} className="btn btn-secondary btn-bold mb-0 d-flex justify-content-center align-items-center" style={{ width: '100%', height: '41.09px' }}>
											<input className="doc-juridico-select" type="file" id={'file'} style={{ display: 'none' }} name={'file'} accept="*/*" onChange={(event) => { this.setState({ file: event.currentTarget.files[0] }) }} />

											Selecionar arquivo
										</label>

										{this.state.file ?
											<div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', alignItems: 'baseline' }}>
												<p style={{ marginTop: '10px', textOverflow: 'ellipsis' }}>{this.state.file ? this.state.file.name : null}</p>
												<i className="fa fa-times align-middle mr-2" onClick={() => this.setState({ file: null })} />
											</div>
											: null}
									</Col>

									<Col md={4}>
										<FormGroup className="w-100">
											<Label className="col-form-label" style={{ color: "#343A40" }}>
												Categoria
											</Label>

											<Autocomplete name={'categoria'} className="mb-2" style={{ background: "#FFF", height: "40px" }} value={values.categoria} onBlur={handleBlur}
												disableClearable noOptionsText="Sem resultados." variant="outlined" clearOnBlur={false} options={this.state.categorias}
												onChange={e => setFieldValue('categoria', e.target.textContent)} renderInput={(params) => (<TextField {...params} type="text" placeholder="Digite para pesquisar" variant="outlined" />)}
											/>
										</FormGroup>
									</Col>

									<Col md={3}>
										<FormGroup className="w-100">
											<Label className="col-form-label" style={{ color: "#343A40" }}>
												Vencimento
											</Label>

											<TextField name="due_date" variant="outlined" value={values.due_date} placeholder="DD/MM/YYYY" onBlur={handleBlur}
												type="date" InputLabelProps={{ shrink: true }} onChange={async e => { handleChange(e); }} style={{ background: '#fff' }} />
										</FormGroup>
									</Col>

									<Col md={3}>
										{(['Cobranças', 'Cobranças jurídicas', 'Sentença', 'Processo conhecimento', 'Processo fase de execução'].includes(values.categoria)) ?
											<FormGroup className="w-100">
												<Label className="col-form-label" style={{ color: "#343A40" }}>
													R$ previsto
												</Label>

												<TextField name="payments_value" type="text" variant="outlined" value={values.payments_value} placeholder="R$ 0,00" onBlur={handleBlur}
													onChange={(e) => handleChange(formatBRLInput(e))} style={{ background: '#fff' }} />
											</FormGroup>
											: null
										}
									</Col>

									<Col md={2}>
										<Button color="success" type="submit" className="waves-effect waves-light" style={{ marginTop: '36px', height: '42px', width: '100%' }}>
											{this.state.loading ?
												<>
													<i className="fas fa-sync fa-spin" />
												</>
												:
												<>
													Salvar documento <i className="fa fa-file-contract align-middle ml-2" />
												</>
											}
										</Button>
									</Col>
								</Row>
							</div>
						</form>
					}
				</Formik>

				<Row className="align-right mb-30">
					<Dropdown className="mr-10 wm-100" isOpen={this.state.is_filter_open} toggle={() => this.setState({ is_filter_open: !this.state.is_filter_open })} >
						<DropdownToggle className="btn-index-action" color="primary" caret>
							<i className="ri-filter-3-line align-middle mr-2"></i>ADD FILTRO
						</DropdownToggle>
						<DropdownMenu className="btn-index-action">
							{!this.state.flags.show_documento ? <DropdownItem onClick={() => this.setState({ flags: { ...this.state.flags, show_documento: true } })}>Documento</DropdownItem> : null}
							{!this.state.flags.show_categoria ? <DropdownItem onClick={() => this.setState({ flags: { ...this.state.flags, show_categoria: true } })}>Categoria</DropdownItem> : null}
							{!this.state.flags.show_upload_user ? <DropdownItem onClick={() => this.setState({ flags: { ...this.state.flags, show_upload_user: true } })}>Quem fez upload</DropdownItem> : null}
						</DropdownMenu>
					</Dropdown>
				</Row>

				<Row>
					{this.state.flags.show_documento ?
						<Col md={6}>
							<FormGroup row>
								<Label className="col-md-2 col-form-label">Documento</Label>

								<div className="col-10 col-sm-8 container-center-input">
									<TextField name="description" type="text" variant="outlined" value={this.state.filters.documento} placeholder="Digite um termo..."
										onChange={({ target }) => this.setState({ filters: { ...this.state.filters, documento: target.value } })} style={{ background: '#fff' }} />
								</div>

								<Button onClick={() => this.setState({ flags: { ...this.state.flags, show_documento: false }, filters: { ...this.state.filters, documento: '' } }, () => this.saveFilters())} color="light" type="button" className="waves-effect waves-light btn-rounded btn-close-input">
									<i className="ri-close-circle-line align-middle"></i>
								</Button>
							</FormGroup>
						</Col>
						: null
					}

					{this.state.flags.show_categoria ?
						<Col md={6}>
							<FormGroup row>
								<Label className="col-md-2 col-form-label">Categoria</Label>

								<div className="col-10 col-sm-8 container-center-input">
									<Autocomplete name={'categoria'} className="mb-2" style={{ background: "#FFF", height: "40px", width: 'inherit' }} value={this.state.filters.categoria}
										disableClearable noOptionsText="Sem resultados." variant="outlined" clearOnBlur={false} options={this.state.categorias}
										onChange={e => this.setState({ filters: { ...this.state.filters, categoria: e.target.textContent } })} renderInput={(params) => (<TextField {...params} type="text" placeholder="Digite para pesquisar" variant="outlined" />)}
									/>
								</div>

								<Button onClick={() => this.setState({ flags: { ...this.state.flags, show_categoria: false }, filters: { ...this.state.filters, categoria: '' } }, () => this.saveFilters())} color="light" type="button" className="waves-effect waves-light btn-rounded btn-close-input">
									<i className="ri-close-circle-line align-middle"></i>
								</Button>
							</FormGroup>
						</Col>
						: null
					}

					{this.state.flags.show_upload_user ?
						<Col md={6}>
							<FormGroup row>
								<Label className="col-md-2 col-form-label">Quem fez upload</Label>

								<div className="col-10 col-sm-8 container-center-input p-0">
									<UserComponent name="uploadFiltro" value={this.state.uploadFiltro} onChange={(e, value) => this.setState({ filters: { ...this.state.filters, upload_user: value } })} />
								</div>

								<Button onClick={() => this.setState({ flags: { ...this.state.flags, show_upload_user: false }, filters: { ...this.state.filters, upload_user: '' } }, () => this.saveFilters())} color="light" type="button" className="waves-effect waves-light btn-rounded btn-close-input">
									<i className="ri-close-circle-line align-middle"></i>
								</Button>
							</FormGroup>
						</Col>
						: null
					}
				</Row>

				<Row>
					<Col lg={12}>
						<Card>
							<CardBody>
								<DataTableDocList filters={this.state.filters} fetchEvent={this.fetchByFilter} crudUrl="/contracts" idRow={`id`} ref={this.dataTableDocListRef} />
							</CardBody>
						</Card>
					</Col>
				</Row>
			</div>
		);
	}
}