import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { formatBRL } from "../../../helpers/Utils";
import { Link } from "react-router-dom";
import moment from "moment";
import { Button } from "reactstrap";
import { removeUserAffiliate } from "../../../crud/affiliate.crud";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faFileLines, faUserMagnifyingGlass } from '@fortawesome/pro-solid-svg-icons';

export default class DataTableIndicado extends Component {
	constructor() {
		super();

		this.state = {
			property: {},
			data: null,
			rows: 10,
			totalRecords: 0,
			first: 0,
			last: 0,
			loading: false,
			sortField: 'created_at',
			sortOrder: 1,
		};

		this.onPage = this.onPage.bind(this);
	}

	componentDidMount() {
		let DataTableIndicado = JSON.parse(localStorage.getItem("DataTableIndicado"));

		if (DataTableIndicado) {
			this.setState(
				{
					data: DataTableIndicado.data,
					rows: DataTableIndicado.rows,
					totalRecords: DataTableIndicado.totalRecords,
					first: DataTableIndicado.first,
					last: DataTableIndicado.last,
					sortField: DataTableIndicado.sortField,
					sortOrder: DataTableIndicado.sortOrder,
					loading: false
				},
			);
		} else {
			this.fetchData();
		}
	}

	componentDidUpdate(prevProps) {
		if (prevProps.filters !== this.props.filters) {
			this.fetchData();
		}
	}

	refreshTable() {
		this.onPage(this.state);
	}

	onPage = async (event) => {
		this.setState({
			loading: true,

		});
		if (event.data) {
			event.data = null;
		}

		this.setState({
			...this.state,
			rows: event.rows,
		});
		this.fetchData(event.rows, event.first);
	};

	fetchData = async (limit, skip) => {
		this.setState({ loading: true });

		let options = {
			$limit: limit ? limit : this.state.rows,
			$skip: skip != null ? skip : this.state.first,
			$sort: { [this.state.sortField]: this.state.sortOrder },
			affiliate_id: this.props.user_id
		};

		return this.props.fetchEvent(options).then(async (res) => {
			this.props.handleSetIndicados(res.data.total);

			if (res.data.data.length == 0) {
				this.setState({
					...this.state,
					data: [],
					totalRecords: res.data.total,
					first: skip,
					last: skip + res.data.limit,
					loading: false
				});
			}

			await res.data.data.map(async (obj, i) => {
				this.setState({
					...this.state,
					data: res.data.data,
					totalRecords: res.data.total,
					first: skip,
					last: skip + res.data.limit,
					loading: false
				});
			});
		}).finally(() => {
			this.setState({ loading: false });
		});
	};

	sortTable = async (e) => {
		this.setState({ sortField: e.sortField, sortOrder: e.sortOrder, loading: true }, () => {

			this.fetchData();
		});
	};

	getValue = (obj) => {
		return (
			<div style={{ textAlign: "center" }}>
				<p>{formatBRL(obj || 0)}</p>
			</div>
		);
	};

	getUserName = obj => (
		<div className='negotiation-active'>
			<FontAwesomeIcon icon={faUserMagnifyingGlass} style={{ marginRight: '8.5px', color: '#4AA3FF' }} />

			<Link to={`/users/show/${obj?.id}`} >{obj?.name}</Link>
		</div>
	);

	handleRemove = id => {
		removeUserAffiliate({
			user_id: id,
			affiliate_id: this.props.user_id
		}).then(({ data }) => {
			this.fetchData();

			this.props.handleSetErrors(['O indicado foi removido. O afiliado não receberá mais comissões desse indicado.'])
		}).catch(() => {

		}).finally(() => {
			this.setState({ ...this.state, loading: false, newIndicado: '' });
		});
	};

	columnOptionsCrud = (obj) => {
		if (this.props.crudUrl) {
			return (
				<div onClick={() => this.handleRemove(obj.id)} className='showButton' style={{ textAlign: 'center', cursor: 'pointer' }}>
					<FontAwesomeIcon icon={faClose} style={{ color: '#5664D2' }} />
				</div>
			);
		}
	};

	render() {
		return (
			<DataTable ref={(el) => this.dt = el} value={this.state.data} paginator={true} rows={this.state.rows} rowsPerPageOptions={[10, 25, 50]} first={this.state.first} last={this.state.last}
				totalRecords={this.state.totalRecords} lazy={true} onPage={this.onPage} loading={this.state.loading} responsive={true} loadingIcon={`fas fa-sync fa-spin`} onSort={(e) => this.sortTable(e)}
				paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" emptyMessage="Nenhum registro encontrado!"
				currentPageReportTemplate="Mostrando {first} até {last} de {totalRecords} registros" sortField={this.state.sortField} sortOrder={this.state.sortOrder}>
				<Column sortable field="created_at" style={{ width: '15%' }} body={(obj) => <p>{moment(obj?.created_at).format("DD/MM/YYYY hh:mm:ss")}</p>} header="Data" />
				<Column field="name" body={(obj) => this.getUserName(obj)} header="Usuário" />
				<Column field="active_contracts" style={{ width: '15%' }} body={(obj) => obj.contractsTotal ?? '0'} header="Contratos" />

				<Column body={(obj) => this.columnOptionsCrud(obj)} style={{ width: '34px', padding: 0 }} />
			</DataTable>
		);
	}
}
