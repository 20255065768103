/* eslint-disable eqeqeq */
import React, { Component } from 'react';
import { Col, FormGroup, Label, TabContent, TabPane, Nav, NavItem, NavLink, Row, Input } from "reactstrap";
import BetterCircularProgress from '../../components/BetterCircularProgress';
import classnames from 'classnames';
import { get as getUser, getAccountBank, storeBankAccount, update as updateUser } from '../../crud/users.crud';
import { createAccountVerification, getAccountVerification, getDocumentStatus, sendFileAsaasDirectly, updateAccountVerification } from '../../crud/account.crud';
import Swal from 'sweetalert2';
import { formatCep, formatTelefone, formatTelefoneInput, formatCPFInput, limparCPF, limparTelefone, getStates, buscaCep, formatBRLInput, formatBRL, limparMoeda, formatCNPJInput, formatCPF, formatCNPJ, limparCNPJ } from '../../helpers/Utils';
import { Link, Redirect } from "react-router-dom";
import { Formik } from "formik";
import BetterAlerts from '../../components/BetterAlerts';
import { validateFormUser } from '../../helpers/FormValidators';
import { Checkbox, OutlinedInput, Select, TextField } from "@material-ui/core";
import BetterSelect from '../../components/BetterSelect';
import moment from 'moment';
import Breadcrumbs from '../../components/Breadcrumb';
import { updateIncome } from '../../crud/income-users.crud';
import { getBankTypes } from '../../helpers/bankHelper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileImport, faPen } from '@fortawesome/pro-regular-svg-icons';
import { faArrowTurnDownRight, faCalendar, faChevronDown, faXmark } from '@fortawesome/pro-solid-svg-icons';
import { Autocomplete, createFilterOptions } from '@material-ui/lab';
import InputMask from 'react-input-mask';

class UsersEdit extends Component {

	state = {
		loading: false,
		submitted: false,
		success: false,
		errors: [],
		activeTab: '1',
		data: null,
		accountVerification: null,
		arrayBank: [],
		arrayCompanyType: [
			{
				label: 'Micro Empreendedor Individual (MEI)',
				key: 'MEI'
			},
			{
				label: 'Empresa Limitada',
				key: 'LIMITED'
			},
			{
				label: 'Empresa Individual',
				key: 'INDIVIDUAL'
			},
			{
				label: 'Associação',
				key: 'ASSOCIATION'
			},
		],
		file: null,
		socialContractExtraFile: null,
		limitedCompanyExtraFile: null,
		typeAcc: [{ type: 'checking', label: 'Corrente' }, { type: 'savings', label: 'Poupança' }],
		documents: [],
		paymentMethod: 'Transferência bancária',
		requestUser: null
	}

	filterOptions = createFilterOptions({
		stringify: (option) => option.name + option.code,
	});

	componentDidMount = async () => {
		this.setState({ loading: true });

		if (localStorage.getItem("authUser")) {
			const obj = JSON.parse(localStorage.getItem("authUser"));
			this.setState({ requestUser: obj?.user })
		}

		const data = getBankTypes();

		data.map((obj, i) => {
			obj.nameCode = `${obj.code} - ${obj.name}`
		})

		this.setState({ arrayBank: data });

		await getUser(this.props.match.params.id).catch(err => {
			Swal.fire('Ops!', 'Ocorreu um erro, entre em contato com o suporte.', 'error');
		}).then((res) => {
			if (res.status == 200) {
				if (res?.data?.data) {
					this.setState({ data: res.data.data });
				}
			}
		});

		await getAccountVerification(this.props.match.params.id).then(res => {
			console.log(res);
			if (res.status === 200) {
				this.setState({ accountVerification: res?.data?.data });
			}
		}).catch(err => { });

		await getDocumentStatus(this.props.match.params.id).then(async res => {
			if (res?.status === 200) {
				if (res.data) {
					console.log(res.data.data);
					this.setState({ documents: res?.data?.data?.data });
				}
			}
		}).catch(err => { });

		await getAccountBank(this.props.match.params.id).then(res => {
			if (res?.status === 200) {
				if (res.data) {
					this.setState({ accountBankData: res.data.data, paymentMethod: res.data.data.is_owner ? 'Transferência bancária' : 'PIX' });
				}
			}
		}).catch(err => {
			console.log(err, 'err');
		});

		this.setState({ loading: false });

		const tabUrl = new URLSearchParams(this.props.location.search).get("tab")

		if (tabUrl) {
			this.setState({ activeTab: tabUrl });
		}
	};

	getOrigensRenda = () => {
		return [
			{ key: 'clt', label: 'Funcionário Privado ou Público Registrado (CLT)' },
			{ key: 'public_agent', label: 'Funcionário Público (Estatutário)' },
			{ key: 'legal_person', label: 'Empresário' },
			{ key: 'liberal_professional', label: 'Profissional liberal' },
			{ key: 'autonomous', label: 'Autônomo' },
			{ key: 'retired', label: 'Aposentado ou Pensionista' },
			{ key: 'intern', label: 'Estagiário ou Bolsista' },
		];
	};

	tipoPix = () => {
		return [
			{ key: 'telefone', label: 'Telefone' },
			{ key: 'cpf', label: 'CPF' },
			{ key: 'aleatorio', label: 'Chave Aleatória' },
			{ key: 'email', label: 'E-mail' },
		];
	};

	verificaTipoEntrada = (entrada) => {
		// Verifica se é um cpf
		const patternCPF = /^\d{3}\.\d{3}\.\d{3}-\d{2}$/;
		if (patternCPF.test(entrada)) {
			return "cpf";
		}

		// Verifica se é um número de telefone
		const patternTelefone = /\(\d{2}\) \d{5}-\d{4}/;
		if (patternTelefone.test(entrada)) {
			return "telefone";
		}

		// Verifica se é um UUID
		const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
		if (uuidRegex.test(entrada)) {
			return "aleatorio";
		}

		// Verifica se é um e-mail
		if (entrada.includes('@')) {
			return "email";
		}

		// Se não for nenhum dos tipos acima
		return "aleatorio";
	}

	toggleTab(tab) {
		if (this.state.activeTab !== tab) this.setState({ activeTab: tab });
	};

	submitIncome = async (values) => {
		if (values.origem != '' || values.valor != '') {
			let dataRenda = {
				type: values.origem,
				gross_income: limparMoeda(values.valor),
			};

			updateIncome(this.props.match.params.id, dataRenda).then((res) => {
				if (res?.status === 200) {
					this.setState({ loading: false });
				}
			}).catch(err => {
				this.setState({ loading: false, submitted: false, success: false, errors: [this.processErrorMessage('Erro ao atualizar renda.')] });
			});
		}
	}

	submitBankAccount = async (values) => {
		if (this.state.paymentMethod == 'Transferência bancária' && values.agency.length < 4 && values.is_owner) {
			alert('Agência invalida, mínimo 4 números.');
			return;
		}

		this.setState({ loading: true });

		let data = {};

		if (this.state.paymentMethod == 'PIX') {
			data.pix_key = values.pix_key;
		} else {
			data.is_owner = values.is_owner;
			data.bank = values.bank?.code ?? values?.bank;
			data.agency = values.agency;
			data.account_type = values.account_type;
			data.account = values.account;
		}

		if (!values.is_owner) {
			data.third_party_name = values.third_party_name;
			data.third_party_cpf = values.third_party_cpf;
		}

		storeBankAccount(this.props.match.params.id, data).catch(err => {
			this.setState({ loading: false, submitted: false, success: false, errors: [this.processErrorMessage('Erro ao atualizar conta bancária.')] });
		}).then(res => {
			this.setState({ loading: false });
		});
	}

	onSubmit = async (values) => {
		if (values.zip_code.length > 0 && values.zip_code.length < 9) {
			alert("O CEP precisa ter ao menos 8 dígitos.");
			return;
		}

		let data = { ...this.state.data };
		let asaasAccountData = {}

		data.name = values.name;
		data.email = values.email;
		data.phone = limparTelefone(values.phone);
		values.password ? data.password = values.password : delete data.password;

		if (values?.roles != '') {
			let roleAsArray = Array.isArray(values?.roles);

			if (roleAsArray) {
				data.roles = values.roles
			} else {
				data.roles = [values.roles]
			}

			if (data.roles.includes('admin') && values.ext_auth) {
				data.roles.push('external_auth')
			}
		} else {
			data.roles = [];
		}

		if (values.city || values.complement || values.neighbourhood || values.number || values.state || values.street || values.zip_code) {
			data.address = {};

			values.city ? data.address.city = values.city : delete data.address.city;
			values.complement ? data.address.complement = values.complement : delete data.address.complement;
			values.neighbourhood ? data.address.neighbourhood = values.neighbourhood : delete data.address.neighbourhood;
			values.number ? data.address.number = values.number : delete data.address.number;
			values.state ? data.address.state = values.state : delete data.address.state;
			values.street ? data.address.street = values.street : delete data.address.street;
			values.zip_code ? data.address.zip_code = limparCPF(values.zip_code) : delete data.address.zip_code;
		} else {
			delete data.address;
		}

		if (values.birthdate) {
			data.birthdate = moment(values?.birthdate, 'DD/MM/YYYY').format('YYYY-MM-DD');
		}

		if (values.accountType === 'PF') {
			data.cpf = values.cpfCnpj ? limparCPF(values.cpfCnpj) : null

			asaasAccountData = {
				cpfCnpj: data.cpf,
				email: data.email,
				name: data.name,
				phone: data.phone,
				accountType: data.type_account ?? values.accountType,
				addressNumber: data.address.number,
				postalCode: data.address.zip_code,
				province: data.address.neighbourhood,
				address: data.address.street,
				complement: data.address.complement,
				incomeValue: parseFloat(limparMoeda(values.valor)),
				birthdate: values.birthdate ? moment(values.birthdate, 'DD/MM/YYYY').format('YYYY-MM-DD') : ''
			}
		}

		if (values.accountType === 'PJ') {
			data.cnpj = values.cpfCnpj ? limparCNPJ(values.cpfCnpj) : null;
			data.razao_social = values.razao_social;

			asaasAccountData = {
				cpfCnpj: data.cnpj,
				email: data.email,
				name: data.name,
				phone: data.phone,
				accountType: data.type_account ?? values.accountType,
				addressNumber: data.address.number,
				postalCode: data.address.zip_code,
				province: data.address.neighbourhood,
				address: data.address.street,
				complement: data.address.complement,
				incomeValue: parseFloat(limparMoeda(values.valor))
			}
		}

		updateUser(this.props.match.params.id, data).catch((err) => {
			this.setState({ submitted: false, success: false, errors: this.processErrorMessage(err?.data?.message) ? [this.processErrorMessage(err?.data?.message)] : [] });
		}).then(async ({ data: user }) => {
			await this.submitIncome(values);

			if (((values?.bank?.code?.trim() != '' && values?.agency?.trim() != '' && values?.account_type?.trim() != '' && values?.account?.trim() != '' && values.is_owner)) || (values.pix_key)) {
				await this.submitBankAccount(values);
			}

			if (data.type_account && this.state?.accountVerification) {
				updateAccountVerification(this.props.match.params.id, asaasAccountData).catch(({ response: { data: { message: msgAcVerify } } }) => {
					this.setState({ submitted: false, success: false, errors: this.processErrorMessage(msgAcVerify) ? [this.processErrorMessage(msgAcVerify)] : [] });
				}).then((res) => {
					if (res?.status == 200) {
						this.setState({ submitted: false, errors: this.processErrorMessage(res?.data?.message) ? [this.processErrorMessage(res?.data?.message)] : [] })
					}
				})
			}

			if (!this.state?.accountVerification && this.state?.activeTab == '5') {
				createAccountVerification({ ...asaasAccountData, user_id: this.props.match.params.id }).then(res => {
					if (res?.status === 200) {
						console.log(res);
					}
				})
			}

			if (this.state?.file) {
				console.log(`UPDALOADING DOCUMENT ASAAS`);
				const typeCustom = this.state.documents.find(obj => obj.type === 'CUSTOM')

				const id = typeCustom?.id;

				const formData = new FormData();

				formData.append('file', this.state?.file);
				formData.append('id', id);
				formData.append('type', 'CUSTOM');

				sendFileAsaasDirectly(this.state.data.id, formData).catch(err => {
					console.log(err);
					this.setState({ loading: false, error: ['Erro  no envio de documentos extras para a verificação de perfil!'] });
				}).then(res => {
					console.log(res);
				})
			}

			if (this.state?.socialContractExtraFile) {
				console.log(`UPDALOADING DOCUMENT ASAAS}`);
				const typeCustom = this.state.documents.find(obj => obj.type === 'SOCIAL_CONTRACT')

				const id = typeCustom?.id;

				const formData = new FormData();

				formData.append('file', this.state?.socialContractExtraFile);
				formData.append('id', id);
				formData.append('type', 'SOCIAL_CONTRACT');

				sendFileAsaasDirectly(this.state.data.id, formData).catch(err => {
					console.log(err);
					this.setState({ loading: false, error: ['Erro  no envio de documentos extras para a verificação de perfil!'] });
				}).then(res => {
					console.log(res);
				})
			}

			if (this.state?.limitedCompanyExtraFile) {
				console.log(`UPDALOADING DOCUMENT ASAAS`);
				const typeCustom = this.state.documents.find(obj => obj.type === 'LIMITED_COMPANY')

				const id = typeCustom?.id;

				const formData = new FormData();

				formData.append('file', this.state?.limitedCompanyExtraFile);
				formData.append('id', id);
				formData.append('type', 'LIMITED_COMPANY');

				sendFileAsaasDirectly(this.state.data.id, formData).catch(err => {
					console.log(err);
					this.setState({ loading: false, error: ['Erro  no envio de documentos extras para a verificação de perfil!'] });
				}).then(res => {
					console.log(res);
				})
			}

			this.setState({ submitted: false, success: !Boolean(user.message), errors: this.processErrorMessage(user.message) ? [this.processErrorMessage(user.message)] : [] })
		}).finally(() => {
			this.setState({ success: true });
		});
	};

	processErrorMessage = (message) => {
		switch (message) {
			case 'Duplicated phone':
				return "Número de telefone duplicado.";
			default:
				return message;
		}
	}

	getInitialValues = () => {
		let cpfCnpj;

		if (this.state.data?.type_account === 'PF') {
			cpfCnpj = formatCPF(this.state.data?.cpf);
		}

		if (this.state.data?.type_account === 'PJ') {
			cpfCnpj = formatCNPJ(this.state.data?.cnpj);
		}

		let typePix = ''

		if (this.state?.accountBankData?.pix_key) {
			typePix = this.verificaTipoEntrada(this.state.accountBankData.pix_key);
		} else {
			typePix = 'aleatorio';
		}

		let bankCode = this.state.arrayBank.find((item) => item.code === this.state?.accountBankData?.bank) ?? ''

		return {
			birthdate: this.state.data?.birthdate ? moment(this.state.data.birthdate).add(3, 'hours').format("DD/MM/YYYY") : '',
			email: this.state.data?.email ?? '',
			name: this.state.data?.name ?? '',
			phone: this.state.data?.phone ? formatTelefone(this.state.data.phone) : '',
			city: this.state.data?.address?.city ?? '',
			complement: this.state.data?.address?.complement ?? '',
			neighbourhood: this.state.data?.address?.neighbourhood ?? '',
			number: this.state.data?.address?.number ?? '',
			state: this.state.data?.address?.state ?? '',
			street: this.state.data?.address?.street ?? '',
			zip_code: this.state.data?.address?.zip_code ? formatCep(this.state.data.address?.zip_code) : '',
			type: this.state.data?.employment?.type ?? '',
			status: this.state.data?.employment?.status ?? '',
			roles: this.state.data?.roles && this.state.data?.roles?.includes('admin') ? 'admin' : '',
			ext_auth: this.state.data?.roles && this.state.data?.roles?.includes('external_auth') ? true : false,
			password: '',
			cpfCnpj: cpfCnpj,
			razao_social: this.state?.data?.razao_social ?? '',
			// income
			valor: this.state.data?.employment?.gross_income ? formatBRL(this.state.data?.employment?.gross_income) : '',
			origem: this.state.data?.employment?.type ?? '',
			// bank
			typePix: typePix,
			bank: this.state?.accountBankData?.bank ? bankCode : '',
			agency: this.state?.accountBankData?.agency ?? '',
			account_type: this.state?.accountBankData?.account_type ?? '',
			account: this.state?.accountBankData?.account ?? '',
			pix_key: this.state?.accountBankData?.pix_key ?? '',
			is_owner: this.state?.accountBankData?.is_owner != null ? this.state?.accountBankData?.is_owner : true,
			third_party_name: this.state?.accountBankData?.third_party_name ?? '',
			third_party_cpf: this.state?.accountBankData?.third_party_cpf ? formatCPF(this.state?.accountBankData?.third_party_cpf) : '',
			accountType: this?.state?.accountVerification?.account_type ?? this.state.data?.type_account ?? '',
			file: null,
			grossIncome: formatBRL(this.state?.data?.employment?.gross_income ?? 0),
			tradingName: this?.state?.accountVerification?.data?.tradingName,
			company_type: this.state.data?.company_type ?? ''
		};
	};

	handleChangeBeneficiario = (setFieldValue, values) => {
		if (values.is_owner) {
			this.setState({ paymentMethod: 'PIX' });
		}

		setFieldValue('is_owner', !values.is_owner)
	};

	changerOption = (event, newValue, setFieldValue) => {
		const u = this.state.arrayBank.find((item) => item.name === newValue.name)

		setFieldValue("bank", u)
	}

	foiAlterado = (values, aba) => {
		let cpfCnpj;

		if (this.state.data?.type_account === 'PF') {
			cpfCnpj = formatCPF(this.state.data?.cpf);
		}

		if (this.state.data?.type_account === 'PJ') {
			cpfCnpj = formatCNPJ(this.state.data?.cnpj);
		}

		let typePix = ''

		if (this.state?.accountBankData?.pix_key) {
			typePix = this.verificaTipoEntrada(this.state.accountBankData.pix_key);
		} else {
			typePix = 'aleatorio';
		}

		let bankCode = this.state.arrayBank.find((item) => item.code === this.state?.accountBankData?.bank) ?? ''

		if (aba == '1') {
			if (
				this.state?.data?.name != values.name ||
				values.cpfCnpj != cpfCnpj ||
				this.state?.data?.email != values.email ||
				formatTelefone(this.state?.data?.phone) != values.phone
			) return (
				<div className='indicadorEdit'>
					<FontAwesomeIcon icon={faPen} />
				</div>
			)
		}

		if (aba == '2') {
			if (
				this.state.data?.address?.city != values.city ||
				this.state.data?.address?.complement != values.complement ||
				this.state.data?.address?.neighbourhood != values.neighbourhood ||
				this.state.data?.address?.number != values.number ||
				this.state.data?.address?.state != values.state ||
				this.state.data?.address?.street != values.street ||
				formatCep(this.state.data.address?.zip_code) != values.zip_code
			) return (
				<div className='indicadorEdit'>
					<FontAwesomeIcon icon={faPen} />
				</div>
			)
		}

		if (aba == '3') {
			if (
				formatBRL(this.state.data?.employment?.gross_income) != values.valor ||
				this.state.data?.employment?.type != values.origem
			) return (
				<div className='indicadorEdit'>
					<FontAwesomeIcon icon={faPen} />
				</div>
			)
		}

		if (aba == '4') {
			if (
				typePix != values.typePix ||
				bankCode != values.bank ||
				(this.state?.accountBankData?.agency ?? '') != values.agency ||
				(this.state?.accountBankData?.account_type ?? '') != values.account_type ||
				(this.state?.accountBankData?.account ?? '') != values.account ||
				this.state?.accountBankData?.pix_key != values.pix_key ||
				(this.state?.accountBankData?.is_owner != null ? this.state?.accountBankData?.is_owner : true) != values.is_owner ||
				(this.state?.accountBankData?.third_party_name ?? '') != values.third_party_name ||
				(this.state?.accountBankData?.third_party_cpf ? formatCPF(this.state?.accountBankData?.third_party_cpf) : '') != values.third_party_cpf
			) return (
				<div className='indicadorEdit'>
					<FontAwesomeIcon icon={faPen} />
				</div>
			)
		}

		if (aba == '5') {
			if (
				this?.state?.accountVerification?.account_type != values.accountType ||
				values.file != null ||
				this.state?.data?.name != values.name ||
				values.cpfCnpj != cpfCnpj || (this.state.data?.roles && this.state.data.roles?.includes('external_auth') && !values.ext_auth) || (this.state.data?.roles && !this.state.data.roles.includes('external_auth') && values.ext_auth)
			) return (
				<div className='indicadorEdit'>
					<FontAwesomeIcon icon={faPen} />
				</div>
			)
		}
	}

	render() {
		return (
			<BetterCircularProgress loading={this.state.loading}>

				<div className="page-content container-page userNew container-fluid">
					<BetterAlerts errors={this.state.errors} />

					{this.state.success ?
						<Redirect to={{ pathname: `/users/show/${this.props.match.params.id}`, state: { success: ['Usuário atualizado com sucesso!'] } }} />
						: null
					}

					<Formik initialValues={this.getInitialValues()} validate={values => validateFormUser(values, 'update')} onSubmit={(values) => this.onSubmit(values)}>
						{({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue, setFieldTouched }) => (
							<form noValidate={true} autoComplete="off" onSubmit={handleSubmit} className="form" style={{ flexDirection: 'column', alignItems: 'center' }}>
								<div style={{ width: '736px' }}>

									<div className='header'>
										<h1>Editar usuário</h1>

										<Breadcrumbs breadcrumbItems={[{ link: '/users/index', title: 'Usuários' }, { link: `#`, title: `ID ${this.props.match.params.id}` }]} />
									</div>

									<div className='box'>
										<Nav tabs className="nav-tabs-custom" role="tablist" style={{ marginBottom: 24 }}>
											<NavItem>
												<NavLink id="info-tab" className={classnames({ active: this.state.activeTab === '1' })} onClick={() => { this.toggleTab('1'); }}>
													<div className='tabName'>
														Dados pessoais
														{this.foiAlterado(values, '1')}
													</div>
												</NavLink>
											</NavItem>
											<NavItem>
												<NavLink id="announcements-tab" className={classnames({ active: this.state.activeTab === '2' })} onClick={() => { this.toggleTab('2'); }}>
													<div className='tabName'>
														Endereço
														{this.foiAlterado(values, '2')}
													</div>
												</NavLink>
											</NavItem>
											<NavItem>
												<NavLink id="negotiations-tab" className={classnames({ active: this.state.activeTab === '3' })} onClick={() => { this.toggleTab('3'); }}>
													<div className='tabName'>
														Renda
														{this.foiAlterado(values, '3')}
													</div>
												</NavLink>
											</NavItem>
											<NavItem>
												<NavLink id="negotiations-tab" className={classnames({ active: this.state.activeTab === '4' })} onClick={() => { this.toggleTab('4'); }}>
													<div className='tabName'>
														Dados bancários
														{this.foiAlterado(values, '4')}
													</div>
												</NavLink>
											</NavItem>
											<NavItem>
												<NavLink id="negotiations-tab" className={classnames({ active: this.state.activeTab === '5' })} onClick={() => { this.toggleTab('5'); }}>
													<div className='tabName'>
														Permissões
														{this.foiAlterado(values, '5')}
													</div>
												</NavLink>
											</NavItem>
										</Nav>

										<TabContent activeTab={this.state.activeTab}>
											<TabPane tabId="1" role="tabpanel">
												<div className='formulario'>
													<Col md={12} className="p-0 m-0">
														<Label>{this.state.data?.type_account == 'PJ' ? 'Nome Fantasia' : 'Nome completo'}</Label>
														<TextField name="name" type="text" placeholder='Nome completo do usuário' variant="outlined" value={values.name} onBlur={handleBlur} onChange={handleChange}
															helperText={touched.name && errors.name} error={Boolean(touched.name && errors.name)} />
													</Col>

													{this.state.data?.type_account == 'PJ' &&
														<Col md={12} className="p-0 m-0">
															<Label>Razão Social</Label>
															<TextField name="razao_social" type="text" variant="outlined" value={values.razao_social} onBlur={handleBlur} onChange={handleChange}
																helperText={touched.razao_social && errors.razao_social} error={Boolean(touched.razao_social && errors.razao_social)} />
														</Col>
													}

													<Col md={12} className="p-0 m-0">
														<Label>{this.state.data?.type_account == 'PJ' ? 'CNPJ' : 'CPF'}</Label>
														<TextField name="cpfCnpj" type="text" placeholder={this.state.data?.type_account == 'PJ' ? '00.000.000/0000-00' : '000.000.000-00'} variant="outlined" value={values.cpfCnpj} onBlur={handleBlur}
															onChange={e => this.state.data?.type_account == 'PJ' ? handleChange(formatCNPJInput(e)) : handleChange(formatCPFInput(e))}
															helperText={touched.cpfCnpj && errors.cpfCnpj} error={Boolean(touched.cpfCnpj && errors.cpfCnpj)} inputProps={{ maxLength: this.state.data?.type_account == 'PJ' ? 18 : 14 }} />
													</Col>

													<Col md={12} className="p-0 m-0">
														<Label>Email</Label>

														<TextField name="email" type="email" placeholder='usuario@servidor.com.br' variant="outlined" value={values.email} onBlur={handleBlur} onChange={handleChange}
															helperText={touched.email && errors.email} error={Boolean(touched.email && errors.email)} />
													</Col>

													<Col md={12} className="p-0 m-0">
														<Label>Telefone (Whatsapp)</Label>

														<TextField name="phone" type="text" placeholder='(00) 00000-0000' variant="outlined" value={values.phone} onBlur={handleBlur} onChange={(e) => handleChange(formatTelefoneInput(e))}
															helperText={touched.phone && errors.phone} error={Boolean(touched.phone && errors.phone)} inputProps={{ maxLength: 15 }} />
													</Col>

													<Col md={12} className="p-0 m-0">
														<Label>Senha</Label>

														<TextField name="password" placeholder='********' type="password" variant="outlined" value={values.password} onBlur={handleBlur} onChange={handleChange}
															helperText={touched.password && errors.password} error={Boolean(touched.password && errors.password)} />
													</Col>
												</div>
											</TabPane>

											<TabPane tabId="2" role="tabpanel">
												<div className='formulario'>
													<Row className="p-0 m-0">
														<Col lg={6} className="p-0 m-0">
															<Label>CEP</Label>

															<TextField name="zip_code" placeholder='88888-888' type="text" variant="outlined" value={values.zip_code} onBlur={handleBlur} onChange={(e) => handleChange(buscaCep(e, setFieldValue, "street", "neighbourhood", "city", "state"))}
																helperText={touched.zip_code && errors.zip_code} error={Boolean(touched.zip_code && errors.zip_code)} inputProps={{ maxLength: 9 }} />
														</Col>

														<Col lg={6} className="marginInputMobile">
															<Label>Estado</Label>

															<BetterSelect name="state" blankOption value={values.state} onBlur={handleBlur} onChange={handleChange}
																helperText={touched.state && errors.state} error={Boolean(touched.state && errors.state)} listItem={getStates()}>
															</BetterSelect>
														</Col>
													</Row>

													<Row className="p-0 m-0">
														<Col lg={3} md={12} className="p-0 m-0">
															<Label>Número</Label>

															<TextField name="number" type="text" placeholder='00000' variant="outlined" value={values.number} onBlur={handleBlur} onChange={handleChange}
																helperText={touched.number && errors.number} error={Boolean(touched.number && errors.number)} />
														</Col>

														<Col lg={9} md={12} className="marginInputMobile">
															<Label>Complemento</Label>

															<TextField name="complement" placeholder='Opcional' type="text" variant="outlined" value={values.complement} onBlur={handleBlur} onChange={handleChange}
																helperText={touched.complement && errors.complement} error={Boolean(touched.complement && errors.complement)} />
														</Col>
													</Row>

													<Row className="p-0 m-0">
														<Col lg={6} md={12} className="p-0 m-0">
															<Label>Bairro</Label>

															<TextField name="neighbourhood" placeholder='Nome do bairro' type="text" variant="outlined" value={values.neighbourhood} onBlur={handleBlur} onChange={handleChange}
																helperText={touched.neighbourhood && errors.neighbourhood} error={Boolean(touched.neighbourhood && errors.neighbourhood)} />
														</Col>

														<Col lg={6} md={12} className="marginInputMobile">
															<Label>Cidade</Label>

															<TextField name="city" placeholder='Nome da cidade' type="text" variant="outlined" value={values.city} onBlur={handleBlur} onChange={handleChange}
																helperText={touched.city && errors.city} error={Boolean(touched.city && errors.city)} />
														</Col>
													</Row>

													<Col lg={12} className="pl-0 marginInputMobileRua">
														<Label>Rua</Label>

														<TextField name="street" placeholder='Nome da rua' type="text" variant="outlined" value={values.street} onBlur={handleBlur} onChange={handleChange}
															helperText={touched.street && errors.street} error={Boolean(touched.street && errors.street)} />
													</Col>
												</div>
											</TabPane>

											<TabPane tabId="3" role="tabpanel">
												<div className='formulario'>
													<Col lg={12} className="p-0 m-0">
														<Label>Origem da renda</Label>

														<select style={{ background: "#FFF", height: "40px" }} name='origem' onChange={handleChange} onBlur={handleBlur} value={values.origem} className="custom-select">
															<option key={null} value={null} hidden></option>
															{this.getOrigensRenda().map((obj) => <option key={obj.key} value={obj.key}>{obj.label}</option>)}
														</select>
													</Col>

													<Col lg={5} className="p-0 m-0">
														<Label>Valor da renda</Label>

														<TextField name='valor' variant="outlined" value={values.valor} onBlur={handleBlur} placeholder="R$ 0,00" onChange={(e) => handleChange(formatBRLInput(e))} />
													</Col>
												</div>
											</TabPane>

											<TabPane tabId="4" role="tabpanel">
												<div className='formulario'>
													{/* <h5 style={{ marginBottom: 8 }}>Conta Bancária</h5> */}

													<Row className="p-0 m-0" style={{ gap: 16 }}>
														<Col md={12} className="p-0 m-0">
															<Label>{this.state.data?.type_account == 'PJ' ? 'Nome Fantasia' : 'Nome completo'}</Label>
															<TextField name="name" type="text" placeholder='Nome completo do usuário' variant="outlined" value={values.name} onBlur={handleBlur} onChange={handleChange}
																helperText={touched.name && errors.name} error={Boolean(touched.name && errors.name)} />
														</Col>

														{/* <Label>A conta é do dono?</Label> */}
														<Col md={12} style={{ marginLeft: "10px", marginTop: '-8px' }}>
															<Input id="isOwner" type="checkbox" name="is_owner" onChange={() => this.handleChangeBeneficiario(setFieldValue, values)}
																onBlur={handleBlur} color="primary" checked={values.is_owner} />

															<Label style={{ padding: "3px" }} for="isOwner">
																É o mesmo que o usuário
															</Label>
														</Col>

														{/* <Col md={12} style={{ marginLeft: "10px" }}>
															<Input id="notOwner" type="radio" name="is_owner" value={false} onChange={() => setFieldValue('is_owner', false)}
																onBlur={handleBlur} color="primary" checked={!values.is_owner} />

															<Label style={{ padding: "3px" }} for="notOwner" >
																Não, a conta é de um beneficiário
															</Label>
														</Col> */}

														<Col lg={4} md={12} className="marginInputMobile p-0">
															<Label>Forma de recebimento</Label>

															<Select native onChange={(e) => this.setState({ paymentMethod: e.target.value })}
																value={this.state.paymentMethod} style={{ height: '41.02px' }}
																input={<OutlinedInput name={'payment_method'} />}>
																{values.is_owner ? <option key='Transferência bancária' value='Transferência bancária'>Transferência bancária</option> : null}
																<option key='PIX' value='PIX'>PIX</option>
															</Select>
														</Col>

														{values.is_owner ?
															null
															:
															<>
																<Col lg={6} md={12} className="marginInputMobile p-0" style={{ display: 'flex', alignItems: 'flex-end' }}>
																	<p onClick={() => console.log({ values, state: this.state })} style={{ fontSize: '13px', fontWeight: '400', color: '#868E96', margin: 0, marginLeft: '16px' }}>Apenas pix é permitido para transações que não são para o mesmo CPF cadastrado no usuário</p>
																</Col>

																<Row className="p-0 m-0 w-100">
																	<Col lg={6} md={6} className="p-0">
																		<Label>Nome do beneficiário</Label>

																		<TextField name="third_party_name" type="text" placeholder='Nome completo do usuário' variant="outlined" value={values.third_party_name} onBlur={handleBlur} onChange={handleChange}
																			helperText={touched.third_party_name && errors.third_party_name} error={Boolean(touched.third_party_name && errors.third_party_name)} />
																	</Col>

																	<Col lg={6} md={6} className=" ">
																		<Label>CPF do beneficiário</Label>

																		<TextField name='third_party_cpf' variant="outlined" value={values.third_party_cpf}
																			onBlur={handleBlur} inputProps={{ maxLength: 14 }} onChange={e => handleChange(formatCPFInput(e))} />
																	</Col>
																	{/* </> */}
																</Row>
															</>
														}

														{this.state.paymentMethod == 'Transferência bancária' ?
															<>
																<Col lg={12} className="pl-0 marginInputMobileRua">
																	<Label>Banco</Label>

																	{/* <Select name='bank' native onChange={handleChange} onBlur={handleBlur} value={values.bank} style={{ height: '41.02px' }}
																		input={<OutlinedInput name={'bank'} />}>
																		<option key={null} value={null} hidden></option>
																		{this.state?.arrayBank.map(({ code, name }) => <option key={code} value={code}>{name}</option>)}
																	</Select> */}

																	<div style={{ position: 'relative' }}>
																		<Autocomplete disableClearable variant="outlined" clearOnBlur={false} options={this.state.arrayBank} getOptionLabel={(item) => item.nameCode || ""} freeSolo filterOptions={this.filterOptions}
																			onChange={(event, newValue) => { this.changerOption(event, newValue, setFieldValue); }}
																			onBlur={(e) => { handleBlur(e); if (e.target.value == "") setFieldValue('bank', '') }} value={values.bank}
																			renderInput={(params) => <TextField {...params} onBlur={handleBlur} value={values.bank} name="bank" error={errors.bank} placeholder="Procure um banco" style={{ width: '100%', height: 48 }} type="text" variant="outlined" />} />
																	</div>
																	<FontAwesomeIcon icon={faChevronDown} style={{ position: 'absolute', right: '24px', top: '50%' }} />
																</Col>



																<Row className="p-0 m-0 w-100">
																	<Col lg={4} md={12} className="p-0 m-0">
																		<Label>Agência</Label>

																		<TextField name='agency' variant="outlined" value={values.agency} onBlur={handleBlur}
																			placeholder="0000" onChange={handleChange} />
																	</Col>

																	<Col lg={4} md={12} className="marginInputMobile pr-0">
																		<Label>Tipo de conta</Label>

																		<Select name='account_type' native onChange={handleChange} onBlur={handleBlur} value={values.account_type} style={{ height: '41.02px' }}
																			input={<OutlinedInput name={'account_type'} />}>
																			<option key={null} value={null} hidden></option>
																			{this.state.typeAcc.map(({ type, label }) => <option key={type} value={type}>{label}</option>)}
																		</Select>
																	</Col>

																	<Col lg={4} md={12} className="marginInputMobile">
																		<Label>Conta</Label>

																		<TextField name='account' variant="outlined" value={values.account} onBlur={handleBlur}
																			placeholder="0000" onChange={handleChange} />
																	</Col>
																</Row>
															</>
															: null
														}
													</Row>


													{this.state.paymentMethod == 'PIX' ?
														<>
															{/* <h5 style={{ marginTop: 32, marginBottom: 8 }}>PIX</h5> */}
															<Row className="p-0 m-0">
																<Col lg={3} md={12} className="p-0 m-0">
																	<Label>Tipo</Label>

																	<Select style={{ height: "40px" }} native name='typePix' onChange={(e) => { handleChange(e); setFieldValue('pix_key', '') }} onBlur={handleBlur} value={values.typePix}
																		input={<OutlinedInput name={'account_type'} />}>
																		<option key={null} value={null} hidden></option>
																		{this.tipoPix().map((obj) => <option key={obj.key} value={obj.key}>{obj.label}</option>)}
																	</Select>
																</Col>

																<Col lg={9} md={12} className="marginInputMobile">
																	<Label>Chave</Label>

																	{values.typePix == 'telefone' ?
																		<TextField name="pix_key" type="text" placeholder='(00) 00000-0000' variant="outlined" value={values.pix_key} onBlur={handleBlur} onChange={(e) => handleChange(formatTelefoneInput(e))}
																			helperText={touched.pix_key && errors.pix_key} error={Boolean(touched.pix_key && errors.pix_key)} inputProps={{ maxLength: 15 }} />
																		: null}

																	{values.typePix == 'cpf' ?
																		<TextField name="pix_key" type="text" placeholder='000.000.000-00' variant="outlined" value={values.pix_key} onBlur={handleBlur} onChange={e => handleChange(formatCPFInput(e))}
																			helperText={touched.pix_key && errors.pix_key} error={Boolean(touched.pix_key && errors.pix_key)} inputProps={{ maxLength: 14 }} />
																		: null}

																	{values.typePix == 'email' ?
																		<TextField name="pix_key" type="text" placeholder='usuario@servidor.com.br' variant="outlined" value={values.pix_key} onBlur={handleBlur} onChange={handleChange}
																			helperText={touched.pix_key && errors.pix_key} error={Boolean(touched.pix_key && errors.pix_key)} />
																		: null}

																	{values.typePix == 'aleatorio' ?
																		<TextField name="pix_key" type="text" placeholder='xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx' variant="outlined" value={values.pix_key} onBlur={handleBlur} onChange={handleChange}
																			helperText={touched.pix_key && errors.pix_key} error={Boolean(touched.pix_key && errors.pix_key)} />
																		: null}
																</Col>
															</Row>
														</>
														: null
													}
												</div>
											</TabPane>

											<TabPane tabId="5" role="tabpanel">
												<div className='formulario'>
													<h5 style={{ marginBottom: 8 }}>VERIFICAÇÃO DE PERFIL</h5>

													<Col className="p-0 m-0">
														<Label style={{ color: '#343A40', fontFamily: 'Inter', fontSize: '13px', fontWeight: '400', marginBottom: '16px' }}>Tipo de cadastro</Label>
														<Row className="p-0 m-0">
															<Col md={2} style={{ marginLeft: "10px" }}>
																<Input id="accountTypePF" type="radio" name="accountType" value={values.accountType} onChange={() => setFieldValue('accountType', 'PF')}
																	onBlur={handleBlur} color="primary" checked={values.accountType == 'PF' ? true : false} />

																<Label style={{ padding: "3px", marginBottom: '0px' }} for="accountTypePF">
																	Pessoa física
																</Label>
															</Col>

															<Col md={3} style={{ marginLeft: "10px" }}>
																<Input id="accountTypePJ" type="radio" name="accountType" value={values.accountType} onChange={() => setFieldValue('accountType', 'PJ')}
																	onBlur={handleBlur} color="primary" checked={values.accountType == 'PJ' ? true : false} />

																<Label style={{ padding: "3px", marginBottom: '0px' }} for="accountTypePJ" >
																	Pessoa jurídica
																</Label>
															</Col>
														</Row>
													</Col>

													{values?.accountType === 'PF' ? (
														<>
															<Row className="p-0 m-0">
																<Col lg={12} md={12} className="p-0 m-0">
																	<Label>Nome completo</Label>

																	<TextField name="name" type="text" placeholder='Nome completo do usuário' variant="outlined" value={values.name} onBlur={handleBlur} onChange={handleChange}
																		helperText={touched.name && errors.name} error={Boolean(touched.name && errors.name)} />
																</Col>
															</Row>

															<Row className="p-0 m-0">
																<Col lg={4} md={12} className="p-0 m-0">
																	<Label>CPF</Label>

																	<TextField name="cpfCnpj" type="text" placeholder='Digite seu cpf' variant="outlined" value={values.cpfCnpj} onBlur={handleBlur} onChange={e => handleChange(formatCPFInput(e))}
																		helperText={touched.cpfCnpj && errors.cpfCnpj} error={Boolean(touched.cpfCnpj && errors.cpfCnpj)} />
																</Col>

																<Col md={4}>
																	<Label>Data de nascimento</Label>

																	<InputMask mask="99/99/9999" placeholder="00/00/0000" onBlur={handleBlur} name="birthdate" value={values.birthdate} onChange={handleChange}>
																		{(inputProps) => <TextField  {...inputProps} style={{ background: "#FFF", height: "40px" }} type="text" variant="outlined" helperText={touched.birthdate && errors.birthdate} error={Boolean(touched.birthdate && errors.birthdate)} />}
																	</InputMask>

																	<FontAwesomeIcon icon={faCalendar} style={{ position: 'absolute', top: '52%', fontSize: '18px', right: '27px' }} />
																</Col>

																<Col lg={4} md={12} className="marginInputMobile">
																	<Label>Valor de renda</Label>

																	<TextField name="valor" placeholder='R$ 0,00' type="text" variant="outlined" value={values.valor} onBlur={handleBlur} onChange={e => handleChange(formatBRLInput(e))}
																		helperText={touched.valor && errors.valor} error={Boolean(touched.valor && errors.valor)} />
																</Col>
															</Row>
														</>
													) : (
														<>
															<Row className="p-0 m-0">
																<Col lg={12} md={12} className="p-0 m-0">
																	<Label>Razão social</Label>

																	<TextField name="razao_social" type="text" placeholder='Preencha' variant="outlined" value={values.razao_social} onBlur={handleBlur} onChange={handleChange}
																		helperText={touched.razao_social && errors.razao_social} error={Boolean(touched.razao_social && errors.razao_social)} />
																</Col>


															</Row>

															<Row className="p-0 m-0">
																<Col lg={8} md={12} className="p-0 m-0">
																	<Label>Nome fantasia</Label>

																	<TextField name="name" type="text" placeholder='Preencha' variant="outlined" value={values.name} onBlur={handleBlur} onChange={handleChange}
																		helperText={touched.name && errors.name} error={Boolean(touched.name && errors.name)} />
																</Col>

																<Col lg={4} md={12} className="marginInputMobile">
																	<Label>Faturamento médio mensal</Label>

																	<TextField name="valor" placeholder='R$ 0,00' type="text" variant="outlined" value={values.valor} onBlur={handleBlur} onChange={e => handleChange(formatBRLInput(e))}
																		helperText={touched.valor && errors.valor} error={Boolean(touched.valor && errors.valor)} />
																</Col>
															</Row>

															<Row className="p-0 m-0">


																<Col lg={8} md={12} className="p-0 m-0">
																	<Label>CNPJ</Label>

																	<TextField name="cpfCnpj" placeholder='00.000.000/0000-00' type="text" variant="outlined" value={values.cpfCnpj} onBlur={handleBlur} onChange={e => handleChange(formatCNPJInput(e))}
																		helperText={touched.cpfCnpj && errors.cpfCnpj} error={Boolean(touched.cpfCnpj && errors.cpfCnpj)} />
																</Col>

																<Col lg={4} md={12} className="marginInputMobile">
																	<Label>Tipo de empresa</Label>

																	<Select name='company_type' native onChange={handleChange} onBlur={handleBlur} value={values.company_type} style={{ height: '41.02px' }}
																		input={<OutlinedInput name={'company_type'} />}>
																		<option key={null} value={null} hidden></option>
																		{this.state?.arrayCompanyType.map(({ key, label }) => <option key={key} value={key}>{label}</option>)}
																	</Select>
																</Col>
															</Row>
														</>
													)}


												</div>
											</TabPane>
										</TabContent>
									</div>
								</div>

								{this.state.activeTab == 5 ?
									<div className='box' style={{ width: "736px" }}>
										<TabContent activeTab={this.state.activeTab}>
											<TabPane tabId="5" role="tabpanel">
												<div className='formulario'>
													<h5 style={{ marginBottom: 0 }}>DOCUMENTO EXTRA</h5>

													<FormGroup className="mb-0 checkBox-admin">
														<Label className="col-form-label" style={{ color: '#343A40', fontSize: '13px' }}>Selecione e importe o <b>Nome do documento</b></Label>
														<br />

														<Row className="p-0 m-0">
															<Col lg={9} md={12} className="p-0 m-0">
																<TextField name="fileName" placeholder='Selecione um arquivo no seu computador' type="text" variant="outlined" value={values.fileName} onBlur={handleBlur} onChange={handleChange}
																	helperText={touched.fileName && errors.fileName} error={Boolean(touched.fileName && errors.fileName)} />
															</Col>


															<Col lg={3} md={12} className="marginInputMobile">
																<label htmlFor={'file'} className="btn btn-secondary btn-bold mb-0 d-flex justify-content-center align-items-center" style={{ width: '100%', height: '41.09px', backgroundColor: '#5664D2' }}>
																	<input className="doc-juridico-select" type="file" id={'file'} style={{ display: 'none' }} name={'file'} accept="*/*" onChange={(event) => { this.setState({ file: event.currentTarget.files[0] }) }} />

																	IMPORTAR
																	<FontAwesomeIcon icon={faFileImport} style={{ marginLeft: '10px' }} />
																</label>
															</Col>
														</Row>

														{this.state.file ?
															<div style={{ display: 'flex', padding: '20px 30px', marginTop: '10px', flexDirection: 'row', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'space-between', width: '100%', backgroundColor: '#F1F5F7' }}>
																<p style={{ textOverflow: 'ellipsis', margin: '0px', color: '#4AA3FF' }}>{this.state.file ? this.state.file.name : null}</p>
																<div style={{ display: 'flex', alignItems: 'center' }}>
																	<p style={{ marginBottom: '0px', height: '15px' }}>
																		{this.state.file ? `${(this.state.file?.size / 1000).toFixed(2)} Kb` : null}
																	</p>
																	<FontAwesomeIcon icon={faXmark} style={{ color: '#343A40', fontSize: '20px', cursor: 'pointer', marginLeft: '16px' }} onClick={() => this.setState({ file: null })} />

																</div>
															</div>
															: null}
													</FormGroup>
												</div>
											</TabPane>
										</TabContent>
									</div>
									: null}

								{this.state.activeTab == 5 && this.state.documents.find(obj => obj.type === 'SOCIAL_CONTRACT') ?
									<div className='box' style={{ width: "736px" }}>
										<TabContent activeTab={this.state.activeTab}>
											<TabPane tabId="5" role="tabpanel">
												<div className='formulario'>
													<h5 style={{ marginBottom: 0 }}>CONTRATO SOCIAL</h5>

													<FormGroup className="mb-0 checkBox-admin">
														<Label className="col-form-label" style={{ color: '#343A40', fontSize: '13px' }}>Selecione e importe o <b>Nome do documento</b></Label>
														<br />

														<Row className="p-0 m-0">
															<Col lg={9} md={12} className="p-0 m-0">
																<TextField name="extraSocialContract" placeholder='Selecione um arquivo no seu computador' type="text" variant="outlined" value={values.extraSocialContract} onBlur={handleBlur} onChange={handleChange}
																	helperText={touched.extraSocialContract && errors.extraSocialContract} error={Boolean(touched.extraSocialContract && errors.extraSocialContract)} />
															</Col>


															<Col lg={3} md={12} className="marginInputMobile">
																<label htmlFor={'socialContractExtraFile'} className="btn btn-secondary btn-bold mb-0 d-flex justify-content-center align-items-center" style={{ width: '100%', height: '41.09px', backgroundColor: '#5664D2' }}>
																	<input className="doc-juridico-select" type="file" id={'socialContractExtraFile'} style={{ display: 'none' }} name={'socialContractExtraFile'} accept="*/*" onChange={(event) => { this.setState({ socialContractExtraFile: event.currentTarget.files[0] }) }} />

																	IMPORTAR
																	<FontAwesomeIcon icon={faFileImport} style={{ marginLeft: '10px' }} />
																</label>
															</Col>
														</Row>
														{console.log(this.state.socialContractExtraFile)}
														{this.state.socialContractExtraFile ?
															<div style={{ display: 'flex', padding: '20px 30px', marginTop: '10px', flexDirection: 'row', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'space-between', width: '100%', backgroundColor: '#F1F5F7' }}>
																<p style={{ textOverflow: 'ellipsis', margin: '0px', color: '#4AA3FF' }}>{this.state.socialContractExtraFile ? this.state.socialContractExtraFile.name : null}</p>
																<div style={{ display: 'flex', alignItems: 'center' }}>
																	<p style={{ marginBottom: '0px', height: '15px' }}>
																		{this.state.socialContractExtraFile ? `${(this.state.socialContractExtraFile?.size / 1000).toFixed(2)} Kb` : null}
																	</p>
																	<FontAwesomeIcon icon={faXmark} style={{ color: '#343A40', fontSize: '20px', cursor: 'pointer', marginLeft: '16px' }} onClick={() => this.setState({ socialContractExtraFile: null })} />

																</div>
															</div>
															: null}
													</FormGroup>
												</div>
											</TabPane>
										</TabContent>
									</div>
									: null}

								{this.state.activeTab == 5 && this.state.documents.find(obj => obj.type === 'LIMITED_COMPANY') ?
									<div className='box' style={{ width: "736px" }}>
										<TabContent activeTab={this.state.activeTab}>
											<TabPane tabId="5" role="tabpanel">
												<div className='formulario'>
													<h5 style={{ marginBottom: 0 }}>COMPANHIA LIMITADA</h5>

													<FormGroup className="mb-0 checkBox-admin">
														<Label className="col-form-label" style={{ color: '#343A40', fontSize: '13px' }}>Selecione e importe o <b>Nome do documento</b></Label>
														<br />

														<Row className="p-0 m-0">
															<Col lg={9} md={12} className="p-0 m-0">
																<TextField name="extraLimitedCompany" placeholder='Selecione um arquivo no seu computador' type="text" variant="outlined" value={values.extraLimitedCompany} onBlur={handleBlur} onChange={handleChange}
																	helperText={touched.extraLimitedCompany && errors.extraLimitedCompany} error={Boolean(touched.extraLimitedCompany && errors.extraLimitedCompany)} />
															</Col>


															<Col lg={3} md={12} className="marginInputMobile">
																<label htmlFor={'limitedCompanyExtraFile'} className="btn btn-secondary btn-bold mb-0 d-flex justify-content-center align-items-center" style={{ width: '100%', height: '41.09px', backgroundColor: '#5664D2' }}>
																	<input className="doc-juridico-select" type="file" id={'limitedCompanyExtraFile'} style={{ display: 'none' }} name={'limitedCompanyExtraFile'} accept="*/*" onChange={(event) => { this.setState({ limitedCompanyExtraFile: event.currentTarget.files[0] }) }} />

																	IMPORTAR
																	<FontAwesomeIcon icon={faFileImport} style={{ marginLeft: '10px' }} />
																</label>
															</Col>
														</Row>

														{this.state.limitedCompanyExtraFile ?
															<div style={{ display: 'flex', padding: '20px 30px', marginTop: '10px', flexDirection: 'row', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'space-between', width: '100%', backgroundColor: '#F1F5F7' }}>
																<p style={{ textOverflow: 'ellipsis', margin: '0px', color: '#4AA3FF' }}>{this.state.limitedCompanyExtraFile ? this.state.limitedCompanyExtraFile.name : null}</p>
																<div style={{ display: 'flex', alignItems: 'center' }}>
																	<p style={{ marginBottom: '0px', height: '15px' }}>
																		{this.state.limitedCompanyExtraFile ? `${(this.state.limitedCompanyExtraFile?.size / 1000).toFixed(2)} Kb` : null}
																	</p>
																	<FontAwesomeIcon icon={faXmark} style={{ color: '#343A40', fontSize: '20px', cursor: 'pointer', marginLeft: '16px' }} onClick={() => this.setState({ limitedCompanyExtraFile: null })} />

																</div>
															</div>
															: null}
													</FormGroup>
												</div>
											</TabPane>
										</TabContent>
									</div>
									: null}

								{this.state.activeTab == 5 ?
									<div className='box' style={{ width: "736px" }}>
										<TabContent activeTab={this.state.activeTab}>
											<TabPane tabId="5" role="tabpanel">
												<div className='formulario' style={{ gap: 0 }}>
													<h5 style={{ marginBottom: 32 }}>ADMIN MELLRO</h5>

													<FormGroup className="checkBox-admin d-flex" style={{ marginBottom: 8 }}>
														<div className='d-flex align-items-start'>
															<Checkbox name="roles" id='roles-check' color="primary" checked={values.roles == 'admin'} onBlur={handleBlur} onChange={() => values.roles == 'admin' ? setFieldValue('roles', '') : setFieldValue('roles', 'admin')} />
														</div>
														<div className='d-flex flex-column'>
															<Label htmlFor='ext_auth-check' style={{ color: 'var(--Cinza-500, #505D69)', fontFamily: 'Inter', fontSize: '13px', fontStyle: 'normal', fontWeight: 600, lineHeight: 'normal', marginBottom: 0 }}>
																Acesso ao admin:
															</Label>
															<Label htmlFor='roles-check' style={{ color: 'var(--Cinza-500, #505D69)', fontFamily: 'Inter', fontSize: '13px', fontStyle: 'normal', fontWeight: 400, lineHeight: 'normal', marginBottom: 0 }}>O usuário poderá fazer login, acessar e editar registros na plataforma Admin da Mellro</Label>
														</div>
													</FormGroup>

													{this.state?.requestUser?.roles?.includes('external_auth') && (values.roles == 'admin') ? (
														<FormGroup className="mb-0 checkBox-admin d-flex">
															<div className='d-flex align-items-start' style={{ marginLeft: '5px' }}>
																<FontAwesomeIcon icon={faArrowTurnDownRight} style={{ color: '#DADBDD', marginRight: 8, fontSize: 21 }} />
																<Checkbox name="ext_auth" id='ext_auth-check' color="primary" checked={values.ext_auth == true} onBlur={handleBlur} onChange={() => values.ext_auth == true ? setFieldValue('ext_auth', false) : setFieldValue('ext_auth', true)} />
															</div>
															<div>
																<Label htmlFor='ext_auth-check' style={{ color: 'var(--Cinza-500, #8590A5)', fontFamily: 'Inter', fontSize: '13px', fontStyle: 'normal', fontWeight: 600, lineHeight: 'normal', marginBottom: 0 }}>
																	Logar como outro usuário:
																</Label>
																<Label htmlFor='ext_auth-check' style={{ color: 'var(--Cinza-500, #8590A5)', fontFamily: 'Inter', fontSize: '13px', fontStyle: 'normal', fontWeight: 400, lineHeight: 'normal' }}>
																	O usuário pode iniciar sessão como outro usuário para efetuar acompanhamento e suporte. </Label>

																<div style={{ display: 'flex', padding: '4px 12px', alignItems: 'flex-start', borderRadius: '2px', background: 'var(--Vermelho-500, #FF3D60)', width: 309, color: '#fff' }}>
																	Funcionalidade direcionada apenas à gestores
																</div>

															</div>
														</FormGroup>
													) : null}
												</div>
											</TabPane>
										</TabContent>
									</div>
									: null}


								<div className='box-footer' style={{ width: '100%', maxWidth: '736px' }}>
									<Link to={`/users/show/${this.props.match.params.id}`} className='button-secundary'>
										<i className="fa fa-arrow-left" />
										VOLTAR
									</Link>

									<button type="submit" className='button-primary' disabled={this.state.submitted} style={{ width: 95 }}>
										{this.state.submitted ?
											<>
												<i className="fas fa-sync fa-spin" />
											</> :
											<>
												Salvar
												<i className="fa fa-check" />
											</>
										}
									</button>
								</div>
							</form>
						)}
					</Formik>
				</div>
			</BetterCircularProgress >
		)
	}
}

export default UsersEdit;