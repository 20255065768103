import React, { Component, createRef } from 'react';
import { Container, Card, CardBody, Row, Col, Button, ButtonDropdown, DropdownMenu, DropdownItem, DropdownToggle, Label, FormGroup, Input, Nav, NavItem, NavLink } from "reactstrap";
import Breadcrumbs from '../../../components/Breadcrumb';
import { get as getAllByParams, getTotalAdmin } from '../../../crud/billings.crud';
import BetterAlerts from '../../../components/BetterAlerts';
import DataTableCobranca from '../../../components/datatables/cobranca/DataTableCobranca';
import { UserFilterComponent } from '../../negotiation/components/user.component';
import { formatBRL } from '../../../helpers/Utils';
import { faFilter, faMagnifyingGlass, faChevronRight, faTimes, faSpinner, faArrowUpRightFromSquare } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BetterCircularProgress from '../../../components/BetterCircularProgress';
import classnames from 'classnames';
import moment from 'moment';
import { Link } from 'react-router-dom';

class CobrancaIndex extends Component {
	dataTableRef = createRef();

	state = {
		activeTab: '1',
		filtersCount: 0,
		newFilters: [],
		indicadores: {},
		totalAtual: 0,
		errors: [],
		success: [],
		filters: {
			landlord: {},
			tenant: {},
			searchAll: '',
			status: '',
			is_badDebt: '',
		},
		flags: {
		},
		is_filter_open: false,
		export: false
	};

	componentDidMount = () => {
		if (this.props.location.state) {
			if (this.props.location.state.success) {
				this.setState({ success: this.props.location.state.success });
			}
		}

		if (this.props.location?.state?.filters) {
			if (this.props.location.state.filters.billing_type == 'monthly') {
				this.setState({
					activeTab: '2'
				})
			} else if (this.props.location.state.filters.billing_type == 'agreement') {
				this.setState({
					activeTab: '3'
				})
			} else if (this.props.location.state.filters.billing_type == 'termination') {
				this.setState({
					activeTab: '4'
				})
			}

			if (this.props.location.state.filters.billing_status == 'overdue') {
				this.setState({
					filters: {
						...this.state.filters,
						status: 'atrasada'
					},
					newFilters: [
						'status'
					]
				})
			} else if (this.props.location.state.filters.billing_status == 'bad_debt') {
				this.setState({
					filters: {
						...this.state.filters,
						is_badDebt: 'Sim'
					},
					newFilters: [
						'inadimplente'
					]
				})
			} else {
				this.setState({
					filters: {
						...this.state.filters,
						status: 'atrasada',
						is_badDebt: 'Sim'
					},
					newFilters: [
						'status',
						'inadimplente'
					]
				})
			}
		}


		getTotalAdmin().catch(err => {
			alert('Ocorreu um erro ao carregar o número de cobranças, entre em contato com o suporte.');
		}).then(async res => {
			if (res?.status === 200) {
				if (res.data) {
					this.dataTableRef?.current?.fetchData();

					this.setState({ indicadores: res.data });
				}
			}
		});
	};

	fetchByFilter = (data) => {
		var params = { ...data };

		if (this.state.filters.signup_after?.trim() != '') params.due_date_gte = this.state.filters.signup_after;
		if (this.state.filters.signup_before?.trim() != '') params.due_date_lte = this.state.filters.signup_before;
		if (this.state.filters.landlord?.id) params.landlord_id = this.state.filters.landlord.id;
		if (this.state.filters.tenant?.id) params.user_id = this.state.filters.tenant.id;

		if (this.state.filters.is_badDebt == 'Sim') {
			params.bad_debt = true;
		} else if (this.state.filters.is_badDebt == 'Não') {
			params.bad_debt = false;
		}


		if (this.state.filters.status?.trim() != '') {
			switch (this.state.filters.status) {
				case 'aguardando':
					params = { ...params, receipts: { rent: { paid: false, paid_for_insurer: false } }, due_date_gte: moment().format('YYYY-MM-DD'), boletoIncluded: false };

					break;
				case 'aberto':
					params = { ...params, receipts: { rent: { paid: false, paid_for_insurer: false } }, due_date_gte: moment().format('YYYY-MM-DD'), due_date_lte: moment().add(10, 'd').format('YYYY-MM-DD'), boletoIncluded: true };

					break;
				case 'atrasada':
					params = { ...params, receipts: { rent: { paid: false, paid_for_insurer: false } }, due_date_lte: moment().subtract(1, 'd').format('YYYY-MM-DD') };

					break;
				case 'paga':
					params = { ...params, receipts: { rent: { paid: true } } };

					break;
				case 'garantia-atrasada':
					params = { ...params, receipts: { rent: { paid: false, paid_for_insurer: true } } };

					break;
				case 'garantia-pago':
					params = { ...params, receipts: { rent: { paid: true, paid_for_insurer: true } } };
				case 'anticipated':
					params = { ...params, anticipated: true }

					break;
				default:
					break;
			}
		}

		if (this.state.filters.searchAll?.trim() != '') params.searchAll = this.state.filters.searchAll;

		getTotalAdmin(params).catch(err => {
			alert('Ocorreu um erro ao carregar o número de cobranças, entre em contato com o suporte.');
		}).then(async res => {
			if (res?.status === 200) {
				if (res.data) this.setState({ indicadores: res.data });
			}
		});

		if (this.state.activeTab === '1') {
			// primeiro pagamento
			params.number = 0;
			params.type = 'monthly';
		}

		if (this.state.activeTab === '2') {
			// mensal
			params.type = 'monthly';
			params.number_ne = 0;
		}

		if (this.state.activeTab === '3') {
			// acordo
			params.type = 'agreement';
		}

		if (this.state.activeTab === '4') {
			// recisão
			params.type = 'termination';
		}

		if (this.state.activeTab === '5') {
			// negativado
			params = { ...params, credit_negative: { negative: 'true' } }
		}

		if (this.state.activeTab === '6') {
			// Devolução de aluguel
			params.type = 'warranty_refund';
		}

		return getAllByParams(params);
	};

	toggleTab(tab) {
		if (this.state.activeTab !== tab) {
			this.setState({ activeTab: tab }, () => {
				this.setState({ totalAtual: 0 });
				this.dataTableRef.current.fetchData();
			});
		}
	};

	saveFilters = () => {
		localStorage.setItem('cobrancaFilters', JSON.stringify(this.state.filters));
		localStorage.setItem('cobrancaFlags', JSON.stringify(this.state.flags));
	};

	FiltersModule = ({ index }) => {
		const getFilterChoised = (filter) => {
			let component = <></>

			if (filter == 'vencimento') {
				component = (
					<>
						<div className='d-flex'>
							<Label htmlFor="signup_after" className="col-form-label">De</Label>

							<div class="input-with-black-icon-date">
								<Input className="form-control" type="date" id="signup_after" value={this.state.filters.signup_after} style={{ width: 200, margin: '0 16px' }}
									onChange={(e) => this.setState({ filters: { ...this.state.filters, signup_after: e.target.value } }, () => this.saveFilters())} />
							</div>
						</div>

						<div className='d-flex'>
							<Label htmlFor="signup_before" className="col-form-label">Até</Label>
							<Input className="form-control" type="date" id="signup_before" value={this.state.filters.signup_before} style={{ width: 200, margin: '0 16px' }}
								onChange={(e) => this.setState({ filters: { ...this.state.filters, signup_before: e.target.value } }, () => this.saveFilters())} />
						</div>
					</>
				)
			} else if (filter == 'proprietario') {
				component = (
					<div className='d-flex'>
						<UserFilterComponent label="Proprietário" value={this.state.filters.landlord} onChange={(e, value) => { this.setState({ filters: { ...this.state.filters, landlord: value } }); this.saveFilters(); }} layout={'row'} />
					</div>
				)
			} else if (filter == 'inquilino') {
				component = (
					<div className='d-flex'>
						<UserFilterComponent label="Inquilino" value={this.state.filters.tenant} onChange={(e, value) => { this.setState({ filters: { ...this.state.filters, tenant: value } }); this.saveFilters(); }} layout={'row'} />
					</div>
				)
			} else if (filter == 'valorAluguel') {
				component = (
					<div className='d-flex'>
						<Input placeholder="Valor aluguel" variant="outlined" style={{ background: "#FFF", height: "40px" }} value={formatBRL(this.state.filters.valor_aluguel)}
							onChange={(e) => this.setState({ filters: { ...this.state.filters, valor_aluguel: Number(e.target.value.toString().replace(/[^0-9-]/g, '')) / 10 ** 2 } }, () => this.saveFilters())} />
					</div>
				)
			} else if (filter == 'status') {
				component = (
					<div className='d-flex'>
						<select onChange={(e) => this.setState({ filters: { ...this.state.filters, status: e.target.value } }, () => this.saveFilters())} style={{ width: 400, margin: '0 16px' }} className="custom-select" value={this.state?.filters?.status}>
							<option value="" hidden>Selecionar</option>
							<option value="aguardando">Aguardando</option>
							<option value="aberto">Fatura em aberto</option>
							<option value="atrasada">Fatura atrasada</option>
							<option value="paga">Fatura Paga</option>
							<option value="garantia-atrasada">Garantido e atrasado</option>
							<option value="garantia-pago">Garantido e pago</option>
							<option value="anticipated">Antecipado</option>
						</select>
					</div>
				)
			} else if (filter == 'inadimplente') {
				component = (
					<div className='d-flex'>
						<select onChange={(e) => this.setState({ filters: { ...this.state.filters, is_badDebt: e.target.value } }, () => this.saveFilters())} style={{ width: 400, margin: '0 16px' }} className="custom-select" value={this.state?.filters?.is_badDebt}>
							<option value="" hidden>Selecionar</option>
							<option value="Sim">Sim</option>
							<option value="Não">Não</option>
						</select>
					</div>
				)
			} else {
				component = <></>
			}

			return component;
		}

		const onSetFilter = (filtro) => {
			let stateFilter = this.state.newFilters.slice();
			stateFilter[index] = filtro;

			this.setState({ newFilters: stateFilter })
		}

		const getSelectOptions = (nameFiltro) => {
			const stateFilter = this.state.newFilters;

			if (stateFilter.includes(nameFiltro)) {
				return 'd-none'
			} else {
				return ''
			}
		}

		const removeFilter = (filterUnit, filterName) => {
			let stateFilter = this.state.newFilters;

			stateFilter.splice(filterUnit, 1);

			switch (filterName) {
				case 'vencimento':
					this.setState({
						flags: { ...this.state.flags, show_signup_after: false, show_signup_before: false },
						filters: { ...this.state.filters, signup_after: '', signup_before: '' }
					}, () => this.saveFilters())

					break;

				case 'proprietario':
					this.setState({
						flags: { ...this.state.flags, proprietario: false },
						filters: { ...this.state.filters, landlord: {} }
					}, () => this.saveFilters())

					break;

				case 'inquilino':
					this.setState({
						flags: { ...this.state.flags, inquilino: false },
						filters: { ...this.state.filters, tenant: {} }
					}, () => this.saveFilters())

					break;

				case 'status':
					this.setState({
						flags: { ...this.state.flags, status: false },
						filters: { ...this.state.filters, status: '' }
					}, () => this.saveFilters())

					break;

				case 'inadimplente':
					this.setState({
						flags: { ...this.state.flags, inadimplente: false },
						filters: { ...this.state.filters, is_badDebt: '' }
					}, () => this.saveFilters())

					break;

				default:
					break;
			}

			this.setState({ newFilters: stateFilter })
		}

		return (
			<Col md={12} className="d-flex m-0 p-0 filtroUnit">
				<div className='d-flex'>
					<select onChange={({ target }) => onSetFilter(target.value)} value={this.state.newFilters[index]} className="custom-select" style={{ width: 300 }}>
						<option value="">Selecione um filtro</option>
						<option className={getSelectOptions('vencimento')} value="vencimento">Vencimento</option>
						<option className={getSelectOptions('proprietario')} value="proprietario">Proprietário</option>
						<option className={getSelectOptions('inquilino')} value="inquilino">Inquilino</option>
						<option className={getSelectOptions('status')} value="status">Status</option>
						<option className={getSelectOptions('inadimplente')} value="inadimplente"> Inadimplente </option>
					</select>

					{this.state.newFilters[index] != '' && (
						<div className='divisionArrow'>
							<FontAwesomeIcon icon={faChevronRight} />
						</div>
					)}

					{getFilterChoised(this.state.newFilters[index])}
				</div>

				<div onClick={() => removeFilter(index, this.state.newFilters[index])}>
					<FontAwesomeIcon icon={faTimes} />
				</div>
			</Col>
		)
	}

	render() {
		return (
			<React.Fragment>
				<div className="page-content container-page userIndex">
					<Container fluid>
						<div className='header'>
							<h1>Cobranças</h1>

							<Breadcrumbs breadcrumbItems={[{ link: '/pagamento/cobrancas/index', title: 'Financeiro' }, { link: '/pagamento/cobrancas/index', title: 'Cobranças' }]} />
						</div>

						<BetterAlerts errors={this.state.errors} success={this.state.success} />

						<Col md={12} className="box-filtros">
							<Col md={12} className="d-flex m-0 p-0">
								<div className='generalSearch'>
									<Input className="inputCustomNew" type="text" value={this.state.filters.searchAll} placeholder="Busque pelos IDs do Responsável, Inquilino, Imóvel ou Billing"
										onChange={(e) => this.setState({ filters: { ...this.state.filters, searchAll: e.target.value } }, () => this.saveFilters())} />

									<FontAwesomeIcon icon={faMagnifyingGlass} />
								</div>

								<button onClick={() => this.setState({ newFilters: [...this.state.newFilters, ''] })} className="btnSecundary" style={{ width: 'max-content', margin: '0', padding: '8px 12px' }}>
									FILTRO
									<FontAwesomeIcon icon={faFilter} />
								</button>

								<Link to={`/pagamento/indices/index`} className="btnSecundary" style={{ width: '100%', maxWidth: 210, margin: '0px 8px 0px 8px', padding: '8px 12px' }}>
									ÍNDICE DE RENOVAÇÃO
									<FontAwesomeIcon icon={faArrowUpRightFromSquare} />
								</Link>
							</Col>

							{this.state.newFilters.map((filtroModular, index) => {
								return <this.FiltersModule filtroModular={filtroModular} key={index} index={index} />
							})}
						</Col>

						<Row>
							<Col lg={12}>
								<Card className='card-general'>
									<Nav tabs className="nav-tabs-custom" role="tablist" style={{ marginBottom: '32px' }}>
										<NavItem>
											<NavLink id="info-tab" className={classnames({ active: this.state.activeTab === '1' })} onClick={() => { this.toggleTab('1'); }}>
												<div className='tabName'>Primeiro pagamento <div className='indicadorUsuarios'>{this.state?.indicadores?.primeiroPagamento ?? <FontAwesomeIcon spin icon={faSpinner} />}</div></div>
											</NavLink>
										</NavItem>

										<NavItem>
											<NavLink id="announcements-tab" className={classnames({ active: this.state.activeTab === '2' })} onClick={() => { this.toggleTab('2'); }}>
												<div className='tabName'>Aluguel mensal <div className='indicadorUsuarios'>{this.state?.indicadores?.mensal ?? <FontAwesomeIcon spin icon={faSpinner} />}</div></div>
											</NavLink>
										</NavItem>

										<NavItem>
											<NavLink id="negotiations-tab" className={classnames({ active: this.state.activeTab === '3' })} onClick={() => { this.toggleTab('3'); }}>
												<div className='tabName'>Parcela de acordo <div className='indicadorUsuarios'>{this.state?.indicadores?.acordo ?? <FontAwesomeIcon spin icon={faSpinner} />}</div></div>
											</NavLink>
										</NavItem>

										<NavItem>
											<NavLink id="negotiations-tab" className={classnames({ active: this.state.activeTab === '4' })} onClick={() => { this.toggleTab('4'); }}>
												<div className='tabName'>Rescisão de contratos <div className='indicadorUsuarios'>{this.state?.indicadores?.rescisao ?? <FontAwesomeIcon spin icon={faSpinner} />}</div></div>
											</NavLink>
										</NavItem>

										<NavItem>
											<NavLink id="negotiations-tab" className={classnames({ active: this.state.activeTab === '6' })} onClick={() => { this.toggleTab('6'); }}>
												<div className='tabName'>Devolução de garantia <div className='indicadorUsuarios'>{this.state?.indicadores?.devolucaoGarantia ?? <FontAwesomeIcon spin icon={faSpinner} />}</div></div>
											</NavLink>
										</NavItem>

										<NavItem>
											<NavLink id="negotiations-tab" className={classnames({ active: this.state.activeTab === '5' })} onClick={() => { this.toggleTab('5'); }}>
												<div className='tabName'>Negativados <div className='indicadorUsuarios'>{this.state?.indicadores?.negativado ?? <FontAwesomeIcon spin icon={faSpinner} />}</div></div>
											</NavLink>
										</NavItem>
									</Nav>

									<div className='datatable-box paginationResized'>
										<BetterCircularProgress loading={this.state.loading}>
											<DataTableCobranca ref={this.dataTableRef} filters={this.state.filters} noDelete fetchEvent={this.fetchByFilter} crudButtons={true}
												crudUrl="/billing" activeTab={this.state.activeTab} idRow={`id`} export={this.state.export} />
										</BetterCircularProgress>
									</div>
								</Card>
							</Col>
						</Row>
					</Container>
				</div>
			</React.Fragment>
		);
	}
}

export default CobrancaIndex;