import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import _ from 'lodash';
import moment from 'moment';
import { get as getUser } from '../../crud/users.crud';
import { formatBRL } from '../../helpers/Utils';


export default class DataTableDocList extends Component {
	constructor() {
		super();

		this.state = {
			data: [],
			rows: 10,
			totalRecords: 0,
			first: 0,
			last: 0,
			loading: false,
			sortField: 'id',
			sortOrder: 1,
		};

		this.onPage = this.onPage.bind(this);
	}

	componentDidMount() {
		let userDocsTable = JSON.parse(localStorage.getItem('userDocsTable'));

		if (userDocsTable) {
			this.setState({
				rows: userDocsTable.rows,
				totalRecords: userDocsTable.totalRecords,
				first: userDocsTable.first,
				last: userDocsTable.last,
				loading: userDocsTable.loading,
				sortField: userDocsTable.sortField,
				sortOrder: userDocsTable.sortOrder,
			}, () => {
				this.fetchData();
			});
		} else {
			this.fetchData();
		}
	}

	componentDidUpdate(prevProps) {
		if (prevProps.filters != this.props.filters) {
			this.onFilterChanged();
		}
	}

	onFilterChanged = _.debounce(() => {
		if (!this.state.isLoading) {
			this.fetchData();
		}
	}, 700);

	refreshTable() {
		this.onPage(this.state);
	}

	onPage = (event) => {
		if (event.data) {
			event.data = null;
		}

		let userDocsTable = {
			rows: event.rows,
			totalRecords: this.state.totalRecords,
			first: event.first,
			last: event.rows + event.first,
			loading: this.state.loading,
			sortField: this.state.sortField,
			sortOrder: this.state.sortOrder,
		}

		localStorage.setItem('userDocsTable', JSON.stringify(userDocsTable));

		this.setState({
			...this.state,
			rows: event.rows,
			loading: true
		});

		this.fetchData(event.rows, event.first);
	};

	fetchData = (limit, skip) => {
		let data = {
			$limit: limit ? limit : this.state.rows,
			$skip: skip != null ? skip : this.state.first,
			$sort: { [this.state.sortField]: this.state.sortOrder }
		};

		this.props.fetchEvent(data).then(async res => {
			res.data.data.map(async (obj, i) => {
				if (obj.created_by) {
					await getUser(obj.created_by).catch(err => {
					}).then(res => {
						if (res?.data?.data) {
							obj.user = res.data.data;
						}
					});
				}

				this.setState({
					...this.state,
					data: res.data.data,
					totalRecords: res.data.total,
					first: res.data.skip,
					last: res.data.skip + res.data.limit,
					loading: false
				});
			});

			this.setState({
				...this.state,
				data: res.data.data,
				totalRecords: res.data.total,
				first: res.data.skip,
				last: res.data.skip + res.data.limit,
				loading: false
			});
		});
	}

	sortTable = e => {
		this.setState({ sortField: e.sortField, sortOrder: e.sortOrder }, () => {
			let userDocsTable = {
				rows: this.state.rows,
				totalRecords: this.state.totalRecords,
				first: this.state.first,
				last: this.state.last,
				loading: this.state.loading,
				sortField: this.state.sortField,
				sortOrder: this.state.sortOrder,
			};

			localStorage.setItem('userDocsTable', JSON.stringify(userDocsTable));

			this.fetchData();
		});
	};

	getDateFormatted = (date) => {
		return (
			<p>{date ? moment(date).format('DD/MM/YYYY HH:mm:ss') : 'N/A'}</p>
		)
	}

	getUserLink = (obj) => {
		return (
			<a target="_blank" rel="noreferrer" href={`/users/show/${obj?.created_by}`}>
				{obj?.user?.name}
			</a>
		)
	}

	getFileNameByUrl = (url) => {
		const parts = url.split("/");
		return parts[parts.length - 1];
	};

	getDocumentLink = (obj) => {
		if (obj?.category === 'Termo Aditivo') {
			return (
				<a target="_blank" rel="noreferrer" href={obj.file_desc?.secure_url ?? obj.file_desc?.url}>{`Termo-aditivo-${moment(obj?.created_at).format('DD-MM-YYYY')}`}</a>
			)
		}

		return (
			<a target="_blank" rel="noreferrer" href={obj.file_desc?.secure_url ?? obj.file_desc?.url}>{this.getFileNameByUrl(obj.file_desc?.secure_url ?? obj.file_desc?.url)}</a>
		)
	}

	render() {
		const header = (
			<div style={{ float: 'right' }}>
				<Button className="tableHeaderItem" icon={!this.state.loading ? "fas fa-sync" : "fas fa-sync fa-spin"} disabled={this.state.loading ? "disabled" : ""} onClick={() => this.refreshTable()} />
			</div>
		);

		return (
			<DataTable ref={(el) => this.dt = el} value={this.state.data} paginator={true} rows={this.state.rows} rowsPerPageOptions={[10, 25, 50]}
				first={this.state.first} last={this.state.last} header={this.props.noHeader ? null : header} responsive={true}
				totalRecords={this.state.totalRecords} lazy={true} onPage={this.onPage} loading={this.state.loading} loadingIcon={`fas fa-sync fa-spin`}
				paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
				currentPageReportTemplate="Mostrando {first} até {last} de {totalRecords} registros" emptyMessage="Nenhum registro encontrado!"
				onSort={(e) => this.sortTable(e)} sortField={this.state.sortField} sortOrder={this.state.sortOrder} >
				<Column sortable field="file_desc.secure_url" header="Documento" body={(obj, i) => this.getDocumentLink(obj)} />
				<Column sortable field="category" header="Categoria" body={(obj, i) => obj.category || 'Documento interno'} />
				<Column sortable field="payments_value" header="R$ cobrança/negociação" body={(obj, i) => formatBRL(obj.payments_value)} />

				<Column field="description" header="Descrição" />
				<Column field="userName" header="Quem fez upload" body={(obj, i) => this.getUserLink(obj)} />
				<Column sortable field="updated_at" body={(obj, i) => this.getDateFormatted(obj.created_at)} header="Upload" />
				<Column sortable field="sentence_date" body={(obj, i) => this.getDateFormatted(obj.sentence_date)} header="Recebimento" />
			</DataTable>
		);
	}
}