import React from "react";
import { Redirect } from "react-router-dom";

// Authentication related pages
import Login from "../pages/authentication/Login";
import Logout from "../pages/authentication/Logout";

// Users
import UsersIndex from "../pages/users/UsersIndex";
import UsersShow from "../pages/users/UsersShow";
import UsersNew from "../pages/users/UsersNew";
import UsersEdit from "../pages/users/UsersEdit";

// Income User
import IncomeUserEdit from "../pages/users/income/IncomeUserEdit";

// Bank Account
import BankAccountEdit from "../pages/users/bankAccount/BankAccountEdit";

// Account
import AccountEdit from "../pages/users/account/AccountEdit";

// Contracts
import ContractsIndex from "../pages/contracts/ContractsIndex";
import ContractsShow from "../pages/contracts/ContractShow";
import ContractsEdit from "../pages/contracts/ContractsEdit";
import ContractualEdit from '../pages/contracts/ContractualEdit'
import ContractualEditShow from '../pages/contracts/ContractualEditShow'
import ContractsTermination from "../pages/contracts/ContractsTermination";
import ContractRestore from "../pages/contracts/ContractRestore";
import UpdateCommissions from "../pages/contracts/UpdateCommissions";

// Billing
import BillingShow from "../pages/billing/BillingShow";
import BillingEdit from "../pages/billing/BillingEdit";
import BillingReembolso from "../pages/billing/BillingReembolso";

// Announcements
import AnnouncementsIndex from "../pages/announcements/AnnouncementsIndex";
import AnnouncementsShow from "../pages/announcements/AnnouncementsShow";
import AnnouncementsEdit from "../pages/announcements/AnnouncementsEdit";

// Crawler
import CrawlerIndex from "../pages/crawler/CrawlerIndex";
import CrawlerShow from "../pages/crawler/CrawlerShow";
import CrawlerEdit from "../pages/crawler/CrawlerEdit";

// Crawler Config
import CrawlerConfigIndex from "../pages/crawlerConfig/crawlerConfigIndex";
import CrawlerConfigShow from "../pages/crawlerConfig/crawlerConfigShow";
import CrawlerConfigEdit from "../pages/crawlerConfig/crawlerConfigEdit";
import CrawlerConfigNew from "../pages/crawlerConfig/crawlerConfigNew";
import HSMConfig from "../pages/crawlerConfig/HSMConfig";
import SMSConfig from "../pages/crawlerConfig/SMSConfig";

// Public Links
import PublicLinksShow from "../pages/publicLinks/PublicLinksShow";
import PublicLinksIndex from "../pages/publicLinks/PublicLinksIndex";

// Vouchers
import VouchersIndex from "../pages/vouchers/VouchersIndex";
import VouchersNew from "../pages/vouchers/VouchersNew";
import VouchersShow from "../pages/vouchers/VouchersShow";

// Notifications
import NotificationsIndex from "../pages/notifications/NotificationsIndex";
import NotificationsShow from "../pages/notifications/NotificationsShow";

// Dashboard
import Dashboard from "../pages/dashboard/index";

// Negotiation
import NegotiationIndex from "../pages/negotiation/NegotiationIndex";
import NegotiationNew from "../pages/negotiation/NegotiationNew";
import NegotiationShow from "../pages/negotiation/NegotiationShow";
import NegotiationEdit from "../pages/negotiation/NegotiationEdit";
import NegotiationLaudo from "../pages/negotiation/NegotiationLaudo";
import NegotiationLaudoAddTenant from "../pages/negotiation/NegotiationLaudoAddTenant";
import NegotiationLaudoEditTenant from "../pages/negotiation/NegotiationLaudoEditTenant";
import NegotiationLaudoHistorico from "../pages/negotiation/NegotiationLaudoHistorico";

// Affiliate
import AffiliateIndex from "../pages/affiliate/AffiliateIndex";

// Error
import ErrorPage1 from "../pages/errors/ErrorPage1";
import ErrorPage2 from "../pages/errors/ErrorPage2";
import ErrorPage3 from "../pages/errors/ErrorPage3";

// Chat
import Chat from "../pages/chat/Chat";

// Template
import TemplateIndex from "../pages/Templates/TemplateIndex";
import TemplateSMSShow from "../pages/Templates/sms/smsShow";
import TemplateSMSEdit from "../pages/Templates/sms/smsEdit";

// Gerador Contrato
import GeradorContratoIndex from "../pages/geradorcontrato/GeradorContratoIndex";
import GeradorContratoEdit from "../pages/geradorcontrato/GeradorContratoEdit";
import BillingReembolsoShow from "../pages/billing/BillingReembolsoShow";

// Pagamento
// import HistoricoIndex from "../pages/pagamento/historico/HistoricoIndex";
import CobrancaIndex from "../pages/pagamento/cobranca/CobrancaIndex";
import NegotiationLaudoInquilino from "../pages/negotiation/NegotiationLaudoInquilino";
import NegativacaoIndex from "../pages/pagamento/negativacao/NegativacaoIndex";
import NegativacaoShow from "../pages/pagamento/negativacao/NegativacaoShow";

// Security
import SecuritizationIndex from "../pages/pagamento/securitization/SecuritizationIndex";

// Property Inspection
import PropertyInspectionShow from "../pages/property-inspection/PropertyInspectionShow";
import PropertyInspectionIndex from "../pages/property-inspection/PropertyInspectionIndex";
import PropertyInspectionEdit from "../pages/property-inspection/PropertyInspectionEdit";

// Cupons
import CouponsIndex from "../pages/coupons/CouponsIndex";
import CouponsNew from "../pages/coupons/CouponsNew";
import CouponsShow from "../pages/coupons/CouponsShow";
import CouponsEdit from "../pages/coupons/CouponsEdit";

//Índices
import IndicesIndex from '../pages/pagamento/indices/IndicesIndex'
import ContractsConfirmTermination from "../pages/contracts/ContractsConfirmTermination";
import FriendlyAgreement from "../pages/contracts/FriendlyAgreement";
import WebhookErrorShow from "../pages/billing/WebhookErrorShow";

import docUserShow from "../pages/doc-users/docUserShow";
import docUserIndex from "../pages/doc-users/docUserIndex";
import friendlyAgreementIndex from "../pages/friendly-agreement/friendlyAgreementIndex";
import OcorrenciaShow from "../pages/negotiation/OcorrenciaShow";
import AnaliseIndex from "../pages/analise-financeira/AnaliseIndex";
import SecuritizationCreate from "../pages/pagamento/securitization/ShipmentCreate";
import SecuritizationShipmentShow from "../pages/pagamento/securitization/SecuritizacaoShipmentShow";
import ReturnPreview from "../pages/pagamento/securitization/ReturnPreview";
import SecuritizationReturnShow from "../pages/pagamento/securitization/SecuritizationReturnShow";

//Gratificações
import GratificationIndex from "../pages/pagamento/gratifications/GratificationIndex";
import GratificationCreate from "../pages/pagamento/gratifications/GratificationCreate";
import GratificationShow from "../pages/pagamento/gratifications/GratificationShow";
import GratificationEdit from "../pages/pagamento/gratifications/GratificationEdit";

// Antecipações
import NewAnticipation from "../pages/users/anticipations/NewAnticipation";
import AnticipationShow from "../pages/users/anticipations/AnticipationShow";

const authProtectedRoutes = [
	{ path: "/dashboard", component: Dashboard },

	// Users
	{ path: "/users/index", component: UsersIndex },
	{ path: "/users/show/:id", component: UsersShow },
	{ path: "/users/edit/:id", component: UsersEdit },
	{ path: "/users/new", component: UsersNew },

	// Income User
	{ path: "/users/income/:id", component: IncomeUserEdit },

	// Bank Account
	{ path: "/bank-account/edit/:id", component: BankAccountEdit },

	// Account
	{ path: "/account/edit/:id", component: AccountEdit },

	// Contracts
	{ path: "/contracts/index", component: ContractsIndex },
	{ path: "/contracts/show/:id", component: ContractsShow },
	{ path: "/contracts/edit/:id", component: ContractsEdit },
	{ path: "/contracts/contractual-edit/:id", component: ContractualEdit },
	{ path: "/show/contractual-edit/:id", component: ContractualEditShow },
	{ path: "/contracts/termination/:id", component: ContractsTermination },
	{ path: "/contracts/termination-confirm/:id", component: ContractsConfirmTermination },
	{ path: "/contracts/restore/:id", component: ContractRestore },
	{ path: "/contracts/friendly-agreement/:id", component: FriendlyAgreement },
	{ path: "/contracts/editar-comissoes/:id", component: UpdateCommissions },

	// Analises
	{ path: "/analise/index", component: AnaliseIndex },
	// { path: "/Analise/show/:id", component: AnaliseShow },

	// Billing
	{ path: "/billing/edit/:id", component: BillingEdit },
	{ path: "/billing/reembolso/show/:id", component: BillingReembolsoShow },
	{ path: "/billing/reembolso/:id", component: BillingReembolso },
	{ path: "/billing/show/:id", component: BillingShow },
	{ path: "/webhook-error/show/:id", component: WebhookErrorShow },

	// Announcements
	{ path: "/announcements/index", component: AnnouncementsIndex },
	{ path: "/announcements/show/:id", component: AnnouncementsShow },
	{ path: "/announcements/edit/:id", component: AnnouncementsEdit },

	// Crawler
	{ path: "/crawler/index", component: CrawlerIndex },
	{ path: "/crawler/show/:id", component: CrawlerShow },
	{ path: "/crawler/edit/:id", component: CrawlerEdit },

	// Crawler Config
	{ path: "/crawlerConfig/index", component: CrawlerConfigIndex },
	{ path: "/crawlerConfig/show/:name", component: CrawlerConfigShow },
	{ path: "/crawlerConfig/edit/:name", component: CrawlerConfigEdit },
	{ path: "/crawlerConfig/new", component: CrawlerConfigNew },
	{ path: "/crawlerConfig/hsm", component: HSMConfig },
	{ path: "/crawlerConfig/sms", component: SMSConfig },

	// Public Links
	{ path: "/public-links/index", component: PublicLinksIndex },
	{ path: "/public-links/show/:id", component: PublicLinksShow },

	// Public Links
	{ path: "/gerador-contrato/index", component: GeradorContratoIndex },
	{ path: "/gerador-contrato/edit/:id", component: GeradorContratoEdit },

	// Vouchers
	{ path: "/vouchers/index", component: VouchersIndex },
	{ path: "/vouchers/show/:id", component: VouchersShow },
	{ path: "/vouchers/new", component: VouchersNew },

	// Notifications
	{ path: "/notifications/index", component: NotificationsIndex },
	{ path: "/notifications/show/:id", component: NotificationsShow },

	// Negotiation
	{ path: "/negotiation/index", component: NegotiationIndex },
	{ path: "/negotiation/new", component: NegotiationNew },
	{ path: "/negotiation/show/:id", component: NegotiationShow },
	{ path: "/negotiation/edit/:id", component: NegotiationEdit },
	{ path: "/negotiation/laudo/inquilino/:id/:inquilinoId", component: NegotiationLaudoInquilino },
	{ path: "/negotiation/laudo/addtenant/:id", component: NegotiationLaudoAddTenant },
	{ path: "/negotiation/laudo/:id/tenant/:tenantId", component: NegotiationLaudoEditTenant },
	{ path: "/negotiation/laudo/historico/:id", component: NegotiationLaudoHistorico },
	{ path: "/negotiation/laudo/:id", component: NegotiationLaudo },

	// Ocorrencias
	{ path: "/ocorrencias/show/:id/:negotiationId", component: OcorrenciaShow },


	// Affiliate
	// { path: "/affiliate/index", component: AffiliateIndex },

	// Chat
	{ path: "/chat", component: Chat },

	// Pagamento
	{ path: "/pagamento/cobrancas/index", component: CobrancaIndex },

	{ path: "/pagamento/negativacao/index", component: NegativacaoIndex },
	{ path: "/pagamento/negativacao/show/:id/:negativationId", component: NegativacaoShow },

	// Error
	{ path: "/error/error-v1", component: ErrorPage1 },
	{ path: "/error/error-v2", component: ErrorPage2 },
	{ path: "/error/error-v3", component: ErrorPage3 },

	//template
	{ path: "/templates/index", component: TemplateIndex },
	{ path: "/templates/sms/show/:id", component: TemplateSMSShow },
	{ path: "/templates/sms/edit/:id", component: TemplateSMSEdit },

	// Pagamento
	// { path: "/pagamento/historico/index", component: HistoricoIndex },
	{ path: "/pagamento/cobrancas/index", component: CobrancaIndex },

	// Property Inspection
	{ path: "/property-inspection/show/:id", component: PropertyInspectionShow },
	{ path: "/contracts/property-inspection/show/:id", component: PropertyInspectionIndex },
	{ path: "/contracts/property-inspection/edit/:id", component: PropertyInspectionEdit },

	// Cupons
	{ path: "/coupons/index", component: CouponsIndex },
	{ path: "/coupons/new", component: CouponsNew },
	{ path: "/coupons/show/:id", component: CouponsShow },
	{ path: "/coupons/edit/:id", component: CouponsEdit },

	//Indices
	{ path: "/pagamento/indices/index", component: IndicesIndex },

	// Security
	{ path: "/pagamento/securitizacao/index", component: SecuritizationIndex },
	{ path: "/pagamento/securitizacao/new", component: SecuritizationCreate },
	{ path: "/pagamento/securitizacao/remessa/show/:id", component: SecuritizationShipmentShow },
	{ path: "/pagamento/securitizacao/retorno/preview/:id", component: ReturnPreview },
	{ path: "/pagamento/securitizacao/retorno/show/:id", component: SecuritizationReturnShow },


	// Gratifications
	{ path: "/pagamento/gratificacao/index", component: GratificationIndex },
	{ path: "/pagamento/gratificacao/new", component: GratificationCreate },
	{ path: "/pagamento/gratificacao/show/:id", component: GratificationShow },
	{ path: "/pagamento/gratificacao/edit/:id", component: GratificationEdit },

	//
	{ path: "/doc-user/show/:id", component: docUserShow },
	{ path: "/doc-user/index", component: docUserIndex },

	//
	{ path: "/friendly-agreement/index", component: friendlyAgreementIndex },

	//
	{ path: "/anticipations/new/:id", component: NewAnticipation },
	{ path: "/anticipations/show/:id", component: AnticipationShow },

	// this route should be at the end of all other routes
	{ path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
];

const publicRoutes = [
	{ path: "/logout", component: Logout },
	{ path: "/login", component: Login },
];

export { authProtectedRoutes, publicRoutes };
