import React, { Component } from 'react';
import { Link, Redirect } from "react-router-dom";
import { Container, Card, CardBody, Col, Label, Button } from "reactstrap";
import { Checkbox, FormHelperText, Input, TextField } from "@material-ui/core";
import { Formik } from "formik";
import BetterCircularProgress from '../../../components/BetterCircularProgress';
import Breadcrumbs from '../../../components/Breadcrumb';
import BetterAlerts from '../../../components/BetterAlerts';
import BetterSelect from '../../../components/BetterSelect';
import { get as getUser, update } from '../../../crud/users.crud';
import moment from 'moment';
import { getAccountVerification, createAccountVerification, updateAccountVerification, getDocumentStatus, sendFileAsaasDirectly } from '../../../crud/account.crud';
import { buscaCep, formatCNPJInput, formatCPFInput, getStates, limparCNPJ, limparCPF } from '../../../helpers/Utils';
import InputMask from 'react-input-mask';

class AccountEdit extends Component {

	state = {
		loading: false,
		activeTab: '1',
		user: null,
		accountVerification: null,
		account: null,
		accountData: null,
		properties: null,
		raw: null,
		success: [],
		error: [],
		typeEmpresa: [
			{
				label: 'Micro Empreendedor Individual (MEI)',
				value: 'MEI'
			},
			{
				label: 'Empresa Limitada',
				value: 'LIMITED'
			},
			{
				label: 'Empresa Individual',
				value: 'INDIVIDUAL'
			},
			{
				label: 'Associação',
				value: 'ASSOCIATION'
			},
		],
		typeAccount: [
			{
				label: 'Pessoa física (PF)',
				value: 'PF'
			},
			{
				label: 'Pessoa Jurídica (CNPJ)',
				value: 'PJ'
			}
		],
		documents: []
	}

	componentDidMount = async () => {
		this.setState({ ...this.state, loading: true });

		await getUser(this.props.match.params.id).catch(err => {
			alert('An error occurred, please contact the support.');
			this.setState({ loading: false });
		}).then(res => {
			if (res?.status === 200) {
				if (res.data) {
					this.setState({ ...this.state, user: res.data.data });
				}

				getAccountVerification(this.props.match.params.id).then(res => {
					if (res?.status === 200) {
						if (res.data) {
							this.setState({ accountVerification: res.data.data });
						}
					}
				}).catch(err => {
					this.setState({ loading: false });
				});
			}
		});

		await getAccountVerification(this.props.match.params.id).then(res => {
			if (res?.status === 200) {
				if (res.data) {
					this.setState({ account: true, accountData: res?.data?.data });
				}
			}
		}).catch(() => {
			this.setState({ loading: false });
			// alert('Ocorreu um erro, entre em contato com o suporte.');
		});

		await getDocumentStatus(this.props.match.params.id).then(async res => {
			if (res?.status === 200) {
				if (res.data) {
					console.log(res.data.data);
					this.setState({ documents: res?.data?.data?.data });
				}
			}
		})

		this.setState({ loading: false });
	};

	handleChangeVerified = () => {
		this.setState({ ...this.state, loading: true });

		const userData = {
			verified: !this.state.user.verified
		}

		update(this.state.user.id, userData).then(({ data }) => {
			this.setState({ ...this.state, user: data.data });
		}).catch(() => {

		}).finally(() => {
			this.setState({ ...this.state, loading: false });
		});
	}

	onSubmit = (values) => {
		this.setState({ ...this.state, loading: true });

		let userData = {
			type_account: values.accountType,
			name: values.name,
			address: {
				zip_code: limparCPF(values.zip_code),
				state: values.state,
				city: values.city,
				street: values.address_line_one,
				number: values.building_number,
				complement: values.complement,
				neighbourhood: values.neighborhood,
			}
		}

		let dataVerificacaoAsaas = {
			user_id: this.state.user.id,
			accountType: values.accountType,
			name: values.name,
			addressNumber: values.building_number,
			postalCode: limparCPF(values.zip_code),
			address: values.address_line_one,
			complement: values.complement,
			province: values.neighborhood,
			city: values.city,
			state: values.state,
			email: this.state.user?.email,
			phone: this.state.user?.phone
		}

		if (values.accountType == 'PJ') {
			userData.cnpj = limparCNPJ(values.cnpj);
			userData.companyType = values.typeEmpresa;
			dataVerificacaoAsaas.companyType = values.typeEmpresa ? values.typeEmpresa : this.state?.accountData?.data.companyType;
			dataVerificacaoAsaas.cpfCnpj = limparCNPJ(values.cnpj);
		} else if (values.accountType == 'PF') {
			userData.cpf = limparCPF(values.cpf);
			dataVerificacaoAsaas.cpfCnpj = limparCPF(values.cpf);
			userData.birthdate = moment(values.birthdate, 'DD-MM-YYYY').add(3, "hours").format('YYYY-MM-DD');
			dataVerificacaoAsaas.birthdate = moment(values.birthdate, 'DD-MM-YYYY').add(3, "hours").format('YYYY-MM-DD');
		}

		update(this.state.user.id, userData).then((res) => {
		}).catch((err) => {
			this.setState({ loading: false, error: ['Erro ao atualizar sua conta!'] });
			return;
		});

		if (this.state?.accountData) {
			updateAccountVerification(this.state.user.id, dataVerificacaoAsaas).then((res) => {
				this.setState({ loading: false, success: ['Perfil atualizado!'] });
				return;
			}).catch((err) => {
				this.setState({ loading: false, error: ['Erro ao atualizar a verificação de perfil!'] });
				return;
			});
		} else {
			createAccountVerification(dataVerificacaoAsaas).then((res) => {
				this.setState({ loading: false, success: ['Verificação de perfil iniciada, use a url na aba Verificação de perfil para enviar os documentos!'] });
				return
			}).catch(err => {
				console.log(err);
				this.setState({ loading: false, error: ['Erro ao criar conta de verificação de perfil!'] });
			})
		}

		console.log(values.anexos);
		if (values.anexos.length > 0) {
			console.log(`UPDALOADING DOCUMENT ASAAS`);
			const typeCustom = this.state.documents.find(obj => obj.type === 'CUSTOM')

			const id = typeCustom?.id;

			const formData = new FormData();
			for (const f of values.anexos) {
				console.log(f);
				formData.append('file', f);
			}
			formData.append('id', id);
			formData.append('type', 'CUSTOM');

			sendFileAsaasDirectly(this.state.user.id, formData).catch(err => {
				console.log(err);
				this.setState({ loading: false, error: ['Erro  no envio de documentos extras para a verificação de perfil!'] });
			}).then(res => {
				console.log(res);
			})
		}

		this.setState({ ...this.state, loading: false });
	};

	getInitialValues = () => {
		let data; let birthdate;

		if (this.state?.user?.birthdate !== null) {
			data = new Date(this.state?.user?.birthdate)
			birthdate = this.state?.user?.birthdate && moment(data).add(3, "hours").format('DD-MM-YYYY')
		}

		return {
			name: this.state?.user?.name ? this.state?.user?.name : '',
			cpf: this.state?.user?.cpf ? this.state?.user?.cpf : '',
			birthdate: birthdate ? birthdate : '',

			address_line_one: this.state?.user?.address?.street ?? '',
			building_number: this.state?.user?.address?.number ?? '',
			complement: this.state?.user?.address?.complement ?? '',
			zip_code: this.state?.user?.address?.zip_code ?? '',
			neighborhood: this.state?.user?.address?.neighbourhood ?? '',
			city: this.state?.user?.address?.city ?? '',
			state: this.state?.user?.address?.state ?? '',

			accountType: this.state?.user?.type_account ? this.state?.user?.type_account : '',
			cnpj: this.state?.user?.cnpj ? this.state?.user?.cnpj : '',
			typeEmpresa: this.state?.user?.companyType ? this.state?.user?.companyType : '',
			anexos: []
		};
	}

	getStatuDocumentVerificacion = (status) => {
		if (status === 'pending') {
			return (
				<button disabled style={{ width: '90px', height: '24px', borderRadius: '2px', border: 0, marginLeft: '25px', marginBottom: '8px', backgroundColor: '#FCB92C', color: '#FFF' }}>
					Em análise
				</button>
			);
		}

		if (status === 'completed') {
			return (
				<button disabled style={{ width: '149px', height: '24px', borderRadius: '2px', border: 0, marginLeft: '25px', marginBottom: '8px', backgroundColor: '#1CBB8C', color: '#FFF' }}>
					Aprovado/verificado
				</button>
			);
		}

		if (status === 'canceled') {
			return (
				<button disabled style={{ width: '149px', height: '24px', borderRadius: '2px', border: 0, marginLeft: '25px', marginBottom: '8px', backgroundColor: '#FF3D60', color: '#FFF' }}>
					Recusado
				</button>
			);
		}

		else {
			return (
				<button disabled style={{ width: '100px', height: '24px', borderRadius: '2px', border: 0, marginLeft: '25px', marginBottom: '8px', backgroundColor: '#8590A5', color: '#FFF' }}>
					Não iniciada
				</button>
			);
		}
	};

	render() {
		return (
			<React.Fragment>
				<BetterCircularProgress loading={this.state.loading}>
					<div className="page-content container-page">
						<Container fluid>
							<Breadcrumbs title="Visualizar usuário" breadcrumbItems={[]} />
							<BetterAlerts errors={this.state.error} success={this.state.success} />
							<Card>
								<CardBody>
									{this.state.success.length > 0 ?
										<Redirect to={{ pathname: `/users/show/${this.props.match.params.id}`, state: { success: ['Conta de verificação criada com sucesso!'] } }} />
										: null
									}

									{this.state.error.length > 0 ?
										<Redirect to={{ pathname: `/users/show/${this.props.match.params.id}`, state: { error: [this.state.error] } }} />
										: null
									}
									<Formik initialValues={this.getInitialValues()} onSubmit={(values) => this.onSubmit(values)}>
										{({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => {
											return (
												<>
													<div className="row">
														<Col md={12} className="mb-3">
															<Label className="col-md-4 col-form-label" style={{ color: '#343A40', fontSize: '13px' }}>Tipo de conta</Label>

															<Col md={4}>
																<select style={{ background: "#FFF", height: "40px" }} name='accountType'
																	onChange={handleChange} onBlur={handleBlur} value={values.accountType} className="custom-select">
																	<option key={null} value={null} hidden>Selecione o tipo de conta</option>
																	{[...this.state.typeAccount].map((obj) => <option key={obj.label} value={obj.value}>{obj.label}</option>)}
																</select>
																{(touched.accountType && errors.accountType) ?
																	<FormHelperText className="ml-3" error>{'O campo tipo de conta é obrigatório.'}</FormHelperText> : null
																}
															</Col>
														</Col>

														<Col md={12} className="mb-3">
															<Label className="col-md-4 col-form-label" style={{ color: '#343A40', fontSize: '13px' }}>{values.accountType == 'PJ' ? 'Nome da empresa' : 'Nome completo do usuário'}</Label>
															<Col md={4}>
																<TextField style={{ background: "#FFF", height: "40px" }} name='name' variant="outlined" value={values.name} onBlur={handleBlur} placeholder="Nome completo" onChange={handleChange} />
															</Col>
														</Col>

														<Col md={12} className="mb-3">
															<Label className="col-md-4 col-form-label" style={{ color: '#343A40', fontSize: '13px' }}>{values.accountType == 'PJ' ? 'CNPJ' : 'CPF'}</Label>
															<Col md={4}>
																{values.accountType == 'PJ' ? (
																	<TextField style={{ background: "#FFF", height: "40px" }} name='cnpj' inputProps={{ maxLength: 18 }} variant="outlined" value={values.cnpj} onBlur={handleBlur} placeholder="CNPJ" onChange={(e) => handleChange(formatCNPJInput(e))} />
																) : (
																	<TextField style={{ background: "#FFF", height: "40px" }} inputProps={{ maxLength: 14 }} name='cpf' variant="outlined" value={values.cpf} onBlur={handleBlur} placeholder="CPF" onChange={(e) => handleChange(formatCPFInput(e))} />
																)}
															</Col>
														</Col>

														{values.accountType == 'PJ' ? (
															<Col md={12} className="mb-3">
																<Label className="col-md-4 col-form-label" style={{ color: '#343A40', fontSize: '13px' }}>Tipo de empresa</Label>

																<Col md={4}>
																	<select style={{ background: "#FFF", height: "40px" }} name='typeEmpresa'
																		onChange={handleChange} onBlur={handleBlur} value={values.typeEmpresa} className="custom-select">
																		<option key={null} value={null} hidden>Selecione o tipo de empresa</option>
																		{[...this.state.typeEmpresa].map((obj) => <option key={obj.label} value={obj.value}>{obj.label}</option>)}
																	</select>
																	{(touched.typeEmpresa && errors.typeEmpresa) ?
																		<FormHelperText className="ml-3" error>{'O campo tipo de empresa é obrigatório.'}</FormHelperText> : null
																	}
																</Col>
															</Col>
														) : (
															<Col md={12} className="mb-3">
																<Label className="col-md-4 col-form-label" style={{ color: '#343A40', fontSize: '13px' }}>Data de nascimento (PF)</Label>

																<Col md={4}>
																	<InputMask mask="99/99/9999" placeholder="00/00/0000" onBlur={handleBlur} name="birthdate" value={values.birthdate} onChange={handleChange}>
																		{(inputProps) => <TextField  {...inputProps} style={{ background: "#FFF", height: "40px" }} type="text" variant="outlined" helperText={touched.birthdate && errors.birthdate} error={Boolean(touched.birthdate && errors.birthdate)} />}
																	</InputMask>
																</Col>
															</Col>
														)}

														<Col md={12} className="mb-3">
															<Label className="col-md-4 col-form-label" style={{ color: '#343A40', fontSize: '13px' }}>CEP</Label>
															<Col md={4}>
																<TextField style={{ background: "#FFF", height: "40px" }} name='zip_code' variant="outlined"
																	value={values.zip_code} onBlur={handleBlur} placeholder="CEP" inputProps={{ maxLength: 9 }}
																	onChange={(e) => { handleChange(buscaCep(e, setFieldValue, 'address_line_one', 'neighborhood', 'city', 'state')) }}
																/>
															</Col>
														</Col>

														<Col md={12} className="mb-3">
															<Label className="col-md-4 col-form-label" style={{ color: '#343A40', fontSize: '13px' }}>Estado</Label>
															<Col md={4}>
																<BetterSelect name="state" blankOption value={values.state} onBlur={handleBlur} onChange={handleChange} listItem={getStates()}
																	helperText={touched.state && errors.state} error={Boolean(touched.state && errors.state)}>
																</BetterSelect>
															</Col>
														</Col>

														<Col md={12} className="mb-3">
															<Label className="col-md-4 col-form-label" style={{ color: '#343A40', fontSize: '13px' }}>Cidade</Label>
															<Col md={4}>
																<TextField style={{ background: "#FFF", height: "40px" }} name='city' variant="outlined"
																	value={values.city} onBlur={handleBlur} placeholder="Cidade" onChange={handleChange} />
															</Col>
														</Col>

														<Col md={12} className="mb-3">
															<Label className="col-md-4 col-form-label" style={{ color: '#343A40', fontSize: '13px' }}>Bairro</Label>
															<Col md={4}>
																<TextField style={{ background: "#FFF", height: "40px" }} name='neighborhood' variant="outlined"
																	value={values.neighborhood} onBlur={handleBlur} placeholder="Bairro" onChange={handleChange} />
															</Col>
														</Col>

														<Col md={12} className="mb-3">
															<Label className="col-md-4 col-form-label" style={{ color: '#343A40', fontSize: '13px' }}>Endereço</Label>
															<Col md={4}>
																<TextField style={{ background: "#FFF", height: "40px" }} name='address_line_one' variant="outlined"
																	value={values.address_line_one} onBlur={handleBlur} placeholder="Endereço" onChange={handleChange} />
															</Col>
														</Col>

														<Col md={12} className="mb-3">
															<Label className="col-md-4 col-form-label" style={{ color: '#343A40', fontSize: '13px' }}>Número</Label>
															<Col md={4}>
																<TextField style={{ background: "#FFF", height: "40px" }} name='building_number' variant="outlined"
																	value={values.building_number} onBlur={handleBlur} placeholder="Número" onChange={handleChange} />
															</Col>
														</Col>

														<Col md={12}>
															<Label className="col-md-4 col-form-label" style={{ color: '#343A40', fontSize: '13px' }}>Complemento</Label>
															<Col md={4}>
																<TextField style={{ background: "#FFF", height: "40px" }} name='complement' variant="outlined"
																	value={values.complement} onBlur={handleBlur} placeholder="Complemento" onChange={handleChange} />
															</Col>
														</Col>

														{this.state.documents.some(obj => obj.type === 'CUSTOM') ? (
															<Col md={12}>
																<Label className="col-md-4 col-form-label" style={{ color: '#343A40', fontSize: '13px' }}>Documento extra</Label>
																<Col md={4}>
																	<label type="button" cursor="pointer" htmlFor="anexos" className='btn button-anexar'>
																		<Input type="file" accept=".png,.jpeg,.jpg,.pdf" id="anexos" name='anexos' multiple className='d-none' onChange={(event) => {
																			setFieldValue('anexos', [...values.anexos, ...event.currentTarget.files]); event.target.value = "";
																		}} />
																		<label htmlFor="anexos" className='mb-0' cursor="pointer">Anexar</label>
																	</label>

																	{values?.anexos?.map((anexo, index) =>
																		<label key={index} className="label-anexo">
																			{anexo.name}

																			<i className="fa fa-times mr-2" onClick={() => {
																				let anexosArray = values.anexos;
																				anexosArray.splice(index, 1);
																				setFieldValue('anexos', anexosArray);
																			}} />
																		</label>
																	)}
																</Col>
															</Col>
														) : null}
													</div>

													<div className="row ml-1" style={{ marginTop: '100px' }}>
														<Col md={6}>
															<Link to={`/users/show/${this.props.match.params.id}`} className="btn btn-secondary btn-bold"><i className="fa fa-arrow-left mr-2"></i>Voltar</Link>
															<Button color="success" type="submit" className="waves-effect waves-light ml-3" style={{ width: '158px' }} onClick={handleSubmit}>
																Salvar
															</Button>
														</Col>
													</div>
												</>
											);
										}}
									</Formik>
								</CardBody>
							</Card>
						</Container>
					</div>
				</BetterCircularProgress>
			</React.Fragment>
		)
	}
}

export default AccountEdit;