/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable eqeqeq */
import React, { Component } from 'react';
import { Container, Card, Col, Label, Input, Row, FormGroup } from "reactstrap";
import BetterCircularProgress from '../../components/BetterCircularProgress';
import { get as getContract } from '../../crud/contracts.crud';
import Breadcrumbs from '../../components/Breadcrumb';
import { Formik } from "formik";
import { validateContractTermination } from '../../helpers/FormValidators';
import { TextField } from "@material-ui/core";
import BetterAlerts from '../../components/BetterAlerts';
import moment from 'moment';
import { formatBRLInput } from '../negotiation/helpers';
import { formatBRL, formatCPFInput, formatTelefoneInput, limparMoeda } from '../../helpers/Utils';
import { addFileTermination, createTermination, getSimulation } from '../../crud/termination.crud';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faCalendar, faCalendarXmark, faCheck, faFileImport } from '@fortawesome/pro-regular-svg-icons';
import { faArrowRight, faArrowRightFromBracket, faCalendarCircleMinus, faChevronDown, faFileExclamation, faFileSlash, faMoneyBillTransfer, faPerson, faShieldExclamation, faUser, faXmark, faCalendarXmark as faCalendarXmarkSolid, faCalendar as faCalendarSolid } from '@fortawesome/pro-solid-svg-icons';
import { Redirect } from "react-router-dom";

class ContractsTermination extends Component {

	state = {
		loading: false,
		data: null,
		errors: [],
		editValues: false,
		step: 'FIRST_STEP',
		renovation: false,
		simulation: {},
		multaRescisoriaMaximo: 0,
		multaQuebraContratualMaximo: 0,
		usufruidoMaximo: 0,
		reembolsosMaximo: 0,
		arquivosAbandono: [],
		arquivos: [],
		success: null,
		disable: false,
		redirect: false
	}

	numeroParcelas = () => {
		let parcelas = [
			{ id: 2, label: '02 parcelas' },
			{ id: 3, label: '03 parcelas' },
			{ id: 4, label: '04 parcelas' },
			{ id: 5, label: '05 parcelas' },
			{ id: 6, label: '06 parcelas' },
			{ id: 7, label: '07 parcelas' },
			{ id: 8, label: '08 parcelas' },
			{ id: 9, label: '09 parcelas' },
			{ id: 10, label: '10 parcelas' },
			{ id: 11, label: '11 parcelas' },
			{ id: 12, label: '12 parcelas' },
			{ id: 13, label: '13 parcelas' },
			{ id: 14, label: '14 parcelas' },
			{ id: 15, label: '15 parcelas' },
			{ id: 16, label: '16 parcelas' },
			{ id: 17, label: '17 parcelas' },
			{ id: 18, label: '18 parcelas' },
			{ id: 19, label: '19 parcelas' },
			{ id: 20, label: '20 parcelas' },
			{ id: 21, label: '21 parcelas' },
			{ id: 22, label: '22 parcelas' },
			{ id: 23, label: '23 parcelas' },
			{ id: 24, label: '24 parcelas' }
		]

		if (this.state.simulation?.overdue_payments?.value == 0) {
			parcelas.unshift({ id: 1, label: '01 parcela' })
		}

		return parcelas;
	};

	guaranteedInstallmentsRefund = () => {
		const guaranteedBills = this.state?.simulation?.retroactive_billings_guaranteed?.billings?.length;

		let parcelas = [
			{ id: 1, label: '01 parcela' },
			{ id: 2, label: '02 parcelas' },
			{ id: 3, label: '03 parcelas' },
			{ id: 4, label: '04 parcelas' },
			{ id: 5, label: '05 parcelas' },
			{ id: 6, label: '06 parcelas' },
			{ id: 7, label: '07 parcelas' },
			{ id: 8, label: '08 parcelas' },
			{ id: 9, label: '09 parcelas' },
			{ id: 10, label: '10 parcelas' },
		];

		if (guaranteedBills && guaranteedBills > 0) {
			const maxInstallments = 6;
			parcelas = parcelas.slice(0, maxInstallments);
		}

		return parcelas;
	};

	componentDidMount = async () => {
		this.setState({ loading: true });
		await this.handleGetSimulation();

		getContract(this.props.match.params.id).catch(err => {
			alert('An error occurred, please contact the support.');
		}).then(async res => {
			if (res?.status == 200) {
				if (res.data) {
					let renovation = false;
					if (res.data.data?.entity?.details?.renovation_info) {
						renovation = true;
					}

					let rent = res.data.data?.entity?.details?.values?.total;

					this.setState({ multaQuebraContratualMaximo: parseFloat(rent) * 3 })

					this.setState({ data: res.data.data, renovation });
					let contract = res.data.data;

					if (contract.entity.is_terminated) {
						this.setState({ errors: ['O contrato já está terminado.'] });
					};
				}
			}
		});

		this.setState({ loading: false })
	};

	handleGetSimulation = async (end_date, setFieldValue, termination_requested_by, type, remain_in_property) => {
		let form = {}

		if (end_date) {
			form.end_date = end_date;
		}

		if (termination_requested_by) {
			form.termination_requested_by = termination_requested_by;
		}

		if (type) {
			form.type = type
		}

		if (this.state.renovation) {
			form.renovation_info = true;
		}

		if (remain_in_property === 'no') {
			form.retroactive = true;
		} else {
			form.retroactive = false;
		}

		await getSimulation(this.props.match.params.id, form).then(({ data }) => {

			let multaMaxima = formatBRL(data.termination_fine.value);
			multaMaxima = limparMoeda(multaMaxima);
			multaMaxima = parseFloat(multaMaxima);

			this.setState({ simulation: data, multaRescisoriaMaximo: multaMaxima, usufruidoMaximo: data?.rent_enjoyed?.value, reembolsosMaximo: data?.repayments?.value ? Math.abs(data?.repayments?.value) : 0 });

			this.setState({ multaQuebraContratualMaximo: data?.contract_violation_fine?.value })

			if (setFieldValue) {
				setFieldValue('multaRescisoriaMaximo', formatBRL(multaMaxima || 0))
				setFieldValue('multaRescisoria', formatBRL(data.termination_fine.value || 0))
			}

			if (setFieldValue) {
				setFieldValue('multaQuebraContratualMaximo', formatBRL(data?.contract_violation_fine?.value || 0))
				setFieldValue('multaQuebraContratual', formatBRL(data?.contract_violation_fine?.value || 0))
			}

			if (setFieldValue) {
				setFieldValue('aluguelUsufruido', formatBRL(data?.rent_enjoyed?.value || 0))
				setFieldValue('multaRescisoria', formatBRL(data?.termination_fine?.value || 0))
			}
		}).catch(({ response }) => {
		}).finally(() => {
			this.setState({ loading: false });
		});
	}

	submitRequest = (form, values) => {
		this.setState({ disable: true })
		createTermination(form).then(async res => {
			if (res?.status == 200) {
				this.setState({ success: true, disable: false })

				if (this.state.arquivos.length > 0) {
					let formData = new FormData();
					formData.append('type', 'details');

					for (let i = 0; i < this.state.arquivos.length; i++) {
						formData.append(`files`, this.state.arquivos[i]);
					}

					try {
						await addFileTermination(res.data.response.id, formData);
					} catch (e) {
					} finally {
					}
				}

				if (form.details == 'Abandono do imóvel por parte do inquilino.' && this.state.arquivosAbandono.length > 0) {
					let formData = new FormData();
					formData.append('type', 'evidences');

					for (let i = 0; i < this.state.arquivosAbandono.length; i++) {
						formData.append(`files`, this.state.arquivosAbandono[i]);
					}

					try {
						await addFileTermination(res.data.response.id, formData);
					} catch (e) {
					} finally {
					}
				}
			}
		}).catch(({ response }) => {
			this.setState({ disable: false });

			if (response?.data?.message) {
				this.setState({ errors: [...this.state.errors, response?.data?.message] });
			} else {
				this.setState({ errors: [...this.state.errors, 'houve um erro, entre em contato com o suporte'] });
			}
		});
	}

	onSubmit = (values) => {
		this.setState(({ submitted: true }));

		let form = {};

		if (values?.number_of_installments === 1 && this.state?.simulation?.overdue_payments?.value > 0) {
			values.number_of_installments = 2;
		}

		if (values.type == 'EXTRAJUDICIAL') {
			form = {
				type: values.type,
				details: values.details,
				contract_id: values.contract_id,
				number_of_installments: values.number_of_installments,
				exit_date: values.dataSaida,
				termination_fine: {
					months: this.state.simulation?.termination_fine?.months,
					value: limparMoeda(values?.multaRescisoria) != 0 ? limparMoeda(values?.multaRescisoria) : Number(this.state.simulation?.termination_fine?.value)
				},
				rent_enjoyed: {
					days_enjoyed: this.state.simulation?.rent_enjoyed?.days_enjoyed,
					return_days: this.state.simulation?.rent_enjoyed?.return_days,
					value: limparMoeda(values?.aluguelUsufruido) != 0 ? limparMoeda(values?.aluguelUsufruido) : Number(this.state.simulation?.rent_enjoyed?.value)
				},
				repayments: {
					type: 'addition',
					value: limparMoeda(values?.reembolso) != 0 ? limparMoeda(values?.reembolso) : Number(this.state.simulation?.repayments?.value)
				},
				contract_violation_fine: {
					value: limparMoeda(values?.multaQuebraContratual) !== 0 ? parseFloat(limparMoeda(values.multaQuebraContratual)) : Number(this.state.simulation?.contract_violation_fine?.value)
				},
				retroactive: values.remain_in_property === 'no' ? true : false,
				retroactive_billings_guaranteed_installments: values.remain_in_property !== 'no' ? 0 : values.retroactive_billings_guaranteed_installments
			}
		}

		if (values.type == 'TERMINATION_TERM') {
			form = {
				type: values.type,
				contract_id: values.contract_id,
				number_of_installments: values.number_of_installments,
				exit_date: values.dataSaida,
				termination_fine: {
					months: this.state.simulation?.termination_fine?.months,
					value: limparMoeda(values?.multaRescisoria) != 0 ? limparMoeda(values?.multaRescisoria) : Number(this.state.simulation?.termination_fine?.value)
				},
				rent_enjoyed: {
					days_enjoyed: this.state.simulation?.rent_enjoyed?.days_enjoyed,
					return_days: this.state.simulation?.rent_enjoyed?.return_days,
					value: limparMoeda(values?.aluguelUsufruido) != 0 ? limparMoeda(values?.aluguelUsufruido) : Number(this.state.simulation?.rent_enjoyed?.value)
				},
				repayments: {
					type: 'addition',
					value: limparMoeda(values?.reembolso) != 0 ? limparMoeda(values?.reembolso) : Number(this.state.simulation?.repayments?.value)
				},
				contract_violation_fine: {
					value: limparMoeda(values?.multaQuebraContratual) !== 0 ? parseFloat(limparMoeda(values.multaQuebraContratual)) : Number(this.state.simulation?.contract_violation_fine?.value)
				},
				retroactive: values.remain_in_property === 'no' ? true : false,
				retroactive_billings_guaranteed_installments: values.retroactive_billings_guaranteed_installments
			}
		}

		if (values.type == 'CLOSURE_TERM') {
			form = {
				type: values.type,
				contract_id: values.contract_id
			}
		}

		if (values.details == 'Outro.') {
			values.details = `Outro: ${values.detalheTexto}`;
		}

		if (values?.termination_requested_by) {
			form.termination_requested_by = values.termination_requested_by;
		}

		if (this.state.step == 'FIRST_STEP') {
			if (values.details == 'Abandono do imóvel por parte do inquilino.') {
				this.salvarValores(values)
				this.setState({ step: 'ABANDONO_DETAILS_STEP' });
				return;
			}

			if (values.type == 'EXTRAJUDICIAL') {
				this.salvarValores(values)
				this.setState({ step: 'REVIEW_STEP_PARCELAS' });
				return;
			}

			if (values.type == 'TERMINATION_TERM') {
				this.salvarValores(values)
				this.setState({ step: 'REVIEW_STEP_PARCELAS' });
				return;
			}

			if (values.type == 'CLOSURE_TERM') {
				this.setState({ step: 'REVIEW_STEP_CLOSURE_TERM' });
				return;
			}
		}

		//second step
		if (this.state.step == 'ABANDONO_DETAILS_STEP') {
			this.submitRequest(form, values);
			return;
		}

		if (this.state.step == 'TESTEMUNHA_DETAILS_STEP') {
			this.setState({ step: 'REVIEW_STEP_PARCELAS' });
			return;
		}

		if (this.state.step == 'REVIEW_STEP_CLOSURE_TERM') {
			this.submitRequest(form, values);
			return;
		}

		//last step
		if (this.state.step == 'REVIEW_STEP_PARCELAS' || this.state.step == 'REVIEW_STEP_ABANDONO') {
			this.submitRequest(form, values);
			return;
		}
	};

	getInitialValues = () => {
		return {
			contract_id: this.props.match.params.id,
			termination_date: '',
			termination_requested_by: '',
			type: '',
			details: '',
			number_of_installments: this.state.simulation?.overdue_payments?.value > 0 ? 2 : 1,
			arquivos: [],
			arquivosAbandono: [],
			confirm_termination: '',
			confirm_termination_ABANDONO_DETAILS_STEP: '',
			confirm_termination_REVIEW_STEP_ABANDONO: '',
			dataSaida: '',
			detalheTexto: '',
			multaRescisoria: formatBRL(this.state.simulation?.termination_fine?.value ?? 0),
			aluguelUsufruido: formatBRL(this.state.simulation?.rent_enjoyed?.value || 0),
			reembolso: formatBRL(Math.abs(this.state.simulation?.repayments?.value || 0)),
			multaRescisoriaMaximo: this.state.multaRescisoriaMaximo,
			usufruidoMaximo: this.state.usufruidoMaximo,
			reembolsosMaximo: this.state.reembolsosMaximo,
			multaQuebraContratualMaximo: this.state.multaQuebraContratualMaximo,
			multaQuebraContratual: formatBRL(this.state.simulation?.contract_violation_fine?.value ?? 0),
			friendly_agreement_try: null,
			remain_in_property: 'yes',
			retroactive_billings_guaranteed_installments: 1
		};
	};

	disabledConditions = values => {
		if (this.state.step === 'FIRST_STEP') {
			if (values.type === '' || values.termination_requested_by === '') {
				return true
			}

			if (values.friendly_agreement_try !== 'yes') {
				return true;
			}

			if (values.type === 'EXTRAJUDICIAL' && values.details === '') {
				return true;
			}

			if (values.type === 'TERMINATION_TERM' && values.dataSaida === '') {
				return true;
			}

			if (!values.confirm_termination) {
				return true;
			}
		}
	};

	handleBack = (values) => {
		if (this.state.step == 'FIRST_STEP') {
			window.location.href = `/contracts/show/${this.props.match.params.id}`;
		}

		if (this.state.step === 'TESTEMUNHA_DETAILS_STEP') {
			this.setState({ step: 'FIRST_STEP' });
		}

		if (this.state.step == 'ABANDONO_DETAILS_STEP') {
			this.setState({ step: 'FIRST_STEP' });
		}

		if (this.state.step == 'REVIEW_STEP_ABANDONO') {
			this.setState({ step: 'ABANDONO_DETAILS_STEP' });
		}

		if (this.state.step == 'REVIEW_STEP_PARCELAS') {
			this.setState({ step: 'FIRST_STEP' });
		}

		if (this.state.step == 'FINISH_STEP') {
			if (values.details == 'Abandono do imóvel por parte do inquilino') {
				this.setState({ step: 'REVIEW_STEP_ABANDONO' });
			} else {
				this.setState({ step: 'REVIEW_STEP_PARCELAS' });
			}
		}
	}

	salvarValores = async (values) => {
		let novoValorMulta = parseFloat(limparMoeda(values?.multaRescisoria ?? 0));
		let novoValorMultaQuebraContratual = parseFloat(limparMoeda(values?.multaQuebraContratual ?? 0));
		let novoValorAluguelUsufruido = parseFloat(limparMoeda(values?.aluguelUsufruido ?? 0));
		let novoValorReembolsos = parseFloat(limparMoeda(values?.reembolso ?? 0))

		let landlordTotal = novoValorMulta + novoValorAluguelUsufruido + Math.abs(novoValorReembolsos) - (this.state.simulation.current_rent.value ? parseFloat(this.state.simulation.current_rent.value) : 0);

		// let finalValue = termination_fine.value + rent_enjoyed.value + overdue_payments.value + repayments.value - current_rent.value;

		// Se o total for menor que zero e o valor final for devido ao dono, subtrair
		// Se o total for maior que zero e o valor final for devido ao inquilino, subtrair
		if ((landlordTotal < 0 && values.termination_requested_by === 'landlord') || (landlordTotal > 0 && values.termination_requested_by === 'tenant') && values.type === 'EXTRAJUDICIAL') {
			// Subtraimos a multa de quebra contratual do valor da multa anterior

			landlordTotal = landlordTotal - novoValorMultaQuebraContratual;

		} else {
			// Somamos a multa de quebra contratual do valor da multa anterior

			landlordTotal = landlordTotal + novoValorMultaQuebraContratual;
		}

		landlordTotal = Math.abs(landlordTotal)

		let total = landlordTotal + parseFloat(this.state.simulation.overdue_payments.value);

		if (values.remain_in_property === 'no') {
			total = total - this.state?.simulation?.retroactive_billings_rent?.value;
		}


		this.setState({
			simulation: {
				...this.state.simulation,
				termination_total: total,
				termination_fine: {
					...this.state.simulation.termination_fine,
					value: novoValorMulta
				},
				rent_enjoyed: {
					...this.state.simulation.rent_enjoyed,
					value: novoValorAluguelUsufruido
				},
				contract_violation_fine: {
					value: novoValorMultaQuebraContratual
				},
				repayments: {
					...this.state.simulation.repayments,
					value: Math.abs(novoValorReembolsos)
				},
			}
		})
	};

	checkViolationFineApply = (values) => {
		if (values.type === 'EXTRAJUDICIAL') {
			return true;
		}

		if (values.termination_requested_by === 'tenant' && moment(values.exit_date).isBefore(moment().add(30, 'days')) && !this.state.data?.entity?.details?.renovation_info) {
			return true;
		}

		return false;
	}

	getParcelamento(values) {
		let { number_of_installments } = values;
		let tenantPayment = false;

		const isOdd = (number) => number % 2 !== 0;

		const contract = this.state.data.entity;

		let totalRescisao = parseFloat(this.state.simulation.termination_fine.value) +
			(this.state.simulation.rent_enjoyed.value ? parseFloat(this.state.simulation.rent_enjoyed.value) : 0) +
			(this.state.simulation.overdue_payments.value ? parseFloat(this.state.simulation.overdue_payments.value) : 0) +
			(this.state.simulation.repayments.value ? parseFloat(this.state.simulation.repayments.value) : 0) -
			(this.state.simulation.current_rent.value ? parseFloat(this.state.simulation.current_rent.value) : 0)

		const mellroPercentage = contract.plan.percentage / 100;

		let landlordValue = totalRescisao - parseFloat(this.state.simulation.overdue_payments.value);
		const mellroValue = parseFloat(this.state.simulation.overdue_payments.value);


		if (values.remain_in_property === 'no') {
			landlordValue = landlordValue - this.state?.simulation?.retroactive_billings_rent?.value;
		}

		// Se o total for menor que zero e o valor final for devido ao dono, subtrair
		// Se o total for maior que zero e o valor final for devido ao inquilino, subtrair
		if ((landlordValue < 0 && values.termination_requested_by === 'landlord') || (landlordValue > 0 && values.termination_requested_by === 'tenant') && values.type === 'EXTRAJUDICIAL') {
			// Subtraimos a multa de quebra contratual do valor da multa anterior

			landlordValue = landlordValue - this.state.simulation.contract_violation_fine.value;

		} else {
			// Somamos a multa de quebra contratual do valor da multa anterior

			if (this.checkViolationFineApply(values)) {
				landlordValue = landlordValue + this.state.simulation.contract_violation_fine.value;
			}
		}

		if (landlordValue < 0) {
			tenantPayment = true;
		}

		if (number_of_installments === 1 && this.state?.simulation?.overdue_payments?.value > 0) {
			number_of_installments = 2;
		}

		let firstDate = moment(contract.details.end_date, 'MM/DD/YYYY').add(0, 'M').format('DD/MM/YYYY');

		if (values.type === 'EXTRAJUDICIAL') {
			firstDate = moment().add(10, 'days').format('DD/MM/YYYY')
		}

		if (values.type === 'TERMINATION_TERM') {
			firstDate = moment(values.dataSaida).add(10, 'days').format('DD/MM/YYYY')
		}

		//caso seja parcela unica
		if (number_of_installments == 1) {
			if (values.remain_in_property === 'no') {
				totalRescisao = totalRescisao - this.state?.simulation?.retroactive_billings_rent?.value;
			}

			if (totalRescisao < 0) {
				tenantPayment = true;
			}

			return [{
				parcela: 1,
				value: totalRescisao,
				date: firstDate,
				text: tenantPayment ? 'Devida ao inquilino' : 'Devida ao responsável',
			}]
		}

		// Divide pagamentos
		const installments = [];
		for (let i = 0; i < number_of_installments; i++) {
			const installment = {
				parcela: i + 1,
				value: 0,
				date: moment(firstDate, 'DD/MM/YYYY').add(i, 'M').format('DD/MM/YYYY'),
				text: '',
			};

			if (number_of_installments % 2 !== 0) {
				if (mellroValue === 0 || !mellroValue) {
					installment.value = landlordValue / number_of_installments;
					installment.text = tenantPayment ? 'Devida ao inquilino' : 'Devida ao responsável';
					installments.push(installment);
					continue;
				}

				if (landlordValue === 0 || !landlordValue) {
					installment.value = mellroValue / number_of_installments;
					installment.text = 'Devida a Mell.ro';
					installments.push(installment);
					continue;
				}

				if (mellroValue < landlordValue) {
					if (i < Math.floor(number_of_installments / 2)) {
						installment.value = mellroValue / (Math.floor(number_of_installments / 2));
						installment.text = 'Devida a Mell.ro';
					}

					if (i >= Math.floor(number_of_installments / 2)) {
						installment.value = landlordValue / (Math.round(number_of_installments / 2));
						installment.text = tenantPayment ? 'Devida ao inquilino' : 'Devida ao responsável';
					}
				}

				if (mellroValue > landlordValue) {
					if (i <= Math.floor(number_of_installments / 2)) {
						installment.value = mellroValue / (Math.round(number_of_installments / 2));
						installment.text = 'Devida a Mell.ro';
					}

					if (i > Math.floor(number_of_installments / 2)) {
						installment.value = landlordValue / (Math.floor(number_of_installments / 2));
						installment.text = tenantPayment ? 'Devida ao inquilino' : 'Devida ao responsável';
					}
				}

				installments.push(installment);
			} else {
				if (mellroValue === 0 || !mellroValue) {
					installment.value = landlordValue / number_of_installments;
					installment.text = tenantPayment ? 'Devida ao inquilino' : 'Devida ao responsável';
					installments.push(installment);
					continue;
				}

				if (landlordValue === 0 || !landlordValue) {
					installment.value = mellroValue / number_of_installments;
					installment.text = 'Devida a Mell.ro';
					installments.push(installment);
					continue;
				}


				if (i < number_of_installments / 2) {
					installment.value = mellroValue / (number_of_installments / 2);
					installment.text = 'Devida a Mell.ro';
				}

				if (i >= number_of_installments / 2) {
					installment.value = landlordValue / (number_of_installments / 2);
					installment.text = tenantPayment ? 'Devida ao inquilino' : 'Devida ao responsável';
				}


				installments.push(installment);
			}
		}

		return installments;
	}

	getParcelamentoReembolsoDeGarantia(values) {
		const number_of_installments = values.retroactive_billings_guaranteed_installments;
		const total = this.state.simulation?.retroactive_billings_guaranteed?.value;

		// Divide pagamentos
		const installments = [];
		for (let i = 0; i < number_of_installments; i++) {
			const installment = {
				parcela: i + 1,
				value: 0,
				date: moment().add(i, 'M').format('DD/MM/YYYY'),
				text: ''
			}

			installment.value = total / number_of_installments;
			installment.text = 'Devida ao Mell.ro';

			installments.push(installment)
		}

		return installments;
	}

	getContractStatus = () => {
		if (this.state?.data?.entity) {
			const contract = this.state.data?.entity;

			if (!contract.is_terminated && contract.scheduled_termination_date === null) {
				return '?typeNegociacao=vigentes'
			}

			if (contract.is_terminated) {
				return '?typeNegociacao=encerrados'
			}

			if (contract.scheduled_termination_date !== null) {
				return '?typeNegociacao=encerrando'
			}

			return ''
		}
	}

	getBillingsGuaranteedValue = () => {
		// This calc is made in backend now
		// const percentage = Number(this.state.data?.entity?.plan?.percentage);

		const billingsValue = this.state.simulation?.retroactive_billings_guaranteed?.value;

		// if (this.state?.data?.entity?.coupon) {
		// 	const couponValue = Number(this.state?.data?.entity?.coupon?.discount_amount);

		// 	return Math.abs(billingsValue - (billingsValue * ((percentage - couponValue) / 100)));
		// }

		return Math.abs(billingsValue);
	}

	getTotalRetroactiveBillings = () => {
		let guaranteeBillings = this.getBillingsGuaranteedValue();
		let paidBillings = 0;

		// Se for o inquilino o pagante, zera o valor dos billings pagos
		if (this.state.simulation?.repayments?.type === 'repayment') {
			paidBillings = this.state.simulation?.retroactive_billings_rent?.value;
		}

		let total = guaranteeBillings + paidBillings;

		return Math.abs(total);
	}

	render() {
		const RadioButton = ({ handleChange, handleBlur, values, field, name, text, style }) => {
			return (
				<Col md={12} style={{ marginLeft: "10px", ...style }}>
					<Input id={field} type="radio" name={name} value={field}
						onChange={handleChange} onBlur={handleBlur} color="primary"
						checked={values[name] === field}
					/>
					<p className='p-termination-input-value' style={{ padding: "3px", marginLeft: 2, marginBottom: name === 'confirm_termination' ? '0px' : '' }} for={field}>
						{text}
					</p>
				</Col>
			)
		}

		const Asset = ({ titleAsset, textAsset, icon, colorBackground, colorIcon, message, children, style, newIcon }) => {
			return (
				<div className={'assetPersonComissao'}>
					<div style={{ backgroundColor: colorBackground ?? '#E1E3F7' }} className={'containerFigure'}>
						{newIcon ? (
							<FontAwesomeIcon icon={newIcon} style={{ color: colorIcon ?? '#5664D2', fontSize: '13px' }} />
						) : <i className={icon} style={{ color: colorIcon ?? '#5664D2' }} />}

					</div>

					<div style={children ? { display: 'flex', alignItems: 'center' } : {}}>
						{titleAsset ? <h6>{titleAsset}</h6> : null}
						<h5>{textAsset ? textAsset : children}</h5>

						{message ? <p className={'info'} style={{ marginTop: 8, ...style }}>
							{message}
						</p> : null}
					</div>
				</div>
			)
		}

		const WarningContainer = ({ styleContainer, title, titleColor, children }) => {
			return (
				<div className={'warning'} style={styleContainer}>
					<h5 style={{ color: titleColor }}>{title}</h5>

					<div>{children}</div>
				</div>
			);
		}

		const SuccessContainer = ({ styleContainer, title, titleColor, children }) => {
			return (
				<div style={{ backgroundColor: '#fff', marginBottom: 24, borderRadius: '0px 0px 8px 8px', boxShadow: '0px 2px 4px 0px rgba(0.0.0. 0.08)' }}>
					<div className={'termination-success'} style={styleContainer}>
						<h5 style={{ color: titleColor }}>{title}</h5>

						<div>{children}</div>
					</div>
					<div style={{ padding: '16px 24px' }}>
						<button className="go-back-1" type='button' onClick={() => redirectSuccess()}>
							<FontAwesomeIcon icon={faArrowLeft} style={{ fontSize: '15px' }} />VOLTAR PARA O CONTRATO
						</button>
					</div>
				</div>
			);
		}

		const redirectSuccess = () => {
			this.setState({ redirect: true })
		}

		const SimulacaoValues = ({ values, handleBlur, touched, errors, handleChange, notEditable }) => {
			const handleSubmit = () => {
				let editValuesReverse = !this.state.editValues

				this.setState({ editValues: editValuesReverse });

				if (editValuesReverse == false) {
					this.salvarValores(values);
				}
			}

			return (
				<div>
					<h2 className='p-termination-optional-title' style={{ padding: '8px 0px' }}>SIMULAÇÃO DE SAÍDA DO IMÓVEL</h2>

					<p className={'p-termination-optional'} style={{ marginTop: 24 }}>
						Esta simulação apresenta um quadro geral da situação atual do contrato, o valor ainda pode <a>sofrer alterações</a>.
						Mais funcionalidades serão liberadas ao confirmar a rescisão do contrato.
					</p>


					<div className={'containerAssets'}>
						{this.state.simulation?.termination_fine?.months == 0 ? null :
							<Asset titleAsset={'Aluguéis previstos'} textAsset={`${this.state.simulation?.termination_fine?.months - 1} ${this.state.simulation?.termination_fine?.months > 1 ? 'meses' : 'mês'}`} icon={'fa fa-key'} />
						}

						{this.state.simulation?.termination_fine?.value == 0 ? null :
							<Asset titleAsset={'Multa rescisória'} textAsset={`${this.state.simulation?.termination_fine?.months * 30} dias: ${formatBRL(this.state.simulation?.termination_fine?.value ?? 0)}`} icon={'fa fa-file-invoice-dollar'} />
						}

						{this.state.simulation?.contract_violation_fine?.value == 0 ? null :
							<Asset titleAsset={'Multa quebra contratual'} textAsset={`3 aluguéis: ${formatBRL(this.state.simulation?.contract_violation_fine?.value ?? 0)}`} newIcon={faFileSlash} />
						}

						{this.state.simulation?.rent_enjoyed?.value == 0 ? null :
							<Asset titleAsset={'Aluguel usufruído'} textAsset={`${this.state.simulation?.rent_enjoyed?.days_enjoyed} dias: ${formatBRL(this.state.simulation?.rent_enjoyed?.value ?? 0)}`} icon={'fa fa-house-user'} />
						}

						{this.state.simulation?.repayments?.value == 0 ? null :
							<Asset titleAsset={'Reembolsos'} textAsset={`${this.state.simulation?.repayments?.type == 'addition' ? '+' : '-'} ${formatBRL(Math.abs(this.state.simulation?.repayments?.value ?? 0))}`} icon={'fa fa-hand-holding-usd'} />
						}

						{this.state.simulation?.current_rent?.value == 0 ? null :
							<Asset titleAsset={'Mês vigente'} textAsset={`${this.state.simulation?.current_rent?.days_enjoyed} dias: ${formatBRL(this.state.simulation?.current_rent?.value ?? 0)}`} icon={'fa-solid fa-calendar-circle-minus'} newIcon={faCalendarCircleMinus} />
						}

						{this.state.simulation?.retroactive_billings_rent?.value == 0 ? null :
							<Asset titleAsset={'Devolução de aluguel'} textAsset={`${this.state.simulation?.retroactive_billings_rent?.billings.length > 1 ? `${this.state.simulation?.retroactive_billings_rent?.billings.length} meses` : `${this.state.simulation?.retroactive_billings_rent?.billings.length} mês`}: ${formatBRL(Math.abs(this.state.simulation?.retroactive_billings_rent?.value ?? 0))}`} newIcon={faMoneyBillTransfer} />
						}

						{this.state.simulation?.retroactive_billings_guaranteed?.value == 0 ? null :
							<Asset titleAsset={'Devolução de garantia'} textAsset={`${this.state.simulation?.retroactive_billings_guaranteed?.billings.length > 1 ? `${this.state.simulation?.retroactive_billings_guaranteed?.billings.length} meses` : `${this.state.simulation?.retroactive_billings_guaranteed?.billings.length} mês`}: ${formatBRL(this.getBillingsGuaranteedValue())}`} newIcon={faShieldExclamation} />
						}

						{this.state.simulation?.overdue_payments?.value == 0 ? null :
							<Asset titleAsset={'Atrasos devidos a Mell.ro'} textAsset={`${this.state.simulation?.overdue_payments?.months} ${this.state.simulation?.overdue_payments?.months > 1 ? 'meses' : 'mês'}: ${formatBRL(this.state.simulation?.overdue_payments?.value ?? 0)} `} icon={'fa fa-file-invoice-dollar'} newIcon={faFileExclamation} message={'Vamos reter os valores já garantidos das parcelas que o inquilino pagar.'} style={{ color: '#8590A5' }} />
						}

						<Asset titleAsset={'Vistoria'} textAsset={'Ainda será definido'} icon={'fa fa-check'} />
					</div>

					<hr className='termination-divisor' />

					<div style={{ marginTop: 16 }}>
						<p className='p-termination-total-label'>Valor total da saída do imóvel</p>
						<div className='d-flex align-center align-items-center'>
							<h2 className='p-termination-total'>{formatBRL(this.state?.simulation?.termination_total)}</h2>
							<p className='p-termination-payer'>{this.state.simulation?.repayments?.type === 'repayment' ? 'Responsável' : 'Inquilino'} paga</p>
						</div>
					</div>

					{this.getTotalRetroactiveBillings() > 0 ?
						(
							<div style={{ marginTop: 30 }}>
								<p className='p-termination-total-label'>Valor total da devolução</p>
								<div className='d-flex align-items-center'>
									<h2 className='p-termination-total' style={{ color: '#FCB92C' }}>{formatBRL(this.getTotalRetroactiveBillings())}</h2>
									<p className='p-termination-payer'>Responsável paga</p>
								</div>
							</div>
						) : null}
				</div>
			)
		}

		const CardEditValues = ({ values, handleBlur, touched, errors, handleChange, notEditable }) => {
			return (
				<div>
					<h2 className='p-termination-optional-title'>VALORES COM DESCONTO (OPCIONAL)</h2>

					<p className={'p-termination-optional'} style={{ marginTop: 24 }}>
						Você pode dar desconto em um dos valores abaixo. Você não poderá incluir um valor maior que o padrão.
					</p>

					<div style={{ marginTop: 28, display: 'flex', gap: '24px' }}>
						{this.state?.simulation?.termination_fine?.value !== 0 ? (
							<div>
								<p className={'label'}>Multa rescisória</p>
								<TextField name="multaRescisoria" style={{ marginBottom: '0px', maxWidth: '217px' }} placeholder={'Ex.: R$ 100,00'} className={'input'} type="text" variant="outlined" value={values.multaRescisoria}
									onBlur={handleBlur} onChange={(e) => handleChange(formatBRLInput(e))}
									helperText={touched.multaRescisoria && errors.multaRescisoria}
									error={Boolean(touched.multaRescisoria && errors.multaRescisoria)}
								/>
							</div>
						) : null}

						<div>
							<p className={'label'}>Multa quebra contratual</p>
							<TextField
								name="multaQuebraContratual"
								style={{ marginBottom: '0px', maxWidth: '217px' }}
								placeholder={'Ex.: R$ 100,00'}
								className={'input'}
								type="text"
								variant="outlined"
								value={values.multaQuebraContratual}
								onChange={(e) => {
									const inputValue = e.target.value;

									const numericValue = Number(inputValue.toString().replace(/[^0-9-]/g, '')) / 10 ** 2;

									const formattedValue = formatBRL(numericValue);

									handleChange({
										target: {
											name: e.target.name,
											value: formattedValue
										}
									});
								}}
								onBlur={async (e) => {
									const inputValue = e.target.value;

									const numericValue = Number(inputValue.toString().replace(/[^0-9-]/g, '')) / 10 ** 2;

									const formattedValue = formatBRL(numericValue);

									await this.salvarValores({
										...values,
										multaQuebraContratual: formattedValue
									});

									handleBlur(e);
								}}
								helperText={touched.multaQuebraContratual && errors.multaQuebraContratual}
								error={Boolean(touched.multaQuebraContratual && errors.multaQuebraContratual)}
							/>
						</div>

						{this?.state?.simulation?.rent_enjoyed?.value !== 0 ?
							<div>
								<p className={'label'}>Aluguel usufruído</p>
								<TextField name="aluguelUsufruido" style={{ marginBottom: '0px', maxWidth: '217px' }} placeholder={'Ex.: R$ 100,00'} className={'input'} type="text" variant="outlined" value={values.aluguelUsufruido}
									onBlur={handleBlur} onChange={(e) => handleChange(formatBRLInput(e))}
									helperText={touched.aluguelUsufruido && errors.aluguelUsufruido}
									error={Boolean(touched.aluguelUsufruido && errors.aluguelUsufruido)}
								/>
							</div>
							: null}

						{this.state?.simulation?.repayments.value && this.state?.simulation?.repayments.value !== 0 ?
							(<>
								<div>
									<p className={'label'}>Reembolsos</p>
									<TextField name="reembolso" style={{ marginBottom: '0px', maxWidth: '217px' }} placeholder={'Ex.: R$ 100,00'} className={'input'} type="text" variant="outlined" value={values.reembolso}
										onBlur={handleBlur} onChange={(e) => handleChange(formatBRLInput(e))}
										helperText={touched.reembolso && errors.reembolso}
										error={Boolean(touched.reembolso && errors.reembolso)}
									/>
								</div>
							</>) : null}
					</div>

				</div>
			)
		}

		return (
			<React.Fragment>
				{this.state?.redirect ? (
					<Redirect
						to={{
							pathname: `/negotiation/show/${this.state?.data?.entity?.proposition_id}${this.getContractStatus()}`,
							state: { success: ["Rescisão agendada com sucesso!"] },
						}}
					/>
				) : null}
				<BetterCircularProgress loading={this.state.loading}>
					<div className="page-content container-page rescisao">
						<Container fluid>
							<BetterAlerts errors={this.state.errors} />

							<div className='wrapper-100'>
								<div className='termination-breadcrumb'>
									<Label className="col-form-label" style={{ color: '#505D69', fontSize: '24px', fontFamily: 'Inter' }}>Agendar rescisão</Label>

									<Breadcrumbs breadcrumbItems={[{ link: '/negotiation/index', title: 'Negociações' }, { link: `/negotiation/index`, title: 'Negociações e contratos' }, { link: `/negotiation/show/${this.state?.data?.entity?.proposition_id}${this.getContractStatus()}`, title: `ID ${this.props.match.params.id.substring(0, 4)}...` }, { link: `/contracts/termination/${this.props.match.params.id}`, title: 'Agendar rescisão' }]} />
								</div>
							</div>

							<Formik initialValues={this.getInitialValues()} validate={values => validateContractTermination(values, 'store', this.state.usufruidoMaximo, this.state.reembolsosMaximo, this.state.multaQuebraContratualMaximo, this.state.multaRescisoriaMaximo)} onSubmit={(values) => this.onSubmit(values)}>
								{({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue, setFieldTouched }) => (
									<form noValidate={true} autoComplete="off" onSubmit={handleSubmit} style={{ display: 'flex', justifyContent: 'center', justifyContent: 'space-between' }}>
										<div style={{ width: '60%', display: 'flex', flexDirection: 'column', alignItems: 'center', paddingLeft: '5%' }}>
											{this.state.step == 'FIRST_STEP' ? (
												<>
													<Card className='card-termination'>

														<p className='p-termination-1' style={{ padding: '8px 0px' }}>GERAL</p>

														<div style={{ marginTop: "20px" }}>
															<p className="p-termination-input-label">As partes conversaram sobre a rescisão?</p>

															<div style={{ display: 'flex', width: '55%', justifyContent: 'flex-start' }}>
																<RadioButton style={{ width: 'auto', flex: 'none' }} handleChange={handleChange} handleBlur={handleBlur} values={values} field='yes' name='friendly_agreement_try' text='Sim, conversaram' />
																<RadioButton style={{ width: 'auto', flex: 'none' }} handleChange={handleChange} handleBlur={handleBlur} values={values} field='no' name='friendly_agreement_try' text='Não entraram em contato' />
															</div>
														</div>

														{values.friendly_agreement_try === 'yes' || values.friendly_agreement_try === null ? null : (
															<div style={{
																display: 'flex',
																padding: '16px 24px 32px 24px',
																flexDirection: 'column',
																alignItems: 'flex-start',
																gap: '16px',
																alignSelf: 'stretch',
																borderRadius: '8px',
																border: '1px solid var(--Cinza-100, #EEE)',
																background: 'var(--Vermelho-100, #FFC9D2)',
																boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.08)',
																margin: '4px 0px'
															}}>
																<p style={{ marginTop: 8, color: 'var(--Vermelho-900, #852032)', fontFamily: 'Inter', fontSize: '12px', fontStyle: 'normal', fontWeight: 600, lineHeight: 'normal' }}>Para poder continuar, peça para que as partes conversem</p>
																<p style={{ color: 'var(--Cinza-800, #343A40)', fontFamily: 'Inter', fontSize: '13px', fontStyle: 'normal', fontWeight: 400, lineHeight: 'normal' }}>Sem que as partes conversem antes de rescindir o contrato, pode ocorrer uma quebra contratual, o que leva meses ou até anos para ser resolvido judicialmente. Para poder continuar, peça para que as partes conversem.</p>
																<a href="https://docs.mellro.com/link/49#bkmrk-benefícios-da-rescis" target='_blank' style={{ color: 'var(--Roxo-500, #5664D2)', fontFamily: 'Inter', fontSize: '13px', fontStyle: 'normal', fontWeight: 400, lineHeight: 'normal', textDecorationLine: 'underline' }} className='link-underline' rel="noreferrer">Clique aqui para saber mais.</a>
															</div>
														)}

														<div style={{ marginTop: "20px" }}>
															<p className="p-termination-input-label">Quem está pedindo a rescisão de contrato?</p>

															<div style={{ display: 'flex', width: '20%', justifyContent: 'flex-start' }}>
																<RadioButton handleChange={async e => { handleChange(e); await this.handleGetSimulation(values.dataSaida, setFieldValue, e.target.value, values.type, values.remain_in_property); }} handleBlur={handleBlur} values={values} field='landlord' name='termination_requested_by' text='Responsável' />
																<RadioButton handleChange={async e => { handleChange(e); await this.handleGetSimulation(values.dataSaida, setFieldValue, e.target.value, values.type, values.remain_in_property); }} handleBlur={handleBlur} values={values} field='tenant' name='termination_requested_by' text='Inquilino' />
															</div>
														</div>

														<div style={{ marginTop: "30px" }}>
															<p className="p-termination-input-label">A rescisão de contrato é de comum acordo?</p>

															<RadioButton handleChange={async e => { handleChange(e); await this.handleGetSimulation(values.dataSaida, setFieldValue, values.termination_requested_by, e.target.value, values.remain_in_property); }} handleBlur={handleBlur} values={values} field='EXTRAJUDICIAL' name='type' text='Alguém descumpriu o contrato' />
															<RadioButton handleChange={async e => { handleChange(e); await this.handleGetSimulation(values.dataSaida, setFieldValue, values.termination_requested_by, e.target.value, values.remain_in_property); }} handleBlur={handleBlur} values={values} field='TERMINATION_TERM' name='type' text='Rescisão de comum acordo' />

															{!this.state.renovation && moment().isAfter(moment(this.state?.data?.entity?.details?.end_date, 'MM/DD/YYYY').subtract(61, 'days')) ? <RadioButton handleChange={handleChange} handleBlur={handleBlur} values={values} field='CLOSURE_TERM' name='type' text='Acabou o tempo previsto do contrato' /> : null

															}
														</div>

														<div style={{ marginTop: "20px" }}>
															<p className="p-termination-input-label">O inquilino ainda está no imóvel?</p>

															<div style={{ display: 'flex', width: '55%', justifyContent: 'flex-start' }}>
																<RadioButton style={{ width: 'auto', flex: 'none' }} handleChange={handleChange} handleBlur={handleBlur} values={values} field='yes' name='remain_in_property' text='Sim, ainda está' />
																<RadioButton style={{ width: 'auto', flex: 'none' }} handleChange={handleChange} handleBlur={handleBlur} values={values} field='no' name='remain_in_property' text='Não, já desocupou' />
															</div>
														</div>

														{values.type === 'EXTRAJUDICIAL' && (
															<div className='cardWarningTypeExtrajudicial'>
																<h3>Rescisão por quebra de contrato</h3>

																<p>Uma das partes do contrato quebrou uma cláusula. Nosso setor jurídico avaliará o caso ou recorrerá à justiça, o que pode demorar meses para rescindir o contrato.</p>
															</div>
														)}

														{values.type === 'TERMINATION_TERM' && (
															<div className='cardWarningTypeTermination'>
																<h3>Termo de rescisão de contrato</h3>

																<p>O contrato não será encerrado conforme o planejado, mas vocês concordaram em rescindi-lo. É necessário assinar o Termo de Rescisão, caso contrário o contrato continuará ativo, gerando cobranças ao inquilino.</p>
															</div>
														)}

														{values.type === 'CLOSURE_TERM' && (
															<div className='cardWarningTypeClosure'>
																<h3>LEIA COM ATENÇÃO</h3>

																<p>O contrato terminará dentro do prazo estipulado sem dívidas. Não é preciso assinar nenhum documento.</p>
															</div>
														)}
													</Card>

													{values.type === 'EXTRAJUDICIAL' ? (
														<Card className='card-termination'>
															<p className='p-termination-1' style={{ padding: '8px 0px' }}>DETALHES</p>

															<p className="p-termination-input-label" style={{ marginTop: '20px' }}>Selecione o motivo</p>
															{values.termination_requested_by == 'landlord' ?
																<div>
																	<RadioButton handleChange={handleChange} handleBlur={handleBlur} values={values} field='Falta de pagamento' name='details' text='Falta de pagamento' />
																	<RadioButton handleChange={handleChange} handleBlur={handleBlur} values={values} field='Descumprimento de cláusula não resolvido em até 30 dias, exceto falta de pagamento.' name='details' text='Descumprimento de cláusula não resolvido em até 30 dias, exceto falta de pagamento.' />
																	<RadioButton handleChange={handleChange} handleBlur={handleBlur} values={values} field='Desapropriação do imóvel que impeça seu uso.' name='details' text='Desapropriação do imóvel que impeça seu uso.' />
																	<RadioButton handleChange={handleChange} handleBlur={handleBlur} values={values} field='Problemas no imóvel, total ou parcial, que impeçam o uso, exceto se culpa do inquilino.' name='details' text='Problemas no imóvel, total ou parcial, que impeçam o uso, exceto se culpa do inquilino.' />
																	<RadioButton handleChange={handleChange} handleBlur={handleBlur} values={values} field='Abandono do imóvel por parte do inquilino.' name='details' text='Abandono do imóvel por parte do inquilino.' />
																	<RadioButton handleChange={handleChange} handleBlur={handleBlur} values={values} field='Outro.' name='details' text='Outro.' />
																</div>
																: null
															}

															{values.termination_requested_by == 'tenant' ?
																<div style={{ marginTop: "20px" }}>
																	<RadioButton handleChange={handleChange} handleBlur={handleBlur} values={values} field='Descumprimento de cláusula não resolvido em até 30 dias, exceto falta de pagamento.' name='details' text='Descumprimento de cláusula não resolvido em até 30 dias, exceto falta de pagamento.' />
																	<RadioButton handleChange={handleChange} handleBlur={handleBlur} values={values} field='Desapropriação do imóvel que impeça seu uso.' name='details' text='Desapropriação do imóvel que impeça seu uso.' />
																	<RadioButton handleChange={handleChange} handleBlur={handleBlur} values={values} field='Problemas no imóvel, total ou parcial, que impeçam o uso, exceto se culpa do inquilino.' name='details' text='Problemas no imóvel, total ou parcial, que impeçam o uso, exceto se culpa do inquilino.' />
																	<RadioButton handleChange={handleChange} handleBlur={handleBlur} values={values} field='Reparos necessários identificados no imóvel durante o aluguel de responsabilidade do responsável' name='details' text='Reparos necessários identificados no imóvel durante o aluguel de responsabilidade do responsável' />
																	<RadioButton handleChange={handleChange} handleBlur={handleBlur} values={values} field='Outro.' name='details' text='Outro.' />
																</div>
																: null
															}

															{values.details == 'Outro.' ?
																<div style={{ marginTop: "20px" }}>
																	<p className="p-termination-input-label">Qual outro motivo?</p>
																	<TextField name="detalheTexto" placeholder={'Adicionar todos os detalhes...'} className={'input'} type="text" variant="outlined" value={values.detalheTexto} style={{ background: '#FFF' }}
																		onBlur={handleBlur} onChange={handleChange} helperText={touched.detalheTexto && errors.detalheTexto} error={Boolean(touched.detalheTexto && errors.detalheTexto)}
																	/>
																</div>
																: null}

															{values.remain_in_property == 'no' ? (
																<>
																	<div>
																		<p className="p-termination-input-label" style={{ marginTop: '20px' }}>
																			{values.remain_in_property === 'yes' ? 'Defina a data de saída e entrega das chaves' : 'Em qual data o inquilino desocupou o imóvel?'}
																		</p>
																		<TextField
																			className="mb-2"
																			style={{ background: "#FFF", height: "40px", width: '272px' }} name={'dataSaida'}
																			InputProps={{
																				inputProps: {
																					min: values.remain_in_property === 'no' ? null : moment(new Date()).add(1, 'days').format('YYYY-MM-DD'),
																					max: values.remain_in_property === 'no' ? moment(new Date()).format('YYYY-MM-DD') : null
																				}
																			}}
																			onBlur={handleBlur} InputLabelProps={{ shrink: true }}
																			type="date" variant="outlined" value={values.dataSaida} placeholder="00/00/0000"
																			onChange={async e => { handleChange(e); await this.handleGetSimulation(e.target.value, setFieldValue, values.termination_requested_by, values.type, values.remain_in_property); }}
																		/>
																	</div>

																	<div style={{ marginTop: "20px" }}>
																		<p className="p-termination-input-label" style={{ marginBottom: '5px' }}>Rescisão de contrato - Quantidade de parcelas</p>
																		<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
																			<div className='d-flex position-relative' style={{ width: "53%" }}>
																				<select style={{ background: "#FFF", height: "38px", width: '100%' }} name='number_of_installments'
																					onChange={handleChange} handleBlur={handleBlur} value={values.number_of_installments} className="custom-select">
																					{this.numeroParcelas().map((parcela, index) =>
																						<option key={parcela.id} value={parcela.id}>{parcela.label}</option>
																					)}
																				</select>
																				<FontAwesomeIcon icon={faChevronDown} style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)', pointerEvents: 'none' }} />
																			</div>

																			<Label className="col-form-label" style={{ color: '#868E96', fontSize: '13px', width: '42%' }}>
																				Primeira parcela terá seu vencimento para a data de saída e entrega da chaves
																			</Label>
																		</div>
																	</div>

																	{values.remain_in_property === 'no' && this?.state?.simulation?.retroactive_billings_guaranteed?.billings?.length > 0 ? (
																		<div style={{ marginTop: "20px" }}>
																			<p className="p-termination-input-label" style={{ marginBottom: '5px' }}>Devolução de garantia - Quantidade de parcelas</p>
																			<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
																				<div className='d-flex position-relative' style={{ width: "53%" }}>
																					<select style={{ background: "#FFF", height: "38px", width: '100%', position: 'relative' }} name='retroactive_billings_guaranteed_installments'
																						onChange={handleChange} handleBlur={handleBlur} value={values.retroactive_billings_guaranteed_installments} className="custom-select">
																						{this.guaranteedInstallmentsRefund().map((parcela, index) =>
																							<option key={parcela.id} value={parcela.id}>{parcela.label}</option>
																						)}

																					</select>
																					<FontAwesomeIcon icon={faChevronDown} style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)', pointerEvents: 'none' }} />
																				</div>


																				<Label className="col-form-label" style={{ color: '#868E96', fontSize: '13px', width: '42%' }}>
																					Aconteceram garantias indevidas, o responsável precisa devolver esse valor
																				</Label>
																			</div>
																		</div>
																	) : null}
																</>
															) : (
																<div style={{ marginTop: "20px" }}>
																	<p className="p-termination-input-label" style={{ marginBottom: '5px' }}>Quantidade de parcelas</p>
																	<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
																		<select style={{ background: "#FFF", height: "38px", width: '60%' }} name='number_of_installments'
																			onChange={handleChange} handleBlur={handleBlur} value={values.number_of_installments} className="custom-select">
																			{this.numeroParcelas().map((parcela, index) =>
																				<option key={parcela.id} value={parcela.id}>{parcela.label}</option>
																			)}
																		</select>

																		<Label className="col-form-label" style={{ color: '#868E96', fontSize: '13px', width: '35%' }}>
																			Primeira parcela terá seu vencimento para a data de saída e entrega da chaves
																		</Label>
																	</div>
																</div>
															)}
														</Card>
													) : null}

													{values.type === 'TERMINATION_TERM' ? (
														<Card className='card-termination'>
															<p className='p-termination-1' style={{ padding: '8px 0px' }}>DETALHES</p>
															<div>
																<p className="p-termination-input-label" style={{ marginTop: '20px' }}>
																	{values.remain_in_property === 'yes' ? 'Defina a data de saída e entrega das chaves' : 'Em qual data o inquilino desocupou o imóvel?'}
																</p>
																<TextField
																	className="mb-2" style={{ background: "#FFF", height: "40px", width: '272px' }} name={'dataSaida'}
																	InputProps={{
																		inputProps: {
																			min: values.remain_in_property === 'no' ? null : moment(new Date()).add(1, 'days').format('YYYY-MM-DD'),
																			max: values.remain_in_property === 'no' ? moment(new Date()).format('YYYY-MM-DD') : null
																		}
																	}}
																	onBlur={handleBlur} InputLabelProps={{ shrink: true }}
																	type="date" variant="outlined" value={values.dataSaida} placeholder="00/00/0000"
																	onChange={async e => { handleChange(e); await this.handleGetSimulation(e.target.value, setFieldValue, values.termination_requested_by, values.type, values.remain_in_property); }} />
															</div>

															<div style={{ marginTop: "20px" }}>
																<p className="p-termination-input-label" style={{ marginBottom: '5px' }}>Rescisão de contrato - Quantidade de parcelas</p>
																<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
																	<div className='d-flex position-relative' style={{ width: "53%" }}>
																		<select style={{ background: "#FFF", height: "38px", width: '100%' }} name='number_of_installments'
																			onChange={handleChange} handleBlur={handleBlur} value={values.number_of_installments} className="custom-select">
																			{this.numeroParcelas().map((parcela, index) =>
																				<option key={parcela.id} value={parcela.id}>{parcela.label}</option>
																			)}
																		</select>
																		<FontAwesomeIcon icon={faChevronDown} style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)', pointerEvents: 'none' }} />
																	</div>

																	<Label className="col-form-label" style={{ color: '#868E96', fontSize: '13px', width: '42%' }}>
																		Primeira parcela terá seu vencimento para a data de saída e entrega da chaves
																	</Label>
																</div>
															</div>

															{values.remain_in_property === 'no' && this?.state?.simulation?.retroactive_billings_guaranteed?.billings?.length > 0 ? (
																<div style={{ marginTop: "20px" }}>
																	<p className="p-termination-input-label" style={{ marginBottom: '5px' }}>Devolução de garantia - Quantidade de parcelas</p>
																	<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
																		<div className='d-flex position-relative' style={{ width: "53%" }}>
																			<select style={{ background: "#FFF", height: "38px", width: '100%', position: 'relative' }} name='retroactive_billings_guaranteed_installments'
																				onChange={handleChange} handleBlur={handleBlur} value={values.retroactive_billings_guaranteed_installments} className="custom-select">
																				{this.guaranteedInstallmentsRefund().map((parcela, index) =>
																					<option key={parcela.id} value={parcela.id}>{parcela.label}</option>
																				)}

																			</select>
																			<FontAwesomeIcon icon={faChevronDown} style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)', pointerEvents: 'none' }} />
																		</div>


																		<Label className="col-form-label" style={{ color: '#868E96', fontSize: '13px', width: '42%' }}>
																			Aconteceram garantias indevidas, o responsável precisa devolver esse valor
																		</Label>
																	</div>
																</div>
															) : null}
														</Card>
													) : null}

													{values.type === 'CLOSURE_TERM' ? (
														<>
															<Card className='card-termination'>
																<p className='p-termination-1' style={{ padding: '8px 0px' }}>DETALHES</p>
																<div>
																	<p className="p-termination-input-label" style={{ marginTop: '20px', marginBottom: '4px' }}>Data de saída e entrega das chaves</p>
																	<p className='p-termination-fixed'>{moment(this.state.data.entity?.details?.end_date, 'MM/DD/YYYY').format('DD/MM/YYYY')}</p>
																</div>

																<div style={{ marginTop: "20px" }}>
																	<p className="p-termination-input-label" style={{ marginBottom: '5px', width: '384px' }}>Ao final do contrato, a saída do imóvel é definida para o último dia previsto no contrato <b>sem possibilidade de alteração.</b></p>

																</div>
															</Card>

															<Card className='card-termination'>
																<div>
																	<p className='p-termination-1' style={{ padding: '8px 0px' }}>ARQUIVOS (OPCIONAL)</p>

																	<FormGroup className="mb-0 checkBox-admin">
																		<Label className="col-form-label" style={{ color: '#343A40', fontSize: '13px', display: this.state.success ? 'none' : '' }}>Selecione o arquivo</Label>
																		<br style={{ display: this.state.success ? 'none' : '' }} />

																		<Row className="p-0 m-0" style={{ display: this.state.success ? 'none' : '' }}>
																			<Col lg={9} md={12} className="p-0 m-0">
																				<TextField name="fileName" placeholder='Selecione um arquivo no seu computador' type="text" variant="outlined" value={values.fileName} onBlur={handleBlur} onChange={handleChange}
																					helperText={touched.fileName && errors.fileName} error={Boolean(touched.fileName && errors.fileName)} />
																			</Col>


																			<Col lg={3} md={12} className="marginInputMobile">
																				<label htmlFor={'file'} className="btn btn-secondary btn-bold mb-0 d-flex justify-content-center align-items-center" style={{ width: '100%', height: '41.09px', backgroundColor: '#5664D2' }}>
																					<input className="doc-juridico-select" type="file" id={'file'} style={{ display: 'none' }} name={'arquivos'} accept="*/*" onChange={(event) => { this.setState({ arquivos: [...this.state.arquivos, event.currentTarget.files[0]] }) }} />

																					IMPORTAR
																					<FontAwesomeIcon icon={faFileImport} style={{ marginLeft: '10px', display: this.state.success ? 'none' : '' }} />
																				</label>
																			</Col>
																		</Row>

																		{this.state.arquivos.length > 0 ?
																			(
																				<>
																					{this.state.arquivos.map((arquivo, i) => (
																						<div style={{ display: 'flex', padding: '20px 30px', marginTop: '10px', flexDirection: 'row', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'space-between', width: '100%', backgroundColor: '#F1F5F7' }}>
																							<p style={{ textOverflow: 'ellipsis', margin: '0px', color: '#4AA3FF' }}>{arquivo?.name}</p>
																							<div style={{ display: 'flex', alignItems: 'center' }}>
																								<p style={{ marginBottom: '0px', height: '15px' }}>
																									{this.state.arquivos ? `${(arquivo?.size / 1000).toFixed(2)} Kb` : null}
																								</p>
																								<FontAwesomeIcon icon={faXmark} style={{ color: '#343A40', fontSize: '20px', cursor: 'pointer', marginLeft: '16px', display: this.state.success ? 'none' : '' }} onClick={() => this.setState({ arquivos: this.state.arquivos.filter(obj => obj !== arquivo) })} />

																							</div>
																						</div>
																					))}
																				</>
																			)
																			: null}
																	</FormGroup>
																</div>

															</Card>
														</>
													) : null}

													{values.type !== '' && values.type !== 'CLOSURE_TERM' ? (
														<Card className='card-termination'>
															<CardEditValues values={values} handleBlur={handleBlur} touched={touched} errors={errors} handleChange={handleChange} />
														</Card>
													) : null}

													{this.state.success ? null : (
														<Card className='card-termination' style={{ padding: '0px' }}>
															<div style={{ padding: '16px 24px 16px 24px' }}>
																<Col md={12} style={{ marginLeft: "10px" }}>
																	<Input id='true' type="radio" name='confirm_termination' value='true'
																		onChange={handleChange} onBlur={handleBlur} color="primary"
																		checked={values['confirm_termination'] === 'true'}
																	/>
																	<p className='p-termination-input-value' style={{ padding: "3px", marginLeft: 2, marginBottom: 'confirm_termination' === 'confirm_termination' ? '0px' : '' }} for='true'>
																		Confirmo em criar essa rescisão e sei que esta ação é <small className='p-termination-input-value' style={{ color: '#FF3D60' }}>irreversível.</small>
																	</p>
																</Col>
															</div>
														</Card>

													)}

													{this.state.success ? null : (
														<Card className='card-termination' style={{ flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center', padding: '16px' }}>
															<>
																<button className="go-back-1" type='button' onClick={() => window.location.href = `/negotiation/show/${this.state?.data?.entity?.proposition_id}${this.getContractStatus()}`}>
																	<FontAwesomeIcon icon={faArrowLeft} style={{ fontSize: '15px' }} />VOLTAR
																</button>

																<button disabled={this.disabledConditions(values)} className="continue-btn-1" style={{ backgroundColor: this.disabledConditions(values) ? '#DADBDD' : '', border: this.disabledConditions(values) ? '#DADBDD' : '0px', marginLeft: '12px' }} type='submit'>
																	PRÓXIMO
																	<FontAwesomeIcon icon={faArrowRight} style={{ fontSize: '15px' }} />
																</button>
															</>

														</Card>
													)}
												</>
											) : null}

											{this.state.step == 'ABANDONO_DETAILS_STEP' ?
												<>
													{this.state.success ? (
														<SuccessContainer title={'RECISÃO DE CONTRATO REGISTRADA!'} titleColor={'#1CBB8C'} styleContainer={{ backgroundColor: '#D6F3EA', border: '1px solid #D6F3EA', padding: '16px 24px 32px 24px' }}>
															<ul style={{ padding: '0px 24px', marginBottom: '0px' }}>
																<li>Seu pedido de rescisão de contrato foi iniciado..</li>
																<li>

																	{values.type === 'EXTRAJUDICIAL' ? 'Agora é conosco, nossa equipe jurídica vai entrar em ação.' : ''}
																	{values.type === 'TERMINATION_TERM' ? 'Todas as partes do contrato acabam de ser notificadas sobre o motivo do pedido de rescisão.' : ''}
																</li>
																<li>Lembre-se que a vistoria de saída do imóvel fica disponível {values.dataSaida && moment(values.dataSaida).isAfter(moment().add(30, 'days')) ? `30 dias antes da data de saída` : `até 5 dias depois da data de saída`} e, a partir dela, podem ser gerados valores para ressarcir alguma das partes do contrato.</li>
															</ul>
														</SuccessContainer>
													) : (
														<WarningContainer title={'Leia com atenção'} titleColor={'#FCB92C'} styleContainer={{ backgroundColor: '#FEF2D9', border: '1px solid #FCB92C', marginBottom: 24, padding: '16px 24px 32px 24px', border: 'none' }}>
															<ul style={{ padding: '0px 24px', marginBottom: '0px', border: 'none' }}>
																<li>Ao continuar seu pedido de rescisão de contrato será iniciado.</li>
																<li>Nossa equipe jurídica vai analisar o caso. Todas as partes do contrato serão notificadas.</li>
																<li>A vistoria de saída será liberada só quando o contrato for rescindido. </li>
															</ul>

														</WarningContainer>
													)}

													<Card className='card-termination'>
														{this.state.data?.entity && Object.keys(this.state?.simulation).length !== 0 && this.getParcelamento(values).length > 0 ? (
															<div style={{ borderRadius: '6px' }}>
																<p className='p-termination-1' style={{ padding: '8px 0px' }}>SIMULAÇÃO DAS PARCELAS DE SAÍDA DO IMÓVEL</p>
																<p className='p-termination-optional'>Esta simulação apresenta um quadro geral da situação atual do contrato, o valor ainda pode sofrer alterações. Mais funcionalidades serão liberadas ao confirmar a rescisão do contrato.</p>

																<div style={{ width: '100%', border: '1px solid var(--Cinza-300, #DADBDD)', borderRadius: '6px' }}>
																	{this.getParcelamento(values).map((obj, i) => (
																		<div style={{ width: '100%', display: 'flex', backgroundColor: i % 2 == 0 ? '#F1F5F7' : '#fff', justifyContent: 'space-between', padding: '10px 10px', alignItems: 'center', borderRadius: '6px' }}>
																			<div style={{ display: 'flex', flexDirection: 'column' }}>
																				<p className={obj.text.includes('Mell.ro') ? 'mellro-installment' : 'landlord-installment'}>Parcela {obj.parcela} - {obj.text}</p>

																			</div>
																			<div style={{ display: 'flex' }}>
																				<p style={{ margin: '0px', color: '#343A40', fontFamily: 'Inter' }}>Vence em {obj?.date ? obj.date : null}</p>
																				<p style={{ marginBottom: '0px', fontWeight: 'bold', color: '#343A40', fontFamily: 'Inter', marginLeft: '16px', width: '80px' }}>{formatBRL(Math.abs(obj.value))}</p>
																			</div>

																		</div>
																	))}
																</div>
															</div>
														) : null}
													</Card>

													<Card className='card-termination'>
														<div >
															<p className='p-termination-1' style={{ padding: '8px 0px' }}>EVIDÊNCIAS</p>

															{this.state.success ? null : (
																<p className='p-termination-optional'>Anexe abaixo alguma evidência de que o imóvel foi abandonado. As evidências podem ser <b>fotos do imóvel, vídeos, capturas de tela, documentos e/ou ata notarial</b>.</p>
															)}


															<FormGroup className="mb-0 checkBox-admin">
																{this.state.success ? null : (
																	<>
																		<Label className="col-form-label" style={{ color: '#343A40', fontSize: '13px' }}>Selecione o arquivo</Label>
																		<br />

																		<Row className="p-0 m-0">
																			<Col lg={9} md={12} className="p-0 m-0">
																				<TextField name="fileName" placeholder='Selecione um arquivo no seu computador' type="text" variant="outlined" value={values.fileName} onBlur={handleBlur} onChange={handleChange}
																					helperText={touched.fileName && errors.fileName} error={Boolean(touched.fileName && errors.fileName)} />
																			</Col>


																			<Col lg={3} md={12} className="marginInputMobile">
																				<label htmlFor={'file'} className="btn btn-secondary btn-bold mb-0 d-flex justify-content-center align-items-center" style={{ width: '100%', height: '41.09px', backgroundColor: '#5664D2' }}>
																					<input className="doc-juridico-select" type="file" id={'file'} style={{ display: 'none' }} name={'arquivosAbandono'} accept="*/*" onChange={(event) => { this.setState({ arquivosAbandono: [...this.state.arquivosAbandono, event.currentTarget.files[0]] }) }} />

																					IMPORTAR
																					<FontAwesomeIcon icon={faFileImport} style={{ marginLeft: '10px' }} />
																				</label>
																			</Col>
																		</Row>
																	</>
																)}

																{this.state.arquivosAbandono.length > 0 ?
																	(
																		<>
																			{this.state.arquivosAbandono.map((arquivo, i) => (
																				<div style={{ display: 'flex', padding: '20px 30px', marginTop: '10px', flexDirection: 'row', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'space-between', width: '100%', backgroundColor: '#F1F5F7' }}>
																					<p style={{ textOverflow: 'ellipsis', margin: '0px', color: '#4AA3FF' }}>{arquivo ? arquivo.name : null}</p>
																					<div style={{ display: 'flex', alignItems: 'center' }}>
																						<p style={{ marginBottom: '0px', height: '15px' }}>
																							{arquivo ? `${(arquivo?.size / 1000).toFixed(2)} Kb` : null}
																						</p>
																						<FontAwesomeIcon icon={faXmark} style={{ color: '#343A40', fontSize: '20px', cursor: 'pointer', marginLeft: '16px' }} onClick={() => this.setState({ arquivosAbandono: this.state.arquivosAbandono.filter(obj => obj !== arquivo) })} />

																					</div>
																				</div>
																			))}
																		</>
																	)
																	: (<div className='evidences-danger'>
																		<p>Adicione evidências</p>
																		<p>
																			Ao continuar você aceita os riscos de não ter anexado nenhuma evidência.
																		</p>
																	</div>)}
															</FormGroup>
														</div>
													</Card>

													<Card className='card-termination'>
														<div>
															<p className='p-termination-1' style={{ padding: '8px 0px' }}>PARA SEU CONHECIMENTO</p>

															<p className='p-termination-optional'>
																Se o imóvel foi abandonado, antes que o responsável possa retomar seu imóvel, precisamos <b>certificar que o imóvel foi realmente abandonado.</b>
																<br /><br />
																Não é recomendável você pular esta etapa de certificação, pois, caso o inquilino realize o pagamento e volte ao imóvel, ele poderá ter seu direito de moradia no imóvel ferido, alegando violação ao art. 22, II, da Lei de Locações (Lei nº 8.245/91).
																<br /><br />
																Para evitar essa violação, <b>o responsável</b> pelo imóvel possui duas <b>possibilidades:</b>
																<br /><br />

																<ol>
																	<li>Realizar a certificação de abandono por Oficial de Justiça. Para isto, você deverá entrar com uma ação indicando o abandono e pedindo a certificação via Oficial de Justiça; ou</li>
																	<li>Realizar a certificação através de uma Ata Notarial de Abandono. Esta ata pode ser feita com o Tabelionato de Notas mais próximo do responsável;</li>
																</ol>

																Nossa equipe jurídica recomenda que o responsável faça a Ata Notarial de Abandono, para que possa voltar a utilizar o imóvel sem maiores problemas.
															</p>
														</div>
													</Card>

													{this.state.success ? null : (
														<Card className='card-termination' style={{ padding: '0px' }}>
															<div style={{ padding: '16px 24px 16px 24px' }}>
																<Col md={12} style={{ marginLeft: "10px" }}>
																	<Input id='true' type="radio" name='confirm_termination' value='true'
																		onChange={handleChange} onBlur={handleBlur} color="primary"
																		checked={values['confirm_termination'] === 'true'}
																	/>
																	<p className='p-termination-input-value' style={{ padding: "3px", marginLeft: 2, marginBottom: 'confirm_termination' === 'confirm_termination' ? '0px' : '' }} for='true'>
																		Confirmo em criar essa rescisão e sei que esta ação é <small className='p-termination-input-value' style={{ color: '#FF3D60' }}>irreversível.</small>
																	</p>
																</Col>
															</div>
														</Card>
													)}

													{this.state.success ? null : (
														<Card className='card-termination' style={{ flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center', padding: '16px' }}>
															<>
																<button className="go-back-1" type='button' onClick={() => this.setState({ step: 'FIRST_STEP' })}>
																	<FontAwesomeIcon icon={faArrowLeft} style={{ fontSize: '15px' }} />VOLTAR
																</button>

																<button disabled={this.disabledConditions(values) || this.state.disable} className="continue-btn-1" style={{ backgroundColor: this.disabledConditions(values) ? '#DADBDD' : '', border: this.disabledConditions(values) ? '#DADBDD' : '0px', marginLeft: '12px' }} type='submit'>
																	CONFIRMAR RESCISÃO
																	<FontAwesomeIcon icon={faCheck} style={{ fontSize: '15px' }} />
																</button>
															</>

														</Card>
													)}
												</>
												: null}

											{this.state.step == 'REVIEW_STEP_PARCELAS' ?
												<>
													{this.state.success ? (
														<SuccessContainer title={'RECISÃO DE CONTRATO REGISTRADA!'} titleColor={'#1CBB8C'} styleContainer={{ backgroundColor: '#D6F3EA', border: '1px solid #D6F3EA', padding: '16px 24px 32px 24px' }}>
															<ul style={{ padding: '0px 24px', marginBottom: '0px' }}>
																<li>Seu pedido de rescisão de contrato foi iniciado..</li>
																<li>

																	{values.type === 'EXTRAJUDICIAL' ? 'Agora é conosco, nossa equipe jurídica vai entrar em ação.' : ''}
																	{values.type === 'TERMINATION_TERM' ? 'Todas as partes do contrato acabam de ser notificadas sobre o motivo do pedido de rescisão.' : ''}
																</li>
																<li>Lembre-se que a vistoria de saída do imóvel fica disponível {values.dataSaida && moment(values.dataSaida).isAfter(moment().add(30, 'days')) ? `30 dias antes da data de saída` : `até 5 dias depois da data de saída`} e, a partir dela, podem ser gerados valores para ressarcir alguma das partes do contrato.</li>
															</ul>
														</SuccessContainer>
													) : (
														<WarningContainer title={'Leia com atenção'} titleColor={'#FCB92C'} styleContainer={{ backgroundColor: '#FEF2D9', border: '1px solid #FCB92C', marginBottom: 24, padding: '16px 24px 32px 24px', border: 'none' }}>
															<ul style={{ padding: '0px 24px', marginBottom: '0px' }}>
																<li>Ao continuar seu pedido de rescisão de contrato será iniciado.</li>
																<li>Nossa equipe jurídica vai analisar o caso. Todas as partes do contrato serão notificadas.</li>
																<li>	A vistoria de saída será liberada só quando o contrato for rescindido.</li>
															</ul>

														</WarningContainer>
													)}

													<Card className='card-termination'>
														{this.state.data?.entity && Object.keys(this.state?.simulation).length !== 0 && this.getParcelamento(values).length > 0 ? (
															<div style={{ borderRadius: '6px' }}>
																<p className='p-termination-1' style={{ padding: '8px 0px' }}>SIMULAÇÃO DAS PARCELAS DE SAÍDA DO IMÓVEL</p>
																<p className='p-termination-optional'>Esta simulação apresenta um quadro geral da situação atual do contrato, o valor ainda pode sofrer alterações. Mais funcionalidades serão liberadas ao confirmar a rescisão do contrato.</p>

																<div style={{ width: '100%', border: '1px solid var(--Cinza-300, #DADBDD)', borderRadius: '6px' }}>
																	{this.getParcelamento(values).map((obj, i) => (
																		<div style={{ width: '100%', display: 'flex', backgroundColor: i % 2 == 0 ? '#F1F5F7' : '#fff', justifyContent: 'space-between', padding: '10px 10px', alignItems: 'center', borderRadius: '6px' }}>
																			<div style={{ display: 'flex', flexDirection: 'column' }}>
																				<p className={obj.text.includes('Mell.ro') ? 'mellro-installment' : 'landlord-installment'}>Parcela {obj.parcela} - {obj.text}</p>

																			</div>
																			<div style={{ display: 'flex' }}>
																				<p style={{ margin: '0px', color: '#343A40', fontFamily: 'Inter' }}>Vence em {obj?.date ? obj.date : null}</p>
																				<p style={{ marginBottom: '0px', fontWeight: 'bold', color: '#343A40', fontFamily: 'Inter', marginLeft: '16px', width: '80px' }}>{formatBRL(Math.abs(obj.value))}</p>
																			</div>

																		</div>
																	))}
																</div>
															</div>
														) : null}
													</Card>

													{this.state.simulation?.retroactive_billings_guaranteed?.value > 0 ? (
														<Card className='card-termination'>
															{this.state.data?.entity && Object.keys(this.state?.simulation).length !== 0 && this.getParcelamento(values).length > 0 ? (
																<div style={{ borderRadius: '6px' }}>
																	<p className='p-termination-1' style={{ padding: '8px 0px' }}>SIMULAÇÃO DAS PARCELAS DE DEVOLUÇÃO DE GARANTIA</p>
																	<p className='p-termination-optional'>Esta simulação apresenta um quadro geral da situação atual do contrato, o valor ainda pode sofrer alterações.Mais funcionalidades serão liberadas ao confirmar a rescisão do contrato.</p>

																	<div style={{ width: '100%', border: '1px solid var(--Cinza-300, #DADBDD)', borderRadius: '6px' }}>
																		{this.getParcelamentoReembolsoDeGarantia(values).map((obj, i) => (
																			<div style={{ width: '100%', display: 'flex', backgroundColor: i % 2 == 0 ? '#F1F5F7' : '#fff', justifyContent: 'space-between', padding: '10px 10px', alignItems: 'center', borderRadius: '6px' }}>
																				<div style={{ display: 'flex', flexDirection: 'column' }}>
																					<p className={obj.text.includes('Mell.ro') ? 'mellro-installment' : 'landlord-installment'}>Parcela {obj.parcela} - {obj.text}</p>

																				</div>
																				<div style={{ display: 'flex' }}>
																					<p style={{ margin: '0px', color: '#343A40', fontFamily: 'Inter' }}>A definir vencimento</p>
																					<p style={{ marginBottom: '0px', fontWeight: 'bold', color: '#343A40', fontFamily: 'Inter', marginLeft: '16px', width: '80px' }}>{formatBRL(Math.abs(obj.value))}</p>
																				</div>

																			</div>
																		))}
																	</div>
																</div>
															) : null}
														</Card>
													) : null}

													<Card className='card-termination'>
														<>
															<div>
																<p className='p-termination-1' style={{ padding: '8px 0px' }}>ARQUIVOS (OPCIONAL)</p>

																<FormGroup className="mb-0 checkBox-admin">
																	<Label className="col-form-label" style={{ color: '#343A40', fontSize: '13px', display: this.state.success ? 'none' : '' }}>Selecione o arquivo</Label>

																	<br style={{ display: this.state.success ? 'none' : '' }} />

																	<Row className="p-0 m-0" style={{ display: this.state.success ? 'none' : '' }}>
																		<Col lg={9} md={12} className="p-0 m-0">
																			<TextField name="fileName" placeholder='Selecione um arquivo no seu computador' type="text" variant="outlined" value={values.fileName} onBlur={handleBlur} onChange={handleChange}
																				helperText={touched.fileName && errors.fileName} error={Boolean(touched.fileName && errors.fileName)} />
																		</Col>

																		<Col lg={3} md={12} className="marginInputMobile">
																			<label htmlFor={'file'} className="btn btn-secondary btn-bold mb-0 d-flex justify-content-center align-items-center" style={{ width: '100%', height: '41.09px', backgroundColor: '#5664D2' }}>
																				<input className="doc-juridico-select" type="file" id={'file'} style={{ display: 'none' }} name={'arquivos'} accept="*/*" onChange={(event) => { this.setState({ arquivos: [...this.state.arquivos, event.currentTarget.files[0]] }) }} />

																				IMPORTAR
																				<FontAwesomeIcon icon={faFileImport} style={{ marginLeft: '10px', display: this.state.success ? 'none' : '' }} />
																			</label>
																		</Col>
																	</Row>

																	{this.state.arquivos.length > 0 ?
																		(
																			<>
																				{this.state.arquivos.map((arquivo, i) => (
																					<div style={{ display: 'flex', padding: '20px 30px', marginTop: '10px', flexDirection: 'row', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'space-between', width: '100%', backgroundColor: '#F1F5F7' }}>
																						<p style={{ textOverflow: 'ellipsis', margin: '0px', color: '#4AA3FF' }}>{arquivo?.name}</p>
																						<div style={{ display: 'flex', alignItems: 'center' }}>
																							<p style={{ marginBottom: '0px', height: '15px' }}>
																								{this.state.arquivos ? `${(arquivo?.size / 1000).toFixed(2)} Kb` : null}
																							</p>
																							<FontAwesomeIcon icon={faXmark} style={{ color: '#343A40', fontSize: '20px', cursor: 'pointer', marginLeft: '16px', display: this.state.success ? 'none' : '' }} onClick={() => this.setState({ arquivos: this.state.arquivos.filter(obj => obj !== arquivo) })} />

																						</div>
																					</div>
																				))}
																			</>
																		)
																		: null}
																</FormGroup>
															</div>
														</>
													</Card>

													{this.state.success ? null : (
														<Card className='card-termination' style={{ padding: '0px' }}>
															<div style={{ padding: '16px 24px 16px 24px' }}>
																<Col md={12} style={{ marginLeft: "10px" }}>
																	<Input id='true' type="radio" name='confirm_termination' value='true'
																		onChange={handleChange} onBlur={handleBlur} color="primary"
																		checked={values['confirm_termination'] === 'true'}
																	/>
																	<p className='p-termination-input-value' style={{ padding: "3px", marginLeft: 2, marginBottom: 'confirm_termination' === 'confirm_termination' ? '0px' : '' }} for='true'>
																		Confirmo em criar essa rescisão e sei que esta ação é <small className='p-termination-input-value' style={{ color: '#FF3D60' }}>irreversível.</small>
																	</p>
																</Col>
															</div>
														</Card>
													)}

													{this.state.success ? null : (
														<Card className='card-termination' style={{ flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center', padding: '16px' }}>
															<>
																<button className="go-back-1" type='button' onClick={() => this.setState({ step: 'FIRST_STEP' })}>
																	<FontAwesomeIcon icon={faArrowLeft} style={{ fontSize: '15px' }} />VOLTAR
																</button>

																<button disabled={this.disabledConditions(values) || this.state.disable} className="continue-btn-1" style={{ backgroundColor: this.disabledConditions(values) ? '#DADBDD' : '', border: this.disabledConditions(values) ? '#DADBDD' : '0px', marginLeft: '12px' }} type='submit'>
																	CONFIRMAR RESCISÃO
																	<FontAwesomeIcon icon={faCheck} style={{ fontSize: '15px' }} />
																</button>
															</>

														</Card>
													)}
												</>
												: null}

											{this.state.step == 'REVIEW_STEP_CLOSURE_TERM' ? (
												<>
													{this.state.success ? (
														<SuccessContainer title={'RECISÃO DE CONTRATO REGISTRADA!'} titleColor={'#1CBB8C'} styleContainer={{ backgroundColor: '#D6F3EA', border: '1px solid #D6F3EA', padding: '16px 24px 32px 24px' }}>
															<ul style={{ padding: '0px 24px', marginBottom: '0px' }}>
																<li>Seu pedido de rescisão de contrato foi iniciado.</li>
																<li>
																	Agora é conosco, nossa equipe jurídica vai entrar em ação.
																</li>
																<li>
																	Todas as partes do contrato acabam de ser notificadas sobre o motivo do pedido de rescisão.
																</li>
																<li>Lembre-se que a vistoria de saída do imóvel fica disponível {this.state?.data?.entity?.details?.end_date && moment(this.state?.data?.entity?.details?.end_date, 'MM/DD/YYYY').isAfter(moment().add(30, 'days')) ? `30 dias antes da data de saída` : `até 5 dias depois da data de saída`} e, a partir dela, podem ser gerados valores para ressarcir alguma das partes do contrato.</li>
															</ul>
														</SuccessContainer>
													) : (
														<WarningContainer title={'Leia com atenção'} titleColor={'#FCB92C'} styleContainer={{ backgroundColor: '#FEF2D9', border: '1px solid #FCB92C', marginBottom: 24, padding: '16px 24px 32px 24px', border: 'none' }}>
															<ul style={{ padding: '0px 24px', marginBottom: '0px' }}>
																<li>Ao continuar, o agendamento para o encerramento do contrato será na data definida.</li>
																<li>Todas as partes do contrato serão notificadas.</li>
																<li>A vistoria de saída será liberada só quando o contrato for encerrado.</li>
															</ul>

														</WarningContainer>
													)}

													{this.state.success ? null : (
														<Card className='card-termination' style={{ padding: '0px' }}>
															<div style={{ padding: '16px 24px 16px 24px' }}>
																<Col md={12} style={{ marginLeft: "10px" }}>
																	<Input id='true' type="radio" name='confirm_termination' value='true'
																		onChange={handleChange} onBlur={handleBlur} color="primary"
																		checked={values['confirm_termination'] === 'true'}
																	/>

																	<p className='p-termination-input-value' style={{ padding: "3px", marginLeft: 2, marginBottom: 'confirm_termination' === 'confirm_termination' ? '0px' : '' }} for='true'>
																		Confirmo em criar essa rescisão e sei que esta ação é <small className='p-termination-input-value' style={{ color: '#FF3D60' }}>irreversível.</small>
																	</p>
																</Col>
															</div>
														</Card>
													)}

													{this.state.success ? null : (
														<Card className='card-termination' style={{ flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center', padding: '16px' }}>
															<>
																<button className="go-back-1" type='button' onClick={() => this.setState({ step: 'FIRST_STEP' })}>
																	<FontAwesomeIcon icon={faArrowLeft} style={{ fontSize: '15px' }} />VOLTAR
																</button>

																<button disabled={this.disabledConditions(values) || this.state.disable} className="continue-btn-1" style={{ backgroundColor: this.disabledConditions(values) ? '#DADBDD' : '', border: this.disabledConditions(values) ? '#DADBDD' : '0px', marginLeft: '12px' }} type='submit'>
																	CONFIRMAR RESCISÃO
																	<FontAwesomeIcon icon={faCheck} style={{ fontSize: '15px' }} />
																</button>
															</>
														</Card>
													)}
												</>
											) : null}
										</div>

										<div style={{ width: '36%', paddingRight: '5%' }}>
											<Card className='card-termination' style={{ padding: '0px' }}>
												<div className='termination-right-card'>
													<p className='p-termination-1' style={{ padding: '8px 0px' }}>RESUMO</p>

													<div className='termination-right-wrapper'>
														<div className='termination-right-icon' style={{ background: (values.type == 'EXTRAJUDICIAL' || !values.dataSaida) && values.remain_in_property !== "no" ? '#EEE' : '' }}>
															<FontAwesomeIcon icon={values.remain_in_property !== "no" ? faCalendarXmark : faCalendarXmarkSolid} style={{ color: (values.type == 'EXTRAJUDICIAL' && values.remain_in_property !== "no") || (!values.dataSaida && values.type !== 'CLOSURE_TERM' && values.remain_in_property !== "no") ? '#fff' : '' }} />
														</div>

														<div className='termination-right-box'>
															{values.remain_in_property === 'no' ? (
																<p className='p-termination-input-label'>Data de desocupação</p>
															) :
																values.remain_in_property === 'no' ? (<p className='p-termination-input-label'>Data de desocupação</p>) : (<p className='p-termination-input-label'>{values.type === 'CLOSURE_TERM' ? 'Data de encerramento' : 'Data de rescisão'}</p>)
															}
															<p className='p-termination-input-value' style={{ fontWeight: '600', color: values.type == 'EXTRAJUDICIAL' ? '#8590A5' : '' }}>
																{values.dataSaida && values.remain_in_property !== 'no' && values.remain_in_property !== 'no' ? moment(values.dataSaida).format('DD/MM/YYYY HH:mm:ss') : ''}

																{values.dataSaida && values.remain_in_property === 'no' ? moment(values.dataSaida).format('DD/MM/YYYY') : ''}

																{!values.dataSaida && values.type == 'TERMINATION_TERM' ? '--' : ''}

																{values.type == 'EXTRAJUDICIAL' && values.remain_in_property !== 'no' ? 'Aguardar jurídico' : ''}

																{values.type == 'EXTRAJUDICIAL' && values.remain_in_property == 'no' && !values.dataSaida ? '--' : ''}

																{values.type == 'CLOSURE_TERM' ? moment(this.state?.data?.entity?.details?.end_date, 'MM/DD/YYYY').format('DD/MM/YYYY HH:mm:ss') : ''}

																{!values.type || values.type == '' ? '--' : ''}
															</p>
														</div>
													</div>

													<div className='termination-right-wrapper'>
														<div className='termination-right-icon'>
															<FontAwesomeIcon icon={faCalendarSolid} />
														</div>

														<div className='termination-right-box'>
															<p className='p-termination-input-label'>Data de requisição</p>
															<p className='p-termination-input-value' style={{ fontWeight: '600' }}>{moment().format('DD/MM/YYYY HH:mm:ss')}</p>
														</div>
													</div>

													<div className='termination-right-wrapper'>
														<div className='termination-right-icon' style={{ background: values.termination_requested_by === '' ? '#EEE' : '' }}>
															<FontAwesomeIcon icon={faUser} style={{ color: values.termination_requested_by === '' ? '#fff' : '' }} />
														</div>

														<div className='termination-right-box'>
															<p className='p-termination-input-label'>Quem fez a requisição</p>

															<p className='p-termination-input-value' style={{ fontWeight: '600', color: values.termination_requested_by === '' ? '#8590A5' : '' }}>
																{values.termination_requested_by === 'landlord' ? 'Responsável' : ''}

																{values.termination_requested_by === 'tenant' ? 'Inquilino' : ''}

																{values.termination_requested_by === '' ? '--' : ''}
															</p>
														</div>
													</div>

													<div className='termination-right-wrapper'>
														<div className='termination-right-icon' style={{ background: values.type === '' ? '#EEE' : '' }}>
															<FontAwesomeIcon icon={faArrowRightFromBracket} style={{ color: values.type === '' ? '#fff' : '' }} />
														</div>

														<div className='termination-right-box'>
															<p className='p-termination-input-label'>Motivo da saída</p>

															<p className='p-termination-input-value' style={{ fontWeight: '600', color: values.type === '' ? '#8590A5' : '', maxWidth: '215px' }}>
																{values.type === 'TERMINATION_TERM' ? 'Rescisão de comum acordo' : ''}

																{values.type === 'EXTRAJUDICIAL' ? (<>
																	Descumprimento de contrato

																	<small className='p-termination-1 '>
																		{values?.details ? (<><br /> {values.details}</>) : null}
																	</small>
																</>) : ''}
																{values.type === 'CLOSURE_TERM' ? 'Encerramento de contrato' : ''}
																{values.type === '' ? '--' : ''}
															</p>
														</div>
													</div>

													{values.type !== '' && values.termination_requested_by !== '' && values.type !== 'CLOSURE_TERM' ? (
														<>
															<hr className='termination-divisor' />

															<div>
																<SimulacaoValues values={values} handleBlur={handleBlur} touched={touched} errors={errors} handleChange={handleChange} />
															</div>
														</>
													) : null}
												</div>
											</Card>
										</div>
									</form>
								)}
							</Formik>
						</Container>
					</div>
				</BetterCircularProgress>
			</React.Fragment>
		)
	}
}

export default ContractsTermination;