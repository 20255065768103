import { Filter } from 'glin-profanity';

export const configureProfanityFilter = () => {
    const filter = new Filter({
        wordBoundaries: true,
        languages: ['portuguese'],
        allLanguages: false,
        caseSensitive: true
    });

    return filter;
};

const whitelist = [
    'veículos', 'veiculos', 'circulo', 'circular', 'calcular',
    'particular', 'facultativo', 'articula', 'veiculo',
    'documento', 'documentação', 'ocupação', 'ocupar',
    'segurança', 'documentos', 'circulação', 'facultativo',
    'cuidado', 'cuidadoso', 'calcule', 'ocupe'
];

// Prefixos e sufixos comuns no br
const commonPatterns = [
    { pattern: /c[uú]/, validSuffixes: ['a', 'e', 'i', 'o', 'tura', 'tural', 'tivo', 'ar', 'ado', 'ação', 'os', 'as', 'ba', 'ra'] },
    { pattern: /oc[uú]/, validPrefixes: ['d', 'f', 'l', 'pr'] },
    { pattern: /c[uú]/, validPrefixes: ['cal', 'cir', 'do', 'fa', 'vin', 'se', 'va'] }
];

export const checkTextForProfanity = (text) => {
    if (!text) return false;

    const filter = configureProfanityFilter();
    const profanityCheck = filter.checkProfanity(text);

    if (profanityCheck.containsProfanity) {
        // Pega os palavrões encontrados
        const words = profanityCheck.profaneWords;

        // Verifica cada palavrão
        for (const word of words) {
            // Limpa a palavra de pontuações
            const cleanWord = word.replace(/[^\w\sáàâãéèêíìîóòôõúùûçñ]/gi, '').toLowerCase();

            // 	Whitelist, pula
            if (whitelist.some(allowedWord => cleanWord === allowedWord)) {
                continue;
            }

            // Análise contextual baseada em padrões comuns br
            let isLegitimateWord = false;

            // Verifica padrões comuns em português que podem gerar falsos positivos
            for (const { pattern, validPrefixes, validSuffixes } of commonPatterns) {
                if (pattern.test(cleanWord)) {
                    if ((validPrefixes && validPrefixes.some(p => cleanWord.startsWith(p))) || (validSuffixes && validSuffixes.some(s => cleanWord.endsWith(s)))) {
                        isLegitimateWord = true;
                        break;
                    }
                }
            }

            // Se a palavra passou nos padrões, pula
            if (isLegitimateWord) {
                continue;
            }

            // Se a palavra é muito longa pula
            if (cleanWord.length > 10 && !filter.isProfane(cleanWord.substring(0, 10)) &&
                !filter.isProfane(cleanWord.substring(cleanWord.length - 10))) {
                continue;
            }

            // Verificação final
            if (filter.isProfane(cleanWord)) {
                return true; // Encontrou palavrão.
            }
        }

        return false; // Não encontrou nada.
    }

    return false; // Não encontrou nada.
}; 