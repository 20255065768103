import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import _ from 'lodash';
import { formatBRL } from '../../../helpers/Utils';
import moment from 'moment';
import { Link } from 'react-router-dom';


export default class DataTableLogsContrato extends Component {
	constructor() {
		super();

		this.state = {
			data: [],
			rows: 10,
			totalRecords: 0,
			first: 0,
			last: 0,
			loading: false,
			sortField: 'created_at',
			sortOrder: '1',
		};

		this.onPage = this.onPage.bind(this);
	}

	componentDidMount() {
		let DataTableLogsContrato = JSON.parse(localStorage.getItem('DataTableLogsContrato'));

		if (DataTableLogsContrato) {
			this.setState({
				rows: DataTableLogsContrato.rows,
				totalRecords: DataTableLogsContrato.totalRecords,
				first: DataTableLogsContrato.first,
				last: DataTableLogsContrato.last,
				loading: DataTableLogsContrato.loading,
				sortField: DataTableLogsContrato.sortField,
				sortOrder: DataTableLogsContrato.sortOrder,
			}, () => {
				this.fetchData();
			});
		} else {
			this.fetchData();
		}
	}

	componentDidUpdate(prevProps) {
		if (prevProps.filters != this.props.filters) {
			this.onFilterChanged();
		}
	}

	onFilterChanged = _.debounce(() => {
		if (!this.state.isLoading) {
			this.fetchData();
		}
	}, 700);

	refreshTable() {
		this.onPage(this.state);
	}

	onPage = async (event) => {
		if (event.data) {
			event.data = null;
		}

		let DataTableLogsContrato = {
			rows: event.rows,
			totalRecords: this.state.totalRecords,
			first: event.first,
			last: event.rows + event.first,
			loading: this.state.loading,
			sortField: this.state.sortField,
			sortOrder: this.state.sortOrder,
		};

		localStorage.setItem('DataTableLogsContrato', JSON.stringify(DataTableLogsContrato));

		this.setState({
			...this.state,
			rows: event.rows,
			loading: true
		});

		this.fetchData(event.rows, event.first);
	};

	fetchData = (limit, skip) => {
		let data = {
			$limit: limit ? limit : this.state.rows,
			$skip: skip != null ? skip : this.state.first,
			$sort: { [this.state.sortField]: this.state.sortOrder }
		};

		this.props.fetchEvent(data).then(async (res) => {
			if (res?.data) {
				this.setState({
					...this.state,
					data: res.data.data,
					totalRecords: res.data.total,
					first: res.data.skip,
					last: res.data.skip + res.data.limit,
					loading: false
				});
			}
		});
	};

	sortTable = e => {
		this.setState({ sortField: e.sortField, sortOrder: e.sortOrder }, () => {
			let DataTableLogsContrato = {
				rows: this.state.rows,
				totalRecords: this.state.totalRecords,
				first: this.state.first,
				last: this.state.last,
				loading: this.state.loading,
				sortField: this.state.sortField,
				sortOrder: this.state.sortOrder,
			};

			localStorage.setItem('DataTableLogsContrato', JSON.stringify(DataTableLogsContrato));

			this.fetchData();
		});
	};

	getContractAdminLogText = (operation, log, user = null) => {
		switch (operation) {
			case 'RESTORE_CONTRACT':
				return (
					<>
						<p>{`Data de rescisão anterior: ${moment(log?.terminated_at_before).format('DD/MM/YYYY HH:mm:ss')}`}</p>

						{log?.terminated_by_before &&
							<Link to={`/users/show/${log?.terminated_by_before}`}>
								{`Rescindido por: ${log?.terminated_by_before ?? 'N/A'}`}
							</Link>
						}
					</>
				)
			case 'comment':
				return (
					<p>{log?.comment ?? log}</p>
				)
			case 'CONTRACT_CREATED':
				return 'Contrato criado';
			case 'SIGNED_BY':
				// return 'Assinado por';
				console.log({ log })
				return (
					<>
						{user?.email &&
							<Link to={`/users/show/${user?.id}`}>
								{`Assinado por: ${user?.email ?? 'N/A'}`}
							</Link>
						}
					</>
				)
			case 'ALL_SIGNED':
				return 'Contrato assinado por todas as partes';
			case 'TERMINATION_CANCELED_LACK_SIGNATURE':
				return 'Rescisão de contrato for cancelada por falta de assinaturas';
			case 'EXPIRED_LACK_SIGNATURE':
				return 'Contrato expirado por falta de assinatura';
			case 'FIRST_PAYMENT_CREATED':
				return 'Primeiro pagamento criado';
			case 'FIRST_PAYMENT_PAID':
				return 'Primeiro pagamento reconhecido como pago';
			case 'TERMINATED_LACK_FIRST_PAYMENT':
				return 'Contrato rescindido automaticamente por falta do primeiro pagamento';
			case 'SCHEDULED_TERMINATION':
				return 'Contrato agendado para rescisão';
			case 'ENTRY_PROPERTY_INSPECTION_CREATED':
				return 'Vistoria de entrada iniciada';
			case 'ENTRY_PROPERTY_INSPECTION_FINISHED':
				return 'Vistoria de entrada encerrada';
			case 'EXIT_PROPERTY_INSPECTION_CREATED':
				return 'Vistoria de saída iniciada';
			case 'EXIT_PROPERTY_INSPECTION_FINISHED':
				return 'Vistoria de saída encerrada';
			case 'CONTRACT_TERMINATED':
				return 'Contrato rescindido/encerrado';
			default:
				return 'N/A';
		}
	};

	getContractOperation = (operation) => {
		switch (operation) {
			case 'RESTORE_CONTRACT':
				return 'Contrato restaurado';
			case 'comment':
				return 'Comentário';
			case 'CONTRACT_CREATED':
				return 'Contrato';
			case 'SIGNED_BY':
				return 'Assinatura';
			case 'TERMINATION_CANCELED_LACK_SIGNATURE':
				return 'Rescisão';
			case 'ALL_SIGNED':
				return 'Assinatura';
			case 'EXPIRED_LACK_SIGNATURE':
				return 'Rescisão';
			case 'FIRST_PAYMENT_CREATED':
				return 'Pagamentos';
			case 'FIRST_PAYMENT_PAID':
				return 'Pagamentos';
			case 'TERMINATED_LACK_FIRST_PAYMENT':
				return 'Rescisão';
			case 'SCHEDULED_TERMINATION':
				return 'Rescisão';
			case 'ENTRY_PROPERTY_INSPECTION_CREATED':
				return 'Vistoria';
			case 'ENTRY_PROPERTY_INSPECTION_FINISHED':
				return 'Vistoria';
			case 'EXIT_PROPERTY_INSPECTION_CREATED':
				return 'Vistoria';
			case 'EXIT_PROPERTY_INSPECTION_FINISHED':
				return 'Vistoria';
			case 'CONTRACT_TERMINATED':
				return 'Rescisão';
			default:
				return 'N/A';
		}
	};

	getUserLink = (user) => {
		return (
			user?.id == '62afd7c2-1d34-4347-9d9a-e4c65d0711b6' ?
				<p style={{ marginBottom: 0 }}>
					Admin Mellro
				</p>
				:
				<Link to={`/users/show/${user?.id}`}>
					{user?.name ?? 'N/A'}
				</Link>
		);
	}

	render() {
		return (
			<DataTable ref={(el) => this.dt = el} value={this.state.data} paginator={true} rows={this.state.rows} rowsPerPageOptions={[10, 25, 50]}
				first={this.state.first} last={this.state.last} header={null} responsive={true} className="ml-auto"
				totalRecords={this.state.totalRecords} lazy={true} onPage={this.onPage} loading={this.state.loading} loadingIcon={`fas fa-sync fa-spin`}
				paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
				currentPageReportTemplate="Mostrando {first} até {last} de {totalRecords} registros" emptyMessage="Nenhum registro encontrado!"
				onSort={(e) => this.sortTable(e)} sortField={this.state.sortField} sortOrder={this.state.sortOrder}>
				<Column sortable field="created_at" body={({ created_at }) => moment(created_at).format('DD/MM/YYYY HH:mm:ss')} header="Data" />
				<Column field={({ user }) => this.getUserLink(user)} header="Usuário" body={({ user }) => this.getUserLink(user)} />
				<Column field="type" header="Categoria" body={({ operation }) => this.getContractOperation(operation)} />
				<Column field="description" header="Informação" body={({ operation, log, user }) => this.getContractAdminLogText(operation, log, user)} style={{ maxWidth: '60%', width: '100%' }} />
			</DataTable>
		);
	}
}