import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { isObject } from 'lodash';

dayjs.extend(isSameOrBefore);
const regExp = new RegExp(['-|_'], 'g');

export const formatDate = ({ date, format }) => {
	return dayjs(date).format(format);
};

export const isBefore = (date) => {
	return dayjs().isSameOrBefore(dayjs(date));
};

export const convertToBool = (value) => {
	return ['true', true].includes(value);
};

export const userFilter = (option, { inputValue }) =>
	option.filter(
		({ name, email, phone }) =>
			name?.toLowerCase().includes(inputValue.toLowerCase()) ||
			email?.toLowerCase().includes(inputValue.toLowerCase()) ||
			phone?.includes(inputValue.toLowerCase()),
	);

export const findPlanByName = (plan, plans) => {
	return plans.find(obj => obj?.name === plan);
};

export const contractFilter = (option, { inputValue }) =>
	option.filter(
		({ friendly_code }) =>
			friendly_code?.toLowerCase().includes(inputValue.toLowerCase())
	);

export const safeJsonParser = (obj) => {
	if (obj && isObject(obj)) {
		return obj;
	} else {
		try {
			return JSON.parse(obj);
		} catch (_) {
			obj = {};
		}
	}
};

export const safeJsonStringify = (obj) => {
	if (obj && isObject(obj)) {
		return JSON.stringify(obj);
	} else {
		return obj
	}
};

export const getPayDay = (data) => {
	return dayjs(data).get('date')
};

export const snakeToNormalCase = (string) => {
	if (typeof string !== 'string') return;
	return string.replace(regExp, ' ')
}

export const formatBRL = (value) => {
	const formatter = new Intl.NumberFormat("pt-BR", {
		style: "currency",
		currency: "BRL",
		minimumFractionDigits: 2,
	});

	value = formatter.format(value);

	return value;
};

export const formatBRLInput = (input) => {
	input.target.value =
		Number(input.target.value.toString().replace(/[^0-9-]/g, "")) / 10 ** 2;

	input.target.value = formatBRL(input.target.value);

	return input;
};

export const limparMoeda = (valor) => {
	valor = valor.replace("R$", "");
	valor = valor.replace(".", "");
	valor = valor.replace(",", ".");
	valor = valor.replace("/^p{Z}+|p{Z}+$/u", "");
	valor = valor.trim();

	if (valor == '') return 0;

	return valor;
};

export const messageError = (err) => {
	if (err == 'Request failed with status code 422') {
		return 'A requisição falhou com o código de status 422';
	}

	if (err === '"details.duration" must be greater than or equal to 3') {
		return 'O campo Tempo de contrato precisa ser maior ou igual a 3';
	}

	if (err == 'Invalid Data') {
		return 'Dados invalidos';
	}

	return err;
}

export const getLaudoInfo = (laudo) => {
	let color = '#343A40';
	let text = 'Não consultado';
	let parecer_key = null;

	if (!laudo) {
		return { color, text };
	}

	if (laudo?.analysis_status) {
		parecer_key = laudo.analysis_status;
	} else {
		if (laudo?.fc_laudo?.humanized_analysis) {
			parecer_key = 'APPROVED_HUMANIZED';
		} else {
			parecer_key = laudo?.fc_laudo?.parecer?.locacao?.parecer_inquilinos?.parecer_key
		}
	}

	if (parecer_key) {
		switch (parecer_key) {
			case 'APPROVED':
				text = "Aprovado";
				color = '#1CBB8C';
				break;
			case 'REFUSED':
				text = "Recusada";
				color = '#FF3D60';
				break;
			case 'AWAITING':
				text = "Aguardando";
				color = '#FCB92C';
				break;
			case 'NOT_CONSULTED':
				text = "Não consultado";
				color = '#8590A5';
				break;
			case 'APPROVED_SYSTEM':
				text = "Aprovado sistêmico";
				color = '#4AA3FF';
				break;
			case 'APPROVED_HUMANIZED':
				text = "Aprovado humanizado";
				color = '#5664D2';
				break;
			case 'EXAUSTED_ANALYSIS':
				text = "Consultas esgotadas";
				color = '#EFA200';
				break;
			case 'FRAUD':
				text = "Fraude";
				color = '#852032';
				break;
			case 'PARECER_SUSPEITA_OBITO':
				text = "Suspeita de óbito";
				color = '#FF3D60';
				break;
			case 'PARECER_NAO_APURADO':
				text = "Não apurado";
				color = '#FF3D60';
				break;
			case 'PARECER_NIVEL_1':
				text = "Risco muito baixo";
				color = '#1CBB8C';
				break;
			case 'PARECER_NIVEL_2':
				text = "Risco baixo";
				color = '#1CBB8C';
				break;
			case 'PARECER_NIVEL_3':
				text = "Risco médio";
				color = '#4AA3FF';
				break;
			case 'PARECER_NIVEL_4':
				text = "Risco acima da média";
				color = '#FF3D60';
				break;
			case 'PARECER_NIVEL_5':
				text = "Risco muito alto";
				color = '#FF3D60';
				break;
			case 'ALERTA_FRAUDE':
				text = "Alerta de fraude";
				color = '#FF3D60';
				break;
			case 'ALERTA_FRAUDE_CRIMINAL':
				text = "Alerta de fraude criminal";
				color = '#FF3D60';
				break;
			case 'ALERTA_ACAO_CRIMINAL':
				text = "Alerta de ação criminal";
				color = '#FF3D60';
				break;
			case 'ALERTA_ACAO_CIVIL':
				text = "Alerta de ação civil";
				color = '#FF3D60';
				break;
			default:
				color = '#343A40';
				text = 'Não informado';
				break;
		}
	} else {
		color = '#FCB92C';
		text = 'Aguardando';
	}

	return { color, text };
}

export const getLaudoBadge = (laudo) => {
	const { color, text } = getLaudoInfo(laudo)

	return (
		<div style={{ width: 'fit-content', backgroundColor: color, padding: '4px 12px', borderRadius: '2px' }}>
			<p style={{ fontWeight: '400', fontSize: '13px', marginBottom: '0', color: '#fff' }}>{text}</p>
		</div>
	)
}

export const getLaudoBadgeInquilino = (parecer_key) => {
	let color = '#343A40';
	let text = 'Não informado';

	if (parecer_key) {
		switch (parecer_key) {
			case 'PARECER_SUSPEITA_OBITO':
				text = "Suspeita de óbito";
				color = '#FF3D60';
				break;
			case 'PARECER_NAO_APURADO':
				text = "Não apurado";
				color = '#FF3D60';
				break;
			case 'PARECER_NIVEL_1':
				text = "Risco muito baixo";
				color = '#1CBB8C';
				break;
			case 'PARECER_NIVEL_2':
				text = "Risco baixo";
				color = '#1CBB8C';
				break;
			case 'PARECER_NIVEL_3':
				text = "risco médio";
				color = '#4AA3FF';
				break;
			case 'PARECER_NIVEL_4':
				text = "Risco acima da média";
				color = '#FF3D60';
				break;
			case 'PARECER_NIVEL_5':
				text = "Risco muito alto";
				color = '#FF3D60';
				break;
			case 'ALERTA_FRAUDE':
				text = "Alerta de fraude";
				color = '#FF3D60';
				break;
			case 'ALERTA_FRAUDE_CRIMINAL':
				text = "Alerta de fraude criminal";
				color = '#FF3D60';
				break;
			case 'ALERTA_ACAO_CRIMINAL':
				text = "Alerta de ação criminal";
				color = '#FF3D60';
				break;
			default:
				color = '#343A40';
				text = 'Não informado';
				break;
		}
	} else {
		color = '#FCB92C';
		text = 'Aguardando';
	}

	return (
		<div style={{ width: 'fit-content', backgroundColor: color, padding: '4px 12px', borderRadius: '2px' }}>
			<p style={{ fontWeight: '400', fontSize: '13px', marginBottom: '0', color: '#fff' }}>{text}</p>
		</div>
	)
}

export function isOdd(num) {
	if (num % 2 === 1) {
		return true;
	}
	return false;
}

export const ocorrenciasGet = (values) => {
	const ocorrenciaList = {
		context: ['Geral', 'Financeiro', 'Jurídico', 'Contrato'],
		reason: [],
		type: [],
		result: [],
		status: ['Aberta', 'Em andamento', 'Finalizada']
	};

	switch (values.context) {
		case 'Geral':
			ocorrenciaList.reason = ['Comentário'];
			break;
		case 'Financeiro':
			ocorrenciaList.reason = ['Comentário', 'Análise financeira', 'Inadimplência', 'Em acordo'];
			switch (values.reason) {
				case 'Análise financeira':
					ocorrenciaList.type = ['Atrasada', 'Não processada'];
					if (values.type === 'Atrasada' || values.type === 'Não processada') {
						ocorrenciaList.result = ['Comunicação enviada'];
					}
					break;
				case 'Inadimplência':
					ocorrenciaList.type = ['Atrasado', 'Inadimplente'];
					if (values.type === 'Atrasado' || values.type === 'Inadimplente') {
						ocorrenciaList.result = ['Comunicação enviada'];
					}
					break;
				case 'Em acordo':
					ocorrenciaList.type = ['Atrasado', 'Inadimplente'];
					if (values.type === 'Atrasado' || values.type === 'Inadimplente') {
						ocorrenciaList.result = ['Pago', 'Comunicação enviada'];
					}
					break;
				default:
					break;
			}
			break;
		case 'Jurídico':
			ocorrenciaList.reason = ['Comentário', 'Negociação', 'Ação'];
			switch (values.reason) {
				case 'Negociação':
					ocorrenciaList.type = ['Procuração do proprietário', 'Notificação extrajudicial'];
					break;
				case 'Ação':
					ocorrenciaList.type = ['Despejo', 'Execução de dívida', 'Cobrança'];
					if (values.type === 'Despejo' || values.type === 'Execução de dívida' || values.type === 'Cobrança') {
						ocorrenciaList.result = ['Cumprimento de sentença', 'Resolvido judicialmente', 'Resolvido amigavelmente'];
					}
					break;
				default:
					break;
			}
			break;
		case 'Contrato':
			ocorrenciaList.reason = ['Comentário', 'Encerramento', 'Recisão', 'Aditivo'];
			switch (values.reason) {
				case 'Recisão':
					ocorrenciaList.type = ['Acordo', 'Quebra de contrato'];
					if (values.type === 'Acordo' || values.type === 'Quebra de contrato') {
						ocorrenciaList.result = ['Simples', 'Inadimplência', 'Abandono de imóvel'];
					}
					break;
				case 'Aditivo':
					ocorrenciaList.type = ['Alteração de valor', 'Alteração cadastral'];
					break;
				default:
					break;
			}
			break;
		default:
			break;
	}

	return ocorrenciaList;
};


export const getLaudoTag = (laudo) => {
	const { color, text } = getLaudoInfo(laudo)

	return (
		<div style={{ width: 'fit-content', backgroundColor: color, padding: '4px 12px', borderRadius: '2px' }}>
			<p style={{ fontWeight: '400', fontSize: '13px', marginBottom: '0', color: '#fff' }}>{text}</p>
		</div>
	)
}

export const getSelectFilterAnalise = (status) => {
	if (status == '1') {
		return [
			{ label: 'Aguardando', value: 'AWAITING' },
			{ label: 'Não consultado', value: 'NOT_CONSULTED' },
			{ label: 'Consultas esgotadas', value: 'EXAUSTED_ANALYSIS' },
		]
	}

	if (status == '2') {
		return [
			{ label: 'Aprovado', value: 'APPROVED' },
			{ label: 'Aprovado sistêmico', value: 'APPROVED_SYSTEM' },
			{ label: 'Aprovado humanizado', value: 'APPROVED_HUMANIZED' },
		]
	}

	if (status == '3') {
		return [
			{ label: 'Recusada', value: 'REFUSED' },
			{ label: 'Fraude', value: 'FRAUD' },
			// { label: 'Suspeita de óbito', value: 'PARECER_SUSPEITA_OBITO' },
			{ label: 'Não apurado', value: 'PARECER_NAO_APURADO' },
			// { label: 'Alerta de fraude', value: 'ALERTA_FRAUDE' },
			// { label: 'Alerta de fraude criminal', value: 'ALERTA_FRAUDE_CRIMINAL' },
			// { label: 'Alerta de ação criminal', value: 'ALERTA_ACAO_CRIMINAL' },
			// { label: 'Alerta de ação civil', value: 'ALERTA_ACAO_CIVIL' },
		]
	}
}
