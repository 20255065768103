import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { get, get as getUser } from '../../crud/users.crud';
import { get as getContract } from '../../crud/contracts.crud';
import { formatBRL, formatBRLInput, limparMoeda } from "../../helpers/Utils";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import moment from "moment";
import { Button } from "reactstrap";
import { createRentBoleto, getById as getBillingById } from "../../crud/billings.crud";
import smile from '../../assets/images/icons-custom/face-smile.png';
import Modal from 'react-bootstrap/Modal';
import { Formik } from "formik";
import { Checkbox, TextField } from "@material-ui/core";
import { formatDate } from "../../pages/negotiation/helpers";
import { createFormikValidation } from "../../helpers/FormValidators";
import { update as updateDocUser } from "../../crud/doc-users.crud";

export default class DataTableConbracasJuridicas extends Component {
	constructor() {
		super();

		this.state = {
			property: {},
			data: null,
			rows: this.props?.rows || 5,
			totalRecords: 0,
			first: 0,
			last: 0,
			loading: false,
			sortField: 'created_at',
			sortOrder: 1,
			showModal: false,
			decision: 'WON',
			errors: null,
			selectedDocId: null,
		};

		this.onPage = this.onPage.bind(this);
	}

	componentDidMount() {
		this.fetchData(this.props.rows);

		if (this.props.rows) {
			this.setState({ rows: this.props.rows })
		}
	}

	componentDidUpdate(prevProps) {
		if (prevProps.filters !== this.props.filters) {
			this.fetchData();
		}
	}

	refreshTable() {
		this.onPage(this.state);
	}

	onPage = async (event) => {
		this.setState({
			loading: true,

		});
		if (event.data) {
			event.data = null;
		}

		let cobrancasJuridicasTable = {
			rows: event.rows,
			totalRecords: this.state.totalRecords,
			data: this.data,
			first: event.first,
			last: event.rows + event.first,
			loading: this.state.loading,
			sortField: this.state.sortField,
			sortOrder: this.state.sortOrder,
		};

		localStorage.setItem("cobrancasJuridicasTable", JSON.stringify(cobrancasJuridicasTable));

		this.setState({
			...this.state,
			rows: event.rows,
		});
		this.fetchData(event.rows, event.first);
	};

	fetchData = async (limit, skip) => {
		this.setState({ loading: true });

		let options = {
			$limit: limit ? limit : this.state.rows,
			$skip: skip != null ? skip : this.state.first,
			$sort: { ['status']: '-1' },
			category: ['Cobranças jurídicas', 'Sentença', 'Processo conhecimento', 'Processo fase de execução', 'Termo de confissão de dívida']
		};

		return this.props.fetchEvent(options).then(async (res) => {
			if (res.data.data.length == 0) {
				this.setState({
					...this.state,
					data: [],
					totalRecords: res.data.total,
					first: skip,
					last: skip + res.data.limit,
					loading: false
				});
			}

			await res.data.data.map(async (obj, i) => {
				if (obj.contract_id) {
					obj.loading_contract = true;
					await getContract(obj.contract_id).catch(err => {
						obj.contract = null;
						obj.loading_contract = false;
					}).then(res => {
						if (res?.data?.data) {
							obj.contract = res.data.data;
						}
					});
				}

				this.setState({
					...this.state,
					data: res.data.data,
					totalRecords: res.data.total,
					first: skip,
					last: skip + res.data.limit,
					loading: false
				});
			});
		}).finally(() => {
			this.setState({ loading: false });
		});
	};

	sortTable = async (e) => {
		this.setState({ sortField: e.sortField, sortOrder: e.sortOrder, loading: true }, () => {
			let cobrancasJuridicasTable = {
				rows: this.state.rows,
				totalRecords: this.state.totalRecords,
				first: this.state.first,
				last: this.state.last,
				loading: this.state.loading,
				sortField: this.state.sortField,
				sortOrder: this.state.sortOrder,
				data: this.state.data
			};
			localStorage.setItem("cobrancasJuridicasTable", JSON.stringify(cobrancasJuridicasTable));

			this.fetchData();
		});
	};

	getValue = (obj) => {
		return (
			<div style={{ textAlign: "center" }}>
				<p>{formatBRL(obj || 0)}</p>
			</div>
		);
	};

	getUserName = (doc) => {
		return (
			<div style={{ textAlign: "center" }}>
				<Link to={`/users/show/${doc.created_by}`}>
					{doc?.user?.name}
				</Link>
			</div>
		)
	};

	getCity = (obj) => {
		if (obj?.property) {
			return (
				<div style={{ textAlign: "center" }}>
					<Link to={`/announcements/show/${obj.property?.id}`}>{obj.property?.address?.city}</Link>
				</div>
			);
		} else {
			return (
				<div style={{ textAlign: "center" }}>
					Não encontrado
				</div>
			)
		}
	};

	getNeighbourhood = (obj) => {
		return (
			<div style={{ textAlign: "center" }}>
				<Link to={`/announcements/show/${obj.property?.id}`}>
					{obj.property?.address?.neighbourhood}
				</Link>
			</div>
		);
	};

	getStatus = (obj) => {
		if (obj?.status === 'OPEN' || obj?.status === null) {
			return (
				<div className="bg-warning d-flex flex-row" style={{ width: '98px', fontSize: '11px', lineHeight: '16px', height: '24px', alignItems: 'center' }}>
					<p className="col-form-label text-white">Em aberto</p>
				</div>
			)
		}
		if (obj?.status === 'WON') {
			return (
				<div className="bg-success d-flex flex-row" style={{ width: '58px', fontSize: '11px', lineHeight: '16px', height: '24px', alignItems: 'center' }}>
					<p className="col-form-label text-white">Ganho</p>
				</div>
			)
		}
		if (obj?.status === 'LOST') {
			return (
				<div className="bg-danger d-flex flex-row" style={{ width: '98px', fontSize: '11px', lineHeight: '16px', height: '24px', alignItems: 'center' }}>
					<p className="col-form-label text-white">Perdido</p>
				</div>
			)
		}
	};

	getDateFormatted = (date) => {
		return (
			<p>
				{date ? moment(date).add(1, 'day').format("DD/MM/YYYY") : '-'}
			</p>
		);
	};

	getStatusProposition = (obj) => {
		let status = '';
		switch (obj.status) {
			case 'FIRST_PROPOSAL':
				status = 'Criada Admin';
				break;
			case 'AWAITING_SIGN':
				status = 'Aguardando sinal';
				break;
			case 'FINALIZED':
				status = 'Finalizado';
				break;
			default:
				status = obj.status;
				break;
		}
		return (
			<div style={{ textAlign: "center" }}>
				<p>{status}</p>
			</div>
		);
	}

	showModal = (obj) => {
		this.handleShow();
		this.setState({ selectedDocId: obj.id })
	}

	columnOptionsCrud = (obj) => {
		if (this.props.crudUrl) {
			return (
				<div style={{ textAlign: "start" }}>
					{obj?.status === 'OPEN' || obj?.status === null ? (
						<Button className="mr-2" style={{ background: 'transparent', borderColor: 'transparent' }} onClick={() => this.showModal(obj)}>
							<i className="fas fa-check-circle" style={{ color: '#5664D2' }} />
						</Button>
					) : <Link to={`/doc-user/show/${obj.id}`} className="btn btn-table-action mr-2">
						<i className="fas fa-eye" style={{ color: "#5664D2" }} />
					</Link>}
				</div>
			);
		} else {
			return "a";
		}
	};

	export() {
		this.dt.exportCSV();
	}

	emptyMessageReturn() {
		return (
			<div className="boxImage">
				<img src={smile} alt="smile" width={48} height={48} />

				<p className="subtitleNoItems">{this?.props?.type === 'agreement' ? 'Nenhum acordo em inadimplência encontrado' : 'Nenhuma cobrança em inadimplência encontrada'}</p>
			</div>
		)
	}

	docUrl = (doc) => {
		let match = doc?.file_desc?.url.match(/\/([^\/?#]+)$/);

		// Verifica se houve uma correspondência e retorna o nome do arquivo, se existir
		match = match ? match[1] : null;

		return (
			<div style={{ textAlign: "center" }}>
				<a href={doc?.file_desc?.url} target="_blank" rel="noopener noreferrer">{match}</a>
			</div>
		);
	}

	getValue = (doc) => {
		if (doc?.payments_value) {
			return formatBRL(doc.payments_value)
		} else {
			return ''
		}
	}

	getStatusProposition = (obj) => {
		let status = '';
		switch (obj?.details?.warranty_type?.trim()) {
			case 'SECURITY_DEPOSIT':
			case 'SECURITY_DEPOSITY':
				status = 'Caução';
				break;
			case 'FREE':
				status = 'Gratuito';
				break;
			case 'WARRANTY':
				status = 'Garantia';
				break;
			case 'WARRANTY_GUARANTEE_DAMAGE':
				status = 'Garantia de danos';
				break;

			default:
				status = obj?.status;
				break;
		}
		if (!status) {
			switch (obj?.property?.warranty?.type.trim()) {
				case 'SECURITY_DEPOSIT':
				case 'SECURITY_DEPOSITY':
					status = 'Caução';
					break;
				case 'FREE':
					status = 'Gratuito';
					break;
				case 'WARRANTY':
					status = 'Garantia';
					break;
				case 'WARRANTY_GUARANTEE_DAMAGE':
					status = 'Garantia de danos';
					break;

				default:
					status = obj?.status;
					break;
			}
		}

		return (
			<div style={{ textAlign: 'center' }}>
				<p>{status}</p>
			</div>
		);
	};

	handleClose = () => this.setState({ showModal: false });
	handleShow = () => this.setState({ showModal: true });

	handleSubmit = (values) => {
		const data = {
			"description": values.description,
			"status": this.state.decision,
			"sentence_date": moment(values.sentence_date).format('YYYY-MM-DD'),
			"final_value": limparMoeda(values.final_value),
			"fees": {
				"type": values.fees_type,
				"value": typeof values.fees_value === 'string' ? limparMoeda(values.fees_value) : values.fees_value
			}
		}

		updateDocUser(this.state.selectedDocId, data).then(res => {
			if (res.status === 200) {
				this.handleClose();
				window.location.reload();
			}
		}).catch(err => {
			console.log(err);
			alert('Houve um erro, contate o suporte!')
		})
	};

	getInitialValues = () => {
		return {
			description: '',
			status: '',
			sentence_date: '',
			final_value: null,
			fees_type: null,
			fees_value: null,
			confirmation: false
		};
	};

	validateCobranca = (values) => {
		let map = new Map();

		map.set('description', 'descrição');
		map.set('sentence_date', 'data');
		map.set('final_value', 'valor final');
		map.set('fees_type', 'honorários');
		map.set('fees_value', 'honorários');

		let errors = createFormikValidation(map, values);

		if (!values.confirmation) {
			errors.confirmation = 'É necesário confimar a ação antes de prosseguir!';
		}

		console.log(errors);

		return errors;
	};

	getContractDocUser = (obj) => {
		return (
			<Link to={`/negotiation/show/${obj?.contract?.entity?.proposition_id}`} className="btn btn-table-action mr-2" style={{ color: '#007ad9' }}>
				{obj?.friendly_contract_code}
			</Link>
		)
	}

	render() {
		return (
			<>
				{/* <Modal centered show={this.state?.showModal} onHide={this.handleClose}>

					<Formik initialValues={this.getInitialValues()} validate={values => this.validateCobranca(values)} onSubmit={(values) => this.handleSubmit(values)}>
						{({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue, setFieldTouched }) => (
							<form noValidate={true} autoComplete="off" onSubmit={handleSubmit}>
								<Modal.Header closeButton={false}>
									<Modal.Title>Concluir cobrança jurídica</Modal.Title>
									{this.state?.errors ? (
										<div style={{ backgroundColor: '#FF3D60' }}>
										</div>
									) : null}
									<button onClick={(() => this.handleClose())} style={{ backgroundColor: 'transparent', color: '#8590A5', display: 'flex', alignItems: 'center', border: 'none', fontWeight: '900', fontSize: '18px' }}>
										X
									</button>
								</Modal.Header>
								<Modal.Body>
									<div>
										<div style={{ display: 'flex' }}>
											<div style={{ width: '50%' }}>
												<p style={{ marginBottom: '5px' }}>Decisão*</p>
												<div style={{ display: 'flex' }}>
													<button style={{ width: '66px', height: '36px', borderRadius: '4px', padding: '8px 12px 8px 12px', border: 'none', marginRight: '12px', backgroundColor: this.state.decision === 'WON' ? '#5664D2' : '#F1F5F7', color: this.state.decision === 'WON' ? '#FFFFFF' : '#505D69' }} onClick={(e) => { e.preventDefault(); this.setState({ decision: 'WON' }) }}>Ganho</button>
													<button style={{ width: '66px', height: '36px', borderRadius: '4px', padding: '8px 12px 8px 12px', border: 'none', backgroundColor: this.state.decision === 'LOST' ? '#5664D2' : '#F1F5F7', color: this.state.decision === 'LOST' ? '#FFFFFF' : '#505D69' }} onClick={(e) => { e.preventDefault(); this.setState({ decision: 'LOST' }) }}>Perdido</button>
												</div>

											</div>
											<div style={{ width: '50%' }}>
												<p style={{ marginBottom: '5px' }}>Data do recebimento*</p>
												<div style={{ display: 'flex' }}>
													<TextField className="mb-2" style={{ background: "#FFF", height: "40px" }} name={'sentence_date'} placeholder="00/00/0000"
														type="date" variant="outlined" value={values.sentence_date} InputLabelProps={{ shrink: true }} inputProps={{
															min: formatDate({ date: moment(), format: 'YYYY-MM-DD' })
														}}
														onChange={async e => { handleChange(e); }} onBlur={handleBlur} helperText={touched.sentence_date && errors.sentence_date}
														error={Boolean(touched.sentence_date && errors.sentence_date)} />
												</div>

											</div>
										</div>

										<div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
											<div style={{ width: '48%' }}>
												<p style={{ marginBottom: '5px' }}>Valor final*</p>
												<div style={{ display: 'flex' }}>
													<TextField name="final_value" placeholder={'R$ 0.000.000,00'} className={'input'} type="text" variant="outlined" value={values.final_value}
														onBlur={handleBlur} onChange={(e) => handleChange(formatBRLInput(e))}
														helperText={touched.final_value && errors.final_value}
														error={Boolean(touched.final_value && errors.final_value)}
													/>
												</div>

											</div>
											<div style={{ width: '48%' }}>
												<p style={{ marginBottom: '5px' }}>Tipo de honorários*</p>
												<div style={{ display: 'flex', flexDirection: 'column' }}>
													<select
														style={{ background: "#FFF", height: "40px" }}
														name="fees_type"
														onChange={(e, value) => {
															handleChange(e);
															setFieldValue('fees_value', null)
														}}
														onBlur={handleBlur}
														value={values.fees_type}
														helperText={touched.fees_type && errors.fees_type}
														error={Boolean(touched.fees_type && errors.fees_type)}
														className="custom-select">
														<option key={null} value={null} hidden>Selecione</option>
														<option key="FIXED_VALUE" value="FIXED_VALUE">Valor fixo</option>
														<option key="PERCENTAGE" value="PERCENTAGE">Porcentagem</option>

													</select>
													{touched.fees_type && errors.fees_type ? (
														<>
															<p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error">{errors.fees_type}</p>
														</>
													) : null}
												</div>

											</div>
										</div>

										{values?.fees_type && values?.fees_type === 'FIXED_VALUE' ? (
											<div style={{ display: 'flex', flexDirection: 'column', marginTop: '12px' }}>
												<p style={{ marginBottom: '5px' }}>Valor fixo dos honorários*</p>
												<div style={{ display: 'flex' }}>
													<TextField name="fees_value" placeholder={'R$ 0,00'} className={'input'} type="text" variant="outlined" value={values.fees_value}
														onBlur={handleBlur} onChange={(e) => handleChange(formatBRLInput(e))}
														helperText={touched.fees_value && errors.fees_value}
														error={Boolean(touched.fees_value && errors.fees_value)}
													/>
												</div>
											</div>
										) : null}

										{values?.fees_type && values?.fees_type === 'PERCENTAGE' ? (
											<div style={{ display: 'flex', flexDirection: 'column', marginTop: '12px' }}>
												<p style={{ marginBottom: '5px' }}>Porcentagem dos honorários*</p>
												<div style={{ display: 'flex' }}>
													<TextField name="fees_value" placeholder={'%'} className={'input'} type="number" variant="outlined" value={values.fees_value}
														onBlur={handleBlur} onChange={(e) => {
															if (e.target.value >= 0 && e.target.value <= 100) {
																handleChange(e)
															}
														}}
														helperText={touched.fees_value && errors.fees_value}
														error={Boolean(touched.fees_value && errors.fees_value)}
													/>
												</div>
											</div>
										) : null}

										<div style={{ display: 'flex', flexDirection: 'column', marginTop: '12px' }}>
											<p style={{ marginBottom: '5px' }}>Descrição*</p>
											<div style={{ display: 'flex' }}>
												<TextField name="description" placeholder={'Digite uma descrição sobre o encerramento jurídica'} className={'input'} type="text" variant="outlined" value={values.description}
													onBlur={handleBlur} onChange={(e) => handleChange(e)}
													helperText={touched.description && errors.description}
													error={Boolean(touched.description && errors.description)}
												/>
											</div>
										</div>

										<div style={{ display: 'flex', marginTop: '12px', alignItems: 'center' }}>

											<div style={{ display: 'flex', flexDirection: 'column' }}>
												<Checkbox name="confirmation" style={{ paddingLeft: '0px' }} color="primary" checked={values.confirmation} onBlur={handleBlur} onChange={() => setFieldValue('confirmation', true)} helperText={touched.confirmation && errors.confirmation}
													error={Boolean(touched.confirmation && errors.confirmation)} />

											</div>
											<p style={{ marginLeft: '0px', marginBottom: '0px' }}>Confirmo que desejo concluir esta cobrança jurídica.</p>
										</div>
										{console.log(errors)}
										{touched.confirmation && errors.confirmation ? (
											<>
												<p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" style={{ color: '#f44336' }}>{errors.confirmation}</p>
											</>
										) : null}

									</div>
								</Modal.Body>
								<Modal.Footer>
									<button onClick={this.handleClose} style={{ backgroundColor: '#F1F5F7', fontFamily: 'Inter', border: 'none', color: '#505D69', padding: '0.47rem 0.75rem', borderRadius: '4px' }}>
										Cancelar
									</button>
									<Button type="submit" variant="primary" style={{ backgroundColor: '#5664D2', fontFamily: 'Inter', border: 'none' }}>
										Continuar
									</Button>
								</Modal.Footer>
							</form>
						)}
					</Formik>

				</Modal> */}

				<DataTable ref={(el) => this.dt = el} value={this.state.data} paginator={true} rows={this.state.rows} rowsPerPageOptions={this.props?.rows ? [10, 25, 50] : [5]} first={this.state.first} last={this.state.last}
					totalRecords={this.state.totalRecords} lazy={true} onPage={this.onPage} loading={this.state.loading} responsive={true} loadingIcon={`fas fa-sync fa-spin`} onSort={(e) => this.sortTable(e)}
					paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" emptyMessage={this.emptyMessageReturn}
					currentPageReportTemplate="Mostrando {first} até {last} de {totalRecords} registros" sortField={this.state.sortField} sortOrder={this.state.sortOrder}>
					{this.props.export ? this.export() : false}
					<Column field="contract" body={(obj) => this.getContractDocUser(obj)} header="Contrato" style={{ width: '180px' }} />
					<Column field="status" body={(obj) => this.getStatus(obj)} header="Status" style={{ width: '156px' }} />
					<Column field="created_by" body={(obj) => this.getUserName(obj)} header="Quem fez upload" style={{ width: '204px' }} />
					<Column field="value" body={(obj) => this.getValue(obj)} header="Valor" style={{ width: '144px' }} />
					<Column field="description" header="Descrição" />
					{/* <Column header="Ações" body={(obj) => this.columnOptionsCrud(obj)} style={{ width: '200px' }} /> */}
				</DataTable>
			</>
		);
	}
}
