/* eslint-disable no-useless-computed-key */
import React, { Component, createRef } from 'react';
import { Container, Card, CardBody, Row, Col, FormGroup, Label, TabContent, TabPane, Nav, NavItem, NavLink, Input, Button } from "reactstrap";
import BetterCircularProgress from '../../components/BetterCircularProgress';
import DataTableNegotiation from '../../components/datatables/DataTableNegotiation';
import classnames from 'classnames';
import { getPropertiesById, getComissioningParticipants, updateProperties as updateAnnouncement, } from '../../crud/announcements.crud';
import { getAllByParams as getAllPropositions, getAllPropositionsUser } from '../../crud/propositions.crud';
import { get as getAllBillings } from '../../crud/billings.crud';
import Breadcrumbs from '../../components/Breadcrumb';
import { Link } from "react-router-dom";
import { getAppliedByAnnouncement } from '../../crud/coupons.crud';
import moment from 'moment';
import BetterAlerts from '../../components/BetterAlerts';
import DataTableUserAnnouncements from '../../components/datatables/DataTableUserAnnouncements';
import { formatBRL, formatTelefone, getImovelTipoName } from '../../helpers/Utils';
import DataTableUserContracts from '../../components/datatables/DataTableUserContracts';
import { getAllByFilter as getContractByFilter, getAllByFilter as getAllContracts, get as getContract } from '../../crud/contracts.crud';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faPen, faUnlockKeyhole, faUser } from '@fortawesome/pro-regular-svg-icons';
import { faPause, faPlay } from '@fortawesome/pro-light-svg-icons';
import DataTableNegotiationAnnouncements from '../../components/datatables/announcements/DataTableNegotiationAnnouncements';
import DataTableContractsAnnouncements from '../../components/datatables/announcements/DataTableContractsAnnouncements';
import DataTableBillingsAnnouncements from '../../components/datatables/announcements/DataTableBillingsAnnouncements';
import imageNoAnnouncementPhoto from '../../assets/images/noFoundAnnouncement.png';
import { faLink } from '@fortawesome/pro-solid-svg-icons';
import { get as getUser } from '../../crud/users.crud';

class AnnouncementsShow extends Component {
	DataTableUserAnnouncementsRef = createRef();
	DataTableUserAnnouncementsNegotiationRef = createRef();
	DataTableUserContractsRef = createRef();

	state = {
		loading: false,
		activeTab: '1',
		data: null,
		success: false,
		successMessage: '',
		errorsMessage: '',
		coupon: null,
		commissioningParticipants: null,
		oldParticipants: null,
		addParticipantArray: [],
		comissionamento: false,
		detalhes: {},
		detailsCondo: [],
		usersEnvolvidos: [],
		contratoAtual: null,
		userCommissioning: null,
	};

	componentDidMount = () => {
		this.setState({ loading: true });

		getAppliedByAnnouncement(this.props.match.params.id).catch(err => {
			alert('Ocorreu um erro, entre em contato com o suporte.');
		}).then(res => {
			if (res?.status == 200) {
				if (res.data) {
					this.setState({ coupon: res.data });
				}
			}
		});

		this.fetchProperties();
	};

	toggleTab(tab) {
		if (this.state.activeTab !== tab) {
			this.setState({
				activeTab: tab
			});
		}
	};

	fetchProperties = () => {
		const isAreaExterna = ["penthouse", "barbecue", "garden", "pool", "gourmet_balcony"];
		const isCozinha = ["kitchen_cabinet", "fridge", "stove"];
		const isBanheiro = ["bathroom_cabinet", "glass_shower_box", "gas_shower", "electric_shower", "hidro"];
		const isServico = ["service_bathroom", "service_room", "washing_machine"];
		const isMobilia = ["room_cabinets", "bed", "decorated", "sofa"];
		const isOutros = ["air_conditioning", "extra_reversible_room"];

		this.setState({ loading: true });

		getPropertiesById(this.props.match.params.id).catch(err => {
			alert('Ocorreu um erro, entre em contato com o suporte.');
		}).then(async res => {
			if (res?.status == 200) {
				if (res.data) {
					let entriesDetails = Object.entries(res.data.data.details || {});
					let entriesCondoDetails = Object.entries(res.data.data.condominium_details || {});

					let detalhes = {
						externa: [],
						cozinha: [],
						banheiro: [],
						servico: [],
						mobilia: [],
						outros: [],
					};

					let detailsCondo = [];

					entriesDetails.map((details) => {
						let key = details[0];
						let ativo = details[1];

						if (ativo == true) {
							if (isAreaExterna.includes(key)) {
								detalhes.externa.push(key)
							}

							if (isCozinha.includes(key)) {
								detalhes.cozinha.push(key)
							}

							if (isBanheiro.includes(key)) {
								detalhes.banheiro.push(key)
							}

							if (isServico.includes(key)) {
								detalhes.servico.push(key)
							}

							if (isMobilia.includes(key)) {
								detalhes.mobilia.push(key)
							}

							if (isOutros.includes(key)) {
								detalhes.outros.push(key)
							}
						}
					})

					entriesCondoDetails.map((details) => {
						let key = details[0];
						let ativo = details[1];

						if (ativo == true) {
							detailsCondo.push(key);
						}
					})

					this.setState({ data: res.data.data, comissionamento: res.data.data?.comissioning, detalhes, detailsCondo });

					let usersEnvolvidos = [];

					if (res.data.data.user_id) {
						await getUser(res.data.data.user_id).then((responseUser) => {
							this.setState({ data: { ...res.data.data, user: responseUser.data.data } });
						});
					}

					getContractByFilter({ property_announcement_id: this.props.match.params.id, $select: ['id', 'tenant_parties', 'tenant_id'] }).catch(err => {
						console.log(err);
					}).then(async res => {
						if (res?.status == 200) {
							if (res.data.data.length > 0) {
								let contrato = res.data.data[0];

								if (contrato.tenant_id) {
									await getUser(contrato.tenant_id).then((res) => {
										usersEnvolvidos.push(res.data.data);
									});
								}

								if (contrato.tenant_parties.length > 0) {
									for await (const id of contrato.tenant_parties) {
										await getUser(id).then((res) => {
											usersEnvolvidos.push(res.data.data);
										});
									}
								}

								console.log(usersEnvolvidos);
								this.setState({ usersEnvolvidos });
							}
						}
					}).finally(() => {
						this.setState({ loading: false });
					})

					getComissioningParticipants({ announcement_id: this.props.match.params.id, $sort: { ['user_role']: 'admin' } }).catch(err => {
						console.log(err);
					}).then(res => {
						if (res?.status == 200) {
							if (res.data.data) {
								this.setState({ commissioningParticipants: res.data.data, oldParticipants: res.data.data, data: { ...this.state.data } })
							}
						}
						this.getUserUpdateComissioning();
					}).finally(() => {
						this.setState({ loading: false });
					})
				} else {
					this.setState({ loading: false });
				}
			}
		}).finally(() => {
		});
	};

	getDetalhesImovelType = (key) => {
		let result = '';

		const detalhes = [
			{ key: "penthouse", label: "Apartamento com cobertura" },
			{ key: "pool", label: "Piscina" },
			{ key: "kitchen_cabinet", label: "Armários na cozinha" },
			{ key: "barbecue", label: "Churrasqueira" },
			{ key: "bathroom_cabinet", label: "Armários no banheiro" },
			{ key: "extra_reversible_room", label: "Quarto extra reversível", },
			{ key: "air_conditioning", label: "Ar condicionado" },
			{ key: "glass_shower_box", label: "Box de vidro" },
			{ key: "hidro", label: "Banheira de hidromassagem" },
			{ key: "gas_shower", label: "Chuveiro à gás" },
			{ key: "service_bathroom", label: "Banheiro de serviço" },
			{ key: "electric_shower", label: "Chuveiro elétrico" },
			{ key: "stove", label: "Fogão incluso" },
			{ key: "room_cabinets", label: "Armário nos quartos" },
			{ key: "fridge", label: "Geladeira inclusa" },
			{ key: "bed", label: "Cama" },
			{ key: "garden", label: "Jardim" },
			{ key: "sofa", label: "Sofá" },
			{ key: "gourmet_balcony", label: "Varanda gourmet" },
			{ key: "washing_machine", label: "Máquina de lavar" },
			{ key: "service_room", label: "Quarto de serviço" },
			{ key: "decorated", label: "Imóvel decorado" },
		]

		detalhes.map((obj) => {
			if (obj.key == key) {
				result = obj.label
			}
		})

		return result;
	};

	getDetalhesCondominioType = (key) => {
		let result = '';

		const detalhes = [
			{ key: "gym", label: "Academia" },
			{ key: "pool", label: "Piscina" },
			{ key: "sauna", label: "Sauna" },
			{ key: "barbecue", label: "Churrasqueira" },
			{ key: "elevator", label: "Elevador" },
			{ key: "party_hall", label: "Salão de festas" },
			{ key: "playground", label: "Playground" },
			{ key: "gourmet_area", label: "Espaço gourmet" },
			{ key: "sports_court", label: "Quadra de esportes" },
			{ key: "building_laundry", label: "Lavanderia" },
		];

		detalhes.map((obj) => {
			if (obj.key == key) {
				result = obj.label
			}
		})

		return result;
	};

	addParticipant = () => {
		const defaultObj = {
			"user_id": null,
			"announcement_id": this.props.match.params.id,
			"user_role": "commissioned",
			"additional_values": {
				"first_month_payment": false,
				"first_month_value": 0
			},
			"commission_percentage": 0,
			"commission_status": "AWAITING_APPROVAL"
		}
		const prevArr = [...this.state.addParticipantArray];
		prevArr.push(defaultObj);
		this.setState({ addParticipantArray: prevArr })
	}

	getAnnouncementCustomLink = (announcement) => {
		const currentUrl = window.location.href;

		let preUrl = '';
		let complement = '';

		const localEnvironment = currentUrl.toLowerCase().includes('localhost');
		const devEnvironment = currentUrl.toLowerCase().includes('dev-admin');

		//preUrl
		if (localEnvironment || devEnvironment) {
			preUrl = 'https://dev-app.mellro.com'
		} else {
			preUrl = 'https://mellro.com'
		}

		//Complement
		if (announcement?.address?.city && announcement?.address?.neighbourhood) {
			complement = `/imovel/brasil/${announcement.address.city}/${announcement?.address?.neighbourhood}/${announcement.id}`;
		} else {
			complement = `/imovel/${announcement?.id}`;
		}

		return window.open(preUrl + complement)
	};

	getAnnouncementCustomLinkText = (announcement) => {
		const currentUrl = window.location.href;

		let preUrl = '';
		let complement = '';

		const localEnvironment = currentUrl.toLowerCase().includes('localhost');
		const devEnvironment = currentUrl.toLowerCase().includes('dev-admin');

		//preUrl
		if (localEnvironment || devEnvironment) {
			preUrl = 'https://dev-app.mellro.com'
		} else {
			preUrl = 'https://mellro.com'
		}

		//Complement
		if (announcement?.address?.city && announcement?.address?.neighbourhood) {
			complement = `/imovel/brasil/${announcement.address.city}/${announcement?.address?.neighbourhood}/${announcement.id}`;
		} else {
			complement = `/imovel/${announcement?.id}`;
		}

		return (preUrl + complement)
	};

	fetchContracts = (data) => {
		let params = { ...data };

		params.property_announcement_id = this.props.match.params.id;

		return getAllContracts(params).catch((err) => {
			console.log(err);
		});
	}

	fetchAnnouncementsBillings = (data) => {
		let params = { ...data };

		params.property_announcement_id = this.props.match.params.id;

		return getAllBillings(params).catch((err) => {
			console.log(err);
		});
	};

	fetchPropositionsUser = (data) => {
		let params = { ...data };

		params.property_announcement_id = this.props.match.params.id;

		return getAllPropositions(params).catch((err) => {
			console.log(err);
		});
	};

	getWarranty = (key) => {
		switch (key) {
			case 'FREE':
				return 'Grátis';

			case 'WARRANTY':
				return 'Pagamento';


			case 'WARRANTY_GUARANTEE_DAMAGE':
				return 'Completa';

			default:
				return 'Não identificado';
		}
	};

	getUserType = (role) => {
		if (role === 'admin') {
			return 'Administrador'
		} else if (role === 'commissioned') {
			return 'Comissionado'
		} else if (role === 'landlord') {
			return 'Dono do imóvel'
		}
	}

	handlePublishAnnouncement = () => {
		this.setState({ loading: true });
		updateAnnouncement(this.props.match.params.id, { is_published: !this.state.data.is_published }).catch(err => {
		}).then(res => {
			if (res?.status == 200) {
				this.fetchProperties();
			}
		});
	}

	getUserUpdateComissioning = async () => {
		const { commissioningParticipants } = this.state;

		const updatedUserId = commissioningParticipants?.[0]?.updated_by;
		const createdUserId = commissioningParticipants?.[0]?.created_by;

		try {
			let userId = updatedUserId ?? createdUserId;

			if (userId) {
				const response = await getUser(userId);
				const user = response?.data?.data;

				this.setState({ userCommissioning: user });
			}
		} catch (error) {
			console.log(error);
		}
	}

	getTimeCommissioning = () => {
		const { commissioningParticipants } = this.state;

		const updatedDate = commissioningParticipants?.[0]?.updated_at;
		const createdDate = commissioningParticipants?.[0]?.created_at;

		const date = updatedDate ?? createdDate;

		return moment(date).format('DD/MM/YYYY - HH:mm:ss');
	}

	transformPercentageCommissioned = (value) => {
		let liquidRent = this.state.data?.values?.rent;
		let percentage = 0;

		if (this.state.data?.warranty?.type === "WARRANTY_GUARANTEE_DAMAGE") {
			percentage = 0.1;
		} else {
			percentage = 0.07;
		}

		if (this.state.coupon) {
			percentage = (percentage - (Number(this.state.coupon.discountValue) / 100)).toFixed(2);
		}

		liquidRent = ((liquidRent - (liquidRent * percentage)) * (value / 100)).toFixed(2);

		return formatBRL(liquidRent);
	}

	render() {
		const BoxInfo = ({ title, href, children, classNameBody, preenchido, noTag = false, indicadorNumero, comissionamento, cardComissionamento }) => {
			return (
				<div className='boxInfoUser'>
					<div className='headerBoxInfoUser'>
						<div className='title-tag'>
							<h4>{title}</h4>

							{indicadorNumero && (
								<div className='indicadorUsuarios'>
									{(indicadorNumero) ?? 0}
								</div>
							)}

							{!cardComissionamento ? null :
								comissionamento ? (
									<div className='tag'>
										Sim
									</div>
								) :
									<div className='tag' style={{ backgroundColor: '#FCB92C' }}>
										Não
									</div>
							}


							{noTag ? null : preenchido ?
								<div className='tag'>
									Preenchido
								</div>
								:
								<div className='tag' style={{ backgroundColor: '#FCB92C' }}>
									Pendente
								</div>
							}
						</div>


						{(cardComissionamento && this.state.data?.is_rented) ? null :
							<a href={href} className='btnSecundary'>
								EDITAR
								<FontAwesomeIcon icon={faPen} />
							</a>}
					</div>

					<div className={`${classNameBody} boxInfoBody`}>
						{children}
					</div>
				</div>
			)
		};

		const address = this?.state?.data?.address;

		return (
			<BetterCircularProgress loading={this.state.loading}>
				<div className="page-content container-page userShow userNew userIndex anuncio">
					<Container fluid>
						<BetterAlerts errors={this.state.errors} success={this.state.success} />

						<div className='header'>
							<h1>Imóvel</h1>

							<Breadcrumbs breadcrumbItems={[{ link: '/announcements/index', title: 'Imóveis' }, { link: `/users/show/${this.state.data?.id}`, title: `ID ${this.props.match.params.id}` }]} />
						</div>

						<div className='boxUser'>
							<div className='headerUser'>
								{this.state.data?.images?.[0]?.medium?.url ?
									<div className='userPhoto' style={{ backgroundImage: `url(${this.state.data?.images?.[0]?.medium?.url})` }} />
									:
									<div className='userPhoto noPhotoAnnouncement' />
								}

								<div className='tagsAssets groupAlign'>
									<h3>{`${address?.street} ${address?.number}, ${address?.complement ?? ''} ${address?.neighbourhood ? `, ${address?.neighbourhood}` : ''} - ${address?.city}/${address?.state}`}</h3>

									<div className='tagGroup'>
										{this?.state?.data?.friendly_code && (
											<div className='tag' style={{ backgroundColor: '#DADBDD', color: '#505D69' }}>{this?.state?.data?.friendly_code}</div>
										)}

										<div className='tag' style={{ backgroundColor: this?.state?.data?.is_published ? '#1CBB8C' : '#5664D2' }}>{this?.state?.data?.is_published ? 'Anunciado' : 'Não anunciado'}</div>
									</div>
								</div>

								<Link to={`/users/show/${this.state?.data?.user?.id}`} className='lastAssets'>
									<FontAwesomeIcon icon={faUser} />
									<p>{this?.state?.data?.user?.name}</p>
								</Link>
							</div>

							<div className='bodyAssets'>
								<div className='boxInformation'>
									<p>Data de cadastro</p>
									<b>{moment(this?.state?.data?.created_at).format('DD/MM/YY HH:mm:ss')}</b>
								</div>

								<div className='boxInformation'>
									<p>Atualizado em</p>
									<b>{moment(this.state?.data?.updated_at).format('DD/MM/YY HH:mm:ss')}</b>
								</div>

								<div className='boxInformation'>
									<p>Local de origem</p>
									<b>{this.state?.data?.origin == 'INTEGRATION' ? 'Importação' : this.state?.data?.metadata?.lead_source ?? 'Orgânico'}</b>
								</div>

								<div className='boxInformation'>
									<p>Anúncio Mellro</p>
									<b className='inBlue' onClick={() => this.getAnnouncementCustomLink(this.state.data)}>
										Link anúncio

										<FontAwesomeIcon icon={faLink} style={{ marginLeft: 8.5 }} />
									</b>
								</div>

								{this.state.coupon?.code && (
									<div className='boxInformation'>
										<p>Cupom aplicado</p>

										<div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
											<Link className='inBlue' to={`/coupons/show/${this.state?.coupon?.couponId}`}>
												{this.state.coupon.code}
											</Link>

											<div className='tag' style={{ backgroundColor: '#1CBB8C' }}>{this.state.coupon.discountType === 'PERCENTAGE' ? `${this.state.coupon.discountValue}%` : formatBRL(this.state.coupon.discountValue)}</div>
										</div>
									</div>
								)}
							</div>

							<div className='bodyAssets envolvidosContainer'>
								<div className='boxInformation'>
									<p>ENVOLVIDOS</p>
								</div>

								<div className='boxInformationContainerEnvolvidos'>
									<div className='envolvidoBox'>
										<div className='userPhoto' style={{ backgroundImage: `url(${this.state.data?.user?.profile_image?.medium?.url})` }} />

										<div className='envolvidoText'>
											<div className='tag' style={{ backgroundColor: '#5664D2' }}>Proprietário</div>

											<h2>{this.state.data?.user?.name}</h2>

											<p>{this.state.data?.user?.email}</p>
											<p>{this?.state?.data?.user?.phone ? formatTelefone(this?.state?.data?.user?.phone ?? 0) : null}</p>
										</div>
									</div>

									{this.state?.usersEnvolvidos?.map((inquilino, index) => {
										return (
											<div className='envolvidoBox'>
												<div className='userPhoto' style={{ backgroundImage: `url(${inquilino?.profile_image?.medium?.url})` }} />

												<div className='envolvidoText'>
													<div className='tag' style={{ backgroundColor: '#5664D2' }}>Inquilino</div>

													<h2>{inquilino?.name}</h2>

													<p>{inquilino?.email}</p>
													<p>{formatTelefone(inquilino?.phone ?? 0)}</p>
												</div>
											</div>
										)
									})}
								</div>
							</div>

							<div className='footerAssets'>
								<button className='buttonPrimary' onClick={this.handlePublishAnnouncement}>
									{this.state?.data?.is_published ?
										<>
											DESPUBLICAR
											<FontAwesomeIcon icon={faPause} />
										</>
										:
										<>
											PUBLICAR
											<FontAwesomeIcon icon={faPlay} />
										</>
									}
								</button>

								<Link className='btnSecundary' to={{ pathname: `/negotiation/new`, params: { announcement: this.state.data } }}>
									NOVA NEGOCIAÇÃO
								</Link>

								{/* <button className='btnSecundary'>
									CRM
									<FontAwesomeIcon icon={faArrowUpRightFromSquare} />
								</button> */}
							</div>
						</div>

						<div className='box'>
							<Nav tabs className="nav-tabs-custom" role="tablist">
								<NavItem>
									<NavLink id="info-tab" className={classnames({ active: this.state.activeTab === '1' })} onClick={() => { this.toggleTab('1'); }}>
										Cadastro
									</NavLink>
								</NavItem>

								<NavItem>
									<NavLink id="announcements-tab" className={classnames({ active: this.state.activeTab === '2' })} onClick={() => { this.toggleTab('2'); }}>
										<div className='tabName'>Histórico
											<div className='indicadorUsuarios'>
												{(
													Number(this.DataTableUserAnnouncementsRef?.current?.state?.totalRecords ?? 0) +
													Number(this.DataTableUserAnnouncementsNegotiationRef?.current?.state?.totalRecords ?? 0) +
													Number(this.DataTableUserContractsRef?.current?.state?.totalRecords ?? 0)
												) ?? 0}
											</div>
										</div>
									</NavLink>
								</NavItem>
							</Nav>

							<BetterCircularProgress loading={this.state.loading}>
								<TabContent activeTab={this.state.activeTab}>
									<TabPane tabId="1" role="tabpanel">
										<h2>Informações cadastrais</h2>

										<div className='boxInfos-container'>
											<BoxInfo title="ENDEREÇO" noTag href={`/announcements/edit/${this.state.data?.id}?tab=1`}>
												<div className='boxInformationRow'>
													<div className='boxInformation'>
														<p>Endereço</p>
														<b>{this?.state?.data?.address?.street}</b>
													</div>
												</div>

												<div className='boxInformationRow'>
													<div className='boxInformation'>
														<p>Número</p>
														<b>{this?.state?.data?.address?.number}</b>
													</div>

													<div className='boxInformation'>
														<p>Complemento</p>
														<b>{this?.state?.data?.address?.complement ?? '--'}</b>
													</div>
												</div>

												<div className='boxInformationRow'>
													<div className='boxInformation'>
														<p>Bairro</p>
														<b>{this?.state?.data?.address?.neighbourhood}</b>
													</div>

													<div className='boxInformation'>
														<p>Cidade</p>
														<b>{this?.state?.data?.address?.city}</b>
													</div>
												</div>
											</BoxInfo>

											<BoxInfo title="INFORMAÇÕES" noTag href={`/announcements/edit/${this.state.data?.id}?tab=2`}>
												<div className='boxInformationRow'>
													<div className='boxInformation'>
														<p>Área</p>
														<b>{this?.state?.data?.info?.area}m²</b>
													</div>

													<div className='boxInformation'>
														<p>Tipo</p>
														<div className='tag' style={{ backgroundColor: '#5664D2' }}>
															{getImovelTipoName(this?.state?.data?.info?.type)}
														</div>
													</div>
												</div>

												<div className='boxInformationRow'>
													<div className='boxInformation'>
														<p>Quartos</p>
														<b>{this?.state?.data?.info?.rooms}</b>
													</div>

													<div className='boxInformation'>
														<p>Banheiros</p>
														<b>{this?.state?.data?.info?.bathrooms}</b>
													</div>

													<div className='boxInformation'>
														<p>Garagens</p>
														<b>{this?.state?.data?.info?.car_slots}</b>
													</div>
												</div>

												<div className='boxInformation'>
													<p>Animais</p>
													<div className='tag' style={{ backgroundColor: '#5664D2' }}>
														{this?.state?.data?.details?.pet_friendly ? 'Permitido' : 'Não permitido'}
													</div>
												</div>
											</BoxInfo>
										</div>

										<div className='boxInfos-container boxInfos-container-unico'>
											<BoxInfo title="DETALHES" noTag href={`/announcements/edit/${this.state.data?.id}?tab=3`}>
												<Row className='content-box'>
													<Col md={4}>
														<h5>ÁREA EXTERNA</h5>

														{this.state?.detalhes?.externa?.map((detalhe) => (
															<div className='tag' style={{ backgroundColor: '#5664D2' }}>
																{this.getDetalhesImovelType(detalhe)}
															</div>
														))}
													</Col>

													<Col md={4}>
														<h5>COZINHA</h5>

														{this.state?.detalhes?.cozinha?.map((detalhe) => (
															<div className='tag' style={{ backgroundColor: '#5664D2' }}>
																{this.getDetalhesImovelType(detalhe)}
															</div>
														))}
													</Col>

													<Col md={4}>
														<h5>BANHEIRO</h5>

														{this.state?.detalhes?.banheiro?.map((detalhe) => (
															<div className='tag' style={{ backgroundColor: '#5664D2' }}>
																{this.getDetalhesImovelType(detalhe)}
															</div>
														))}
													</Col>
												</Row>

												<Row style={{ marginTop: 32 }} className='content-box'>
													<Col md={4}>
														<h5>SERVIÇO</h5>

														{this.state?.detalhes?.servico?.map((detalhe) => (
															<div className='tag' style={{ backgroundColor: '#5664D2' }}>
																{this.getDetalhesImovelType(detalhe)}
															</div>
														))}
													</Col>

													<Col md={4}>
														<h5>MOBÍLIA</h5>

														{this.state?.detalhes?.mobilia?.map((detalhe) => (
															<div className='tag' style={{ backgroundColor: '#5664D2' }}>
																{this.getDetalhesImovelType(detalhe)}
															</div>
														))}
													</Col>

													<Col md={4}>
														<h5>OUTROS</h5>

														{this.state?.detalhes?.outros?.map((detalhe) => (
															<div className='tag' style={{ backgroundColor: '#5664D2' }}>
																{this.getDetalhesImovelType(detalhe)}
															</div>
														))}
													</Col>
												</Row>

												{this.state.detailsCondo.length > 0 &&
													<Row style={{ marginTop: 32 }} className='content-box'>
														<Col md={12}>
															<h5>CONDOMÍNIO</h5>

															<Row>
																{this.state?.detailsCondo?.map((detalhe) => (
																	<Col md={4}>
																		<div className='tag' style={{ backgroundColor: '#5664D2' }}>
																			{this.getDetalhesCondominioType(detalhe)}
																		</div>
																	</Col>
																))}
															</Row>

														</Col>
													</Row>}
											</BoxInfo>
										</div>

										<div className='boxInfos-container boxInfos-container-unico'>
											<BoxInfo title="DESCRIÇÃO" noTag href={`/announcements/edit/${this.state.data?.id}?tab=7`}>
												{/* <Row className='content-box'>

													<div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', alignItems: 'center', rowGap: 12, gap: 12 }}>
														
													</div>

												</Row> */}

												<p style={{ whiteSpace: 'pre-line' }}>{this.state?.data?.description}</p>
											</BoxInfo>
										</div>


										<div className='boxInfos-container boxInfos-container-unico'>
											<BoxInfo title="FOTOS" noTag indicadorNumero={this.state?.data?.images.length} href={`/announcements/edit/${this.state.data?.id}?tab=4`}>
												<Row className='content-box' style={this.state?.data?.images.length > 0 ? {} : { justifyContent: 'center', padding: '32px 0' }}>
													{this.state?.data?.images.length > 0 ?
														<div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', alignItems: 'center', rowGap: 12, gap: 12 }}>
															{this.state?.data?.images.map((element, index) => (
																<div key={index} style={{ width: '200px', height: '200px', borderRadius: '5px', position: 'relative' }}>
																	<img src={element?.secure_url} style={{ width: '100%', height: '100%', borderRadius: '5px' }} alt="Img" onClick={() => window.open(element?.secure_url)} />
																</div>
															))}
														</div>
														:
														<div className='no-images-box'>
															Nenhum registro encontrado
														</div>
													}
												</Row>
											</BoxInfo>
										</div>

										<h2>Financeiro</h2>

										<div className='boxInfos-container'>
											<BoxInfo title="VALORES" noTag href={`/announcements/edit/${this.state.data?.id}?tab=5`}>
												<div className='boxInformationRow' style={{ gap: 8 }}>
													<div className='boxInformation'>
														<p>Garantia</p>

														<div className='tag' style={{ backgroundColor: '#5664D2' }}>
															{this.getWarranty(this.state?.data?.warranty?.type)}
														</div>
													</div>
												</div>

												<div className='boxInformationRow' style={{ gap: 16 }}>
													<div className='boxInformation'>
														<p>Valor do aluguel</p>

														<b>{formatBRL(this.state?.data?.values?.rent)}</b>
													</div>

													<div className='boxInformation'>
														<div style={{ display: 'flex', gap: 8, alignItems: 'end' }}>
															<div>
																<p>IPTU</p>

																<b>{formatBRL(this.state?.data?.values?.iptu ?? 0)}</b>
															</div>

															<div className='tag' style={{ backgroundColor: '#5664D2' }}>
																{this.state?.data?.tenant_pays?.iptu ? 'Inquilino Paga' : 'Proprietário paga'}
															</div>
														</div>
													</div>
												</div>

												<div className='boxInformation'>
													<div style={{ display: 'flex', gap: 8, alignItems: 'end' }}>
														<div>
															<p>Condomínio</p>

															<b>{formatBRL(this.state?.data?.values?.condo ?? 0)}</b>
														</div>

														<div className='tag' style={{ backgroundColor: '#5664D2' }}>
															{this.state?.data?.tenant_pays?.condo ? 'Inquilino Paga' : 'Proprietário paga'}
														</div>
													</div>
												</div>
											</BoxInfo>

											<BoxInfo title="COMISSÕES" cardComissionamento classNameBody={this.state.comissionamento ? 'comissionamento-body' : `comissionamento-nao-configurado`} comissionamento={this.state.comissionamento} noTag href={`/announcements/edit/${this.state.data?.id}?tab=6`}>
												{this.state.comissionamento ?
													<>
														{this.state?.commissioningParticipants?.map((participant, index) =>
															<div className='commissionedBox'>
																<div className='boxInformationRow comissioningRow' key={index} style={{ gap: 8 }}>
																	<Link to={`/users/show/${participant?.user_id}`}>{participant.user.name}</Link>

																	<div className='tag' style={{ backgroundColor: '#5664D2' }}>
																		{this.getUserType(participant.user_role)}
																	</div>

																	{participant.commission_status == "AWAITING_APPROVAL" && <div className='tag' style={{ backgroundColor: '#8590A5' }}>Aguardando</div>}
																	{participant.commission_status == "APPROVED" && <div className='tag' style={{ backgroundColor: '#1CBB8C' }}>Aceito</div>}
																	{participant.commission_status == "REFUSED" && <div className='tag' style={{ backgroundColor: '#FF3D60' }}>Recusado</div>}
																</div>

																<div className='text-comissioning'>
																	{participant?.additional_values?.first_month_payment ?
																		<p>
																			<b>R$ {participant?.additional_values?.first_month_value}</b> do primeiro aluguel
																		</p>
																		: ''}

																	<p><b>{this.transformPercentageCommissioned(participant?.commission_percentage)}</b> do aluguel mensal</p>


																	<p>aceito às <b>{participant?.accepted_at ? moment(participant?.accepted_at).format('DD/MM/YYYY - HH:mm:ss') : '--'}</b></p>
																</div>
															</div>
														)}

														<div className='commissioningInfos'>
															<div className='comissioningRow'>
																<p>Configurado por</p>
																<Link
																	to={`/users/show/${this.state.userCommissioning?.id}`}
																	style={{
																		display: 'inline-block',
																		maxWidth: '172px',
																		whiteSpace: 'nowrap',
																		overflow: 'hidden',
																		textOverflow: 'ellipsis',
																	}}
																>
																	{this.state.userCommissioning?.name ?? 'Usuário não identificado'}
																</Link>
															</div>
															<div>
																<p>Última atualização:</p>
																<p style={{ fontWeight: '600', color: '#343A40' }}>{this.getTimeCommissioning()}</p>
															</div>
														</div>
													</>
													:
													<p className='nao-configurado'>
														Não configurado
													</p>
												}

											</BoxInfo>
										</div>
									</TabPane>

									<TabPane tabId="2" role="tabpanel">
										<Col md={12}>
											<div className='datatable-box' style={{ marginTop: 32 }}>
												<DataTableNegotiationAnnouncements noDelete fetchEvent={this.fetchPropositionsUser} crudButtons={true} user={this.props.match.params.id}
													crudUrl="/negotiation" idRow={`id`} friendlyCode={this.state?.data?.friendly_code} noShow titleTable={'Negociações'} ref={this.DataTableUserAnnouncementsNegotiationRef} />
											</div>
										</Col>

										<Col md={12}>
											<div className='datatable-box' style={{ marginTop: 32 }}>
												<DataTableContractsAnnouncements noDelete fetchEvent={this.fetchContracts} crudButtons={true}
													crudUrl="/negotiation" idRow={`proposition_id`} friendlyCode={this.state?.data?.friendly_code} noShow titleTable={'Contratos'} user={this.props.match.params.id} ref={this.DataTableUserContractsRef} />
											</div>
										</Col>

										<Col md={12}>
											<div className='datatable-box' style={{ marginTop: 32 }}>
												<DataTableBillingsAnnouncements noDelete fetchEvent={this.fetchAnnouncementsBillings} crudButtons={true} property_announcement_id={this.props.match.params.id}
													crudUrl="/billing" idRow={`id`} friendlyCode={this.state?.data?.friendly_code} noShow titleTable={'Pagamentos'} ref={this.DataTableUserAnnouncementsRef} />
											</div>
										</Col>
									</TabPane>
								</TabContent>
							</BetterCircularProgress>
						</div>
					</Container>
				</div>
			</BetterCircularProgress>
		);
	}
}

export default AnnouncementsShow;